import * as React from "react";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";

const ContentEditor = ({ context }) => {
  const handleEditorChange = (event) => {
    console.log({
      event: event,
    });
  };

  return (
    <Editor
      tinymceScriptSrc={"/static/tinymce/tinymce.min.js"}
      initialValue={undefined !== context ? context.name : "butt"}
      init={{
        height: 500,
        menubar: true,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor |" +
          "alignleft aligncenter alignright alignjustify |" +
          "bullist numlist outdent indent | removeformat | help",
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

ContentEditor.propTypes = {
  context: PropTypes.shape({
    name: PropTypes.string
  }),
};

export default ContentEditor;
