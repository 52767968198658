import {
  managementRequestedResetMrfAccessPin,
  managementCompletedResetMrfAccessPin,
} from "../../../../redux/reducer";

import { resetMrfAccessPin } from '../../../../services/CurbCycleApi';

export const resetMrfAccessPinThunk = (payload) => dispatch => {
  dispatch(managementRequestedResetMrfAccessPin());
  resetMrfAccessPin(payload)
    .then(result => {
      dispatch(managementCompletedResetMrfAccessPin(result));
    })
    .catch(() => {
      dispatch(managementCompletedResetMrfAccessPin({}));
    });
};

export default resetMrfAccessPinThunk;
