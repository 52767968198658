import React, { useRef, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// material-ui components
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import useSplitButtonStyleIm from "../../../../../../assets/jss/material-dashboard-react/components/splitButtonStyleIm";
import { useHistory } from "react-router-dom";

export default function SplitButton(props) {
  const classes = useSplitButtonStyleIm();
  const {
    actionOptions,
    label,
    color,
    round,
    children,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
  });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  // const [selectedIndex, setSelectedIndex] = useState(null);
  // let selectedIndex = null;
  const history = useHistory();

  const handleMenuItemClick = (event, index) => {
    // let selectedIndex = null;
    // selectedIndex = index;
    setOpen(false);
    // console.log("Index", index);
    // console.log("event", event.target);
    // console.log({ object: actionOptions.options[index] });
    history.push({
      pathname: actionOptions.options[index].option_link,
      state: actionOptions.options[index].option_state,
    });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="flex-start">
      <Grid item xs={12}>
        <ButtonGroup
          style={{ margin: 0, padding: 0 }}
          className={btnClasses}
          variant="contained"
          // color={color}
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            {...rest}
            classes={muiClasses}
            style={{ margin: 0, padding: 5, paddingLeft: 15, paddingRight: 15 }}
            className={btnClasses}
            onClick={handleToggle}
            size="small"
          >
            {label}
          </Button>
          <Button
            {...rest}
            classes={muiClasses}
            style={{ margin: 0, padding: 5, paddingRight: 0 }}
            className={btnClasses}
            // color={color}
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          style={{ zIndex: 9 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {actionOptions.options.map((
                      option,
                      index // eslint-disable-line react/prop-types
                    ) => (
                      <MenuItem
                        key={option.option_name}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option.option_name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}

SplitButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "transparent",
    "gray",
  ]),
  option_link: PropTypes.string,
  option_name: PropTypes.string,
  option_state: PropTypes.any,
  actionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      block: PropTypes.bool,
      children: PropTypes.node,
      className: PropTypes.string,
      disabled: PropTypes.bool,
      justIcon: PropTypes.bool,
      label: PropTypes.string,
      link: PropTypes.bool,
      muiClasses: PropTypes.object,
      round: PropTypes.bool,
      simple: PropTypes.bool,
      size: PropTypes.oneOf(["sm", "lg"]),
    })
  ),
};
