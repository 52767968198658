import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { ItemTypes } from "./../../constants/items";
import { useDrag, useDrop } from "react-dnd";
// import * as d3 from "d3";
// import * as d3Transform from "d3-transform";

function CustomTreeItem({
  moveNodeCallback,
  treeItemType,
  onClickCallback,
  onContextMenuCallback,
  pathIdentifier,
  uuid,
  label,
  children,
}) {
  const ref = useRef(null);

  const whatCanBeAccepted = () => {
    return ItemTypes.TREE_ITEM;
  };

  const canMoveItem = (draggedItemTreeType, destinationItemTreeType) => {
    return true;
    // switch(destinationItemTreeType) {
    //   case ItemTypes.TREE_ITEM:
    //     return (draggedItemTreeType === ItemTypes.TREE_ITEM);
    //   default:
    //     return false;
    // }
  };

  const moveItem = (
    dragIndex,
    hoverIndex,
    draggedItemTreeType,
    destinationItemTreeType,
    draggedItem
  ) => {
    console.log(
      {
        dragIndex: dragIndex,
      },
      {
        hoverIndex: hoverIndex,
      },
      {
        draggedItemTreeType: draggedItemTreeType,
      },
      {
        destinationItemTreeType: destinationItemTreeType,
      },
      {
        draggedItem: draggedItem,
      }
    );
    if (!isDragging) {
      moveNodeCallback(dragIndex, hoverIndex, false, draggedItem);
    }
  };

  const [{ handlerId }, drop] = useDrop({
    accept: whatCanBeAccepted(),
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.pathIdentifier;
      const hoverIndex = pathIdentifier;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // more rules about the moving a nested type to another...
      const draggedItemTreeType = item.treeItemType;
      const destinationItemTreeType = treeItemType;

      if (!canMoveItem(draggedItemTreeType, destinationItemTreeType)) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(
        dragIndex,
        hoverIndex,
        draggedItemTreeType,
        destinationItemTreeType,
        item
      );
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.pathIdentifier = hoverIndex;
    },
  });

  const [{ opacity, isDragging }, drag] = useDrag({
    item: {
      type: `${ItemTypes.TREE_ITEM}`,
      treeItemType: treeItemType,
      uuid,
      pathIdentifier,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
      isDragging: monitor.isDragging(),
    }),
  });

  const doOnClickCallback = () => {
    // console.log(ref);
    // console.log({pathIdentifier: pathIdentifier});
    onClickCallback(uuid, pathIdentifier);
  };

  const doOnContextMenuCallback = () => {
    // console.log(ref);
    // console.log({pathIdentifier: pathIdentifier});
    onContextMenuCallback(uuid, pathIdentifier);
  };

  const startDrag = (event) => {
    setIsActiveNode(true);
    console.log({
      theFunction: "startDrag",
      event: event,
      currentTarget: event.currentTarget,
    });
  };

  const doDrag = (event) => {
    event.preventDefault();
    if (isActiveNode) {
      // const oG = d3.select(".rd3t-g");
      // const oGTransform = d3.select(".rd3t-g").attr("transform");

      // console.log({
      //   oG: oG,
      //   oGTransform: oGTransform,
      //   d3.select(`#node-${uuid}`): d3.select(`#node-${uuid}`),
      //   "event.currentTarget.getBoundingClientRect()": event.currentTarget.getBoundingClientRect(),
      // });

      const dims = event.currentTarget.getBoundingClientRect();
      const rawX = event.clientX - dims.left;
      const rawY = event.clientY - dims.top;
      const x = (rawX / dims.width) * 1344;
      const y = (rawY / dims.height) * 502;

      // console.log({
      //   theFunction: "doDrag",
      //   event: event,
      //   currentTarget: event.currentTarget,
      //   xNow: cX,
      //   yNow: cY,
      //   xNext: x,
      //   yNext: y,
      // });

      setCx(x);
      setCy(y);
    }
  };

  const endDrag = (event) => {
    if (isActiveNode) {
      setIsActiveNode(false);
      console.log({
        theFunction: "endDrag",
        event: event,
        currentTarget: event.currentTarget,
      });
    }
  };

  const [isActiveNode, setIsActiveNode] = useState(false);
  const [cX, setCx] = useState(0);
  const [cY, setCy] = useState(0);

  drag(drop(ref));
  return (
    <circle
      r="5"
      id={`node-${uuid}`}
      draggable="true"
      className="draggable"
      isActiveNode=""
      onClick={doOnClickCallback}
      onContextMenu={doOnContextMenuCallback}
      treeItemType={treeItemType}
      nodeId={uuid}
      label={label}
      ref={ref}
      onPointerDown={startDrag}
      onPointerMove={doDrag}
      onPointerUp={endDrag}
      onPointerLeave={endDrag}
      data-handler-id={handlerId}
      style={{
        opacity,
        zIndex: "500000",
        cursor: "move",
        strokeWidth: isActiveNode === true ? 2 : 1,
      }}
      cx={cX}
      cy={cY}
    >
      {children}
    </circle>
  );
}

CustomTreeItem.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  moveNodeCallback: PropTypes.func,
  onClickCallback: PropTypes.func,
  onContextMenuCallback: PropTypes.func,
  pathIdentifier: PropTypes.string,
  treeItemType: PropTypes.any,
  uuid: PropTypes.string,
};

export default CustomTreeItem;
