import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { getAllRelevantCurbyAppDataForAccount, getAccountPoints, getAccountOrders } from '../../../../../services/CurbCycleApi';
import materialTypeHelper from '../../../../../utilities/materialTypeHelper';
// @material-ui/core
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
// @material-ui/icons
import Icon from '@material-ui/core/Icon';
import TodayIcon from '@material-ui/icons/Today';
import IconButton from '@material-ui/core/IconButton';
import NameIcon from '@material-ui/icons/Person';
import IdIcon from '@material-ui/icons/LibraryBooks';
import EmailIcon from '@material-ui/icons/Email';
import AddressIcon from '@material-ui/icons/Home';
import LgaIcon from '@material-ui/icons/Business';
import CurbyProgramsEnabledIcon from '@material-ui/icons/Add';
import ParticipationOpportunitiesIcon from '@material-ui/icons/Queue';
import OrdersIcon from '@material-ui/icons/PermPhoneMsg';
import CanPlaceOrdersIcon from '@material-ui/icons/LocalPhone';
import CanScanIcon from '@material-ui/icons/CropFree';
import CopyIcon from '@material-ui/icons/FileCopy';
import PointsIcon from '@material-ui/icons/Loyalty';
// core components
import GridItem from '../../../../../components/Grid/GridItem';
import GridContainer from '../../../../../components/Grid/GridContainer';
import Card from '../../../../../components/Card/Card';
import CardHeader from '../../../../../components/Card/CardHeader';
import CardIcon from '../../../../../components/Card/CardIcon';
import CardBody from '../../../../../components/Card/CardBody';
import Button from '../../../../../components/CustomButtons/Button';
// custom modal components
import PointsHistoryModal from './PointsHistoryModal';
import OrdersHistoryModal from './OrdersHistoryModal';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
  },
  active: {
    color: theme.palette.primary.main,
  },
  activeIcon: {
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    padding: 0,
    marginLeft: '12px',
  },
  historyButton: {
    marginLeft: '22px',
  },
}));

function AccountSummary() {
  const classes = useStyles();
  const history = useHistory();
  const [theData, setTheData] = useState(null);
  const [pointsData, setPointsData] = useState(null);
  const [ordersData, setOrdersData] = useState(null);
  const { uuid } = useParams();
  const [showPointsModal, setShowPointsModal] = useState(false);
  const [showOrdersModal, setShowOrdersModal] = useState(false);

  const CopyToClipboard = ({ text }) => {
    const copyToClipboard = () => {
      navigator.clipboard.writeText(text);
    };
    return (
      <IconButton aria-label="delete" onClick={copyToClipboard} className={classes.button} color="primary">
        <CopyIcon fontSize="small" />
      </IconButton>
    );
  };

  const OpenModalButton = ({ buttonText }) => {
    return (
      <Button color="primary" aria-label="history" size="sm" onClick={handleOpenPoints} className={classes.historyButton}>
        {buttonText}
      </Button>
    );
  };

  const OpenPointsButton = ({ buttonText }) => {
    return (
      <Button color="primary" aria-label="history" size="sm" onClick={handleOpenOrders} className={classes.historyButton}>
        {buttonText}
      </Button>
    );
  };

  const handleOpenPoints = () => {
    setShowPointsModal(true);
  };

  const handleClosePoints = () => {
    setShowPointsModal(false);
  };
  const handleOpenOrders = () => {
    setShowOrdersModal(true);
  };

  const handleCloseOrders = () => {
    setShowOrdersModal(false);
  };

  useEffect(() => {
    let isCancelled = false;
    const getTheData = async () => {
      getAllRelevantCurbyAppDataForAccount(uuid).then((response) => {
        if (!isCancelled) {
          setTheData(response);
        }
      });
      getAccountPoints(uuid).then((response) => {
        if (!isCancelled) {
          setPointsData(response.data);
        }
      });
      getAccountOrders(uuid).then((response) => {
        if (!isCancelled) {
          setOrdersData(response.data);
        }
      });
    };
    getTheData();
    return () => {
      isCancelled = true;
    };
  }, []);

  const hasRegistrationDate = theData?.account.created_at;
  const hasNameAndSurname = theData?.account?.first_name && theData?.account?.last_name;
  const hasInternalReference = theData?.account?.internal_reference;
  const hasEmail = theData?.account?.email;
  const hasAddress = theData?.account?.addresses?.length;
  const curbyProgramsEnabled = theData?.additional_relevant_data?.enabled_curby_programs?.length;
  const hasParticipationOpportunities = theData?.additional_relevant_data?.can_join_curby_programs.opportunities?.length;
  const hasOrders = theData?.additional_relevant_data?.has_orders;
  const canPlaceOrder = theData?.additional_relevant_data?.can_place_order;
  const canScan = theData?.additional_relevant_data?.can_place_order;
  const hasPoints = theData?.account?.account_points;

  const activeOrdersDataCount = () => {
    let activeOrderCount = 0;
    if (!ordersData) {
      return;
    } else {
      ordersData.forEach((item, index) => {
        if (item.status === 'active') {
          activeOrderCount++;
        }
      });
    }
    return activeOrderCount;
  };

  return theData?.account ? (
    <>
      {showPointsModal && <PointsHistoryModal data={pointsData} handleClosePoints={handleClosePoints} showPointsModal={showPointsModal} />}
      {showOrdersModal && <OrdersHistoryModal data={ordersData} handleCloseOrders={handleCloseOrders} showOrdersModal={showOrdersModal} />}
      <Fade in={true} timeout={3000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader stats icon>
                <CardIcon color="primary" style={{ fontSize: '22px', fontWeight: 400 }}>
                  Account Summary
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer container direction="row" justifyContent="flex-end" alignItems="flex-end">
                  <GridItem>
                    <Button
                      link={true}
                      onClick={() => {
                        history.push({
                          pathname: `/management/accounts`,
                        });
                      }}
                    >
                      <Icon>arrow_back</Icon>
                      <label style={{ fontSize: '18px' }}>Back</label>
                    </Button>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <List className={classes.root}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={hasNameAndSurname ? classes.activeIcon : ''}>
                            <NameIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Name"
                          className={hasNameAndSurname ? classes.active : ''}
                          secondary={
                            hasNameAndSurname ? (
                              <>
                                {theData?.account?.first_name && theData?.account?.last_name ? `${theData.account.first_name} ${theData.account.last_name}` : ''}
                                <CopyToClipboard text={theData?.account?.first_name && theData?.account?.last_name ? `${theData.account.first_name} ${theData.account.last_name}` : ''} />
                              </>
                            ) : (
                              ''
                            )
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={hasRegistrationDate ? classes.activeIcon : ''}>
                            <TodayIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Member Since"
                          className={hasRegistrationDate ? classes.active : ''}
                          secondary={hasRegistrationDate ? <>{`${moment(theData.account.created_at).format('dddd, Do MMMM YYYY')}`}</> : ''}
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={hasInternalReference ? classes.activeIcon : ''}>
                            <IdIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className={hasInternalReference ? classes.active : ''}
                          primary="Internal Account Reference"
                          secondary={
                            hasInternalReference ? (
                              <>
                                {theData.account.internal_reference}
                                <CopyToClipboard text={theData.account.internal_reference || ''} />
                              </>
                            ) : (
                              'Account name has not been registered'
                            )
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={hasEmail ? classes.activeIcon : ''}>
                            <EmailIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className={hasEmail ? classes.active : ''}
                          primary="Email"
                          secondary={
                            (
                              <>
                                {theData.account.email}
                                <CopyToClipboard text={theData.account.email || ''} />
                              </>
                            ) || ''
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={hasAddress ? classes.activeIcon : ''}>
                            <AddressIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className={hasAddress ? classes.active : ''}
                          primary="Primary Address"
                          secondary={
                            hasAddress ? (
                              <>
                                {theData.account.addresses[0].formatted_address}
                                <CopyToClipboard text={theData.account.addresses[0].formatted_address || ''} />
                              </>
                            ) : (
                              'No address registered'
                            )
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={hasAddress ? classes.activeIcon : ''}>
                            <LgaIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className={hasAddress ? classes.active : ''}
                          primary="LGA"
                          secondary={
                            hasAddress ? (
                              <>
                                {theData.account.addresses[0].lga_key}
                                <CopyToClipboard text={theData.account.addresses[0].lga_key || ''} />
                              </>
                            ) : (
                              'No address registered'
                            )
                          }
                        />
                      </ListItem>
                    </List>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <List className={classes.root}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={curbyProgramsEnabled ? classes.activeIcon : ''}>
                            <CurbyProgramsEnabledIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Enabled Curby Programs"
                          className={curbyProgramsEnabled ? classes.active : ''}
                          secondary={
                            curbyProgramsEnabled
                              ? theData.additional_relevant_data.enabled_curby_programs.map((program) => <span key={`programs-${program}`}>{materialTypeHelper.getMaterialName(program)}</span>)
                              : 'No programs enabled'
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={hasParticipationOpportunities ? classes.activeIcon : ''}>
                            <ParticipationOpportunitiesIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className={hasParticipationOpportunities ? classes.active : ''}
                          primary="Curby Program Participation Opportunities"
                          secondary={
                            hasParticipationOpportunities
                              ? theData.additional_relevant_data.can_join_curby_programs.opportunities.map((opportunity) => (
                                  <div key={`opportunities-${opportunity}`}>{materialTypeHelper.getMaterialName(opportunity)}</div>
                                ))
                              : 'No new opportunities available'
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={hasOrders ? classes.activeIcon : ''}>
                            <OrdersIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className={hasOrders ? classes.active : ''}
                          primary="Curby Program Orders (last 30 days)"
                          secondary={
                            hasOrders
                              ? theData.additional_relevant_data.enabled_curby_programs.map((materialType) => (
                                  <span key={`orders-${materialType}`}>
                                    {materialTypeHelper.getMaterialName(materialType)}: {theData.additional_relevant_data.orders_in_last_30_days[materialType]}
                                  </span>
                                ))
                              : 'No orders placed within the last 30 days'
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={canPlaceOrder ? classes.activeIcon : ''}>
                            <CanPlaceOrdersIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className={canPlaceOrder ? classes.active : ''}
                          primary="Can Place New CurbyPack Orders in the App"
                          secondary={
                            canPlaceOrder
                              ? theData.additional_relevant_data.enabled_curby_programs.map((materialType) => (
                                  <span key={`can-place-orders-${materialType}`}>
                                    {materialTypeHelper.getMaterialName(materialType)}: {theData.additional_relevant_data.can_place_order[materialType] === true ? 'Yes' : 'No'}
                                  </span>
                                ))
                              : 'No! No programs are currently enabled...'
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={canScan ? classes.activeIcon : ''}>
                            <CanScanIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className={canScan ? classes.active : ''}
                          primary="Can Scan CurbyTags in the Apps"
                          secondary={
                            canScan ? (
                              <>
                                {theData.additional_relevant_data.enabled_curby_programs.map((materialType) => (
                                  <span key={`enabled_curby_programs_with_restrictions-${materialType}`}>
                                    {materialTypeHelper.getMaterialName(materialType)}:{' '}
                                    {theData.additional_relevant_data.enabled_curby_programs_with_restrictions[materialType] === false ? 'Yes' : 'No'}
                                  </span>
                                ))}
                              </>
                            ) : (
                              'No! No programs are currently enabled...'
                            )
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={hasPoints ? classes.activeIcon : ''}>
                            <PointsIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className={hasPoints ? classes.active : ''}
                          primary="Points"
                          secondary={
                            hasPoints ? (
                              <>
                                {theData.account.account_points}
                                <OpenModalButton buttonText={'See points history'} />
                              </>
                            ) : (
                              'No points earned'
                            )
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={hasOrders ? classes.activeIcon : ''}>
                            <PointsIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className={hasOrders ? classes.active : ''}
                          primary="Orders"
                          secondary={
                            hasOrders ? (
                              <>
                                {ordersData && activeOrdersDataCount() > 0 ? `${activeOrdersDataCount()} ${'active'}` : null}
                                <OpenPointsButton buttonText={'See all orders'} />
                              </>
                            ) : (
                              'No orders created'
                            )
                          }
                        />
                      </ListItem>
                    </List>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fade>
    </>
  ) : null;
}

AccountSummary.propTypes = {
  buttonText: PropTypes.string,
  text: PropTypes.string || PropTypes.number,
};

export default AccountSummary;
