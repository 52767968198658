import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import paginationHelper from "../../../../../utilities/paginationHelper";
import useDebounce from "../../../../../hooks/useDebounce";
// thunks
import fetchFirstValidMrfManagerThunk from "../../../../../thunks/Management/MrfOperations/People/fetchFirstValidMrfManagerThunk";
import pageChangeMrfManagersWithFiltersThunk from "../../../../../thunks/Management/MrfOperations/DataPagination/pageChangeMrfManagersWithFiltersThunk";
import pageChangeMrfOperatorsWithFiltersThunk from "../../../../../thunks/Management/MrfOperations/DataPagination/pageChangeMrfOperatorsWithFiltersThunk";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
// core components
import CustomTabs from "../../../../../components/CustomTabs/CustomTabs.js";
import GridItem from "../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../components/Grid/GridContainer.js";
import ActionableDataTable from "../../../../../components/ActionableDataTable/ActionableDataTable";
import Card from "../../../../../components/Card/Card.js";
import CardHeader from "../../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../../components/Card/CardIcon.js";
import CardBody from "../../../../../components/Card/CardBody.js";
import Button from "../../../../../components/CustomButtons/Button";

function People({
  fetchingMrfManagers,
  mrfManagersTableData,
  mrfManagersTableRawData,
  fetchingMrfOperators,
  mrfOperatorsTableData,
  mrfOperatorsTableRawData,
  fetchingInitialManagerUuid,
  initialManagerUuid,
}) {
  const dispatch = useDispatch();

  const history = useHistory();

  const { uuid } = useParams();

  const [
    mrfManagerTableFilterOptions,
    setMrfManagerTableFilterOptions,
  ] = useState(undefined);
  const [
    mrfManagerTableFilterForStatus,
    setMrfManagerTableFilterForStatus,
  ] = useState(undefined);
  const debouncedMrfManagerTableFilterOptions = useDebounce(
    mrfManagerTableFilterOptions,
    400
  );
  const debouncedMrfManagerTableStatus = useDebounce(
    mrfManagerTableFilterForStatus,
    300
  );

  const [
    mrfOperatorTableFilterOptions,
    setMrfOperatorTableFilterOptions,
  ] = useState(undefined);
  const [
    mrfOperatorTableFilterForStatus,
    setMrfOperatorTableFilterForStatus,
  ] = useState(undefined);
  const debouncedMrfOperatorTableFilterOptions = useDebounce(
    mrfOperatorTableFilterOptions,
    400
  );
  const debouncedMrfOperatorTableStatus = useDebounce(
    mrfOperatorTableFilterForStatus,
    300
  );

  useEffect(() => {
    if (undefined === debouncedMrfManagerTableFilterOptions) {
      return;
    }
    dispatch(
      pageChangeMrfManagersWithFiltersThunk(
        uuid,
        paginationHelper.generateFilterQueryStringFromFilterOptions(
          debouncedMrfManagerTableFilterOptions
        )
      )
    );
  }, [debouncedMrfManagerTableFilterOptions, dispatch, uuid]);

  useEffect(() => {
    const handler = setTimeout(
      paginationHelper.handleAddSimpleKeyValueFilterOption(
        "status",
        debouncedMrfManagerTableStatus,
        setMrfManagerTableFilterOptions,
        debouncedMrfManagerTableFilterOptions
      ),
      500
    );

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedMrfManagerTableStatus]);

  useEffect(() => {
    if (undefined === debouncedMrfOperatorTableFilterOptions) {
      return;
    }
    dispatch(
      pageChangeMrfOperatorsWithFiltersThunk(
        uuid,
        paginationHelper.generateFilterQueryStringFromFilterOptions(
          debouncedMrfOperatorTableFilterOptions
        )
      )
    );
  }, [debouncedMrfOperatorTableFilterOptions, dispatch, uuid]);

  useEffect(() => {
    const handler = setTimeout(
      paginationHelper.handleAddSimpleKeyValueFilterOption(
        "status",
        debouncedMrfOperatorTableStatus,
        setMrfOperatorTableFilterOptions,
        debouncedMrfOperatorTableFilterOptions
      ),
      500
    );

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedMrfOperatorTableStatus]);

  useEffect(() => {
    dispatch(fetchFirstValidMrfManagerThunk(uuid));
  }, [dispatch, uuid]);

  return (
    <Fade in={true} timeout={2000}>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title="People:"
              headerColor="success"
              tabs={[
                {
                  tabName: "Managers",
                  tabContent: (
                    <Fade in={true} timeout={3000}>
                      <Card loading={fetchingMrfManagers}>
                        <CardHeader color="success" stats icon>
                          <CardIcon color="success">
                            Manage SMRF Managers
                          </CardIcon>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={9}>
                              <div className="data-table-filter-controls">
                                <form>
                                  <p>Status</p>
                                  <input
                                    type="radio"
                                    name="status"
                                    value="active"
                                    defaultChecked={
                                      debouncedMrfManagerTableStatus ===
                                      "active"
                                    }
                                    onClick={() => {
                                      setMrfManagerTableFilterForStatus(
                                        "active"
                                      );
                                      paginationHelper.handleAddSimpleKeyValueFilterOption(
                                        "status",
                                        "active",
                                        setMrfManagerTableFilterOptions,
                                        debouncedMrfManagerTableFilterOptions
                                      );
                                    }}
                                  />
                                  <label>Active</label>
                                  <input
                                    type="radio"
                                    name="status"
                                    value="inactive"
                                    defaultChecked={
                                      debouncedMrfManagerTableStatus ===
                                      "inactive"
                                    }
                                    onClick={() => {
                                      setMrfManagerTableFilterForStatus(
                                        "inactive"
                                      );
                                      paginationHelper.handleAddSimpleKeyValueFilterOption(
                                        "status",
                                        "inactive",
                                        setMrfManagerTableFilterOptions,
                                        debouncedMrfManagerTableFilterOptions
                                      );
                                    }}
                                  />
                                  <label>Inactive</label>
                                </form>
                              </div>
                            </GridItem>
                            {/*<GridItem xs={6} sm={6} md={6}>*/}

                            {/*</GridItem>*/}
                            <GridItem xs={1} sm={1} md={1}>
                              <Button
                                color={"warning"}
                                link={true}
                                onClick={() => {
                                  history.push(
                                    `/management/mrf-management/mrf/${uuid}/add-mrf-manager`
                                  );
                                }}
                              >
                                <span className="material-icons">add</span> Add
                                SMRF Manager
                              </Button>
                            </GridItem>
                          </GridContainer>
                          <ActionableDataTable
                            tableHeaderColor="warning"
                            tableHead={["Username", "Status"]}
                            tableData={mrfManagersTableData}
                            pagination={true}
                            paginationConfig={{
                              collectionData: mrfManagersTableRawData,
                              handlePageChange: (payload) => {
                                setMrfManagerTableFilterOptions(
                                  paginationHelper.addSimpleKeyValueFilterOption(
                                    debouncedMrfManagerTableFilterOptions,
                                    "page",
                                    payload.selected,
                                    true,
                                    false
                                  )
                                );
                              },
                            }}
                          />
                        </CardBody>
                      </Card>
                    </Fade>
                  ),
                },
                {
                  tabName: "Operators",
                  tabContent: (
                    <Fade in={true} timeout={3000}>
                      <Card loading={fetchingMrfOperators}>
                        <CardHeader color="success" stats icon>
                          <CardIcon color="success">
                            Manage SMRF Operators
                          </CardIcon>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={9}>
                              <div className="data-table-filter-controls">
                                <form>
                                  <p>Status</p>
                                  <input
                                    type="radio"
                                    name="status"
                                    value="active"
                                    defaultChecked={
                                      debouncedMrfOperatorTableStatus ===
                                      "active"
                                    }
                                    onClick={() => {
                                      setMrfOperatorTableFilterForStatus(
                                        "active"
                                      );
                                      paginationHelper.handleAddSimpleKeyValueFilterOption(
                                        "status",
                                        "active",
                                        setMrfOperatorTableFilterOptions,
                                        debouncedMrfOperatorTableFilterOptions
                                      );
                                    }}
                                  />
                                  <label>Active</label>
                                  <input
                                    type="radio"
                                    name="status"
                                    value="inactive"
                                    defaultChecked={
                                      debouncedMrfOperatorTableStatus ===
                                      "inactive"
                                    }
                                    onClick={() => {
                                      setMrfOperatorTableFilterForStatus(
                                        "inactive"
                                      );
                                      paginationHelper.handleAddSimpleKeyValueFilterOption(
                                        "status",
                                        "inactive",
                                        setMrfOperatorTableFilterOptions,
                                        debouncedMrfOperatorTableFilterOptions
                                      );
                                    }}
                                  />
                                  <label>Inactive</label>
                                </form>
                              </div>
                            </GridItem>
                            {/*<GridItem xs={5} sm={5} md={5}>*/}

                            {/*</GridItem>*/}
                            <GridItem xs={1} sm={1} md={1}>
                              <Button
                                color={"warning"}
                                link={true}
                                onClick={() => {
                                  if (initialManagerUuid === null) {
                                    alert(
                                      "Please create a SMRF Manager first..."
                                    );
                                    history.push(
                                      `/management/mrf-management/mrf/${uuid}/add-mrf-manager`
                                    );
                                  } else {
                                    history.push(
                                      `/management/mrf-management/mrf/${uuid}/add-mrf-operator`
                                    );
                                  }
                                }}
                              >
                                <span className="material-icons">add</span> Add
                                SMRF Operator
                              </Button>
                            </GridItem>
                          </GridContainer>
                          <ActionableDataTable
                            tableHeaderColor="warning"
                            tableHead={["Username", "Status"]}
                            tableData={mrfOperatorsTableData}
                            pagination={true}
                            paginationConfig={{
                              collectionData: mrfOperatorsTableRawData,
                              handlePageChange: (payload) => {
                                setMrfOperatorTableFilterOptions(
                                  paginationHelper.addSimpleKeyValueFilterOption(
                                    debouncedMrfOperatorTableFilterOptions,
                                    "page",
                                    payload.selected,
                                    true,
                                    false
                                  )
                                );
                              },
                            }}
                          />
                        </CardBody>
                      </Card>
                    </Fade>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    </Fade>
  );
}

function mapStateToProps(state) {
  return {
    fetchingInitialManagerUuid:
      state.journeyFlows.CurbCycleManagement.mrfs.addMrfOperator
        .fetchingFirstValidMrfManagerUuid,
    initialManagerUuid:
      state.journeyFlows.CurbCycleManagement.mrfs.addMrfOperator
        .initialManagerUuid,
    fetchingMrfManagers:
      state.journeyFlows.CurbCycleManagement.mrfs.mrfManagers.fetching,
    mrfManagersTableRawData:
      state.journeyFlows.CurbCycleManagement.mrfs.mrfManagers.mrfManagers,
    mrfManagersTableData: state.journeyFlows.CurbCycleManagement.mrfs.mrfManagers.mrfManagers.data.map(
      (item) => {
        return [
          {
            display: item.email,
          },
          {
            display: item.status,
          },
        ];
      }
    ),
    fetchingMrfOperators:
      state.journeyFlows.CurbCycleManagement.mrfs.mrfOperators.fetching,
    mrfOperatorsTableRawData:
      state.journeyFlows.CurbCycleManagement.mrfs.mrfOperators.mrfOperators,
    mrfOperatorsTableData: state.journeyFlows.CurbCycleManagement.mrfs.mrfOperators.mrfOperators.data.map(
      (item) => {
        return [
          {
            display: item.email,
          },
          {
            display: item.status,
          },
        ];
      }
    ),
  };
}

People.propTypes = {
  fetchingInitialManagerUuid: PropTypes.bool.isRequired,
  fetchingMrfManagers: PropTypes.bool.isRequired,
  fetchingMrfOperators: PropTypes.bool.isRequired,
  initialManagerUuid: PropTypes.string,
  mrfManagersTableData: PropTypes.any,
  mrfManagersTableRawData: PropTypes.any,
  mrfOperatorsTableData: PropTypes.any,
  mrfOperatorsTableRawData: PropTypes.any,
};

export default connect(mapStateToProps)(People);
