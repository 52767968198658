module.exports = {
  component: 'vfb-mb-form-element-text-input-field',
  elementPrefix: '',
  name: "MB: Basic Text Field",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'placeholder_text',
      value: 'Some placeholder'
    }
  ]
}
