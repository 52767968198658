import {
  managementRequestedAddMrfManager,
  managementCompletedAddMrfManager,
} from "../../../../../redux/reducer";

import { addMrfManager } from '../../../../../services/CurbCycleApi';

export const addMrfManagerThunk = (payload, mrfUuid, setIsSaving, setWasSuccessful) => dispatch => {
  dispatch(managementRequestedAddMrfManager());
  setIsSaving(true);
  addMrfManager(mrfUuid, payload)
    .then(result => {
      dispatch(managementCompletedAddMrfManager(result));
      setIsSaving(false);
      setWasSuccessful(true);
    })
    .catch(() => {
      dispatch(managementCompletedAddMrfManager({}));
      setIsSaving(false);
    });
};

export default addMrfManagerThunk;
