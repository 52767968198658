import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
// import * as yup from "yup";
import { Formik } from "formik";
import { useDispatch, connect } from "react-redux";
import requestNewOtpThunk from "../../../thunks/Auth/requestNewOtpThunk";
import verifyOtpThunk from "../../../thunks/Auth/verifyOtpThunk";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import logo from "../../../assets/img/curbcycle-tagline.png";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

function Auth_EnterEmail({ isLoggedIn }) {
  const dispatch = useDispatch();
  const [otpRequested, setOtpRequested] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");
  // const [passCode, setPassCode] = useState('');
  const classes = useStyles();
  const passCode = "";

  useEffect(() => {
    if (!otpVerified) {
      setIsLoading(false);
    }
  }, []);

  if (otpVerified) {
    return null;
  }

  if (!otpRequested) {
    return (
      <Fade in={true} timeout={1000}>
        <div
          className="container-fluid"
          style={{ margin: "0 auto", maxWidth: "500px" }}
        >
          <img
            src={logo}
            alt=""
            style={{
              marginLeft: "20%",
              marginRight: "20%",
              marginTop: "25px",
              marginBottom: "25px",
              maxWidth: "60%",
            }}
          />
          <Card loading={isLoading}>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Login</h4>
              <p className={classes.cardCategoryWhite}>Enter your email</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Formik
                    initialValues={{ email: email, pass_code: passCode }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = "Required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      // console.log(values);
                      setEmail(values.email);
                      dispatch(
                        requestNewOtpThunk(
                          values.email,
                          setIsLoading,
                          setOtpRequested,
                          setSubmitting
                        )
                      );
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <label aria-label="Email address">Email address</label>
                        <input
                          type="email"
                          name="email"
                          style={{
                            width: "99%",
                            height: "2.2em",
                            fontSize: "1em",
                            border: "1px solid #d2d2d2",
                            paddingLeft: "10px",
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        <span style={{ color: "#cc2526" }}>
                          {errors.email && touched.email && errors.email}
                        </span>
                        <Button
                          style={{ margin: "15px auto", width: "100%" }}
                          color={"info"}
                          type="submit"
                          disabled={isLoading}
                        >
                          Continue
                        </Button>
                      </form>
                    )}
                  </Formik>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </div>
      </Fade>
    );
  }

  if (!otpVerified) {
    return (
      <div
        className="container-fluid"
        style={{ margin: "0 auto", maxWidth: "500px" }}
      >
        <img
          src={logo}
          alt=""
          style={{
            marginLeft: "20%",
            marginRight: "20%",
            marginTop: "25px",
            marginBottom: "25px",
            maxWidth: "60%",
          }}
        />
        <Card loading={isLoading}>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Enter your access code</h4>
            <p className={classes.cardCategoryWhite}>Enter your access code</p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Formik
                  initialValues={{ email: email, pass_code: passCode }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.pass_code) {
                      errors.pass_code = "Required";
                    } else if (!/^[0-9]{6,6}$/i.test(values.pass_code)) {
                      errors.pass_code = "Enter the 6 digit pass code";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    dispatch(
                      verifyOtpThunk(
                        values.email,
                        values.pass_code,
                        setIsLoading,
                        setOtpRequested,
                        setOtpVerified,
                        setSubmitting
                      )
                    );
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <label aria-label="Pass code">Pass code</label>
                      <input
                        type="hidden"
                        name="email"
                        style={{
                          width: "99%",
                          height: "2.2em",
                          fontSize: "1em",
                          border: "1px solid #d2d2d2",
                          paddingLeft: "10px",
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <input
                        type="text"
                        name="pass_code"
                        style={{
                          width: "99%",
                          height: "2.2em",
                          fontSize: "1em",
                          border: "1px solid #d2d2d2",
                          paddingLeft: "10px",
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span style={{ color: "#cc2526" }}>
                        {errors.pass_code &&
                          touched.pass_code &&
                          errors.pass_code}
                      </span>
                      <Button
                        style={{ margin: "15px auto", width: "100%" }}
                        color={"info"}
                        type="submit"
                        disabled={isLoading}
                      >
                        Submit Pass Code
                      </Button>
                    </form>
                  )}
                </Formik>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
  };
}

export default connect(mapStateToProps)(Auth_EnterEmail);
