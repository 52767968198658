import React, { useEffect } from "react";
import PropTypes from "prop-types";
//  core components
import Spinner from "../../../components/Spinner/Spinner";
import { useDispatch, connect } from "react-redux";
import setAppInitialisedThunk from "../../../thunks/Initialisation/setAppInitialisedThunk";

function Initialising({ tempEmail, isLoggedIn }) {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(setAppInitialisedThunk());
    }, 900);
  }, [isLoggedIn, dispatch]);

  return <Spinner centerWindow={true} />;
}

function mapStateToProps(state) {
  return {
    tempEmail: state.tempEmail.email,
    isLoggedIn: state.isLoggedIn,
  };
}

Initialising.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  tempEmail: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(Initialising);
