const Australia = require('./geoJsonData/Australia_0.5pct.json');
const AustraliaStates = require('./geoJsonData/States_0.5pct.json');
const LocalGovernementAuthorities = require('./geoJsonData/IQRenew_5pct.json');
// const PostCodes = require('./geoJsonData/postal_areas_polygons_reduced_5.json');
// const PostCodes = require('./geoJsonData/postal_areas_polygons_5.json');
const PostCodes = require('./geoJsonData/postal_areas_polygons_nsw.json');

const geoJsonData = {
  au: {
    levels: {
      country: Australia,
      state: AustraliaStates,
      lga: LocalGovernementAuthorities,
      post_codes: PostCodes
    }
  }
};

export default geoJsonData;