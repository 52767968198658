import axios from 'axios';
import CurbCycleAppStorage from './CurbCycleAppStorage.js';

import {
  JOURNEY_NEW_ACCOUNT_FLOW,
  // JOURNEY_EXISTING_ACCOUNT_FLOW,
  JOURNEY_MRF_OPERATOR_FLOW,
  JOURNEY_MRF_MANAGER_FLOW,
  JOURNEY_CURBCYCLE_DEVELOPMENT_FLOW,
  JOURNEY_CURBCYCLE_SUPPORT_FLOW,
  JOURNEY_LGA_FLOW,
  JOURNEY_RETAIL_SPONSOR_FLOW,
  JOURNEY_CURBCYCLE_MANAGEMENT_FLOW,
} from '../redux/reducer';
const generateUUID = () => {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

// @todo finish updating this as part of MVP-23
const BASE_URL = 'https://dev-916093.okta.com/oauth2/v1/authorize?';
const CLIENT_ID = '0oa10j6kutSUnW2wY4x7';
const RESPONSE_TYPE_ID_TOKEN = 'id_token';
const RESPONSE_TYPE_ACCESS_TOKEN = 'id_token token';
const RESPONSE_MODE = 'fragment';
const SCOPE = 'openid email profile groups';
const REDIRECT_URI = 'http://localhost:8777/auth/authorization-code/callback';
const STATE = 'STATE_TEMPLATE';

const AUTH_URL_ID_TOKEN = `${BASE_URL}client_id=${CLIENT_ID}&response_type=${RESPONSE_TYPE_ID_TOKEN}&response_mode=${RESPONSE_MODE}&scope=${SCOPE}&redirect_uri=${REDIRECT_URI}&state=${STATE}&nonce=`;
const AUTH_URL_ACCESS_TOKEN = `${BASE_URL}client_id=${CLIENT_ID}&response_type=${RESPONSE_TYPE_ACCESS_TOKEN}&response_mode=${RESPONSE_MODE}&scope=${SCOPE}&redirect_uri=${REDIRECT_URI}&state=${STATE}&nonce=`;
const STORAGE_CC_APP_AUTH = 'cc_app_auth';
const STORAGE_CC_APP_ACCOUNT = 'cc_app_account';

let theOs = '';
if (navigator.appVersion.indexOf('Win') !== -1) theOs = 'Windows OS';
if (navigator.appVersion.indexOf('Mac') !== -1) theOs = 'MacOS';
if (navigator.appVersion.indexOf('X11') !== -1) theOs = 'UNIX OS';
if (navigator.appVersion.indexOf('Linux') !== -1) theOs = 'Linux OS';

const setAuthData = (authData) => {
  return CurbCycleAppStorage.storeJsonData(STORAGE_CC_APP_AUTH, authData);
};

const clearAuthAndAccountData = () => {
  return storeAuthData({
    auth: {
      aclGroups: [null],
      idToken: '',
      token: '',
    },
  }).then(() => {
    return storeAccountData({ email: '' });
  });
};

const getStoredStateData = () => {
  return getAuthData().then((authData) => {
    if (undefined === authData || null === authData || undefined === authData.aclGroups || null === authData.aclGroups || undefined === authData.aclGroups[0] || null === authData.aclGroups[0]) {
      return {
        auth: {
          aclGroups: [null],
          idToken: '',
          token: '',
        },
        userType: JOURNEY_NEW_ACCOUNT_FLOW,
      };
    }

    let userType = JOURNEY_NEW_ACCOUNT_FLOW;

    switch (authData.aclGroups[0]) {
      case undefined:
      case null:
      case 'Account Profile':
        userType = JOURNEY_NEW_ACCOUNT_FLOW;
        // userType = JOURNEY_EXISTING_ACCOUNT_FLOW;
        break;
      case 'CurbCycle Support':
        userType = JOURNEY_CURBCYCLE_SUPPORT_FLOW;
        break;
      case 'CurbCycle Management':
        userType = JOURNEY_CURBCYCLE_MANAGEMENT_FLOW;
        break;
      case 'CurbCycle Development':
        userType = JOURNEY_CURBCYCLE_DEVELOPMENT_FLOW;
        break;
      case 'Retail Sponsor':
        userType = JOURNEY_RETAIL_SPONSOR_FLOW;
        break;
      case 'Local Government Authority':
        userType = JOURNEY_LGA_FLOW;
        break;
      case 'MRF Manager':
        userType = JOURNEY_MRF_MANAGER_FLOW;
        break;
      case 'MRF Operator':
        userType = JOURNEY_MRF_OPERATOR_FLOW;
        break;
      default:
        throw new Error('That should not happen');
    }

    authData.userType = userType;

    return authData;
  });
};

const saveSecret = (id, secret) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest
      .post(`management/secret/${id}`, {
        value: secret,
      })
      .then((response) => {
        return response.data;
      });
  });
};

const getSecret = (id) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/secret/${id}`).then((response) => {
      return response.data;
    });
  });
};

const getAppMetadata = () => {
  return getUnauthenticatedHttpService()
    .get(`app-bff/curby-app`)
    .then((response) => {
      return response.data;
    });
};

const getCrmIntegrationLinkData = (uuid) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management-accounts/crm-integration-links/${uuid}`).then((response) => {
      return response.data;
    });
  });
};

const getAuthenticatedHttpService = () => {
  return getAuthData().then((auth) => {
    return axios.create({
      baseURL: 'https://development-api.curby.cc/api/',
      // baseURL: "https://backend-api.dev.curbcycle.dev/api/",
      // baseURL: 'https://testing-api.curby.cc/api/',
      timeout: 30000,
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
        'X-Identity': `${auth.id_token}`,
        'X-AppVersion': `WEB`,
        'X-AppOS': `${theOs}`,
        'X-RequestId': `${generateUUID()}`,
      },
    });
  });
};

const getUnauthenticatedHttpService = () => {
  return axios.create({
    baseURL: 'https://development-api.curby.cc/api/',
    // baseURL: "https://backend-api.dev.curbcycle.dev/api/",
    // baseURL: 'https://testing-api.curby.cc/api/',
    timeout: 30000,
    headers: {
      'X-AppVersion': `WEB`,
      'X-AppOS': `${theOs}`,
      'X-RequestId': `${generateUUID()}`,
    },
  });
};
// Jackson Data
const getStewardshipData = async () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`lga-stewardship-analytics-v8`).then((response) => {
      return JSON.parse(response.data);
    });
  });
};

const getRedshiftTest = async () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`redshift-test-route`).then((response) => {
      return response.data;
    });
  });
};

// Retail Data
const getStewardshipCurbyData = async () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`rs-stewardship-curby-data`).then((response) => {
      return response.data;
    });
  });
};

const getStewardshipBaleData = async () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`rs-stewardship-monthly-data`).then((response) => {
      return response.data;
    });
  });
};


/**
 * Retail sponsor
 */
const getProductStewardshipAnalytics = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`stewardship-analytics-v7`).then((response) => {
      return response.data;
    });
  });
};

const getProductStewardshipAnalyticsV2 = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`stewardship-analytics-v2`).then((response) => {
      return response.data;
    });
  });
};

const getProvenanceDataForBales = (scanDate) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`inventory-provenance-data-v1?filter_date=${scanDate}`).then((response) => {
      return response.data;
    });
  });
};

const getProvenanceDataForBalesV2 = (scanDate) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`inventory-provenance-data-v3?filter_date=${scanDate}`).then((response) => {
      return response.data;
    });
  });
};

const getMRFQuicksightUrl = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`mrf-qs-url`).then((response) => {
      return response.data;
    });
  });
};

const getRSQuicksightUrl = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`/management/rs-get-qs-url`).then((response) => {
      return response.data;
    });
  });
};

/**
 * LGA
 */
 const getLGAQuicksightUrl = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`lga-qs-url`).then((response) => {
      return response.data;
    });
  });
};


 const getLgaAnalytics = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`rs-lga-get-data`).then((response) => {
      return response.data;
    });
  });
};

const getLgaSummaryAnalytics = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`rs-lga-get-summary-data`).then((response) => {
      return response.data;
    });
  });
};

const getLgaPostCodeAnalytics = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`rs-lga-get-post-code-data`).then((response) => {
      return response.data;
    });
  });
};


const getLgaProductStewardshipAnalytics = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`lga-stewardship-analytics-v7`).then((response) => {
      return response.data;
    });
  });
};

const getLgaProductStewardshipAnalyticsV2 = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`lga-stewardship-analytics-v2`).then((response) => {
      return response.data;
    });
  });
};

const getLgaProvenanceDataForBales = (scanDate) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`lga-mrf-inventory-provenance-data-v1?filter_date=${scanDate}`).then((response) => {
      return response.data;
    });
  });
};

const getLgaProvenanceDataForBalesV2 = (scanDate) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`lga-mrf-inventory-provenance-data-v3?filter_date=${scanDate}`).then((response) => {
      return response.data;
    });
  });
};

/**
 * MY MRF (MRF Manager Context)
 */

 const getMrfAnalytics = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`mrf-management/my-mrf/rs-mrf-data`).then((response) => {
      return response.data;
    });
  });
};
const getMrfSummaryAnalytics = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`mrf-management/my-mrf/rs-mrf-summary-data`).then((response) => {
      return response.data;
    });
  });
};
const getMrfPostCodeAnalytics = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`mrf-management/my-mrf/rs-mrf-post-code-data`).then((response) => {
      return response.data;
    });
  });
};

const getAllMrfOperatorsAtMyMrf = (filtersAsQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`mrf-management/my-mrf/operators?${filtersAsQueryString}`).then((response) => {
      return response.data;
    });
  });
};

// =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
// THIS API END-POINT DOES NOT EXIST YET YOU NEED TO CREATE IT
const updateMrfOperatorAtMyMrf = (MrfOperatorUuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`mrf-management/my-mrf/operators/${MrfOperatorUuid}`, payload).then((response) => {
      return response.data;
    });
  });
};

// THIS API END-POINT DOES NOT EXIST YET YOU NEED TO CREATE IT
// =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
const toggleMrfOperatorStatusAtMyMrf = (MrfOperatorUuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`mrf-management/my-mrf/operators/${MrfOperatorUuid}/toggle-active-status-event`, payload).then((response) => {
      return response.data;
    });
  });
};
// =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
// THIS API END-POINT DOES NOT EXIST YET YOU NEED TO CREATE IT
const getAllMrfManagersAtMyMrf = (filtersAsQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`mrf-management/my-mrf/managers?${filtersAsQueryString}`).then((response) => {
      return response.data;
    });
  });
};
// THIS API END-POINT DOES NOT EXIST YET YOU NEED TO CREATE IT
// =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
// THIS API END-POINT DOES NOT EXIST YET YOU NEED TO CREATE IT
const updateMrfManagerAtMyMrf = (MrfOperatorUuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`mrf-management/my-mrf/operators/${MrfOperatorUuid}`, payload).then((response) => {
      return response.data;
    });
  });
};
// =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
// THIS API END-POINT DOES NOT EXIST YET YOU NEED TO CREATE IT
const toggleMrfManagerStatusAtMyMrf = (MrfOperatorUuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`mrf-management/my-mrf/managerss/${MrfOperatorUuid}/toggle-active-status-event`, payload).then((response) => {
      return response.data;
    });
  });
};

const addMrfOperatorToMyMrf = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`mrf-management/my-mrf/operators`, payload).then((response) => {
      return response.data;
    });
  });
};

const fetchMyMrfAccessPin = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`/mrf-management/my-mrf/mrf-access-pin`).then((response) => {
      return response.data;
    });
  });
};

const resetMyMrfAccessPin = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`/mrf-management/my-mrf/reset-mrf-access-pin-event`, {}).then((response) => {
      return response.data;
    });
  });
};

// =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
// THIS API END-POINT DOES NOT EXIST YET YOU NEED TO CREATE IT
const getAllMyMrfMaterialTypes = (filtersAsQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`/mrf-management/my-mrf/mrf-material-types?${filtersAsQueryString}`).then((response) => {
      return response.data;
    });
  });
};

const getMyMrfProductStewardshipAnalytics = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`/mrf-management/mrf-analytics`).then((response) => {
      return JSON.parse(response.data);
    });
  });
};

const getMyMrfProvenanceDataForBales = (scanDate) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`/mrf-management/inventory-provenance-data?filter_date=${scanDate}`).then((response) => {
      // console.log(response);
      return response.data;
    });
  });
};

const getMyMrfAnalyticsData = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`/mrf-management/mrf-management-summary`).then((response) => {
      return response.data;
    });
  });
};

const getMyMrfAnalyticsDataByMaterialType = (materialType) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`/mrf-management/mrf-management-summary/${materialType}`).then((response) => {
      // console.log(response);
      return response.data;
    });
  });
};

/**
 * Management
 */

//quicksight dash url
 const getManagementQuicksightUrl = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`management/get-dashboard-url`).then((response) => {
      return response.data;
    });
  });
};


const getAllAccounts = (filterQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    let urlEncodedFilterQueryString = filterQueryString?.replace('+', '%2b');
    return httpRequest.get(`management-accounts?${urlEncodedFilterQueryString}&account_type=INDIVIDUAL`).then((response) => {
      return response.data;
    });
  });
};

const getAllRedirectionLinks = (filterQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    let urlEncodedFilterQueryString = filterQueryString?.replace('+', '%2b');
    return httpRequest.get(`management/redirection-links?${urlEncodedFilterQueryString}`).then((response) => {
      return response.data;
    });
  });
};

const getRedirectionLink = ({ code }) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/redirection-links/${code}`).then((response) => {
      return response.data;
    });
  });
};

const createRedirectionLink = (params) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`management/create-redirection-link-event`, params).then((response) => {
      return response.data;
    });
  });
};

const updateRedirectionLink = (code, params) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`management/update-redirection-link-event/${code}`, params).then((response) => {
      return response.data;
    });
  });
};

const getAccountEnabledMaterialJourneys = (uuid) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/accounts/${uuid}/enabled-material-type-journeys`).then((response) => {
      return response.data;
    });
  });
};

const toggleAccountMaterialJourney = (uuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`management/accounts/${uuid}/toggle-material-type-journey-event`, payload).then((response) => {
      return response.data;
    });
  });
};

const getCurbyProgramDataCache = (uuid) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`app-bff/accounts/${uuid}/cache/fetch-program-data-cache-event`).then((response) => {
      return response.data;
    });
  });
};

const getAccountEnabledLGA = (uuid) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/accounts/${uuid}/enabled-lga-engagement-options`).then((response) => {
      return response.data;
    });
  });
};

const toggleAccountMaterialLGA = (uuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`management/accounts/${uuid}/toggle-lga-engagement-option-event`, payload).then((response) => {
      return response.data;
    });
  });
};

const getMaterialJourneyProductStewardDropOffOptions = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/material-type-journey-options`).then((response) => {
      return response.data;
    });
  });
};

const toggleMaterialJourneyProductStewardDropOffOptions = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`management/toggle-material-type-journey-option-event`, payload).then((response) => {
      return response.data;
    });
  });
};

const getMvpTrialParticipant = (batchReference) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/mvp-trial-participant/${batchReference}`).then((response) => {
      return response.data;
    });
  });
};

const getManagementAnalytics = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/analytics-v6`).then((response) => {
      return response.data;
    });
  });
};

const getAllRelevantCurbyAppDataForAccount = (uuid) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management-accounts/all-relevant-curby-app-account-data/${uuid}`).then((response) => {
      return response.data;
    });
  });
};

// All Mrfs mangagers and Operators

const getAllMrfs = (filtersAsQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/mrfs?${filtersAsQueryString}`).then((response) => {
      return response.data;
    });
  });
};

const getMrf = (uuid) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/mrfs/${uuid}`).then((response) => {
      return response.data;
    });
  });
};

const getAllMrfManagers = (uuid, filtersAsQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/mrfs/${uuid}/managers?${filtersAsQueryString}`).then((response) => {
      return response.data;
    });
  });
};

const getAllMrfOperators = (uuid, filtersAsQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/mrfs/${uuid}/operators?${filtersAsQueryString}`).then((response) => {
      return response.data;
    });
  });
};

const fetchMrfAccessPin = (mrfUuid) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`/management/mrfs/${mrfUuid}/mrf-access-pin`).then((response) => {
      return response.data;
    });
  });
};

const resetMrfAccessPin = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`/management/reset-mrf-access-pin-event`, payload).then((response) => {
      return response.data;
    });
  });
};

const addMrf = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`management/mrfs`, payload).then((response) => {
      return response.data;
    });
  });
};

const updateMrf = (uuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.put(`management/mrfs/${uuid}`, payload).then((response) => {
      return response.data;
    });
  });
};

const addMrfManager = (mrfUuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`management/mrfs/${mrfUuid}/managers`, payload).then((response) => {
      return response.data;
    });
  });
};

const addMrfOperator = (mrfUuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`management/mrfs/${mrfUuid}/operators`, payload).then((response) => {
      return response.data;
    });
  });
};

const updateMrfManager = (mrfUuid, mrfManagerUuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.put(`management/mrfs/${mrfUuid}/managers/${mrfManagerUuid}`, payload).then((response) => {
      return response.data;
    });
  });
};

const getFirstValidMrfManager = (mrfUuid) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`/management/mrfs/${mrfUuid}/valid-mrf-manager`).then((response) => {
      return response.data;
    });
  });
};

// Mrf specific Bale Code data requests
const getAllBaleCodes = (filterQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    // let urlEncodedFilterQueryString = filterQueryString.replace("+", "%2B");
    let urlEncodedFilterQueryString = filterQueryString;
    return httpRequest.get(`mrf-management/bale-codes?${urlEncodedFilterQueryString}`).then((response) => {
      return response.data;
    });
  });
};

const getInboundBaleCodes = (filterQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    // let urlEncodedFilterQueryString = filterQueryString.replace("+", "%2B");
    let urlEncodedFilterQueryString = filterQueryString;
    return httpRequest.get(`mrf-management/bale-codes?${urlEncodedFilterQueryString}`).then((response) => {
      // console.log("[RESPONSE]", response);
      return response.data;
    });
  });
};

const getOutboundBaleBatchCodes = (filterQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    // let urlEncodedFilterQueryString = filterQueryString.replace("+", "%2B");
    let urlEncodedFilterQueryString = filterQueryString;
    return httpRequest.get(`mrf-management/bale-code-batch-codes?${urlEncodedFilterQueryString}`).then((response) => {
      return response.data;
    });
  });
};

const updateBaleCodes = (uuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.put(`mrf-management/bale-codes`, payload).then((response) => {
      return response.data;
    });
  });
};

const addBaleCodes = (uuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`mrf-management/bale-codes`, payload).then((response) => {
      return response.data;
    });
  });
};

// retail sponsor admin

const getAllRetailSponsors = (filtersAsQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/retail-sponsors?${filtersAsQueryString}`).then((response) => {
      return response.data;
    });
  });
};

const getRetailSponsor = (uuid) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/retail-sponsors/${uuid}`).then((response) => {
      return response.data;
    });
  });
};

const getAllRetailSponsorManagers = (uuid, filtersAsQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/retail-sponsors/${uuid}/managers?${filtersAsQueryString}`).then((response) => {
      return response.data;
    });
  });
};

const addRetailSponsor = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`management/retail-sponsors`, payload).then((response) => {
      return response.data;
    });
  });
};

const updateRetailSponsor = (uuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.put(`management/retail-sponsors/${uuid}`, payload).then((response) => {
      return response.data;
    });
  });
};

const addRetailSponsorManager = (retailSponsorUuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`management/retail-sponsors/${retailSponsorUuid}/managers`, payload).then((response) => {
      return response.data;
    });
  });
};

const updateRetailSponsorManager = (retailSponsorUuid, retailSponsorManagerUuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.put(`management/retail-sponsors/${retailSponsorUuid}/managers/${retailSponsorManagerUuid}`, payload).then((response) => {
      return response.data;
    });
  });
};

// lga admin

const getAllLGAs = (filtersAsQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/lgas?${filtersAsQueryString}`).then((response) => {
      return response.data;
    });
  });
};

const getLGA = (uuid) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/lgas/${uuid}`).then((response) => {
      return response.data;
    });
  });
};

const getAllLGAManagers = (uuid, filtersAsQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/lgas/${uuid}/managers?${filtersAsQueryString}`).then((response) => {
      return response.data;
    });
  });
};

const addLGA = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`management/lgas`, payload).then((response) => {
      return response.data;
    });
  });
};

const updateLGA = (uuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.put(`management/lgas/${uuid}`, payload).then((response) => {
      return response.data;
    });
  });
};

const addLGAManager = (lgaUuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`management/lgas/${lgaUuid}/managers`, payload).then((response) => {
      return response.data;
    });
  });
};

const updateLGAManager = (lgaUuid, lgaManagerUuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.put(`management/lgas/${lgaUuid}/managers/${lgaManagerUuid}`, payload).then((response) => {
      return response.data;
    });
  });
};

// I want to use lgaUuid
// getLGAMaterialTypeJourneyStates(lgaUuid) {
const getLGAMaterialTypeJourneyStates = (uuid) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/lgas/${uuid}/enabled-material-type-journeys`).then((response) => {
      return response.data;
    });
  });
};

// I want to use lgaUuid
// toggleLGAMaterialTypeJourneyStates(lgaUuid, payload) {
const toggleLGAMaterialTypeJourneyStates = (uuid, payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`management/lgas/${uuid}/toggle-material-type-journey-event`, payload).then((response) => {
      return response.data;
    });
  });
};

// curby-codes

const getAllCurbyCodes = (filtersAsQueryString) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management/curby-codes?${filtersAsQueryString}`).then((response) => {
      return response.data;
    });
  });
};

const getAccount = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`app-bff/accounts/me`).then((response) => {
      return response.data;
    });
  });
};

const getAllRelevantAccountDataForCurbyApp = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`app-bff/all-relevant-curby-app-data/me`).then((response) => {
      return response.data;
    });
  });
};

const createAccount = (email) => {
  return getUnauthenticatedHttpService()
    .post(`app-bff/accounts`, {
      email: email,
    })
    .then((response) => {
      return response.data;
    });
};

const createAccountVerifyOTPForToken = (email, passcode) => {
  return getUnauthenticatedHttpService()
    .post(`app-bff/accounts/me/verification`, {
      email: email,
      pass_code: passcode,
    })
    .then((response) => {
      return response.data;
    });
};

const createAccountRequestNewOtpChallenge = (email) => {
  return getUnauthenticatedHttpService()
    .post(`app-bff/otp`, {
      email: email,
    })
    .then((response) => {
      return response.data;
    });
};

const requestNewOtpChallenge = (email) => {
  return getUnauthenticatedHttpService()
    .post(`app-bff/otp`, {
      email: email,
    })
    .then((response) => {
      return response.data;
    });
};

const respondToOtpChallenge = (email, passcode) => {
  return getUnauthenticatedHttpService()
    .post(`app-bff/otp-verification`, {
      email: email,
      pass_code: passcode,
    })
    .then((response) => {
      return response.data;
    });
};

const addAddress = (payload) => {
  return getAuthenticatedHttpService().then((httpService) => {
    return httpService.post(`app-bff/accounts/me/addresses`, payload).then((response) => {
      return response.data;
    });
  });
};

const updateAddress = () => {
  // @todo
};

const updateAccountName = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.put(`app-bff/accounts/me`, payload).then((response) => {
      return response.data;
    });
  });
};

const checkShouldWaitlistAddress = (payload) => {
  return getUnauthenticatedHttpService()
    .post(`app-bff/accounts/me/address-waitlist-validation`, payload)
    .then((response) => {
      return response.data;
    });
};

const checkShouldWaitlistAddressAuthenticated = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`app-bff/accounts/me/app-waitlist-validation-event`, payload).then((response) => {
      return response.data;
    });
  });
};

const registerPushNotificationToken = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`app-bff/accounts/me/push-notifications-registration-event`, payload).then((response) => {
      return response.data;
    });
  });
};

const addProfiles = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`app-bff/accounts/me/profiles`, payload).then((response) => {
      return response.data;
    });
  });
};

const updateProfile = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.put(`app-bff/accounts/me/profiles/${payload.uuid}`, payload).then((response) => {
      return response.data;
    });
  });
};

const addReminder = () => {
  // @todo
};

const addMetadataFinishedSignUp = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`app-bff/accounts/me/metadata/finished-sign-up`, payload).then((response) => {
      return response.data;
    });
  });
};

const addMetadataIsWaitlisted = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.post(`app-bff/accounts/me/metadata/add-to-waitlist`, payload).then((response) => {
      return response.data;
    });
  });
};

const addReminders = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest
      .post(`app-bff/accounts/me/reminders`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  });
};

const updateReminder = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest
      .put(`app-bff/accounts/me/reminders/${payload.uuid}`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  });
};

const getAuthUrlForIdTokenWithNonce = () => {
  return `${AUTH_URL_ID_TOKEN}${generateUUID()}`;
};

const getAuthUrlForAccessTokenWithNonce = () => {
  return `${AUTH_URL_ACCESS_TOKEN}${generateUUID()}`;
};

const login = (state) => {
  const url = state ? getAuthUrlForIdTokenWithNonce().replace('STATE_TEMPLATE', state) : getAuthUrlForIdTokenWithNonce();
  window.location.href = url;
};

const getNewAcccessToken = (state) => {
  const url = state ? getAuthUrlForAccessTokenWithNonce().replace('STATE_TEMPLATE', state) : getAuthUrlForAccessTokenWithNonce();
  window.location.href = url;
};

const getAccessToken = () => {
  return getAuthData().then((data) => {
    return undefined !== data.token ? data.token : null;
  });
};

const getIdToken = () => {
  return getAuthData().then((data) => {
    return undefined !== data.idToken ? data.idToken : null;
  });
};

const storeAuthData = (data) => {
  console.log(STORAGE_CC_APP_AUTH, data);
  return CurbCycleAppStorage.storeJsonData(STORAGE_CC_APP_AUTH, data);
};

const getAuthData = () => {
  return CurbCycleAppStorage.getJsonData(STORAGE_CC_APP_AUTH);
};

const storeAccountData = (data) => {
  return CurbCycleAppStorage.storeJsonData(STORAGE_CC_APP_ACCOUNT, data);
};

const getAccountData = () => {
  return CurbCycleAppStorage.getJsonData(STORAGE_CC_APP_ACCOUNT);
};

const getName = () => {
  return getAccountData().then((data) => {
    return undefined !== data.first_name ? data.first_name : '';
  });
};

const getGroups = () => {
  return getAuthData().then((auth) => {
    return undefined !== auth.aclGroups ? auth.aclGroups : [];
  });
};

const isManagement = () => {
  return getGroups().then((groups) => {
    return groups.includes('CurbCycle Management');
  });
};

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

const logout = (cb) => {
  storeAccountData(null);
  storeAuthData(null);
  if (cb) cb();
  onChange(false);
};

const getMyMrfAccountInfo = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`mrf/me`).then((response) => {
      return response.data;
    });
  });
};

const getMyMrfAccountActivities = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`mrf/me/activities`).then((response) => {
      return response.data;
    });
  });
};

const mrfConfirmReceiptOfTagsWithWeight = (payload) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest
      .post(`mrf/curby-tags-scan-event`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  });
};

const getEnabledAppExperiments = () => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest
      .get(`app-bff/curby-app-enabled-experiments`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

/**
 * App definitions
 */
const getMrfAppDefinitions = () => {
  // todo make dynamic based on API endpoint for definitions
  return {
    ACTIVITY_TYPES: {
      OUTBOUND_BALE_BATCH_SHIPPED: 'OUTBOUND_BALE_BATCH_SHIPPED',
      BALES_SCANNED_TO_OUTBOUND_BALE_BATCH: 'BALES_SCANNED_TO_OUTBOUND_BALE_BATCH',
      BALE_CODE_REPLACEMENT_SUCCESS: 'BALE_CODE_REPLACEMENT_SUCCESS',
      BALE_CODE_SCAN_TO_INVENTORY_SUCCESS: 'BALE_CODE_SCAN_TO_INVENTORY_SUCCESS',
      BALE_SCAN_IN_TO_INVENTORY_SUCCESS: 'BALE_SCAN_IN_TO_INVENTORY_SUCCESS',
      BATCH_SCAN_SUCCESS: 'BATCH_SCAN_SUCCESS',
      BATCH_SCAN_ERROR: 'BATCH_SCAN_ERROR',
      BATCH_SCAN_CANCELLED: 'BATCH_SCAN_CANCELLED',
    },
    MATERIAL_TYPES: {
      ALUMINIUM: 'ALUMINIUM',
      CARDBOARD: 'CARDBOARD',
      COFFEE_PODS: 'COFFEE_PODS',
      MIXED_PLASTIC: 'MIXED_PLASTIC',
      MIXED_PAPER_GRADE_A: 'MIXED_PAPER_GRADE_A',
      MIXED_PAPER_GRADE_B: 'MIXED_PAPER_GRADE_B',
      SOFT_PLASTIC: 'SOFT_PLASTIC',
      MOBILE_PHONES: 'MOBILE_PHONES',
      STEEL: 'STEEL',
      GLASS: 'GLASS',
      BATTERIES: 'BATTERIES',
      LOOSE_WASTE: 'LOOSE_WASTE',
      TEXTILES: 'TEXTILES',
      WASTE: 'WASTE',
    },
    ACTIVITY_TYPES__TRANSLATIONS: {
      SCANNED_IN_TO_INVENTORY_BY_MRF: 'Scanned in to inventory',
      SCANNED_OUT_OF_INVENTORY_BY_MRF: 'Scanned out of inventory',
      SCANNED_OUT_FROM_INVENTORY_BY_MRF: 'Scanned out of inventory',
      DRAFT: 'Draft',
      OUTBOUND_BALE_BATCH_SHIPPED: 'Outbound batch shipped',
      BALES_SCANNED_TO_OUTBOUND_BALE_BATCH: 'Bales scanned to outbound batch',
      BALE_CODE_REPLACEMENT_SUCCESS: 'Bale tag replaced successfully',
      BALE_CODE_SCAN_TO_INVENTORY_SUCCESS: 'Bale scanned in to inventory',
      BALE_SCAN_IN_TO_INVENTORY_SUCCESS: 'Bale scanned in to inventory',
      BATCH_SCAN_SUCCESS: 'Bags scanned successfully',
      BATCH_SCAN_ERROR: 'Bags scanned with errors',
      BATCH_SCAN_CANCELLED: 'Bag scan cancelled',
    },
    MATERIAL_TYPES__TRANSLATIONS: {
      ALUMINIUM: 'Aluminium',
      CARDBOARD: 'Cardboard',
      SOFT_PLASTIC: 'Soft Plastics',
      COFFEE_PODS: 'Aluminium Coffee Capsules',
      MIXED_PLASTIC: 'Mixed Plastic',
      MIXED_PAPER: 'Mixed paper',
      MIXED_PAPER_GRADE_A: 'Mixed Paper (Grade A)',
      MIXED_PAPER_GRADE_B: 'Mixed Paper (Grade B)',
      MOBILE_PHONES: 'Mobile Phones',
      STEEL: 'Steel',
      GLASS: 'Glass',
      TEXTILES: 'Textiles',
      BATTERIES: 'Batteries',
      WASTE: 'Waste',
      LOOSE_WASTE: 'Loose Waste',
    },
    MATERIAL_TYPES__COLORS: {
      ALUMINIUM: '#54aa46',
      CARDBOARD: '#e47723',
      SOFT_PLASTIC: '#f2e93b',
      COFFEE_PODS: '#f56c0a',
      MIXED_PLASTIC: '#603176',
      MIXED_PAPER_GRADE_A: '#0c4388',
      MIXED_PAPER_GRADE_B: '#0194ca',
      MOBILE_PHONES: '#2CB34A',
      STEEL: '#9b3379',
      GLASS: '#cb0a21',
      TEXTILES: '#263236',
      BATTERIES: '#e20547',
      WASTE: '#cb0a21',
      LOOSE_WASTE: '#cb0a21',
    },
  };
};

const scanTag = (payload) => {
  /**
     * Notes:
     * - A code can only be "activated" once by scanning, further scan attempts result in 422 response
     * - User needs to be authenticated
     * - Payload needs to look like this:
     
       {
         "redirect_url": "https://s.curby.cc/MuzbnrYIHOWF6jy",
         "profile": "6f0c1e89-2591-49c7-8605-3a6fa7e05b3b",
         "account": "80bb08e6-3bc3-4ba6-af2a-a4d777ad2ef9",
         "address": "5d289d93-3ecb-4c15-b7a7-875209e51620"
       }
       
     */
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest
      .post(`app-bff/accounts/me/curby-tag-scan-event`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  });
};

const onChange = () => {};

const getMrfSettings = (uuid) => {
  // return getAuthenticatedHttpService().then((httpRequest) => {
  //   return httpRequest.get(`management/mrfs/settings/${uuid}`).then((response) => {
  //     return response.data;
  //   });
  // });

  return {
    data: {
      displayName: 'iQRenew',
      logo: 'aHR0cHM6Ly9jZG4uY3VyYnkuY2MvbG9nby1pcXJlbmV3LnN2Zw==',
      theme: {
        primaryColor: '#3fc380',
        secondaryColor: '#475460',
      },
      sites: [
        {
          address: 'Level 7/140 Arthur St, North Sydney NSW 2060',
          phoneNumber: '(02) 9119 4950',
          isPrimary: true,
        },
      ],
      materialTypes: [
        {
          name: 'ALUMINIUM',
          status: 'active',
          baleTagColour: getMrfAppDefinitions().MATERIAL_TYPES__COLORS.ALUMINIUM,
          indicativeDryWeightPerBale: 375,
          indicativeWetWeightPerBale: 375,
        },
        {
          name: 'CARDBOARD',
          status: 'active',
          baleTagColour: getMrfAppDefinitions().MATERIAL_TYPES__COLORS.CARDBOARD,
          indicativeDryWeightPerBale: 1000,
          indicativeWetWeightPerBale: 1200,
        },
        {
          name: 'SOFT_PLASTIC',
          status: 'active',
          baleTagColour: getMrfAppDefinitions().MATERIAL_TYPES__COLORS.SOFT_PLASTIC,
          indicativeDryWeightPerBale: 58.4,
          indicativeWetWeightPerBale: 58.4,
        },
        {
          name: 'COFFEE_PODS',
          status: 'inactive',
          baleTagColour: getMrfAppDefinitions().MATERIAL_TYPES__COLORS.COFFEE_PODS,
          indicativeDryWeightPerBale: 50,
          indicativeWetWeightPerBale: 50,
        },
        {
          name: 'MIXED_PAPER_GRADE_A',
          status: 'inactive',
          baleTagColour: getMrfAppDefinitions().MATERIAL_TYPES__COLORS.MIXED_PAPER_GRADE_A,
          indicativeDryWeightPerBale: 930,
          indicativeWetWeightPerBale: 1023,
        },
        {
          name: 'MIXED_PAPER_GRADE_B',
          status: 'inactive',
          baleTagColour: getMrfAppDefinitions().MATERIAL_TYPES__COLORS.MIXED_PAPER_GRADE_B,
          indicativeDryWeightPerBale: 930,
          indicativeWetWeightPerBale: 1023,
        },
        {
          name: 'STEEL',
          status: 'active',
          baleTagColour: getMrfAppDefinitions().MATERIAL_TYPES__COLORS.STEEL,
          indicativeDryWeightPerBale: 700,
          indicativeWetWeightPerBale: 700,
        },
        {
          name: 'MIXED_PLASTIC',
          status: 'active',
          baleTagColour: getMrfAppDefinitions().MATERIAL_TYPES__COLORS.MIXED_PLASTIC,
          indicativeDryWeightPerBale: 480,
          indicativeWetWeightPerBale: 480,
        },
        {
          name: 'GLASS',
          status: 'inactive',
          baleTagColour: getMrfAppDefinitions().MATERIAL_TYPES__COLORS.GLASS,
          indicativeDryWeightPerBale: 960,
          indicativeWetWeightPerBale: 960,
        },
        {
          name: 'WASTE',
          status: 'inactive',
          baleTagColour: getMrfAppDefinitions().MATERIAL_TYPES__COLORS.WASTE,
          indicativeDryWeightPerBale: 21000,
          indicativeWetWeightPerBale: 21000,
        },
        {
          name: 'LOOSE_WASTE',
          status: 'inactive',
          baleTagColour: getMrfAppDefinitions().MATERIAL_TYPES__COLORS.LOOSE_WASTE,
          indicativeDryWeightPerBale: 920,
          indicativeWetWeightPerBale: 1012,
        },
      ],
    },
  };
};

const getAccountPoints = (uuid) => {
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management-accounts/${uuid}/points`).then((response) => {
      return response.data;
    });
  });
};

const getAccountOrders = (uuid) => {
  // console.log("[CurbCycleApi.js]", "getAccounts not yet available");
  return getAuthenticatedHttpService().then((httpRequest) => {
    return httpRequest.get(`management-accounts/account-orders/${uuid}`).then((response) => {
      return response.data;
    });
  });
};

const saveMrfSettings = (uuid, mrfSettingsData) => {
  // return getAuthenticatedHttpService().then((httpRequest) => {
  //   return httpRequest.post(`management/mrfs/settings/${uuid}`, {
  //     data: mrfSettingsData,
  //   })
  //   .then((response) => {
  //     return response.data;
  //   });
  // });

  return {};
};

const getMrfLogisticsByDate = (uuid, date) => {
  // return getAuthenticatedHttpService().then((httpRequest) => {
  //   return httpRequest.get(`management/mrfs/settings/${uuid}`).then((response) => {
  //     return response.data;
  //   });
  // });
  return {
    data: [
      {
        jobType: 'inbound',
        arrivalTime: '2021-07-21T11:10:55.840',
        departureTime: '2021-07-21T11:12:55.840',
        vehicleDetails: {
          rego: 'AA12BN',
          driverName: 'Dave Clark',
          vehicleType: 'large',
        },
        pinCode: '1032',
        customerAccount: 'Infrabuild',
        materialType: 'ALUMINIUM',
        arrivalWeightKg: '20000',
        departureWeightKg: '5000',
      },
      {
        jobType: 'outbound',
        arrivalTime: '2021-07-21T09:50:55.840',
        departureTime: '2021-07-21T11:12:55.840',
        vehicleDetails: {
          rego: 'CC12BN',
          driverName: 'Tania Tkachuk',
          vehicleType: 'large',
        },
        pinCode: '1048',
        customerAccount: 'Superior Metals',
        materialType: 'STEEL',
        arrivalWeightKg: '7000',
        departureWeightKg: '2000',
      },
      {
        jobType: 'inbound',
        arrivalTime: '2021-07-21T08:10:55.840',
        departureTime: '2021-07-21T10:19:55.840',
        vehicleDetails: {
          rego: 'CC65II',
          driverName: 'Rob Stoelhorst',
          vehicleType: 'large',
        },
        pinCode: '2209',
        customerAccount: 'VISY',
        materialType: 'MIXED_PAPER_GRADE_A',
        arrivalWeightKg: '5000',
        departureWeightKg: '1000',
      },
    ],
  };
};

const getMrfCustomers = (uuid) => {
  // return getAuthenticatedHttpService().then((httpRequest) => {
  //   return httpRequest.get(`management/mrfs/settings/${uuid}`).then((response) => {
  //     return response.data;
  //   });
  // });
  return {
    data: [
      {
        name: 'Infrabuild',
        pinCode: '1032',
      },
      {
        name: 'Superior Metals',
        pinCode: '1048',
      },
      {
        name: 'GENUINE',
        pinCode: '2260',
      },
      {
        name: 'SUEZ',
        pinCode: '1243',
      },
      {
        name: 'VISY',
        pinCode: '2209',
      },
    ],
  };
};

const saveMrfLogistics = (uuid, data) => {
  // return getAuthenticatedHttpService().then((httpRequest) => {
  //   return httpRequest.post(`management/mrfs/settings/${uuid}`, {
  //     data: mrfSettingsData,
  //   })
  //   .then((response) => {
  //     return response.data;
  //   });
  // });

  return {};
};

export {
  getStewardshipData,
  getManagementQuicksightUrl,
  getRedshiftTest,
  getStewardshipCurbyData,
  getStewardshipBaleData,
  addAddress,
  addBaleCodes,
  addLGA,
  addLGAManager,
  addMetadataFinishedSignUp,
  addMetadataIsWaitlisted,
  addMrf,
  addMrfManager,
  addMrfOperator,
  addMrfOperatorToMyMrf,
  addProfiles,
  addReminder,
  addReminders,
  addRetailSponsor,
  addRetailSponsorManager,
  checkShouldWaitlistAddress,
  checkShouldWaitlistAddressAuthenticated,
  clearAuthAndAccountData,
  createAccount,
  createAccountRequestNewOtpChallenge,
  createAccountVerifyOTPForToken,
  fetchMrfAccessPin,
  fetchMyMrfAccessPin,
  getAccessToken,
  getAccount,
  getAccountData,
  getAccountEnabledLGA,
  getAccountEnabledMaterialJourneys,
  getAllAccounts,
  getAllRedirectionLinks,
  getRedirectionLink,
  createRedirectionLink,
  updateRedirectionLink,
  getAllBaleCodes,
  getAllCurbyCodes,
  getAllLGAManagers,
  getAllLGAs,
  getAllMrfManagers,
  getAllMrfManagersAtMyMrf,
  getAllMrfOperators,
  getAllMrfOperatorsAtMyMrf,
  getAllMrfs,
  getAllMyMrfMaterialTypes,
  getAllRelevantAccountDataForCurbyApp,
  getAllRelevantCurbyAppDataForAccount,
  getAllRetailSponsorManagers,
  getAllRetailSponsors,
  getAppMetadata,
  getAuthData,
  getAuthUrlForAccessTokenWithNonce,
  getAuthUrlForIdTokenWithNonce,
  getAuthenticatedHttpService,
  getCrmIntegrationLinkData,
  getCurbyProgramDataCache,
  getEnabledAppExperiments,
  getFirstValidMrfManager,
  getGroups,
  getIdToken,
  getInboundBaleCodes,
  getLGA,
  getLGAMaterialTypeJourneyStates,
  getLgaProductStewardshipAnalytics,
  getLgaProductStewardshipAnalyticsV2,
  getLgaProvenanceDataForBales,
  getLgaProvenanceDataForBalesV2,
  getLGAQuicksightUrl,
  getMRFQuicksightUrl,
  getRSQuicksightUrl,
  getLgaAnalytics,
  getLgaPostCodeAnalytics,
  getLgaSummaryAnalytics,
  getManagementAnalytics,
  getMaterialJourneyProductStewardDropOffOptions,
  getMrf,
  getMrfAnalytics,
  getMrfSummaryAnalytics,
  getMrfPostCodeAnalytics,
  getMrfCustomers,
  getMrfLogisticsByDate,
  getMrfSettings,
  getMrfAppDefinitions,
  getMvpTrialParticipant,
  getMyMrfAccountActivities,
  getMyMrfAccountInfo,
  getMyMrfAnalyticsData,
  getMyMrfAnalyticsDataByMaterialType,
  getMyMrfProductStewardshipAnalytics,
  getMyMrfProvenanceDataForBales,
  getName,
  getNewAcccessToken,
  getOutboundBaleBatchCodes,
  getAccountPoints,
  getAccountOrders,
  getProductStewardshipAnalytics,
  getProductStewardshipAnalyticsV2,
  getProvenanceDataForBales,
  getProvenanceDataForBalesV2,
  getRetailSponsor,
  getSecret,
  getStoredStateData,
  getUnauthenticatedHttpService,
  isManagement,
  login,
  logout,
  mrfConfirmReceiptOfTagsWithWeight,
  parseJwt,
  registerPushNotificationToken,
  requestNewOtpChallenge,
  resetMrfAccessPin,
  resetMyMrfAccessPin,
  respondToOtpChallenge,
  saveMrfLogistics,
  saveSecret,
  scanTag,
  saveMrfSettings,
  storeAccountData,
  storeAuthData,
  toggleAccountMaterialJourney,
  toggleAccountMaterialLGA,
  toggleLGAMaterialTypeJourneyStates,
  toggleMaterialJourneyProductStewardDropOffOptions,
  toggleMrfManagerStatusAtMyMrf,
  toggleMrfOperatorStatusAtMyMrf,
  updateAccountName,
  updateAddress,
  updateBaleCodes,
  updateLGA,
  updateLGAManager,
  updateMrf,
  updateMrfManager,
  updateMrfManagerAtMyMrf,
  updateMrfOperatorAtMyMrf,
  updateProfile,
  updateReminder,
  updateRetailSponsor,
  updateRetailSponsorManager,
};
