import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import addMrfOperatorThunk from '../../../../../thunks/Management/MrfOperations/People/MrfOperators/addMrfOperatorThunk';
import fetchFirstValidMrfManagerThunk from '../../../../../thunks/Management/MrfOperations/People/fetchFirstValidMrfManagerThunk';
import { Formik } from 'formik';
// core components
import GridItem from '../../../../../components/Grid/GridItem';
import GridContainer from '../../../../../components/Grid/GridContainer';
import Card from '../../../../../components/Card/Card.js';
import CardHeader from '../../../../../components/Card/CardHeader.js';
import CardIcon from '../../../../../components/Card/CardIcon.js';
import CardBody from '../../../../../components/Card/CardBody.js';
import Button from '../../../../../components/CustomButtons/Button';

function AddMrfOperator({ mrfs, fetchingInitialManagerUuid, initialManagerUuid }) {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [wasSuccessful, setWasSuccessful] = useState(false);
  const history = useHistory();
  const { uuid } = useParams();

  useEffect(() => {
    if (wasSuccessful) {
      history.push(`/management/mrf-management/mrf/${uuid}/people`);
    }
  }, [wasSuccessful, isSaving, history, uuid]);

  useEffect(() => {
    dispatch(fetchFirstValidMrfManagerThunk(uuid));
  }, [dispatch, uuid]);

  if (mrfs.length < 1) {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card loading={isSaving || fetchingInitialManagerUuid}>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">Error Occurred</CardIcon>
              </CardHeader>
              <CardBody>
                <Link to="/management/mrf-management-home">
                  <blockquote style={{ color: '#g2d2g2' }}>
                    This can happen when you refresh the page manually during an operation or try to navigate directly to this screen. The application currently depends on the state provided by the
                    previous page in an effort to speed up display of information and prevent unnecessary refreshing of data.
                  </blockquote>
                  <Button style={{ margin: '15px auto', width: '100%' }} color={'info'} link={false}>
                    Return to SMRF Management Area
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  if (!fetchingInitialManagerUuid && null === initialManagerUuid) {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card loading={isSaving || fetchingInitialManagerUuid}>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">Error Occurred</CardIcon>
              </CardHeader>
              <CardBody>
                <Link to="/management/mrf-management-home">
                  <blockquote style={{ color: '#g2d2g2' }}>You need to add a SMRF Manager for this SMRF first...</blockquote>
                  <Button style={{ margin: '15px auto', width: '100%' }} color={'info'} link={false}>
                    Return to SMRF Management Area
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card loading={isSaving || fetchingInitialManagerUuid}>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">Add SMRF Operator</CardIcon>
              <Link to="/management/mrf-management-home">
                <Button color={'warning'} link={true}>
                  <span className="material-icons">cancel</span> Cancel
                </Button>
              </Link>
            </CardHeader>
            <CardBody>
              <Formik
                initialValues={{
                  username: '',
                  mrf_uuid: uuid,
                  mrf_manager_uuid: '',
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.username) {
                    errors.username = 'Required';
                  } else if (values.username.length < 3) {
                    errors.username = 'SMRF Operator usernames should be at least 3 characters';
                  } else if (!/^[A-Za-z0-9+-]{3,90}$/i.test(values.username)) {
                    errors.username =
                      'Invalid username, must be between 3 and 90 characters long, no "@","%" or "." characters are allowed, You Do not need to type the @... this will be appended to the username automatically';
                  } else if (values.username.length > 90) {
                    errors.username = 'SMRF Operator usernames should be a maximum of 90 characters';
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  values.mrf_manager_uuid = initialManagerUuid;
                  dispatch(addMrfOperatorThunk(values, uuid, setIsSaving, setWasSuccessful));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <input type="hidden" name="mrf_uuid" value={uuid} />
                    <input type="hidden" name="mrf_manager_uuid" value={initialManagerUuid} />
                    <label aria-label="SMRF Operator username">Username</label>
                    <input
                      type="text"
                      name="username"
                      style={{
                        width: '99%',
                        height: '2.2em',
                        fontSize: '1em',
                        border: '1px solid #d2d2d2',
                        paddingLeft: '10px',
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                    />
                    <span style={{ color: '#cc2526' }}>{errors.username && touched.username && errors.username}</span>
                    <Button style={{ margin: '15px auto', width: '100%' }} color={'info'} type="submit" disabled={isSaving}>
                      Save
                    </Button>
                  </form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    fetchingInitialManagerUuid: state.journeyFlows.CurbCycleManagement.mrfs.addMrfOperator.fetchingFirstValidMrfManagerUuid,
    initialManagerUuid: state.journeyFlows.CurbCycleManagement.mrfs.addMrfOperator.initialManagerUuid,
    mrfs: state.journeyFlows.CurbCycleManagement.mrfs.mrfs.data,
  };
}

AddMrfOperator.propTypes = {
  fetchingInitialManagerUuid: PropTypes.bool.isRequired,
  initialManagerUuid: PropTypes.string.isRequired,
  mrfs: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

export default connect(mapStateToProps)(AddMrfOperator);
