import {
  mrfManagementRequestedResetMrfAccessPin,
  mrfManagementCompletedResetMrfAccessPin,
} from "../../../../redux/reducer";

import { resetMyMrfAccessPin } from '../../../../services/CurbCycleApi';

export const resetMrfAccessPinThunk = (payload) => dispatch => {
  dispatch(mrfManagementRequestedResetMrfAccessPin());
  resetMyMrfAccessPin({})
    .then(result => {
      dispatch(mrfManagementCompletedResetMrfAccessPin(result));
    })
    .catch(() => {
      dispatch(mrfManagementCompletedResetMrfAccessPin({}));
    });
};

export default resetMrfAccessPinThunk;
