/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
// @material-ui/core components
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "../Navbars/RTLNavbarLinks.js";
import useSidebarStyles from "../../assets/jss/material-dashboard-react/components/sidebarStyleIm.js";
import LogoutAvatar from "components/Sidebar/components/LogoutAvatarIm";

function Sidebar(props) {
  const classes = useSidebarStyles();

  // verifies if routeName is the one active (in browser input)
  // function activeRoute(routeName) {
  //   return window.location.href.indexOf(routeName) > -1 ? true : false;
  // }

  // =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  // The isActiveRoute function will break down the window.location.pathname
  // and use it's parts to make a comparison and match the current path to the
  // route config that has the matching name and parent keys
  function isActiveRoute(theRouteConfig, allRoutesConfig) {
    let path = window.location.pathname;
    let pathParts = path.split("/");

    let potentialMatches = [];

    let routePaths = [];

    allRoutesConfig.forEach((value, valueIndex) => {
      let routePathAsString = `${value.layout}${value.path}`;
      routePaths.push(routePathAsString.split("/"));
    });

    let numberOfPartsInCurrentPath = pathParts.length;

    let round1PotentialMatches = [];

    routePaths.forEach((item, itemIndex) => {
      if (item.length === numberOfPartsInCurrentPath) {
        round1PotentialMatches.push(itemIndex);
      }
    });

    round1PotentialMatches.forEach((potentialMatch) => {
      let shouldAddPotentialMatch = true;

      pathParts.forEach((pathPart, pathPartIndex) => {
        if (routePaths[potentialMatch][pathPartIndex].indexOf(":") !== -1) {
          // assume it's a match
        } else {
          if (routePaths[potentialMatch][pathPartIndex] !== pathPart) {
            shouldAddPotentialMatch = false;
          }
        }
      });

      // at this point we can add the potential route if it's true
      if (shouldAddPotentialMatch) {
        potentialMatches.push(potentialMatch);
      }
    });

    // console.log({
    //   potentialMatches: potentialMatches,
    // });

    if (potentialMatches.length === 0) {
      // no match
      return "NO_MATCH";
    } else if (potentialMatches.length === 1) {
      // great, that's a match
      const theMatchedRoute = allRoutesConfig[potentialMatches[0]];
      // console.log({
      //   theMatchedRoute: theMatchedRoute,
      // });

      if (theMatchedRoute.parent === null) {
        return `${theMatchedRoute.layout}${theMatchedRoute.path}`;
      } else {
        // this is for child pages
        // we need to get the parent configuration and return the combination of layout and path as a string...
        let parentName = theMatchedRoute.parent;
        let matchState = "NO_MATCH";
        allRoutesConfig.forEach((potentialParent) => {
          // console.log({
          //   potentialParent: potentialParent,
          //   check1: potentialParent.name === parentName,
          //   check2: potentialParent.show === true,
          // });

          if (
            potentialParent.name === parentName &&
            potentialParent.show === true
          ) {
            matchState = `${potentialParent.layout}${potentialParent.path}`;
          }
        });
        return matchState;
      }
    } else {
      // we have more than one and more granular checks are required... should not happen
      return "NO_MATCH";
    }
  }

  const { color, logo, image, logoText, routes } = props;

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        // listItemClasses = classNames({
        //   [" " + classes[color]]: activeRoute(prop.layout + prop.path),
        // });
        // const whiteFontClasses = classNames({
        //   [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        // });

        listItemClasses = classNames({
          [" " + classes[color]]:
            prop.layout + prop.path === isActiveRoute(prop, routes),
        });
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]:
            prop.layout + prop.path === isActiveRoute(prop, routes),
        });

        if (prop.show === false) {
          return null;
        }

        // console.log(prop);
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem
              button
              className={classes.itemLink + listItemClasses}
              title={`${prop.name}`}
            >
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(
                    "material-icons-two-tone",
                    classes.itemIcon,
                    whiteFontClasses,
                    {
                      [classes.itemIconRTL]: props.rtlActive,
                    }
                  )}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(
                    "material-icons-two-tone",
                    classes.itemIcon,
                    whiteFontClasses,
                    {
                      [classes.itemIconRTL]: props.rtlActive,
                    }
                  )}
                />
              )}
              <ListItemText
                primary={props.rtlActive ? prop.rtlName : prop.name}
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive,
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
      <ListItem>
        {/* <Button link={true}> */}
        <LogoutAvatar style={{ backgroundColor: "#63707d" }} />
        <ListItemText
          primary={"Log out"}
          className={classNames(classes.itemText, {
            [classes.itemTextRTL]: props.rtlActive,
          })}
          style={{ marginLeft: "15px" }}
          disableTypography={true}
        />
        {/* </Button> */}
      </ListItem>
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="https://www.iqrenew.com/"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
        target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" style={{ height: "65px" }} />
        </div>
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{
                backgroundImage: "url(" + image + ")",
              }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{
                backgroundImage: "url(" + image + ")",
              }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  // popOut: {
  //     header: PropTypes.string,
  //     description: PropTypes.string,
  //   },
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Sidebar);
