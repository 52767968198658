import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import {
  getCurbyProgramDataCache,
  getAccountEnabledMaterialJourneys,
  toggleAccountMaterialJourney,
} from "../../../../../services/CurbCycleApi";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import Icon from "@material-ui/core/Icon";
// core components
import ProfileAvatar from "../../../../../components/Avatar/ProfileAvatar/ProfileAvatar";
import GridContainer from "../../../../../components/Grid/GridContainer";
import GridItem from "../../../../../components/Grid/GridItem";
import Card from "../../../../../components/Card/Card";
import CardHeader from "../../../../../components/Card/CardHeader";
import CardIcon from "../../../../../components/Card/CardIcon";
import Button from "../../../../../components/CustomButtons/Button";
import CardBody from "../../../../../components/Card/CardBody";
import ActionableDataTable from "../../../../../components/ActionableDataTable/ActionableDataTable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
  },
}));

function MaterialJourneys() {
  const classes = useStyles();
  const [errorResponse, setErrorResponse] = useState(undefined);
  const [wasSuccessful, setWasSuccessful] = useState(undefined);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [cacheData, setCacheData] = useState("");
  const [tableData, setTableData] = useState({ data: [] });
  const [
    enabledMaterialTypeJourneys,
    setEnabledMaterialTypeJourneys,
  ] = useState(undefined);
  const [activeIdentifier, setActiveIdentifier] = useState(null);
  const history = useHistory();
  const { uuid } = useParams();

  const account = history.location.state.account;

  useEffect(() => {
    let isCancelled = false;
    const getCacheData = async () => {
      getCurbyProgramDataCache(uuid).then((response) => {
        if (!isCancelled) {
          setCacheData(response.config.lga_details.name);
        }
      });
    };
    getCacheData();
    return () => {
      isCancelled = true;
    };
  }, [uuid]);

  useEffect(() => {
    if (wasSuccessful) {
      history.push("/management/accounts");
    }
  }, [wasSuccessful, history]);

  useEffect(() => {
    let isCancelled = false;
    const getMaterialJourneyStates = async () => {
      getAccountEnabledMaterialJourneys(uuid).then((response) => {
        if (!isCancelled) {
          setEnabledMaterialTypeJourneys(response);
          setShouldRefresh(false);
          setActiveIdentifier(null);
        }
      });
    };
    getMaterialJourneyStates();
    return () => {
      isCancelled = true;
    };
  }, [uuid]);

  useEffect(() => {
    let isCancelled = false;
    const getMaterialJourneyStates = async () => {
      getAccountEnabledMaterialJourneys(uuid).then((response) => {
        if (!isCancelled) {
          setEnabledMaterialTypeJourneys(response);
          setShouldRefresh(false);
          setActiveIdentifier(null);
        }
      });
    };
    if (shouldRefresh === true) {
      getMaterialJourneyStates();
    }
    return () => {
      isCancelled = true;
    };
  }, [shouldRefresh, uuid]);

  const handleNewToggleState = async (newToggleState) => {
    let payload = {
      key: newToggleState.key,
      enabled: newToggleState.value,
    };
    setActiveIdentifier(newToggleState.key);
    toggleAccountMaterialJourney(uuid, payload).then((response) => {
      if (
        response.code === "ACCOUNT_CANNOT_PARTICIPATE" ||
        response.code === null
      ) {
        setErrorResponse(response.code);
        setWasSuccessful(false);
        setShouldRefresh(true);
      } else {
        setWasSuccessful(true);
        setShouldRefresh(true);
      }
    });
  };

  useEffect(() => {
    if (undefined === enabledMaterialTypeJourneys) {
      return;
    }

    let newTableData = { data: [] };
    newTableData.data = enabledMaterialTypeJourneys.data.map((item) => {
      return [
        {
          display: `${item.key}`,
        },
        {
          display: `${item.value}`,
        },
        {
          hasActions: true,
          actions: [
            {
              type: "toggle-input-switch",
              color: "primary",
              label: item.enabled === true ? "On" : "Off",
              spinnerLabel: "Updating",
              description: "Material Journeys",
              size: "medium",
              context: {
                initialToggleState: {
                  identifier: `${item.key}`,
                  value: item.enabled === true ? true : false,
                },
                toggleState: {
                  identifier: `${item.key}`,
                  value: undefined,
                },
                isSaving: false,
                activeIdentifier: null,
                onToggleSwitch: (newToggleState) => {
                  handleNewToggleState(newToggleState);
                },
              },
            },
          ],
        },
      ];
    });
    setTableData(newTableData);
  }, [enabledMaterialTypeJourneys]);

  useEffect(() => {
    if (undefined === enabledMaterialTypeJourneys) {
      return;
    }

    if (activeIdentifier !== null) {
      let newTableData = { data: [] };
      newTableData.data = enabledMaterialTypeJourneys.data.map((item) => {
        return [
          {
            display: `${item.key}`,
          },
          {
            display: `${item.value}`,
          },
          {
            hasActions: true,
            actions: [
              {
                type: "toggle-input-switch",
                color: "primary",
                label: item.enabled === true ? "On" : "Off",
                spinnerLabel: "Updating",
                description: "Material Journeys",
                size: "medium",
                context: {
                  initialToggleState: {
                    identifier: `${item.key}`,
                    value: item.enabled === true ? true : false,
                  },
                  toggleState: {
                    identifier: `${item.key}`,
                    value: undefined,
                  },
                  isSaving: true,
                  activeIdentifier: activeIdentifier,
                  onToggleSwitch: (newToggleState) => {
                    handleNewToggleState(newToggleState);
                  },
                },
              },
            ],
          },
        ];
      });
      setTableData(newTableData);
    }
  }, [activeIdentifier]);

  if (undefined === enabledMaterialTypeJourneys) {
    return <div>Loading</div>;
  }

  if (undefined === account) {
    return <div>Loading</div>;
  }

  if (account.addresses[0] === undefined) {
    return (
      <Fade in={true} timeout={3000}>
        <Card>
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger">Failed with error</CardIcon>
          </CardHeader>
          <br />
          <CardBody>
            <GridContainer
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
            >
              <GridItem>
                <Button
                  link={true}
                  onClick={() => {
                    history.push({
                      pathname: `/management/accounts`,
                      state: {
                        account: account,
                      },
                    });
                    setErrorResponse(undefined);
                    setWasSuccessful(undefined);
                  }}
                >
                  <Icon>arrow_back</Icon>
                  <label style={{ fontSize: "18px" }}>Back</label>
                </Button>
              </GridItem>
            </GridContainer>
            <div>
              <h1>Incomplete Registration</h1>
              <p style={{ fontSize: "20px", fontWeight: 200 }}>
                The account user with registration email{" "}
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: 200,
                    color: "blue",
                  }}
                >
                  {" "}
                  {account.email}
                </span>{" "}
                has not completed registration. The user is required to enter a
                valid address during registration. Direct them the the App
                registration page.
              </p>

              <p style={{ fontSize: "20px", fontWeight: 200 }}>
                Account User Identification number:
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: 200,
                    color: "blue",
                  }}
                >
                  {" "}
                  {account.uuid}
                </span>
              </p>
            </div>
          </CardBody>
        </Card>
      </Fade>
    );
  }

  if (wasSuccessful === false) {
    return (
      <Fade in={true} timeout={3000}>
        <Card>
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger">Failed with error</CardIcon>
          </CardHeader>
          <br />
          <CardBody>
            <GridContainer
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
            >
              <GridItem>
                <Button
                  link={true}
                  onClick={() => {
                    history.push({
                      pathname: `/management/material-type/${uuid}`,
                      state: {
                        account: account,
                      },
                    });
                    setErrorResponse(undefined);
                    setWasSuccessful(undefined);
                  }}
                >
                  <Icon>arrow_back</Icon>
                  <label style={{ fontSize: "18px" }}>Back</label>
                </Button>
              </GridItem>
            </GridContainer>
            <div>
              <h1>{errorResponse}</h1>
              <p style={{ fontSize: "20px", fontWeight: 200 }}>
                Please check if {account.name}'s address is in a paticapating
                LGA for this program and material type
              </p>
              <p style={{ fontSize: "20px", fontWeight: 200 }}>
                LGA: {cacheData}
              </p>
              <p style={{ fontSize: "20px", fontWeight: 200 }}>
                {account.addresses[0].formatted_address}
              </p>
            </div>
          </CardBody>
        </Card>
      </Fade>
    );
  }

  return (
    <Fade in={true} timeout={3000}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon
                color="success"
                style={{ fontSize: "22px", fontWeight: 400 }}
              >
                Enable Material Journeys
              </CardIcon>
            </CardHeader>
            <br />
            <CardBody>
              <GridContainer
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <GridItem>
                  <Button
                    link={true}
                    onClick={() => {
                      history.push({
                        pathname: `/management/accounts/${uuid}`,
                        state: {
                          account: account,
                        },
                      });
                    }}
                  >
                    <Icon>arrow_back</Icon>
                    <label style={{ fontSize: "18px" }}>Back</label>
                  </Button>
                </GridItem>
              </GridContainer>

              <GridContainer
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <GridItem xs={4}>
                  <Card>
                    <CardIcon color="primary">
                      <ProfileAvatar
                        profile={{ avatar: "curby" }}
                      ></ProfileAvatar>
                    </CardIcon>
                    <br />
                    <p
                      style={{
                        fontSize: "22px",
                        paddingLeft: 150,
                        paddingRight: 5,
                      }}
                    >
                      {account.email}
                    </p>
                    <CardBody>
                      <p style={{ fontSize: "18px" }}>
                        {account.addresses[0].formatted_address}
                      </p>
                      <p style={{ fontSize: "18px" }}>
                        {account.name}'s account has ({account.account_points})
                        points
                      </p>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={4}>
                  <Card>
                    <CardHeader color="success" stats icon>
                      <CardIcon
                        color="success"
                        style={{ fontSize: "18px", fontWeight: 400 }}
                      >
                        LGA {cacheData}
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <br />
                      <p>
                        <i style={{ fontSize: "22px" }}>
                          enable/disable LGA engagement contact council button
                        </i>
                      </p>
                      <div style={{ margin: "auto", width: "50%" }}>
                        <Button
                          style={{
                            marginTop: 25,
                            marginBottom: 25,
                          }}
                          onClick={() => {
                            history.push({
                              pathname: `/management/enabled-lga-engagement-options/${uuid}`,
                              state: {
                                account: account,
                              },
                            });
                          }}
                          size="lg"
                          color="info"
                          name="LGA Engagement Options"
                        >
                          LGA Engagement Options
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
              <ActionableDataTable
                tableHeaderColor="warning"
                tableHead={["Material", "Type", "Actions"]}
                tableData={tableData.data}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Fade>
  );
}

MaterialJourneys.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        account: PropTypes.shape({
          account_points: PropTypes.number,
          uuid: PropTypes.string,
          name: PropTypes.string,
          addresses: PropTypes.arrayOf({
            formatted_address: PropTypes.string,
          }),
          email: PropTypes.string,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  accountData: PropTypes.any.isRequired,
};

export default MaterialJourneys;
