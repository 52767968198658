module.exports = {
  component: 'vfb-mb-enrol-for-membership-rewards',
  elementPrefix: '',
  name: "MB: Enrol for membership rewards",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'marketing_opt_in'
    }
  ]
}
