import {
  managementRequestedEditLGA,
  managementCompletedEditLGA,
} from "../../../redux/reducer";

import { updateLGA } from '../../../services/CurbCycleApi';

export const editLGAThunk = (payload, uuid, setIsSaving, setWasSuccessful) => dispatch => {
  dispatch(managementRequestedEditLGA());
  setIsSaving(true);
  updateLGA(uuid, payload)
    .then(result => {
      dispatch(managementCompletedEditLGA(result));
      setIsSaving(false);
      setWasSuccessful(true);
    })
    .catch(() => {
      dispatch(managementCompletedEditLGA({}));
      setIsSaving(false);
    });
};

export default editLGAThunk;
