import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import addMrfManagerThunk from "../../../../../thunks/Management/MrfOperations/People/MrfManagers/addMrfManagerThunk";
// @material-ui/core
import { Formik } from "formik";
// core components
import GridItem from "../../../../../components/Grid/GridItem";
import GridContainer from "../../../../../components/Grid/GridContainer";
import Card from "../../../../../components/Card/Card.js";
import CardHeader from "../../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../../components/Card/CardIcon.js";
import CardBody from "../../../../../components/Card/CardBody.js";
import Button from "../../../../../components/CustomButtons/Button";

function AddMrfManager({ mrfs }) {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [wasSuccessful, setWasSuccessful] = useState(false);
  const history = useHistory();
  const { uuid } = useParams();

  useEffect(() => {
    if (wasSuccessful) {
      history.push(`/management/mrf-management/mrf/${uuid}/people`);
    }
  }, [wasSuccessful, isSaving, history, uuid]);

  if (mrfs.length < 1) {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card loading={isSaving}>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">Error Occurred</CardIcon>
              </CardHeader>
              <CardBody>
                <Link to="/management/mrf-management-home">
                  <blockquote style={{ color: "#g2d2g2" }}>
                    This can happen when you refresh the page manually during an
                    operation or try to navigate directly to this screen. The
                    application currently depends on the state provided by the
                    previous page in an effort to speed up display of
                    information and prevent unnecessary refreshing of data.
                  </blockquote>
                  <Button
                    style={{ margin: "15px auto", width: "100%" }}
                    color={"info"}
                    link={false}
                  >
                    Return to SMRF Management Area
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card loading={isSaving}>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">Add SMRF Manager</CardIcon>
              <Link to="/management/mrf-management-home">
                <Button color={"warning"} link={true}>
                  <span className="material-icons">cancel</span> Cancel
                </Button>
              </Link>
            </CardHeader>
            <CardBody>
              <Formik
                initialValues={{ username: "", mrf_uuid: uuid }}
                validate={(values) => {
                  const errors = {};
                  if (!values.username) {
                    errors.username = "Required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.username
                    )
                  ) {
                    errors.username = "Please enter a valid email address";
                  } else if (values.username.length > 100) {
                    errors.username =
                      "SMRF Manager email addresses should be a maximum of 100 characters";
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  dispatch(
                    addMrfManagerThunk(
                      values,
                      uuid,
                      setIsSaving,
                      setWasSuccessful
                    )
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <label aria-label="SMRF Manager email address">
                      Email Address
                    </label>
                    <input type="hidden" name="mrf_uuid" value={uuid} />
                    <input
                      type="text"
                      name="username"
                      style={{
                        width: "99%",
                        height: "2.2em",
                        fontSize: "1em",
                        border: "1px solid #d2d2d2",
                        paddingLeft: "10px",
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                    />
                    <span style={{ color: "#cc2526" }}>
                      {errors.username && touched.username && errors.username}
                    </span>
                    <Button
                      style={{ margin: "15px auto", width: "100%" }}
                      color={"info"}
                      type="submit"
                      disabled={isSaving}
                    >
                      Save
                    </Button>
                  </form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    mrfs: state.journeyFlows.CurbCycleManagement.mrfs.mrfs.data,
  };
}

AddMrfManager.propTypes = {
  mrfs: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

export default connect(mapStateToProps)(AddMrfManager);
