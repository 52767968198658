import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, connect } from "react-redux";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import logo from "../../../assets/img/curbcycle-tagline.png";
import logOutThunk from "../../../thunks/Auth/logOutThunk";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

function Unauthorized({ tempEmail, isLoggedIn }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    console.log(isLoggedIn);
  }, [isLoggedIn]);

  return (
    <div className="container-fluid" style={{ margin: "0 auto", width: "60%" }}>
      <img
        src={logo}
        alt=""
        style={{
          marginLeft: "20%",
          marginRight: "20%",
          marginTop: "25px",
          marginBottom: "25px",
          maxWidth: "60%",
        }}
      />
      <Card>
        <CardHeader color="success">
          <h4 className={classes.cardTitleWhite}>Unauthorised access</h4>
          <p className={classes.cardCategoryWhite}>
            Your access attempt has been logged and administrators have been
            notified
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                style={{ margin: "15px auto", width: "100%" }}
                color="danger"
                type="submit"
                onClick={() => dispatch(logOutThunk(null, null))}
              >
                Log out
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    tempEmail: state.tempEmail.email,
    isLoggedIn: state.isLoggedIn,
  };
}

Unauthorized.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  tempEmail: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(Unauthorized);
