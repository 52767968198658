import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
import GridItem from "../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../components/Grid/GridContainer.js";
import Alert from "@material-ui/lab/Alert";
// custom components
import SiteDetails from "./SiteDetails/SiteDetails";
import Materials from "./Materials/Materials";
import Button from "../../layouts/components/CustomButtons/Button";
import Spinner from "../../layouts/components/Spinner/Spinner";
import Card from "../../layouts/components/Card/Card";
import CardHeader from "../../layouts/components/Card/CardHeader.js";
import CardBody from "../../layouts/components/Card/CardBody.js";
import { saveMrfSettings } from "../../../../../services/CurbCycleApi";
import ThemeDetails from "./ThemeDetails/ThemeDetails";

function MrfSettingsPage({
  getSettingsAttemptFailed,
  globalLogo,
  mrfSettingsData,
  mrfUuid,
  setLogo,
  setTheme,
  theme,
  updateMrfSettings,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const theCardHeaderStyle = {
    fontFamily: "nunito",
    fontSize: "2.0em",
    padding: 30,
  };

  const saveMrfSettingsData = async () => {
    setIsLoading(true);
    try {
      await saveMrfSettings(mrfUuid, mrfSettingsData);
    } catch (err) {
      // handle error
    }
    setIsLoading(false);
  };

  const onSaveButtonClick = async () => {
    await saveMrfSettingsData();
  };

  const setPrimaryColor = (color) => {
    updateMrfSettings({
      ...mrfSettingsData,
      theme: { ...mrfSettingsData.theme, primaryColor: color },
    });
  };

  const setSecondaryColor = (color) => {
    updateMrfSettings({
      ...mrfSettingsData,
      theme: { ...mrfSettingsData.theme, secondaryColor: color },
    });
  };

  const setName = (name) => {
    updateMrfSettings({
      ...mrfSettingsData,
      displayName: name,
    });
  };

  const setSites = (sites) => {
    updateMrfSettings({
      ...mrfSettingsData,
      sites: sites,
    });
  };

  const setMaterialTypes = (materialTypes) => {
    updateMrfSettings({
      ...mrfSettingsData,
      materialTypes: materialTypes,
    });
  };

  const setLogoData = (logo) => {
    setLogo(logo);
    updateMrfSettings({
      ...mrfSettingsData,
      logo: logo,
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (getSettingsAttemptFailed) {
    return (
      <>
        <Alert severity="error">
          Oops! We were not able to get your data. Please try again later.
        </Alert>
      </>
    );
  }

  return (
    <Fade in={true} timeout={3000}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" style={theCardHeaderStyle}>
              SMRF Settings
            </CardHeader>
            <CardBody>
              <GridContainer
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-end"
              >
                <GridItem xs={12} sm={12} md={12}>
                  <SiteDetails
                    configData={mrfSettingsData}
                    setName={setName}
                    setSites={setSites}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Materials
                    materialTypes={mrfSettingsData?.materialTypes}
                    setMaterialTypes={setMaterialTypes}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <ThemeDetails
                    primaryColor={mrfSettingsData?.theme?.primaryColor}
                    secondaryColor={mrfSettingsData?.theme?.secondaryColor}
                    setLogo={setLogoData}
                    setPrimaryColor={setPrimaryColor}
                    setSecondaryColor={setSecondaryColor}
                    logo={mrfSettingsData?.logo}
                    defaultLogo={globalLogo}
                    theme={theme}
                    setTheme={setTheme}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Button
                    style={{
                      margin: "15px auto",
                      width: "30%",
                      display: "flex",
                    }}
                    color="primary"
                    type="submit"
                    onClick={onSaveButtonClick}
                    disabled={
                      !mrfSettingsData ||
                      !mrfSettingsData?.sites ||
                      mrfSettingsData?.displayName === "" ||
                      mrfSettingsData?.sites[0].address === "" ||
                      mrfSettingsData?.sites[0].phoneNumber === ""
                    }
                  >
                    Save
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Fade>
  );
}

MrfSettingsPage.propTypes = {
    getSettingsAttemptFailed: PropTypes.bool.isRequired,
  globalLogo: PropTypes.string.isRequired,
  mrfSettingsData: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    materialTypes: PropTypes.arrayOf({
      name: PropTypes.string.isRequired,
      baleTagColour: PropTypes.string.isRequired,
indicativeDryWeightPerBale: PropTypes.number.isRequired,
    indicativeWetWeightPerBale: PropTypes.number.isRequired,
    }),
    displayName: PropTypes.string.isRequired,
    sites: PropTypes.arrayOf({
      address: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string.isRequired,
    }),
    theme: PropTypes.shape({
      primaryColor: PropTypes.string.isRequired,
      secondaryColor: PropTypes.string.isRequired,
    }).isRequired,
  }),
  mrfUuid: PropTypes.string.isRequired,
  setLogo: PropTypes.func.isRequired,
  setTheme: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string.isRequired,
      }).isRequired,
      secondary: PropTypes.shape({
        main: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  updateMrfSettings: PropTypes.func.isRequired,
};

export default MrfSettingsPage;
