import React, { useState } from 'react';
import { Formik } from 'formik';
import { encodeString, decodeString } from '../../../../utilities/helpers/helpers';
import { updateRedirectionLink } from '../../../../services/CurbCycleApi';
import Button from '../../../../components/CustomButtons/Button';

const UpdateForm = ({ onUpdateSuccess, onUpdateError, uiContextData }) => {
  const [isSaving, setIsSaving] = useState(false);

  const theInitialValues = { ...uiContextData };
  theInitialValues.label = decodeString(uiContextData.label);
  theInitialValues.description = decodeString(uiContextData.description);

  return (
    <>
      <Formik
        initialValues={theInitialValues}
        validate={(values) => {
          const errors = {};
          if (!values.code) {
            errors.code = 'Required';
          } else if (!/^[ a-z0-9\-]{3,15}$/i.test(values.code)) {
            errors.code = 'Invalid code, please make sure it is all lower case letters, numbers and dashes.  A minimum of 3 characters and a maximum of 15.';
          }

          if (!values.label) {
            errors.label = 'Required';
          } else if (!/^[ A-Za-z0-9._%+-]{2,255}$/i.test(values.label)) {
            errors.label = 'Give it a label to help identify the code in future... Example: 2021 CCC Retail Integration Trial';
          }

          if (!values.description) {
            errors.description = 'Required';
          } else if (!/^[ A-Za-z0-9.,_%+-]{2,255}$/i.test(values.description)) {
            errors.description =
              'Give it a description to help you understand its purpose in future but avoid using any special characters... Example: Used for polo shirts only in Central Coast Council, requested by Sarah at CCC';
          }

          if (!values.destination) {
            errors.destination = 'Required';
          } else if (!/^(https:\/\/)[ A-Za-z0-9._%+-/?&=]{2,}$/i.test(values.destination)) {
            errors.destination = 'You need to provide a secure https:// link to redirect to... Example: https://google.com.au';
          }

          return errors;
        }}
        onSubmit={(values) => {
          setIsSaving(true);

          let valuesToSend = { ...values };
          valuesToSend.label = encodeString(valuesToSend.label);
          valuesToSend.description = encodeString(valuesToSend.description);

          updateRedirectionLink(theInitialValues.code, valuesToSend)
            .then((response) => {
              if (response.code !== 'SUCCESS') {
                onUpdateError(response);
              } else {
                onUpdateSuccess(response);
              }
              setIsSaving(false);
            })
            .catch((error) => {
              onUpdateError({ code: 'FAILED', reason: 'Server Error' });
              setIsSaving(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            {/*<label aria-label="Code">Code</label>*/}
            <p>
              Updating values for code: <strong>{uiContextData.code}</strong>
            </p>

            <input
              type="hidden"
              name="code"
              style={{
                width: '99%',
                height: '2.2em',
                fontSize: '1em',
                border: '1px solid #d2d2d2',
                paddingLeft: '10px',
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.code}
            />
            {errors.code && touched.code && errors.code}
            <br />
            <br />
            <label aria-label="Label">Label</label>
            <input
              type="text"
              name="label"
              style={{
                width: '99%',
                height: '2.2em',
                fontSize: '1em',
                border: '1px solid #d2d2d2',
                paddingLeft: '10px',
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.label}
            />
            {errors.label && touched.label && errors.label}
            <br />
            <br />
            <label aria-label="Description">Description</label>
            <input
              type="text"
              name="description"
              style={{
                width: '99%',
                height: '2.2em',
                fontSize: '1em',
                border: '1px solid #d2d2d2',
                paddingLeft: '10px',
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
            />
            {errors.description && touched.description && errors.description}
            <br />
            <br />
            <label aria-label="Destination">Destination</label>
            <input
              type="text"
              name="destination"
              style={{
                width: '99%',
                height: '2.2em',
                fontSize: '1em',
                border: '1px solid #d2d2d2',
                paddingLeft: '10px',
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.destination}
            />
            {errors.destination && touched.destination && errors.destination}
            <br />
            <br />
            <span style={{ color: '#cc2526' }}></span>
            <Button style={{ margin: '15px auto', width: '100%' }} color={'info'} type="submit" disabled={isSaving}>
              Save
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default UpdateForm;
