import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

function BigNumberProgressBar({
  bigNumberText,
  bigNumberTextMeasurementText = null,
  optionalText = null,
  progressBarValue = null,
  barBackgroundColor = "#f05673",
  children,
}) {
  let barBackgroundColorToUse = "#f05673";

  switch (barBackgroundColor) {
    case "primary":
      break;
    case "secondary":
      barBackgroundColorToUse = "#262626";
      break;
    default:
      barBackgroundColorToUse = "#f05673";
      break;
  }

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 15,
      borderRadius: 0,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: barBackgroundColorToUse,
    },
  }))(LinearProgress);

  let bigNumberPadding = "1em";
  if (
    null === optionalText &&
    null === progressBarValue &&
    undefined === children
  ) {
    bigNumberPadding = "2.5em";
  }

  return (
    <div style={{ height: "14vh", minHeight: "120px", maxHeight: "120px" }}>
      <div
        style={{
          paddingTop: bigNumberPadding,
          textAlign: "center",
          verticalAlign: "center",
        }}
      >
        <span
          style={{
            fontSize: "2.8em",
            fontWeight: "700",
          }}
        >
          {bigNumberText}
          <span
            style={{
              fontSize: "0.4em",
              fontWeight: "500",
            }}
          >
            {null !== bigNumberTextMeasurementText
              ? bigNumberTextMeasurementText
              : ""}
          </span>
        </span>
      </div>

      <div
        style={{
          display: null === optionalText ? "none" : "flex",
          paddingTop: "0.7em",
        }}
      >
        <span
          style={{
            fontSize: "1.1em",
            fontWeight: "400",
          }}
        >
          {optionalText}
        </span>
      </div>

      <div
        style={{
          display: undefined === children ? "none" : "flex",
          paddingTop: "0.7em",
        }}
      >
        {children}
      </div>

      <div
        style={{
          display: null === progressBarValue ? "none" : "inherit",
          paddingTop: "0.7em",
        }}
      >
        <BorderLinearProgress
          variant="determinate"
          value={parseFloat(progressBarValue)}
        />
      </div>
    </div>
  );
}

BigNumberProgressBar.propTypes = {
  barBackgroundColor: PropTypes.string.isRequired,
  bigNumberText: PropTypes.string.isRequired,
  bigNumberTextMeasurementText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  optionalText: PropTypes.string || null,
  progressBarValue: PropTypes.string.isRequired,
};

export default BigNumberProgressBar;
