import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import GridItem from "../../../../../components/Grid/GridItem";
import GridContainer from "../../../../../components/Grid/GridContainer";
import Card from "../../../../../components/Card/Card.js";
import CardHeader from "../../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../../components/Card/CardIcon.js";
import CardBody from "../../../../../components/Card/CardBody.js";
import Button from "../../../../../components/CustomButtons/Button";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import addRetailSponsorThunk from "../../../../../thunks/Management/RetailSponsors/addRetailSponsorThunk";

function AddRetailSponsor() {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [wasSuccessful, setWasSuccessful] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (wasSuccessful === true) {
      history.push("/management/retail-sponsor-management-home");
    }
  }, [wasSuccessful, history]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">Add Retail Sponsor</CardIcon>
              <Link to="/management/retail-sponsor-management-home">
                <Button color={"warning"} link={true}>
                  <span className="material-icons">cancel</span> Cancel
                </Button>
              </Link>
            </CardHeader>
            <CardBody>
              <Formik
                initialValues={{ name: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = "Required";
                  } else if (!/^[ A-Za-z0-9._%+-]{2,255}$/i.test(values.name)) {
                    errors.name = "Invalid Retail Sponsor Name";
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  console.log(values);
                  dispatch(
                    addRetailSponsorThunk(values, setIsSaving, setWasSuccessful)
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <label aria-label="Retail Sponsor Name">Name</label>
                    <input
                      type="text"
                      name="name"
                      style={{
                        width: "99%",
                        height: "2.2em",
                        fontSize: "1em",
                        border: "1px solid #d2d2d2",
                        paddingLeft: "10px",
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <span style={{ color: "#cc2526" }}>
                      {errors.name && touched.name && errors.name}
                    </span>
                    <Button
                      style={{ margin: "15px auto", width: "100%" }}
                      color={"info"}
                      type="submit"
                      disabled={isSaving}
                    >
                      Save
                    </Button>
                  </form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(AddRetailSponsor);
