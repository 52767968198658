
import React, { useEffect, useState } from "react";
// thunks
// @material-ui/core
import Fade from "@material-ui/core/Fade";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Overview from "pages/JourneyFlows/SharedPages/Analytics/Overview.js";
import { getMRFQuicksightUrl } from "services/CurbCycleApi";
import UserStatsTable from "components/DashboardComponents/UserStatsTable";
import PostCodeStatsTable from "components/DashboardComponents/PostCodeStatsTable";
import StewardStatsTable from "components/DashboardComponents/StewardStatsTable";
import GenericBarGraph from "components/DashboardComponents/GenericBarGraph";
import MrfStatsTable from "components/DashboardComponents/MrfStatsTable";
import GenericScanBarGraph from "components/DashboardComponents/GenericScanBarGraph";
import GenericBaleBarGraph from "components/DashboardComponents/GenericBaleBarGraph.js";
import SignupsByProgramBarGraph from "components/DashboardComponents/SignupsByProgramBarGraph";
import { Divider } from "@material-ui/core";
import Spinner from "components/Spinner/Spinner.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Dashboard from "components/DashboardComponents/Dashboard.js";

function MrfDashboard() {
  const [selectedProgram, setSelectedProgram] = useState(0);
  const handleChangeProgram = (event) => {
    setSelectedProgram(event.target.value);
  };
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  function handleChangePeriod(item) {
    setSelectedPeriod(item);
  };
  const [selectedDataAggregation, setSelectedDataAggregation] = useState(0);
  function handleChangeDataAggregation(item) {
    setSelectedDataAggregation(item);
  };
  const [analyticsData, setAnalyticsData] = useState(null);
  const [monthlyAnalyticsData, setMonthlyAnalyticsData] = useState(null);
  const [dashUrl, setDashUrl] = useState(null);

  useEffect(() => {
    getMRFQuicksightUrl().then((data) => {
      setDashUrl(data.EmbedUrl)
    
    })
  }, []);

  
  return (
    <div>
      <Fade in={true} timeout={3000}>
        <GridContainer
          style={{
            width: "100%",
            height: "140%",
          }}
          id="productStewardshipHeadingBox"
        >
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
            

            <Dashboard dashUrl={dashUrl}/>
        
        </GridItem>
        </GridContainer>
            
            

            
      </Fade>
    </div>
  );
}


export default MrfDashboard;
