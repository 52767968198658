import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
// import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../../layouts/components/Grid/GridItem.js";
import GridContainer from "../../../layouts/components/Grid/GridContainer.js";
import Spinner from "../../../layouts/components/Spinner/Spinner";
// custom components
// project tools
import useDashboardStyleIm from "../../../../../../assets/jss/material-dashboard-react/views/dashboardStyleIm.js";
import materialTypeHelper from "../../../../../../utilities/materialTypeHelper";
import CardRow from "./CardRow";

export default function InventoryMaterialTypeSummary({
  onClickedAction,
  materialTypes,
  loading,
}) {
  const classes = useDashboardStyleIm();
  const successText = {
    fontSize: "20px",
    paddingLeft: "5px",
    marginTop: "6px",
    paddingTop: "6px",
  };

  if (loading || !materialTypes) {
    return <Spinner centerWindow={true} />;
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <h5 className={classes.successText} style={successText}>
            Material Type
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <h5 className={classes.successText} style={successText}>
            Bales In Stock
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <h5 className={classes.successText} style={successText}>
            Tonnes <small>(dry)</small>
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <h5 className={classes.successText} style={successText}>
            Tonnes <small>(wet)</small>
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <h5 className={classes.successText} style={successText}>
            Ave. Condition
          </h5>
        </GridItem>
      </GridContainer>
      {materialTypes.length > 0 &&
        materialTypes.map((key, value) => {
          const materialData = {
            materialType:
              materialTypeHelper.getMaterialName(key.name) || "No data",
            colorKey: key.baleTagColour || "No data",
            dry: key.indicativeDryWeightPerBale / 1000 || 0,
            wet: key.indicativeWetWeightPerBale / 1000 || 0,
            activeStatus: key.status,
          };
          return (
            <CardRow
              materialTypeKey={key.name}
              key={key.name}
              onClickedAction={onClickedAction}
              materialData={materialData}
            />
          );
        })}
    </>
  );
}

InventoryMaterialTypeSummary.propTypes = {
  onClickedAction: PropTypes.func.isRequired,
  materialTypes: PropTypes.arrayOf({
    name: PropTypes.string.isRequired,
    baleTagColour: PropTypes.string.isRequired,
    indicativeDryWeightPerBale: PropTypes.number.isRequired,
    indicativeWetWeightPerBale: PropTypes.number.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};
