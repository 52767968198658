import { getMrfAppDefinitions } from "../services/CurbCycleApi";

const colors = getMrfAppDefinitions().MATERIAL_TYPES__COLORS;
const materialTranslations = getMrfAppDefinitions()
  .MATERIAL_TYPES__TRANSLATIONS;
const activityTranslations = getMrfAppDefinitions()
  .ACTIVITY_TYPES__TRANSLATIONS;
const materialTypeHelper = {
  getBackgroundColor(materialType) {
    return colors[materialType] ? colors[materialType] : "transparent";
  },
  getActivityName(activityType) {
    return activityTranslations[activityType]
      ? activityTranslations[activityType]
      : activityType;
  },
  getMaterialName(materialType) {
    return materialTranslations[materialType]
      ? materialTranslations[materialType]
      : materialType;
  },
  getMaterialTypes() {
    return Object.keys(colors);
  },
  formatWeightGramsToTons(weight_in_g) {
    if (!weight_in_g || weight_in_g === true) {
      return weight_in_g;
    }
    if (weight_in_g === 0) {
      return 0;
    }
    return weight_in_g / 1000 / 1000;
  },
};
export default materialTypeHelper;
