import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import editLGAThunk from "../../../../thunks/Management/LGAs/editLGAThunk";
import {
  getLGAMaterialTypeJourneyStates as getLGAMaterialTypeJourneyStatesAPI,
  toggleLGAMaterialTypeJourneyStates,
} from "services/CurbCycleApi";
import { Formik } from "formik";
// core components
import ActionableDataTable from "../../../../components/ActionableDataTable/ActionableDataTable";
import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../components/Card/CardIcon.js";
import CardBody from "../../../../components/Card/CardBody.js";
import Button from "../../../../components/CustomButtons/Button";

function EditLGA({ lgas }) {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [wasSuccessful, setWasSuccessful] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const [tableData, setTableData] = useState({
    data: [],
  });
  const [activeIdentifier, setActiveIdentifier] = useState(null);
  const [
    enabledLGAMaterialTypeJourneys,
    setEnabledLGAMaterialTypeJourneys,
  ] = useState(undefined);
  const history = useHistory();
  const { uuid } = useParams();

  useEffect(() => {
    if (wasSuccessful) {
      history.push("/management/lga-management-home");
    }
  }, [wasSuccessful, history]);

  useEffect(() => {
    let isCancelled = false;
    const getLGAMaterialTypeJourneyStates = async () => {
      getLGAMaterialTypeJourneyStatesAPI(uuid).then((response) => {
        if (!isCancelled) {
          setEnabledLGAMaterialTypeJourneys(response);
          setShouldRefresh(false);
          setActiveIdentifier(null);
        }
      });
    };
    getLGAMaterialTypeJourneyStates();
    return () => {
      isCancelled = true;
    };
  }, [uuid]);

  useEffect(() => {
    let isCancelled = false;
    const getLGAMaterialTypeJourneyStates = async () => {
      getLGAMaterialTypeJourneyStatesAPI(uuid).then((response) => {
        if (!isCancelled) {
          console.log(response);
          setEnabledLGAMaterialTypeJourneys(response);
          setShouldRefresh(false);
          setActiveIdentifier(null);
        }
      });
    };
    if (shouldRefresh === true) {
      getLGAMaterialTypeJourneyStates();
    }
    return () => {
      isCancelled = true;
    };
  }, [shouldRefresh, uuid]);

  const handleNewToggleState = async (newToggleState) => {
    let payload = {
      key: newToggleState.key,
      enabled: newToggleState.value,
    };
    setActiveIdentifier(newToggleState.key);
    toggleLGAMaterialTypeJourneyStates(uuid, payload).then((response) => {
      setShouldRefresh(true);
    });
  };

  useEffect(() => {
    if (undefined === enabledLGAMaterialTypeJourneys) {
      return;
    }

    let newTableData = { data: [] };
    newTableData.data = enabledLGAMaterialTypeJourneys.data.map((item) => {
      return [
        {
          display: `${item.key}`,
        },
        {
          display: `${item.value}`,
        },
        {
          hasActions: true,
          actions: [
            {
              type: "toggle-input-switch",
              color: "primary",
              label: item.enabled === true ? "On" : "Off",
              spinnerLabel: "Updating",
              description: "LGA Material Journeys",
              size: "medium",
              context: {
                initialToggleState: {
                  identifier: `${item.key}`,
                  value: item.enabled === true ? true : false,
                },
                toggleState: {
                  identifier: `${item.key}`,
                  value: undefined,
                },
                isSaving: false,
                activeIdentifier: null,
                onToggleSwitch: (newToggleState) => {
                  handleNewToggleState(newToggleState);
                },
              },
            },
          ],
        },
      ];
    });
    setTableData(newTableData);
  }, [enabledLGAMaterialTypeJourneys]);

  useEffect(() => {
    if (undefined === enabledLGAMaterialTypeJourneys) {
      return;
    }

    if (activeIdentifier !== null) {
      let newTableData = { data: [] };
      newTableData.data = enabledLGAMaterialTypeJourneys.data.map((item) => {
        return [
          {
            display: `${item.key}`,
          },
          {
            display: `${item.value}`,
          },
          {
            hasActions: true,
            actions: [
              {
                type: "toggle-input-switch",
                color: "primary",
                label: item.enabled === true ? "On" : "Off",
                spinnerLabel: "Updating",
                description: "LGA Material Journeys",
                size: "medium",
                context: {
                  initialToggleState: {
                    identifier: `${item.key}`,
                    value: item.enabled === true ? true : false,
                  },
                  toggleState: {
                    identifier: `${item.key}`,
                    value: undefined,
                  },
                  isSaving: true,
                  activeIdentifier: activeIdentifier,
                  onToggleSwitch: (newToggleState) => {
                    handleNewToggleState(newToggleState);
                  },
                },
              },
            ],
          },
        ];
      });
      setTableData(newTableData);
    }
  }, [activeIdentifier]);

  if (undefined === enabledLGAMaterialTypeJourneys) {
    return <div>Loading</div>;
  }

  let lga;
  let flowIsBroken;

  if (lgas.length < 1) {
    flowIsBroken = true;
    lga = {
      name: "",
    };
  } else {
    lgas.forEach((item) => {
      if (undefined !== item) {
        if (item.uuid === uuid) {
          lga = item;
        }
      }
    });
  }

  if (flowIsBroken === true) {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">Error Occurred</CardIcon>
              </CardHeader>
              <CardBody>
                <Link to="/management/lga-management-home">
                  <blockquote style={{ color: "#g2d2g2" }}>
                    This can happen when you refresh the page manually during an
                    operation or try to navigate directly to this screen. The
                    application currently depends on the state provided by the
                    previous page in an effort to speed up display of
                    information and prevent unnecessary refreshing of data.
                  </blockquote>
                  <Button
                    style={{
                      margin: "15px auto",
                      width: "100%",
                    }}
                    color={"info"}
                    link={false}
                  >
                    Return to LGA Management Area
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">Edit {lga.name}</CardIcon>
              <Link to="/management/lga-management-home">
                <Button color={"warning"} link={true}>
                  <span className="material-icons">cancel</span> Cancel
                </Button>
              </Link>
            </CardHeader>
            <CardBody>
              <h2 styles={{ textAlign: "center" }}>{lga.name}</h2>
              <Formik
                initialValues={{ name: lga.name }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = "Required";
                  } else if (!/^[ A-Za-z0-9._%+-]{2,255}$/i.test(values.name)) {
                    errors.name = "Invalid LGA Name";
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  // console.log(values, uuid);
                  dispatch(
                    editLGAThunk(values, uuid, setIsSaving, setWasSuccessful)
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <label aria-label="LGA Name">Name</label>
                    <input
                      type="text"
                      name="name"
                      style={{
                        width: "99%",
                        height: "2.2em",
                        fontSize: "1em",
                        border: "1px solid #d2d2d2",
                        paddingLeft: "10px",
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <span style={{ color: "#cc2526" }}>
                      {errors.name && touched.name && errors.name}
                    </span>
                    <Button
                      style={{
                        margin: "15px auto",
                        width: "100%",
                      }}
                      color={"info"}
                      type="submit"
                      disabled={isSaving}
                    >
                      Save
                    </Button>
                  </form>
                )}
              </Formik>
              <h3>Curby Programs</h3>
              <p>Toggle Curby Program participation for the LGA</p>
              <ActionableDataTable
                // pagination={false}
                tableHeaderColor="warning"
                tableHead={["Material", "Type", "Actions"]}
                tableData={tableData.data}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    lgas: state.journeyFlows.CurbCycleManagement.lgas.lgas.data,
  };
}

EditLGA.propTypes = {
  lgas: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

export default connect(mapStateToProps)(EditLGA);
