import React, { useState } from 'react';
import PropTypes from "prop-types";
import GridItem from "../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../components/Grid/GridContainer.js";
import ToolingDrawer from "./layouts/ToolingDrawer";

function FlowBuilderUi ({ config }) {
  
  const [contentTreeContext, setContentTreeContext] = useState(undefined);
  
  return (
    <div>
      <h1>FlowBuilder</h1>
      <ToolingDrawer
        contentTreeContext={contentTreeContext}
        onContentTreeContextChangeCallback={(newContext) => {
          console.log({
            newContext: newContext
          });
          setContentTreeContext(newContext)
        }}
      />
      <GridContainer
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <GridItem xs={12} sm={12} md={12}>
      
          <GridContainer>
            <GridItem
              xs={11} sm={11} md={11}
              style={{
                marginLeft: '6rem'
              }}
            >
              <GridContainer
                style={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <GridItem xs={12} sm={12} md={4}>
                  
                </GridItem>
            
            
                <GridItem xs={12} sm={12} md={4}>
                  {/*<h2>Content Tree Context</h2>*/}
                  {/*<pre>*/}
                    {/*{JSON.stringify(contentTreeContext, null, 2)}*/}
                  {/*</pre>*/}
                </GridItem>
            
            
                <GridItem xs={12} sm={12} md={4}>
                              
                </GridItem>
          
          
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

FlowBuilderUi.propTypes = {
  config: PropTypes.any.isRequired,
}

export default FlowBuilderUi;
