import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
// import Button from '../../components/CustomButtons/Button';
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Close from "@material-ui/icons/Close";

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    height: "80%",
    marginTop: "2.5%",
    marginLeft: "15%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[6],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1000001,
    overflow: "scroll",
  },
}));

export default function ModalWithChildContent({
  children,
  modalTitle,
  modalDescription,
  shouldBeOpen,
  onCloseCallback,
}) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open === true && shouldBeOpen === true) {
      // nothing to do
    } else if (open === false && shouldBeOpen === true) {
      handleOpen();
    } else if (open === true && shouldBeOpen === false) {
      handleClose();
    } else {
      // nothing to do
    }
  }, [shouldBeOpen]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onCloseCallback();
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <IconButton
        classes={{}}
        key="close"
        aria-label="Close"
        color="inherit"
        style={{ marginLeft: "95%" }}
        onClick={handleClose}
      >
        <Close />
      </IconButton>
      <h2 id="simple-modal-title">{modalTitle}</h2>
      <p id="simple-modal-description">{modalDescription}</p>
      {children}
    </div>
  );

  return (
    <Modal
      style={{ marginTop: "1em", height: "100%", zIndex: 1000000 }}
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
}

ModalWithChildContent.propTypes = {
  children: PropTypes.node,
  modalDescription: PropTypes.string,
  modalTitle: PropTypes.string,
  onCloseCallback: PropTypes.func,
  shouldBeOpen: PropTypes.bool.isRequired,
};
