import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardIcon from "../../../components/Card/CardIcon.js";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import {
  getSecret as getSecretAPI,
  saveSecret as saveSecretAPI,
} from "../../../services/CurbCycleApi";
import Spinner from "../../../components/Spinner/Spinner";
import generateUUID from "../../../utilities/uuid";

function Secret() {
  const [secret, setSecret] = useState(undefined);
  const [savedSecretId, setSavedSecretId] = useState(undefined);
  const [retrievedSecret, setRetrievedSecret] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const pagePrefix = "secret/";
  const isRetrievingMode = window.location.pathname.includes(pagePrefix);
  const secretLink = `${window.location.origin}${window.location.pathname}/${savedSecretId}`;

  const getSecretLinkOrErrorMessage = () => {
    if (savedSecretId) {
      return `${window.location.origin}${window.location.pathname}/${savedSecretId}`;
    }
    return `Error: ${errorMessage}`;
  };

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    const getAndSetSecret = async (secretId) => {
      await getSecret(secretId);
      setIsLoading(false);
    };
    if (isRetrievingMode) {
      const secretId = window.location.pathname.substr(
        window.location.pathname.lastIndexOf(pagePrefix) + pagePrefix.length
      );
      getAndSetSecret(secretId);
    }
    setIsLoading(false);
  }, []);

  const getSecret = async (id) => {
    try {
      const response = await getSecretAPI(id);
      if (response.code === "ERROR_OCCURRED") {
        return setErrorMessage("Your secret is either expired or was not set");
      }
      setRetrievedSecret(response.response?.value);
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const saveSecret = async (secret) => {
    setIsLoading(true);
    try {
      const id = generateUUID.uuid();
      const response = await saveSecretAPI(id, secret);

      setSavedSecretId(response.response?.identifier);
    } catch (err) {
      setErrorMessage(err.message);
    }
    setIsLoading(false);
  };

  const onSaveButtonClick = async () => {
    await saveSecret(secret);
  };

  const onInputChange = (e) => {
    setSecret(e.target.value);
  };

  return (
    <div className="container-fluid" style={{ margin: "0 auto", width: "60%" }}>
      <>
        {isLoading ? (
          <Spinner />
        ) : (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {isRetrievingMode ? (
                <>
                  <Card>
                    <CardHeader color="success" stats icon>
                      <CardIcon color="success">
                        {errorMessage
                          ? "Oops!"
                          : "Someone shared the secret with you"}
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <form>
                        <TextField
                          style={{ marginTop: "15px" }}
                          id="secret"
                          fullWidth
                          multiline
                          value={retrievedSecret || `Error: ${errorMessage}`}
                          disabled
                        />
                      </form>
                      <Button
                        style={{ margin: "15px auto", width: "100%" }}
                        color={"info"}
                        type="submit"
                        disabled={!!errorMessage}
                        onClick={() => {
                          copyTextToClipboard(retrievedSecret);
                        }}
                      >
                        Copy secret
                      </Button>
                    </CardBody>
                  </Card>
                </>
              ) : (
                <Card>
                  <CardHeader color="success" stats icon>
                    <CardIcon color="success">
                      {savedSecretId
                        ? "Hurray, your secret was saved! You can share it now"
                        : "Add one time secret"}
                    </CardIcon>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <TextField
                        style={{ marginTop: "15px" }}
                        placeholder="Type your secret here"
                        id="secret"
                        fullWidth
                        multiline
                        value={savedSecretId && getSecretLinkOrErrorMessage()}
                        onChange={onInputChange}
                      />
                    </form>
                    {savedSecretId ? (
                      <Button
                        style={{ margin: "15px auto", width: "100%" }}
                        color={"info"}
                        type="submit"
                        disabled={!!errorMessage}
                        onClick={() => {
                          copyTextToClipboard(secretLink);
                        }}
                      >
                        Copy secret link
                      </Button>
                    ) : (
                      <Button
                        style={{ margin: "15px auto", width: "100%" }}
                        color={"info"}
                        type="submit"
                        disabled={!!errorMessage || !secret}
                        onClick={onSaveButtonClick}
                      >
                        Save my secret
                      </Button>
                    )}
                  </CardBody>
                </Card>
              )}
            </GridItem>
          </GridContainer>
        )}
      </>
    </div>
  );
}

export default Secret;
