import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import resetMrfAccessPinThunk from "../../../../../thunks/Management/MrfOperations/Security/resetMrfAccessPinThunk";
import fetchMrfAccessPinThunk from "../../../../../thunks/Management/MrfOperations/Security/fetchMrfAccessPinThunk";
// core components
import CustomTabs from "../../../../../components/CustomTabs/CustomTabs.js";
import GridItem from "../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../components/Grid/GridContainer.js";
import Card from "../../../../../components/Card/Card.js";
import CardHeader from "../../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../../components/Card/CardIcon.js";
import CardBody from "../../../../../components/Card/CardBody.js";
import Button from "../../../../../components/CustomButtons/Button";

function Security({ isResettingMrfAccessPin }) {
  const dispatch = useDispatch();
  const { uuid } = useParams();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title="Security Settings:"
            headerColor="success"
            tabs={[
              {
                tabName: "Access Pin",
                tabContent: (
                  <Card loading={isResettingMrfAccessPin}>
                    <CardHeader color="success" stats icon>
                      <CardIcon color="success">
                        Manage SMRF Access Pin
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <Button
                        style={{ margin: "15px auto", width: "100%" }}
                        color={"info"}
                        type="submit"
                        disabled={isResettingMrfAccessPin}
                        onClick={() => {
                          dispatch(fetchMrfAccessPinThunk(uuid));
                        }}
                      >
                        Show SMRF Access PIN
                      </Button>

                      <Button
                        style={{ margin: "15px auto", width: "100%" }}
                        color={"danger"}
                        type="submit"
                        disabled={isResettingMrfAccessPin}
                        onClick={() => {
                          dispatch(
                            resetMrfAccessPinThunk({
                              mrf_uuid: uuid,
                            })
                          );
                        }}
                      >
                        Reset SMRF Access PIN
                      </Button>
                    </CardBody>
                  </Card>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isResettingMrfAccessPin:
      state.journeyFlows.CurbCycleManagement.mrfs.isResettingMrfAccessPin,
  };
}

Security.propTypes = {
  isResettingMrfAccessPin: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Security);
