module.exports = {
  component: 'vfb-mb-form-component-people-name-on-card',
  elementPrefix: '',
  name: "MB: Person name on card - uses the values from an existing component",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'name_on_card'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'name_on_card'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'name_on_card'
    },
    {
      type: 'literal',
      variable: 'maximum_number_of_characters',
      value: '20'
    },
    {
      type: 'literal',
      variable: 'element_name_prefix',
      value: 'element_name_prefix'
    },
    {
      type: 'literal',
      variable: 'review_data',
      value: 'review_data'
    }
  ]
}
