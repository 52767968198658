import React, { useEffect, useState } from "react";
import ContentCreationForm from "./ContentCreator/ContentCreationForm";

const ContentCreator = () => {
  const [contentTypes, setContentTypes] = useState(undefined);
  const [contentType, setContentType] = useState(undefined);

  useEffect(() => {
    let isCancelled = false;

    const getContentTypes = async () => {
      if (!isCancelled) {
        setContentTypes({
          card: {
            displayName: "Card",
            valueSelectionKey: "card",
          },
          image: {
            displayName: "Image",
            valueSelectionKey: "image",
          },
          text: {
            displayName: "Text",
            valueSelectionKey: "text",
          },
        });
      }
    };

    getContentTypes();

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <div style={{ width: window.outerWidth - 120, float: "left" }}>
      <div
        style={{
          width: window.outerWidth - 120 > 300 ? 300 : window.outerWidth,
          float: "left",
        }}
      >
        <h4>Form</h4>

        <div style={{ clear: "both" }} />

        <ContentCreationForm
          contentTypes={contentTypes}
          selectedContentType={contentType}
          contentTypeSelectionCallback={(selectedContentType) => {
            setContentType(selectedContentType);
          }}
        />
      </div>

      <div
        style={{
          width:
            window.outerWidth - 120 - 300 > 500
              ? 500
              : window.outerWidth - 120 - 300,
          float: "left",
        }}
      >
        <h4>Preview Pane</h4>

        <div style={{ clear: "both" }} />
      </div>
    </div>
  );
};

export default ContentCreator;
