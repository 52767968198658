function getDefaultCollectionShape() {
  return {
    current_page: 1,
    data: [],
    first_page_url: null,
    from: 1,
    last_page: 1,
    last_page_url: null,
    next_page_url: null,
    path: null,
    per_page: 15,
    prev_page_url: null,
    to: 1,
    total: 1,
  };
}

function removeSimpleKeyValueFilterOption(filterOptions, key) {
  // console.log(
  //   "removeSimpleKeyValueFilterOption",
  //   "filterOptions",
  //   filterOptions,
  //   "key",
  //   key
  // );
  let newFilterOptions = [];

  filterOptions.forEach((item) => {
    // console.log(item);
    if (item.filterKey !== key) {
      newFilterOptions.push(item);
    } else {
      // nada
    }
  });

  // if the only filter option was the one we are replacing then filterOptions will now be undefined

  if (undefined === newFilterOptions) {
    newFilterOptions = addSimpleKeyValueFilterOption(
      [],
      "page",
      1,
      false,
      false
    );
  }

  if ("page" !== key) {
    newFilterOptions = addSimpleKeyValueFilterOption(
      [],
      "page",
      1,
      false,
      false
    );
  }

  return newFilterOptions;
}

function generateFilterQueryStringFromFilterOptions(filterOptions) {
  let filterString = "";

  /**
   * FilterOptions should be an array of objects used to conditionally format a query string
   *
   * [
   *   {
   *     'type': 'simpleKeyValue',
   *     'filterKey': 'status',
   *     'filterValue: 'active'
   *   }
   * ]
   *
   */

  let i = 0;

  if (undefined === filterOptions) {
    return filterString;
  }

  // console.log("filterOptions", filterOptions);

  filterOptions.forEach((item) => {
    // console.log(item);
    switch (item.type) {
      case "simpleKeyValue":
        if ("" === item.filterValue) {
          // we don't want this in the query
          i++;
          break;
        }
        if (i === 0) {
          filterString += `${item.filterKey}=${item.filterValue}`;
        } else {
          filterString += `&`;
          filterString += `${item.filterKey}=${item.filterValue}`;
        }
        i++;
        break;
      default:
        break;
    }
  });

  // console.log("filterString", filterString);

  return filterString;
}

function addSimpleKeyValueFilterOption(
  currentFilterOptions,
  key,
  value,
  overrideForExistingFiltersWithSameKey,
  resetPageTo1
) {
  // console.log(
  //   "addSimpleKeyValueFilterOption",
  //   currentFilterOptions,
  //   key,
  //   value,
  //   overrideForExistingFiltersWithSameKey,
  //   resetPageTo1
  // );

  if (undefined === currentFilterOptions) {
    let filterOptions = [];

    if (undefined === value || null === value || "" === value) {
      if (resetPageTo1) {
        // console.log(
        //   "beforeRemoval",
        //   "key",
        //   key,
        //   "value",
        //   value,
        //   "filterOptions",
        //   filterOptions
        // );
        filterOptions = removeSimpleKeyValueFilterOption(filterOptions, "page");
        // console.log(
        //   "afterRemoval",
        //   "key",
        //   key,
        //   "value",
        //   value,
        //   "filterOptions",
        //   filterOptions
        // );
        filterOptions.push({
          type: "simpleKeyValue",
          filterKey: "page",
          filterValue: 1,
        });
        // console.log(
        //   "afterPush",
        //   "key",
        //   key,
        //   "value",
        //   value,
        //   "filterOptions",
        //   filterOptions
        // );
      }
      return filterOptions;
    }

    filterOptions.push({
      type: "simpleKeyValue",
      filterKey: key,
      filterValue: value,
    });

    if (resetPageTo1) {
      // console.log(
      //   "beforeRemoval",
      //   "key",
      //   key,
      //   "value",
      //   value,
      //   "filterOptions",
      //   filterOptions
      // );
      filterOptions = removeSimpleKeyValueFilterOption(filterOptions, "page");
      // console.log(
      //   "afterRemoval",
      //   "key",
      //   key,
      //   "value",
      //   value,
      //   "filterOptions",
      //   filterOptions
      // );
      filterOptions.push({
        type: "simpleKeyValue",
        filterKey: "page",
        filterValue: 1,
      });
      // console.log(
      //   "afterPush",
      //   "key",
      //   key,
      //   "value",
      //   value,
      //   "filterOptions",
      //   filterOptions
      // );
    }

    return filterOptions;
  }

  // if (currentFilterOptions && (null === value || '' === value)) {
  //   let filterOptions = [];
  //   return removeSimpleKeyValueFilterOption(currentFilterOptions, key);
  // }

  if (overrideForExistingFiltersWithSameKey) {
    let filterOptions = [...currentFilterOptions];

    // console.log("filterOptions", filterOptions);
    // console.log("currentFilterOptions", currentFilterOptions);

    let newFilterOptions = removeSimpleKeyValueFilterOption(filterOptions, key);
    // console.log("newFilterOptions", newFilterOptions);

    newFilterOptions.push({
      type: "simpleKeyValue",
      filterKey: key,
      filterValue: value,
    });

    // console.log("newFilterOptions", newFilterOptions);

    return newFilterOptions;
  }

  return [
    ...currentFilterOptions,
    {
      type: "simpleKeyValue",
      filterKey: key,
      filterValue: value,
    },
  ];
}

function initialiseFilterOptions() {
  let newFilterOptions = [];
  return addSimpleKeyValueFilterOption(newFilterOptions, "status", "active");
}

function handleAddSimpleKeyValueFilterOption(
  key,
  value,
  stateFunction,
  debouncedFilterOptions
) {
  if ("page" === key) {
    stateFunction(
      addSimpleKeyValueFilterOption(
        debouncedFilterOptions,
        "page",
        value,
        true,
        false
      )
    );
  } else {
    stateFunction(
      addSimpleKeyValueFilterOption(
        debouncedFilterOptions,
        key,
        value,
        true,
        true
      )
    );
  }
}

const paginationHelper = {
  getDefaultCollectionShape() {
    return getDefaultCollectionShape();
  },
  initialiseFilterOptions(filterOptions) {
    return initialiseFilterOptions(filterOptions);
  },
  generateFilterQueryStringFromFilterOptions(filterOptions) {
    return generateFilterQueryStringFromFilterOptions(filterOptions);
  },
  addSimpleKeyValueFilterOption(
    currentFilterOptions,
    key,
    value,
    overrideForExistingFiltersWithSameKey
  ) {
    return addSimpleKeyValueFilterOption(
      currentFilterOptions,
      key,
      value,
      overrideForExistingFiltersWithSameKey
    );
  },
  handleAddSimpleKeyValueFilterOption(
    key,
    value,
    stateFunction,
    debouncedFilterOptions
  ) {
    return handleAddSimpleKeyValueFilterOption(
      key,
      value,
      stateFunction,
      debouncedFilterOptions
    );
  },
  // handleAddSimpleKeyValueFilterOptions(key, value, stateFunction, debouncedFilterOptions) {
  //   return handleAddSimpleKeyValueFilterOptions(key, value, stateFunction, debouncedFilterOptions);
  // }
};

export default paginationHelper;
