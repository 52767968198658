import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { getCrmIntegrationLinkData } from "../../../../../services/CurbCycleApi";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
import Icon from "@material-ui/core/Icon";
// core components
import GridItem from "../../../../../components/Grid/GridItem";
import GridContainer from "../../../../../components/Grid/GridContainer";
import Card from "../../../../../components/Card/Card";
import CardHeader from "../../../../../components/Card/CardHeader";
import CardIcon from "../../../../../components/Card/CardIcon";
import CardBody from "../../../../../components/Card/CardBody";
import Button from "../../../../../components/CustomButtons/Button";

function CrmIntegrationSettings() {
  const [theData, setTheData] = useState(null);
  const history = useHistory();
  const { uuid } = useParams();

  const account = history.location.state.account;

  useEffect(() => {
    let isCancelled = false;
    const getTheData = async () => {
      getCrmIntegrationLinkData(uuid).then((response) => {
        if (!isCancelled) {
          setTheData(response);
        }
      });
    };
    getTheData();
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Fade in={true} timeout={3000}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon
                color="success"
                style={{ fontSize: "22px", fontWeight: 400 }}
              >
                CRM Integration Settings
              </CardIcon>
            </CardHeader>
            <CardBody>
              <GridContainer
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <GridItem>
                  <Button
                    link={true}
                    onClick={() => {
                      history.push({
                        pathname: `/management/accounts`,
                      });
                      // backAction();
                    }}
                  >
                    <Icon>arrow_back</Icon>
                    <label style={{ fontSize: "18px" }}>Back</label>
                  </Button>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  {/*<pre>*/}
                  {/*{JSON.stringify(theData, null, 2)}*/}
                  {/*</pre>*/}
                  <h3>CRM Links</h3>

                  {null !== theData && theData.links && (
                    <>
                      {/*<div>*/}
                      {/*<b>Integration Type:</b> {theData.links.integration_type}*/}
                      {/*</div>*/}

                      {/*<div>*/}
                      {/*<b>Integration Key:</b> {theData.links.integration_key}*/}
                      {/*</div>*/}

                      <h4>CurbCycle Salesforce Integration</h4>

                      <div>
                        <a
                          target="_blank"
                          href={`https://curbcycle.lightning.force.com/lightning/r/Account/${theData.links.metadata.identifiers.account_identifier}/view`}
                          rel="noreferrer"
                        >
                          {account.first_name} {account.last_name}
                          {"'s "}Account
                        </a>
                      </div>

                      <div>
                        <a
                          target="_blank"
                          href={`https://curbcycle.lightning.force.com/lightning/r/Contact/${theData.links.metadata.identifiers.contact_identifier}/view`}
                          rel="noreferrer"
                        >
                          Contact
                        </a>
                      </div>

                      <div>
                        <a
                          target="_blank"
                          href={`https://curbcycle.lightning.force.com/lightning/r/Order/${theData.links.metadata.identifiers.account_identifier}/related/Orders/view`}
                          rel="noreferrer"
                        >
                          Orders
                        </a>
                      </div>

                      <div>
                        <a
                          target="_blank"
                          href={`https://curbcycle.lightning.force.com/lightning/r/Case/${theData.links.metadata.identifiers.account_identifier}/related/Cases/view`}
                          rel="noreferrer"
                        >
                          Support Cases
                        </a>
                      </div>

                      {/*<div>*/}
                      {/*<a target="_blank" href={`https://curbcycle.lightning.force.com/lightning/r/Task/${theData.links.metadata.identifiers.account_identifier}/related/Tasks/view`}>Tasks</a>*/}
                      {/*</div>*/}

                      <h4>Identifiers</h4>
                      <div>
                        <table>
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Value</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Account</td>
                              <td>
                                {
                                  theData.links.metadata.identifiers
                                    .account_identifier
                                }
                              </td>
                              <td>
                                <Button>Edit</Button>
                              </td>
                            </tr>
                            <tr>
                              <td>Contact</td>
                              <td>
                                {
                                  theData.links.metadata.identifiers
                                    .contact_identifier
                                }
                              </td>
                              <td>
                                <Button>Edit</Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div>
                        <h4>Experimental iframe</h4>
                        <iframe
                          width={`${window.innerWidth - 170}`}
                          height={`${window.innerHeight}`}
                          src={`https://curbcycle.lightning.force.com/lightning/r/Account/${theData.links.metadata.identifiers.account_identifier}/view`}
                        ></iframe>
                      </div>
                    </>
                  )}

                  {null !== theData && undefined === theData.links && (
                    <>
                      <div>
                        <b>No CRM link has been established for this account</b>
                      </div>

                      {null !== account &&
                        undefined !== account &&
                        null !== account.internal_reference &&
                        account.internal_reference.indexOf("AU-") !== -1 && (
                          <Button>Create CRM Link</Button>
                        )}

                      {null !== account &&
                        undefined !== account &&
                        null === account.internal_reference && (
                          <div>
                            Once the account has an internal reference it will
                            be possible to create a CRM link. The internal
                            reference is assigned when the account registers
                            their primary address.
                          </div>
                        )}
                    </>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Fade>
  );
}

CrmIntegrationSettings.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        account: PropTypes.shape({
          internal_reference: PropTypes.arrayOf(PropTypes.any),
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  accountData: PropTypes.any.isRequired,
};

export default CrmIntegrationSettings;
