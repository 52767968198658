import React, { useRef } from "react";
import PropTypes from "prop-types";
import TreeItem from "@material-ui/lab/TreeItem";
import { ItemTypes } from "./../../constants/items";
import { ContentTreeItemTypes } from "./../../constants/content-tree-items";
import { useDrag, useDrop } from "react-dnd";

function CustomTreeItem({
  moveContentCallback,
  treeItemType,
  onSelectItemCallback,
  onContextMenuCallback,
  pathIdentifier,
  uuid,
  label,
  children,
}) {
  const ref = useRef(null);

  const myType = `${ItemTypes.TREE_ITEM}::${treeItemType}`;

  const whatCanBeAccepted = () => {
    switch (myType) {
      case `${ItemTypes.TREE_ITEM}::${ContentTreeItemTypes.PAGE}`:
        return `${ItemTypes.TREE_ITEM}::${ContentTreeItemTypes.PAGE}`;
      case `${ItemTypes.TREE_ITEM}::${ContentTreeItemTypes.SECTION}`:
        return `${ItemTypes.TREE_ITEM}::${ContentTreeItemTypes.SECTION}`;
      case `${ItemTypes.TREE_ITEM}::${ContentTreeItemTypes.CONTAINER}`:
        return `${ItemTypes.TREE_ITEM}::${ContentTreeItemTypes.CONTAINER}`;
      case `${ItemTypes.TREE_ITEM}::${ContentTreeItemTypes.CONTENT}`:
        return `${ItemTypes.TREE_ITEM}::${ContentTreeItemTypes.CONTENT}`;
      default:
        throw new Error("That should not happen");
    }
  };

  const canMoveItem = (draggedItemTreeType, destinationItemTreeType) => {
    /**
     * Given this hierarchy:
     *
     * - Page
     *   - Content Section
     *     - Container
     *       - Content
     */

    switch (destinationItemTreeType) {
      case ContentTreeItemTypes.CONTENT:
        return draggedItemTreeType === ContentTreeItemTypes.CONTENT;
      case ContentTreeItemTypes.CONTAINER:
        return draggedItemTreeType === ContentTreeItemTypes.CONTAINER;
      case ContentTreeItemTypes.SECTION:
        return draggedItemTreeType === ContentTreeItemTypes.SECTION;
      case ContentTreeItemTypes.PAGE:
        return draggedItemTreeType === ContentTreeItemTypes.PAGE;
      default:
        return false;
    }
  };

  const moveItem = (
    dragIndex,
    hoverIndex,
    draggedItemTreeType,
    destinationItemTreeType,
    draggedItem
  ) => {
    // console.log(
    //   {
    //     dragIndex: dragIndex
    //   },
    //   {
    //     hoverIndex: hoverIndex
    //   },
    //   {
    //     draggedItemTreeType: draggedItemTreeType
    //   },
    //   {
    //     destinationItemTreeType: destinationItemTreeType
    //   },
    //   {
    //     draggedItem: draggedItem
    //   }
    // );
    if (!isDragging) {
      moveContentCallback(dragIndex, hoverIndex, false, draggedItem);
    }
  };

  const [{ handlerId }, drop] = useDrop({
    accept: whatCanBeAccepted(),
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.pathIdentifier;
      const hoverIndex = pathIdentifier;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        console.log({
          "dragIndex === hoverIndex": dragIndex === hoverIndex,
          dragIndex: dragIndex,
          hoverIndex: hoverIndex,
          item: item,
        });
        return;
      }

      // more rules about the moving a nested type to another...
      const draggedItemTreeType = item.treeItemType;
      const destinationItemTreeType = treeItemType;

      if (!canMoveItem(draggedItemTreeType, destinationItemTreeType)) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(
        dragIndex,
        hoverIndex,
        draggedItemTreeType,
        destinationItemTreeType,
        item
      );
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.pathIdentifier = hoverIndex;
    },
  });

  const [{ opacity, isDragging }, drag] = useDrag({
    item: {
      type: `${ItemTypes.TREE_ITEM}::${treeItemType}`,
      treeItemType: treeItemType,
      uuid,
      pathIdentifier,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
      isDragging: monitor.isDragging(),
    }),
  });

  const doCallback = () => {
    // console.log(ref);
    // console.log({pathIdentifier: pathIdentifier});
    onSelectItemCallback(uuid, pathIdentifier);
  };

  const doContextMenuCallback = () => {
    // console.log(ref);
    // console.log({pathIdentifier: pathIdentifier});
    onContextMenuCallback(uuid, pathIdentifier);
  };

  drag(drop(ref));
  return (
    <TreeItem
      treeItemType={treeItemType}
      pathIdentifier={pathIdentifier}
      nodeId={uuid}
      label={label}
      onClick={doCallback}
      onContextMenu={doContextMenuCallback}
      ref={ref}
      data-handler-id={handlerId}
      style={{ opacity }}
    >
      {children}
    </TreeItem>
  );
}

CustomTreeItem.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  moveContentCallback: PropTypes.func,
  moveNodeCallback: PropTypes.func,
  onClickCallback: PropTypes.func,
  onContextMenuCallback: PropTypes.func,
  onSelectItemCallback: PropTypes.func,
  pathIdentifier: PropTypes.string,
  treeItemType: PropTypes.any,
  uuid: PropTypes.string,
};

export default CustomTreeItem;
