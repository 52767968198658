import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import geoJsonData from "./../../../../../src/components/DashboardComponents/Map/AwesomeMapComponent/geoJsonData";
import { K_SIZE } from "./jss/maps";

function PostMvpParticipationMap({ awesomeAnalyticsData }) {
  // console.log({
  //   "awesomeAnalyticsData - src/pages/JourneyFlows/RetailSponsor/layouts/components/Maps/MvpParticipationMap.js:8": awesomeAnalyticsData
  // });

  const [minParticipationRate, setTheMinParticipationRate] = useState(50);
  const [maxParticipationRate, setTheMaxParticipationRate] = useState(100);
  const [thePostcode, setThePostcode] = useState(null);
  // const [theSignUps, setTheSignUps] = useState(null);
  // const [theParticipationRate, setTheParticipationRate] = useState(null);
  const [theBagsCollected, setTheBagsCollected] = useState(null);
  const [theWeightOfBagsCollected, setTheWeightOfBagsCollected] = useState(
    null
  );
  const [theUnitsString, setTheUnitsString] = useState(null);
  // const [theMap, setTheMap] = useState(null);

  const nswPostCodes = {
    "1001": "1001",
    "1002": "1002",
    "1003": "1003",
    "1004": "1004",
    "1005": "1005",
    "1006": "1006",
    "1007": "1007",
    "1008": "1008",
    "1009": "1009",
    "1010": "1010",
    "1020": "1020",
    "1021": "1021",
    "1022": "1022",
    "1023": "1023",
    "1025": "1025",
    "1026": "1026",
    "1027": "1027",
    "1028": "1028",
    "1029": "1029",
    "1030": "1030",
    "1031": "1031",
    "1032": "1032",
    "1033": "1033",
    "1034": "1034",
    "1035": "1035",
    "1036": "1036",
    "1037": "1037",
    "1038": "1038",
    "1039": "1039",
    "1040": "1040",
    "1041": "1041",
    "1042": "1042",
    "1043": "1043",
    "1044": "1044",
    "1045": "1045",
    "1046": "1046",
    "1100": "1100",
    "1101": "1101",
    "1105": "1105",
    "1106": "1106",
    "1107": "1107",
    "1108": "1108",
    "1109": "1109",
    "1110": "1110",
    "1112": "1112",
    "1113": "1113",
    "1114": "1114",
    "1115": "1115",
    "1116": "1116",
    "1117": "1117",
    "1118": "1118",
    "1119": "1119",
    "1120": "1120",
    "1121": "1121",
    "1122": "1122",
    "1123": "1123",
    "1124": "1124",
    "1125": "1125",
    "1126": "1126",
    "1127": "1127",
    "1128": "1128",
    "1129": "1129",
    "1130": "1130",
    "1131": "1131",
    "1132": "1132",
    "1133": "1133",
    "1134": "1134",
    "1135": "1135",
    "1136": "1136",
    "1137": "1137",
    "1138": "1138",
    "1139": "1139",
    "1140": "1140",
    "1141": "1141",
    "1142": "1142",
    "1143": "1143",
    "1144": "1144",
    "1145": "1145",
    "1146": "1146",
    "1147": "1147",
    "1148": "1148",
    "1149": "1149",
    "1150": "1150",
    "1151": "1151",
    "1152": "1152",
    "1153": "1153",
    "1154": "1154",
    "1155": "1155",
    "1156": "1156",
    "1157": "1157",
    "1158": "1158",
    "1159": "1159",
    "1160": "1160",
    "1161": "1161",
    "1162": "1162",
    "1163": "1163",
    "1164": "1164",
    "1165": "1165",
    "1166": "1166",
    "1167": "1167",
    "1168": "1168",
    "1169": "1169",
    "1170": "1170",
    "1171": "1171",
    "1172": "1172",
    "1173": "1173",
    "1174": "1174",
    "1175": "1175",
    "1176": "1176",
    "1177": "1177",
    "1178": "1178",
    "1179": "1179",
    "1180": "1180",
    "1181": "1181",
    "1182": "1182",
    "1183": "1183",
    "1184": "1184",
    "1185": "1185",
    "1186": "1186",
    "1187": "1187",
    "1188": "1188",
    "1189": "1189",
    "1190": "1190",
    "1191": "1191",
    "1192": "1192",
    "1193": "1193",
    "1194": "1194",
    "1195": "1195",
    "1196": "1196",
    "1197": "1197",
    "1198": "1198",
    "1199": "1199",
    "1200": "1200",
    "1201": "1201",
    "1202": "1202",
    "1203": "1203",
    "1204": "1204",
    "1205": "1205",
    "1206": "1206",
    "1207": "1207",
    "1208": "1208",
    "1209": "1209",
    "1210": "1210",
    "1211": "1211",
    "1212": "1212",
    "1213": "1213",
    "1214": "1214",
    "1215": "1215",
    "1216": "1216",
    "1217": "1217",
    "1218": "1218",
    "1219": "1219",
    "1220": "1220",
    "1221": "1221",
    "1222": "1222",
    "1223": "1223",
    "1224": "1224",
    "1225": "1225",
    "1226": "1226",
    "1227": "1227",
    "1228": "1228",
    "1229": "1229",
    "1230": "1230",
    "1231": "1231",
    "1232": "1232",
    "1233": "1233",
    "1234": "1234",
    "1235": "1235",
    "1236": "1236",
    "1237": "1237",
    "1238": "1238",
    "1239": "1239",
    "1240": "1240",
    "1291": "1291",
    "1292": "1292",
    "1293": "1293",
    "1294": "1294",
    "1295": "1295",
    "1296": "1296",
    "1297": "1297",
    "1298": "1298",
    "1299": "1299",
    "1300": "1300",
    "1311": "1311",
    "1312": "1312",
    "1313": "1313",
    "1314": "1314",
    "1315": "1315",
    "1316": "1316",
    "1317": "1317",
    "1318": "1318",
    "1319": "1319",
    "1320": "1320",
    "1321": "1321",
    "1322": "1322",
    "1323": "1323",
    "1324": "1324",
    "1325": "1325",
    "1326": "1326",
    "1327": "1327",
    "1328": "1328",
    "1329": "1329",
    "1330": "1330",
    "1331": "1331",
    "1332": "1332",
    "1333": "1333",
    "1334": "1334",
    "1335": "1335",
    "1340": "1340",
    "1350": "1350",
    "1355": "1355",
    "1360": "1360",
    "1363": "1363",
    "1391": "1391",
    "1401": "1401",
    "1416": "1416",
    "1419": "1419",
    "1420": "1420",
    "1422": "1422",
    "1423": "1423",
    "1424": "1424",
    "1425": "1425",
    "1426": "1426",
    "1427": "1427",
    "1428": "1428",
    "1429": "1429",
    "1430": "1430",
    "1435": "1435",
    "1440": "1440",
    "1441": "1441",
    "1445": "1445",
    "1450": "1450",
    "1455": "1455",
    "1460": "1460",
    "1465": "1465",
    "1466": "1466",
    "1470": "1470",
    "1475": "1475",
    "1476": "1476",
    "1480": "1480",
    "1481": "1481",
    "1484": "1484",
    "1485": "1485",
    "1487": "1487",
    "1490": "1490",
    "1493": "1493",
    "1495": "1495",
    "1499": "1499",
    "1515": "1515",
    "1560": "1560",
    "1565": "1565",
    "1570": "1570",
    "1582": "1582",
    "1585": "1585",
    "1590": "1590",
    "1595": "1595",
    "1597": "1597",
    "1602": "1602",
    "1608": "1608",
    "1610": "1610",
    "1611": "1611",
    "1630": "1630",
    "1635": "1635",
    "1639": "1639",
    "1640": "1640",
    "1655": "1655",
    "1658": "1658",
    "1660": "1660",
    "1670": "1670",
    "1675": "1675",
    "1680": "1680",
    "1685": "1685",
    "1690": "1690",
    "1691": "1691",
    "1692": "1692",
    "1693": "1693",
    "1694": "1694",
    "1695": "1695",
    "1696": "1696",
    "1697": "1697",
    "1698": "1698",
    "1699": "1699",
    "1700": "1700",
    "1701": "1701",
    "1710": "1710",
    "1712": "1712",
    "1715": "1715",
    "1730": "1730",
    "1740": "1740",
    "1741": "1741",
    "1750": "1750",
    "1755": "1755",
    "1765": "1765",
    "1771": "1771",
    "1781": "1781",
    "1790": "1790",
    "1797": "1797",
    "1800": "1800",
    "1805": "1805",
    "1811": "1811",
    "1816": "1816",
    "1819": "1819",
    "1825": "1825",
    "1826": "1826",
    "1830": "1830",
    "1831": "1831",
    "1835": "1835",
    "1848": "1848",
    "1851": "1851",
    "1860": "1860",
    "1871": "1871",
    "1875": "1875",
    "1885": "1885",
    "1888": "1888",
    "1890": "1890",
    "1891": "1891",
    "1900": "1900",
    "1902": "1902",
    "2000": "2000",
    "2001": "2001",
    "2004": "2004",
    "2006": "2006",
    "2007": "2007",
    "2008": "2008",
    "2009": "2009",
    "2010": "2010",
    "2011": "2011",
    "2012": "2012",
    "2013": "2013",
    "2015": "2015",
    "2016": "2016",
    "2017": "2017",
    "2018": "2018",
    "2019": "2019",
    "2020": "2020",
    "2021": "2021",
    "2022": "2022",
    "2023": "2023",
    "2024": "2024",
    "2025": "2025",
    "2026": "2026",
    "2027": "2027",
    "2028": "2028",
    "2029": "2029",
    "2030": "2030",
    "2031": "2031",
    "2032": "2032",
    "2033": "2033",
    "2034": "2034",
    "2035": "2035",
    "2036": "2036",
    "2037": "2037",
    "2038": "2038",
    "2039": "2039",
    "2040": "2040",
    "2041": "2041",
    "2042": "2042",
    "2043": "2043",
    "2044": "2044",
    "2045": "2045",
    "2046": "2046",
    "2047": "2047",
    "2048": "2048",
    "2049": "2049",
    "2050": "2050",
    "2052": "2052",
    "2055": "2055",
    "2057": "2057",
    "2058": "2058",
    "2059": "2059",
    "2060": "2060",
    "2061": "2061",
    "2062": "2062",
    "2063": "2063",
    "2064": "2064",
    "2065": "2065",
    "2066": "2066",
    "2067": "2067",
    "2068": "2068",
    "2069": "2069",
    "2070": "2070",
    "2071": "2071",
    "2072": "2072",
    "2073": "2073",
    "2074": "2074",
    "2075": "2075",
    "2076": "2076",
    "2077": "2077",
    "2079": "2079",
    "2080": "2080",
    "2081": "2081",
    "2082": "2082",
    "2083": "2083",
    "2084": "2084",
    "2085": "2085",
    "2086": "2086",
    "2087": "2087",
    "2088": "2088",
    "2089": "2089",
    "2090": "2090",
    "2091": "2091",
    "2092": "2092",
    "2093": "2093",
    "2094": "2094",
    "2095": "2095",
    "2096": "2096",
    "2097": "2097",
    "2099": "2099",
    "2100": "2100",
    "2101": "2101",
    "2102": "2102",
    "2103": "2103",
    "2104": "2104",
    "2105": "2105",
    "2106": "2106",
    "2107": "2107",
    "2108": "2108",
    "2109": "2109",
    "2110": "2110",
    "2111": "2111",
    "2112": "2112",
    "2113": "2113",
    "2114": "2114",
    "2115": "2115",
    "2116": "2116",
    "2117": "2117",
    "2118": "2118",
    "2119": "2119",
    "2120": "2120",
    "2121": "2121",
    "2122": "2122",
    "2123": "2123",
    "2124": "2124",
    "2125": "2125",
    "2126": "2126",
    "2127": "2127",
    "2128": "2128",
    "2129": "2129",
    "2130": "2130",
    "2131": "2131",
    "2132": "2132",
    "2133": "2133",
    "2134": "2134",
    "2135": "2135",
    "2136": "2136",
    "2137": "2137",
    "2138": "2138",
    "2139": "2139",
    "2140": "2140",
    "2141": "2141",
    "2142": "2142",
    "2143": "2143",
    "2144": "2144",
    "2145": "2145",
    "2146": "2146",
    "2147": "2147",
    "2148": "2148",
    "2150": "2150",
    "2151": "2151",
    "2152": "2152",
    "2153": "2153",
    "2154": "2154",
    "2155": "2155",
    "2156": "2156",
    "2157": "2157",
    "2158": "2158",
    "2159": "2159",
    "2160": "2160",
    "2161": "2161",
    "2162": "2162",
    "2163": "2163",
    "2164": "2164",
    "2165": "2165",
    "2166": "2166",
    "2167": "2167",
    "2168": "2168",
    "2170": "2170",
    "2171": "2171",
    "2172": "2172",
    "2173": "2173",
    "2174": "2174",
    "2175": "2175",
    "2176": "2176",
    "2177": "2177",
    "2178": "2178",
    "2179": "2179",
    "2190": "2190",
    "2191": "2191",
    "2192": "2192",
    "2193": "2193",
    "2194": "2194",
    "2195": "2195",
    "2196": "2196",
    "2197": "2197",
    "2198": "2198",
    "2199": "2199",
    "2200": "2200",
    "2203": "2203",
    "2204": "2204",
    "2205": "2205",
    "2206": "2206",
    "2207": "2207",
    "2208": "2208",
    "2209": "2209",
    "2210": "2210",
    "2211": "2211",
    "2212": "2212",
    "2213": "2213",
    "2214": "2214",
    "2216": "2216",
    "2217": "2217",
    "2218": "2218",
    "2219": "2219",
    "2220": "2220",
    "2221": "2221",
    "2222": "2222",
    "2223": "2223",
    "2224": "2224",
    "2225": "2225",
    "2226": "2226",
    "2227": "2227",
    "2228": "2228",
    "2229": "2229",
    "2230": "2230",
    "2231": "2231",
    "2232": "2232",
    "2233": "2233",
    "2234": "2234",
    "2250": "2250",
    "2251": "2251",
    "2252": "2252",
    "2256": "2256",
    "2257": "2257",
    "2258": "2258",
    "2259": "2259",
    "2260": "2260",
    "2261": "2261",
    "2262": "2262",
    "2263": "2263",
    "2264": "2264",
    "2265": "2265",
    "2267": "2267",
    "2278": "2278",
    "2280": "2280",
    "2281": "2281",
    "2282": "2282",
    "2283": "2283",
    "2284": "2284",
    "2285": "2285",
    "2286": "2286",
    "2287": "2287",
    "2289": "2289",
    "2290": "2290",
    "2291": "2291",
    "2292": "2292",
    "2293": "2293",
    "2294": "2294",
    "2295": "2295",
    "2296": "2296",
    "2297": "2297",
    "2298": "2298",
    "2299": "2299",
    "2300": "2300",
    "2302": "2302",
    "2303": "2303",
    "2304": "2304",
    "2305": "2305",
    "2306": "2306",
    "2307": "2307",
    "2308": "2308",
    "2309": "2309",
    "2310": "2310",
    "2311": "2311",
    "2312": "2312",
    "2314": "2314",
    "2315": "2315",
    "2316": "2316",
    "2317": "2317",
    "2318": "2318",
    "2319": "2319",
    "2320": "2320",
    "2321": "2321",
    "2322": "2322",
    "2323": "2323",
    "2324": "2324",
    "2325": "2325",
    "2326": "2326",
    "2327": "2327",
    "2328": "2328",
    "2329": "2329",
    "2330": "2330",
    "2331": "2331",
    "2333": "2333",
    "2334": "2334",
    "2335": "2335",
    "2336": "2336",
    "2337": "2337",
    "2338": "2338",
    "2339": "2339",
    "2340": "2340",
    "2341": "2341",
    "2342": "2342",
    "2343": "2343",
    "2344": "2344",
    "2345": "2345",
    "2346": "2346",
    "2347": "2347",
    "2348": "2348",
    "2350": "2350",
    "2351": "2351",
    "2352": "2352",
    "2353": "2353",
    "2354": "2354",
    "2355": "2355",
    "2356": "2356",
    "2357": "2357",
    "2358": "2358",
    "2359": "2359",
    "2360": "2360",
    "2361": "2361",
    "2365": "2365",
    "2369": "2369",
    "2370": "2370",
    "2371": "2371",
    "2372": "2372",
    "2379": "2379",
    "2380": "2380",
    "2381": "2381",
    "2382": "2382",
    "2386": "2386",
    "2387": "2387",
    "2388": "2388",
    "2390": "2390",
    "2395": "2395",
    "2396": "2396",
    "2397": "2397",
    "2398": "2398",
    "2399": "2399",
    "2400": "2400",
    "2401": "2401",
    "2402": "2402",
    "2403": "2403",
    "2404": "2404",
    "2405": "2405",
    "2406": "2406",
    "2408": "2408",
    "2409": "2409",
    "2410": "2410",
    "2411": "2411",
    "2415": "2415",
    "2420": "2420",
    "2421": "2421",
    "2422": "2422",
    "2423": "2423",
    "2424": "2424",
    "2425": "2425",
    "2426": "2426",
    "2427": "2427",
    "2428": "2428",
    "2429": "2429",
    "2430": "2430",
    "2431": "2431",
    "2439": "2439",
    "2440": "2440",
    "2441": "2441",
    "2442": "2442",
    "2443": "2443",
    "2444": "2444",
    "2445": "2445",
    "2446": "2446",
    "2447": "2447",
    "2448": "2448",
    "2449": "2449",
    "2450": "2450",
    "2452": "2452",
    "2453": "2453",
    "2454": "2454",
    "2455": "2455",
    "2456": "2456",
    "2460": "2460",
    "2462": "2462",
    "2463": "2463",
    "2464": "2464",
    "2465": "2465",
    "2466": "2466",
    "2469": "2469",
    "2470": "2470",
    "2471": "2471",
    "2472": "2472",
    "2473": "2473",
    "2474": "2474",
    "2475": "2475",
    "2476": "2476",
    "2477": "2477",
    "2478": "2478",
    "2479": "2479",
    "2480": "2480",
    "2481": "2481",
    "2482": "2482",
    "2483": "2483",
    "2484": "2484",
    "2485": "2485",
    "2486": "2486",
    "2487": "2487",
    "2488": "2488",
    "2489": "2489",
    "2490": "2490",
    "2500": "2500",
    "2502": "2502",
    "2505": "2505",
    "2506": "2506",
    "2508": "2508",
    "2515": "2515",
    "2516": "2516",
    "2517": "2517",
    "2518": "2518",
    "2519": "2519",
    "2520": "2520",
    "2521": "2521",
    "2522": "2522",
    "2525": "2525",
    "2526": "2526",
    "2527": "2527",
    "2528": "2528",
    "2529": "2529",
    "2530": "2530",
    "2533": "2533",
    "2534": "2534",
    "2535": "2535",
    "2536": "2536",
    "2537": "2537",
    "2538": "2538",
    "2539": "2539",
    "2540": "2540",
    "2541": "2541",
    "2545": "2545",
    "2546": "2546",
    "2548": "2548",
    "2549": "2549",
    "2550": "2550",
    "2551": "2551",
    "2555": "2555",
    "2556": "2556",
    "2557": "2557",
    "2558": "2558",
    "2559": "2559",
    "2560": "2560",
    "2563": "2563",
    "2564": "2564",
    "2565": "2565",
    "2566": "2566",
    "2567": "2567",
    "2568": "2568",
    "2569": "2569",
    "2570": "2570",
    "2571": "2571",
    "2572": "2572",
    "2573": "2573",
    "2574": "2574",
    "2575": "2575",
    "2576": "2576",
    "2577": "2577",
    "2578": "2578",
    "2579": "2579",
    "2580": "2580",
    "2581": "2581",
    "2582": "2582",
    "2583": "2583",
    "2584": "2584",
    "2585": "2585",
    "2586": "2586",
    "2587": "2587",
    "2588": "2588",
    "2590": "2590",
    "2594": "2594",
    "2618": "2618",
    "2619": "2619",
    "2620": "2620",
    "2621": "2621",
    "2622": "2622",
    "2623": "2623",
    "2624": "2624",
    "2625": "2625",
    "2626": "2626",
    "2627": "2627",
    "2628": "2628",
    "2629": "2629",
    "2630": "2630",
    "2631": "2631",
    "2632": "2632",
    "2633": "2633",
    "2640": "2640",
    "2641": "2641",
    "2642": "2642",
    "2643": "2643",
    "2644": "2644",
    "2645": "2645",
    "2646": "2646",
    "2647": "2647",
    "2648": "2648",
    "2649": "2649",
    "2650": "2650",
    "2651": "2651",
    "2652": "2652",
    "2653": "2653",
    "2655": "2655",
    "2656": "2656",
    "2658": "2658",
    "2659": "2659",
    "2660": "2660",
    "2661": "2661",
    "2663": "2663",
    "2665": "2665",
    "2666": "2666",
    "2668": "2668",
    "2669": "2669",
    "2671": "2671",
    "2672": "2672",
    "2675": "2675",
    "2678": "2678",
    "2680": "2680",
    "2681": "2681",
    "2700": "2700",
    "2701": "2701",
    "2702": "2702",
    "2703": "2703",
    "2705": "2705",
    "2706": "2706",
    "2707": "2707",
    "2708": "2708",
    "2710": "2710",
    "2711": "2711",
    "2712": "2712",
    "2713": "2713",
    "2714": "2714",
    "2715": "2715",
    "2716": "2716",
    "2717": "2717",
    "2720": "2720",
    "2721": "2721",
    "2722": "2722",
    "2725": "2725",
    "2726": "2726",
    "2727": "2727",
    "2729": "2729",
    "2730": "2730",
    "2731": "2731",
    "2732": "2732",
    "2733": "2733",
    "2734": "2734",
    "2735": "2735",
    "2736": "2736",
    "2737": "2737",
    "2738": "2738",
    "2739": "2739",
    "2745": "2745",
    "2747": "2747",
    "2748": "2748",
    "2749": "2749",
    "2750": "2750",
    "2751": "2751",
    "2752": "2752",
    "2753": "2753",
    "2754": "2754",
    "2755": "2755",
    "2756": "2756",
    "2757": "2757",
    "2758": "2758",
    "2759": "2759",
    "2760": "2760",
    "2761": "2761",
    "2762": "2762",
    "2763": "2763",
    "2765": "2765",
    "2766": "2766",
    "2767": "2767",
    "2768": "2768",
    "2770": "2770",
    "2773": "2773",
    "2774": "2774",
    "2775": "2775",
    "2776": "2776",
    "2777": "2777",
    "2778": "2778",
    "2779": "2779",
    "2780": "2780",
    "2782": "2782",
    "2783": "2783",
    "2784": "2784",
    "2785": "2785",
    "2786": "2786",
    "2787": "2787",
    "2790": "2790",
    "2791": "2791",
    "2792": "2792",
    "2793": "2793",
    "2794": "2794",
    "2795": "2795",
    "2796": "2796",
    "2797": "2797",
    "2798": "2798",
    "2799": "2799",
    "2800": "2800",
    "2803": "2803",
    "2804": "2804",
    "2805": "2805",
    "2806": "2806",
    "2807": "2807",
    "2808": "2808",
    "2809": "2809",
    "2810": "2810",
    "2820": "2820",
    "2821": "2821",
    "2823": "2823",
    "2824": "2824",
    "2825": "2825",
    "2827": "2827",
    "2828": "2828",
    "2829": "2829",
    "2830": "2830",
    "2831": "2831",
    "2832": "2832",
    "2833": "2833",
    "2834": "2834",
    "2835": "2835",
    "2836": "2836",
    "2839": "2839",
    "2840": "2840",
    "2842": "2842",
    "2843": "2843",
    "2844": "2844",
    "2845": "2845",
    "2846": "2846",
    "2847": "2847",
    "2848": "2848",
    "2849": "2849",
    "2850": "2850",
    "2852": "2852",
    "2864": "2864",
    "2865": "2865",
    "2866": "2866",
    "2867": "2867",
    "2868": "2868",
    "2869": "2869",
    "2870": "2870",
    "2871": "2871",
    "2873": "2873",
    "2874": "2874",
    "2875": "2875",
    "2876": "2876",
    "2877": "2877",
    "2878": "2878",
    "2879": "2879",
    "2880": "2880",
    "2890": "2890",
    "2891": "2891",
    "2898": "2898",
    "2899": "2899",
    "3586": "3586",
    "3644": "3644",
    "3707": "3707",
  };

  const defaultOptions = {
    mapId: "7ec42b1f011dc285",
    zoom: 0,
    center: {
      lat: -28.309356,
      lng: 140.309208,
    },
  };

  const createMapOptions = (maps) => {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL,
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
    };
  };

  const handleApiLoaded = (map, maps, awesomeAnalyticsData) => {
    const doAllTheInitalisationStuff = async (
      theMap,
      theMaps,
      theAnalyticsData
    ) => {
      map.data.addGeoJson(geoJsonData.au.levels.state, {
        idPropertyName: "STE_CODE16",
      });

      let features = map.data.addGeoJson(geoJsonData.au.levels.post_codes, {
        idPropertyName: "POA_CODE16",
      });
      return features;
    };

    const doAllTheEnrichmentStuff = async (
      map,
      maps,
      features,
      awesomeAnalyticsData
    ) => {
      if (null === awesomeAnalyticsData.thePostMvpParticipationData) {
        return features;
      }

      awesomeAnalyticsData.thePostMvpParticipationData.forEach((row) => {
        // console.log('row', row);
        let numberOfBags = row["bags_collected"];
        let participationRate = row["participation_rate"];
        let postcode = row["postcode"];
        let lgaName = row["lga_name"];
        let signUps = row["sign_ups"];

        // keep track of min and max values
        if (participationRate < minParticipationRate) {
          setTheMinParticipationRate(participationRate);
        }
        if (participationRate > maxParticipationRate) {
          setTheMaxParticipationRate(participationRate);
        }

        // update the existing row with the new data
        if (undefined !== map.data.getFeatureById(postcode)) {
          map.data.getFeatureById(postcode).setProperty("postcode", postcode);
          map.data
            .getFeatureById(postcode)
            .setProperty("bags_collected", numberOfBags);
          map.data
            .getFeatureById(postcode)
            .setProperty("participation_rate", participationRate);
          map.data.getFeatureById(postcode).setProperty("lga_name", lgaName);
          map.data.getFeatureById(postcode).setProperty("sign_ups", signUps);
        }
      });
      return features;
    };

    doAllTheInitalisationStuff(map, maps, awesomeAnalyticsData).then(
      (features) => {
        doAllTheEnrichmentStuff(map, maps, features, awesomeAnalyticsData).then(
          (theFeatures) => {
            setTimeout(() => {
              map.data.revertStyle();
              map.data.setStyle(styleFeature);

              // theFeatures.forEach((item, index) => {
              //   // if (undefined === item.i.postcode || item.i.sign_ups < 10) {
              //   //   arrayOfFeaturesToRemove.push(index);
              //   // }
              // });
              // if (feature.i.hasOwnProperty('STE_CODE16')) {
              //   // handle style if the feature is a state
              //
              //   if (feature.i['STE_CODE16'] == '1') {
              //     return {
              //       strokeWeight: 1.2,
              //       strokeColor: '#282828',
              //       zIndex: 2,
              //       fillColor: '#ecedff',
              //       fillOpacity: 0.5,
              //       visible: true
              //     };
              //   } else {
              //     console.log({
              //       nope: feature.i['STE_CODE16']
              //     })
              //   }
              //
              //   return {
              //     strokeWeight: 1,
              //     strokeColor: '#2a2a2d',
              //     zIndex: 2,
              //     fillColor: 'hsl(' + 151 + ',' + 69 + '%,' + 1 + '%, 0.4)',
              //     fillOpacity: 0.1,
              //     visible: true
              //   };
              // }

              function mouseInToRegion(e) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    e.feature.i,
                    "STE_CODE16"
                  )
                ) {
                  // it's a state so handle it
                  if (e.feature.i["STE_CODE16"] === "1") {
                    // it's NSW so handle it
                    e.feature.setProperty("state", "hover");
                    return;
                  }
                  return;
                }

                if (
                  undefined ===
                  awesomeAnalyticsData.thePostMvpRelevantDataObject[e.feature.j]
                ) {
                  // setThePostcode(e.feature.j);
                  setThePostcode(e.feature.j);
                  setTheBagsCollected(0);
                  // setTheParticipationRate(0);
                  setTheWeightOfBagsCollected(0);
                  setTheUnitsString("Nothing has been collected in this area");
                  // setTheSignUps(0);
                  e.feature.setProperty("state", "hover");
                  return;
                }
                setThePostcode(
                  awesomeAnalyticsData.thePostMvpRelevantDataObject[e.feature.j]
                    .postcode
                );
                setTheBagsCollected(
                  awesomeAnalyticsData.thePostMvpRelevantDataObject[e.feature.j]
                    .bags_collected
                );
                // setTheParticipationRate(
                //   awesomeAnalyticsData.thePostMvpRelevantDataObject[e.feature.j]
                //     .participation_rate
                // );
                setTheWeightOfBagsCollected(
                  awesomeAnalyticsData.thePostMvpRelevantDataObject[e.feature.j]
                    .bags_collected * awesomeAnalyticsData.average_bag_weight
                );
                setTheUnitsString(
                  awesomeAnalyticsData.thePostMvpRelevantDataObject[e.feature.j]
                    .units_string
                );
                // setTheSignUps(
                //   awesomeAnalyticsData.thePostMvpRelevantDataObject[e.feature.j]
                //     .sign_ups
                // );
                // set the hover state so the setStyle function can change the border
                e.feature.setProperty("state", "hover");
              }

              function mouseOutOfRegion(e) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    e.feature.i,
                    "STE_CODE16"
                  )
                ) {
                  // it's a state so handle it
                  if (e.feature.i["STE_CODE16"] === "1") {
                    // it's NSW so handle it
                    e.feature.setProperty("state", "normal");
                    return;
                  }
                  return;
                }

                // reset the hover state, returning the border to normal
                e.feature.setProperty("state", "normal");
                setThePostcode(null);
                setTheBagsCollected(null);
                setTheWeightOfBagsCollected(null);
                // setTheParticipationRate(null);
                setTheUnitsString(null);
                // setTheSignUps(null);
              }

              function clickEventHandler(e) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    e.feature.i,
                    "STE_CODE16"
                  )
                ) {
                  // it's a state so handle it
                  if (e.feature.i["STE_CODE16"] === "1") {
                    // it's NSW so handle it
                    map.setZoom(9);
                    map.setCenter({
                      lat: -33.309356,
                      lng: 151.309208,
                    });
                    return;
                  }
                  return;
                }

                return;
              }

              setTimeout(() => {
                let arrayOfFeaturesToRemove = [];

                theFeatures.forEach((item, index) => {
                  if (undefined === nswPostCodes[item.i.postcode]) {
                    arrayOfFeaturesToRemove.push(index);
                  }
                });

                arrayOfFeaturesToRemove.forEach((item, index) => {
                  map.data.remove(features[item]);
                });
              }, 10);

              map.data.addListener("click", clickEventHandler);
              map.data.addListener("mouseover", mouseInToRegion);
              map.data.addListener("mouseout", mouseOutOfRegion);
            }, 10);
          }
        );
      }
    );

    // setTimeout(
    //   () => {
    //     setTimeout(() => {
    //       loadParticipationData(map, awesomeAnalyticsData);
    //         setTimeout(() => {
    //           loadParticipationData(map, awesomeAnalyticsData);
    //         }, 100);
    //     }, 100);
    //     // console.log({
    //     //   "features": features
    //     // })
    //
    //     // console.log(features);
    //
    //     setTimeout(() => {
    //       let arrayOfFeaturesToRemove = [];
    //       features.forEach((item, index) => {
    //         if (undefined === item.i.postcode || item.i.sign_ups < 10) {
    //           arrayOfFeaturesToRemove.push(index);
    //         }
    //         console.log({
    //           feature: item
    //         })
    //       });
    //
    //
    //     }, 300);
    //
    //       // console.log({
    //       //   "arrayOfFeaturesToRemove": arrayOfFeaturesToRemove
    //       // });
    //
    //       arrayOfFeaturesToRemove.forEach((item, index) => {
    //         map.data.remove(features[item]);
    //       });
    //     // }, 2000);
    //   }, 200
    // );
  };

  const styleFeature = (feature) => {
    // let delta = (feature.getProperty('participation_rate') - minParticipationRate) /
    //   (maxParticipationRate - minParticipationRate);

    if (Object.prototype.hasOwnProperty.call(feature.i, "STE_CODE16")) {
      // handle style if the feature is a state

      if (feature.i["STE_CODE16"] === "1") {
        return {
          strokeWeight: 1.2,
          strokeColor: "#282828",
          zIndex: 1,
          fillColor: "#ecedff",
          fillOpacity: 0.5,
          visible: true,
        };
      } else {
        console.log({
          nope: feature.i["STE_CODE16"],
        });
      }

      return {
        strokeWeight: 1,
        strokeColor: "#2a2a2d",
        zIndex: 1,
        fillColor: "hsl(" + 151 + "," + 69 + "%," + 1 + "%, 0.4)",
        fillOpacity: 0.1,
        visible: true,
      };
    }

    let delta = (feature.getProperty("bags_collected") - 1) / (476 - 1);

    let low = [5, 69, 54]; // color of smallest datum
    let high = [151, 83, 34]; // color of largest datum

    // delta represents where the value sits between the min and max
    // bags_collected
    // let delta = (feature.getProperty('participation_rate') - 2) /
    //   (50);
    // console.log(
    //   {
    //     "feature": feature,
    //     // "feature.getProperty('participation_rate')": feature.getProperty('participation_rate'),
    //     // "Number.MAX_VALUE": Number.MAX_VALUE,
    //     // "-Number.MAX_VALUE": -Number.MAX_VALUE,
    //     // "(-Number.MAX_VALUE - Number.MAX_VALUE)": (-Number.MAX_VALUE - Number.MAX_VALUE),
    //     // "(feature.getProperty('participation_rate') - Number.MAX_VALUE)": (feature.getProperty('participation_rate') - Number.MAX_VALUE),
    //     "minParticipationRate": minParticipationRate,
    //     "maxParticipationRate": maxParticipationRate,
    //     // "delta": delta
    //   }
    // );

    let color = [];
    for (let i = 0; i < 3; i++) {
      // calculate an integer color based on the delta
      color[i] = (high[i] - low[i]) * delta + low[i];
    }

    // determine whether to show this shape or not
    let showRow = true;

    if (
      feature.getProperty("bags_collected") === 0 ||
      feature.getProperty("bags_collected") === null
    ) {
      return {
        strokeWeight: 0.5,
        strokeColor: "#fff17e",
        zIndex: 3,
        fillColor: "#ccc",
        fillOpacity: 0.75,
        visible: showRow,
      };
    }

    if (
      feature.getProperty("bags_collected") == null ||
      isNaN(feature.getProperty("bags_collected"))
    ) {
      showRow = true;
    }

    // if (feature.getProperty('bags_collected') == null ||
    //   isNaN(feature.getProperty('bags_collected'))) {
    //   showRow = false;
    // }

    let outlineWeight = 0.7;
    // let zIndex = 1;
    if (feature.getProperty("state") === "hover") {
      // let outlineWeight = 0.7;
      outlineWeight = 3;
      // zIndex = 3;
    }

    return {
      strokeWeight: outlineWeight,
      strokeColor: "#fff17e",
      zIndex: 2,
      fillColor: "hsl(" + color[0] + "," + color[1] + "%," + color[2] + "%)",
      fillOpacity: 0.75,
      visible: showRow,
    };
  };

  // const loadParticipationData = (map, awesomeAnalyticsData) => {
  // console.log("______ Called loadParticipationData ____");

  //   // console.log({
  //   //   "awesomeAnalyticsData.participationData": awesomeAnalyticsData.participationData
  //   // });

  //   awesomeAnalyticsData.thePostMvpParticipationData.forEach((row) => {
  //     // console.log('row', row);
  //     let numberOfBags = row["bags_collected"];
  //     let participationRate = row["participation_rate"];
  //     let postcode = row["postcode"];
  //     let lgaName = row["lga_name"];
  //     let signUps = row["sign_ups"];

  //     // console.log({
  //     //   "numberOfBags": numberOfBags,
  //     //   "participationRate": participationRate,
  //     //   "postcode": postcode,
  //     //   "lgaName": lgaName,
  //     //   "signUps": signUps,
  //     // });
  //     //
  //     // keep track of min and max values
  //     if (participationRate < minParticipationRate) {
  //       setTheMinParticipationRate(participationRate);
  //     }
  //     if (participationRate > maxParticipationRate) {
  //       setTheMaxParticipationRate(participationRate);
  //     }

  //     // update the existing row with the new data

  //     if (undefined !== map.data.getFeatureById(parseInt(postcode))) {
  //       map.data.getFeatureById(postcode).setProperty("postcode", postcode);
  //       map.data
  //         .getFeatureById(postcode)
  //         .setProperty("bags_collected", numberOfBags);
  //       map.data
  //         .getFeatureById(postcode)
  //         .setProperty("participation_rate", participationRate);
  //       map.data.getFeatureById(postcode).setProperty("lga_name", lgaName);
  //       map.data.getFeatureById(postcode).setProperty("sign_ups", signUps);
  //     } else if (undefined !== map.data.getFeatureById(postcode)) {
  //       map.data.getFeatureById(postcode).setProperty("postcode", postcode);
  //       map.data
  //         .getFeatureById(postcode)
  //         .setProperty("bags_collected", numberOfBags);
  //       map.data
  //         .getFeatureById(postcode)
  //         .setProperty("participation_rate", participationRate);
  //       map.data.getFeatureById(postcode).setProperty("lga_name", lgaName);
  //       map.data.getFeatureById(postcode).setProperty("sign_ups", signUps);
  //     }
  //   });

  //   setTimeout(() => map.data.setStyle(styleFeature), 100);

  //   function mouseInToRegion(e) {
  //     if (e.feature.i.hasOwnProperty("STE_CODE16")) {
  //       // it's a state so handle it
  //       if (e.feature.i["STE_CODE16"] == "1") {
  //         // it's NSW so handle it
  //         e.feature.setProperty("state", "hovering");
  //         return;
  //       }
  //       return;
  //     }

  //     if (
  //       undefined ===
  //       awesomeAnalyticsData.thePostMvpRelevantDataObject[e.feature.o]
  //     ) {
  //       setThePostcode(e.feature.j);
  //       setTheBagsCollected(0);
  //       setTheParticipationRate(0);
  //       setTheWeightOfBagsCollected(0);
  //       setTheUnitsString("Nothing has been collected in this area");
  //       setTheSignUps(0);
  //       e.feature.setProperty("state", "hover");
  //       return;
  //     }

  //     setThePostcode(
  //       awesomeAnalyticsData.thePostMvpRelevantDataObject[e.feature.o].postcode
  //     );
  //     setTheBagsCollected(
  //       awesomeAnalyticsData.thePostMvpRelevantDataObject[e.feature.o]
  //         .bags_collected
  //     );
  //     setTheParticipationRate(
  //       awesomeAnalyticsData.thePostMvpRelevantDataObject[e.feature.o]
  //         .participation_rate
  //     );
  //     setTheWeightOfBagsCollected(
  //       awesomeAnalyticsData.thePostMvpRelevantDataObject[e.feature.o]
  //         .bags_collected * awesomeAnalyticsData.average_bag_weight
  //     );
  //     setTheUnitsString(
  //       awesomeAnalyticsData.thePostMvpRelevantDataObject[e.feature.o]
  //         .units_string
  //     );
  //     setTheSignUps(
  //       awesomeAnalyticsData.thePostMvpRelevantDataObject[e.feature.o].sign_ups
  //     );
  //     // set the hover state so the setStyle function can change the border
  //     e.feature.setProperty("state", "hover");
  //   }

  //   function mouseOutOfRegion(e) {
  //     if (e.feature.i.hasOwnProperty("STE_CODE16")) {
  //       // it's a state so handle it
  //       if (e.feature.i["STE_CODE16"] == "1") {
  //         // it's NSW so handle it
  //         e.feature.setProperty("state", "normal");
  //         return;
  //       }
  //       return;
  //     }

  //     // reset the hover state, returning the border to normal
  //     e.feature.setProperty("state", "normal");
  //     setThePostcode(null);
  //     setTheBagsCollected(null);
  //     setTheWeightOfBagsCollected(null);
  //     setTheParticipationRate(null);
  //     setTheUnitsString(null);
  //     setTheSignUps(null);
  //   }

  //   function clickEventHandler(e) {
  //     if (e.feature.i.hasOwnProperty("STE_CODE16")) {
  //       // it's a state so handle it
  //       if (e.feature.i["STE_CODE16"] == "1") {
  //         // it's NSW so handle it
  //         map.setZoom(10);
  //         map.setCenter({
  //           lat: -33.309356,
  //           lng: 151.309208,
  //         });
  //         return;
  //       }
  //       return;
  //     }

  //     return;
  //   }

  //   map.data.addListener("click", clickEventHandler);
  //   map.data.addListener("mouseover", mouseInToRegion);
  //   map.data.addListener("mouseout", mouseOutOfRegion);
  // };

  useEffect(() => {
    // console.log(console.log(Object.keys(awesomeAnalyticsData)));
    // setTheData(massageTheData(awesomeAnalyticsData));
    // setTheData
  }, [awesomeAnalyticsData]);

  if (awesomeAnalyticsData.fetching) {
    return null;
  }

  // const thePostcode = (null === awesomeAnalyticsData.participationData) ? '' : awesomeAnalyticsData.participationData.postcode;
  // const theNumberOfUnitsOffsetString =
  //   null === theWeightOfBagsCollected
  //     ? ""
  //     : "theWeightOfBagsCollected equivalent";
  const weightToShow =
    null === theWeightOfBagsCollected
      ? 0
      : theBagsCollected * awesomeAnalyticsData.average_bag_weight;
  const bagsCollected = null === theBagsCollected ? 0 : theBagsCollected;
  const unitsString = null === theUnitsString ? "" : theUnitsString;
  // const participationRate =
  //   null === theParticipationRate ? 0 : theParticipationRate;
  // const signUps = null === theSignUps ? 0 : theSignUps;
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "60vh", width: "100%" }}>
      <div
        style={{
          flexDirection: "column",
          borderRadius: "0px 0px 10px 0px",
          opacity: "0.9",
          display: null === thePostcode ? "none" : "flex",
        }}
        className="nicebox"
      >
        <h4>
          Postcode: <b>{thePostcode}</b>
        </h4>
        {/*<p style={{ textAlign: 'left', margin: 0}}>Sign Ups: <b>{(signUps).toLocaleString(undefined, { style: 'decimal', maximumFractionDigits: 1})}</b></p>*/}
        {/*<p style={{ textAlign: 'left', margin: 0}}>Participation Rate: <b>{(participationRate).toLocaleString(undefined, { style: 'decimal', maximumFractionDigits: 1})} %</b></p>*/}
        <p style={{ textAlign: "left", margin: 0 }}>
          Bags collected:{" "}
          <b>
            {bagsCollected.toLocaleString(undefined, {
              style: "decimal",
              maximumFractionDigits: 1,
            })}
          </b>
        </p>
        <p style={{ textAlign: "left", margin: 0 }}>
          Total weight of bags collected:{" "}
          <b>
            {(weightToShow / 1000).toLocaleString(undefined, {
              style: "decimal",
              maximumFractionDigits: 1,
            })}{" "}
            kg
          </b>
        </p>
        <p style={{ textAlign: "left", margin: 0 }}>
          {bagsCollected === 0 ? `` : `That's equivalent to`}{" "}
          <b>{unitsString}</b>
        </p>
      </div>

      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={defaultOptions.center}
        defaultZoom={defaultOptions.zoom}
        mapId={defaultOptions.mapId}
        onGoogleApiLoaded={({ map, maps }) =>
          handleApiLoaded(map, maps, awesomeAnalyticsData)
        }
        options={createMapOptions}
        hoverDistance={K_SIZE / 2}
      ></GoogleMapReact>
    </div>
  );
}

PostMvpParticipationMap.propTypes = {
  awesomeAnalyticsData: PropTypes.shape({
    average_bag_weight: PropTypes.number.isRequired,
    fetching: PropTypes.bool.isRequired,
    thePostMvpParticipationData: PropTypes.arrayOf(PropTypes.any).isRequired,
    thePostMvpRelevantDataObject: PropTypes.shape(PropTypes.any.isRequiered),
  }),
};

export default PostMvpParticipationMap;
