import React, { useEffect, useState } from 'react';

// @material-ui/core
import Fade from "@material-ui/core/Fade";
// core components
import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import ActionableDataTable from "../../../../components/ActionableDataTable/ActionableDataTable";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../components/Card/CardIcon.js";
import CardBody from "../../../../components/Card/CardBody.js";
import CustomSearchBar from "../../../../components/CustomSearchBar/CustomSearchBar";
import Spinner from "../../../../components/Spinner/Spinner";
import Button from "../../../../components/CustomButtons/Button";
import paginationHelper from "../../../../utilities/paginationHelper";
import useDebounce from "../../../../hooks/useDebounce";
import { decodeString } from '../../../../utilities/helpers/helpers';
import { getAllRedirectionLinks } from "services/CurbCycleApi";

import CreateForm from './CreateForm';
import UpdateForm from './UpdateForm';

const RedirectionServiceUi = () => {
  // ui context, is it list, create or update
  const [uiContext, setUiContext] = useState('list');
  
  // the values to be managed
  const [uiContextData, setUiContextData] = useState({
    code: '',
    label: '',
    link: '',
    description: '',
    destination: '',
  });
  
  // all the state management for the list view...
  const tableData = { data: [] };
  const [linkTableRawData, setLinkTableRawData] = useState({
    data: [],
  });
  
  const [results, setResults] = useState({ data: [] });
  const [isSearching, setIsSearching] = useState(false);
  const [linkTableFilter, setLinkTableFilter] = useState(null);
  const debouncedLinkTable = useDebounce(linkTableFilter, 500);
  
  useEffect(() => {
    let isCancelled = false;
    setIsSearching(true);
    getAllRedirectionLinks(debouncedLinkTable).then((response) => {
      if (!isCancelled) {
        setLinkTableRawData(response);
        setIsSearching(false);
      }
      let newTableData = {
        data: [],
      };
      newTableData.data = response.data.map((item) => {
        return [
          {
            display: item.code || "-",
          },
          {
            display: decodeString(item.label),
          },
          {
            display: item.link,
          },
          {
            display: decodeString(item.description),
          },
          {
            display: item.destination,
          },
          {
            hasActions: true,
            actions: [
              {
                type: "callback-button",
                icon: "edit",
                color: "info",
                description: "Update",
                size: "sm",
                context: {
                  callback: () => {
                    setUiContext('update');
                    setUiContextData(item);
                  },
                },
              },
              {
                type: "callback-button",
                icon: "code",
                color: "info",
                description: "View QR code",
                size: "sm",
                context: {
                  callback: () => {
                    var url =  `https://development-api.curby.cc/generate-qr-code/${item.code}`;
                    window.open(url, '_blank');
                  },
                },
              },
            ],
          },
        ];
      });
      setResults(newTableData);
      return () => {
        isCancelled = true;
      };
    });
  }, [debouncedLinkTable]);
  
  const handleCodeInput = (event) => {
    event.preventDefault(event);
    console.log(event.target.value);
    
    if (event.target.value === undefined) {
      return;
    }
    if (event.target.value === "") {
      setLinkTableFilter(null);
    } else {
      setLinkTableFilter(`codeLike=${event.target.value}`);
      setIsSearching(true);
    }
  };
  
  // the "list" context view
  if (uiContext === 'list') {
    return (
      <>
      <Fade in={true} timeout={1000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">Manage Redirection Links</CardIcon>
              </CardHeader>
              <br />
              <CardBody>
                <GridContainer
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="flex-end"
                >
                  <GridItem>
                    <CustomSearchBar
                      id="search-uuid"
                      name="code"
                      label="Code"
                      defaultValue={debouncedLinkTable}
                      onChange={(event) => {
                        console.log(event.target.value);
                        if (event.target.value === "") {
                          setLinkTableFilter(null);
                        } else {
                          setLinkTableFilter(`codeLike=${event.target.value}`);
                          setIsSearching(true);
                        }
                      }}
                      onClick={handleCodeInput}
                      onSubmit={handleCodeInput}
                    />
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1}>
                    <Button color={"warning"} link={true} size={"lg"} onClick={() => { setUiContext('create') }}>
                      <span className="material-icons">add</span> Add Redirection Code
                    </Button>
                  </GridItem>
                </GridContainer>
                {isSearching ? (
                    <div>
                      <ActionableDataTable
                        disabled
                        tableHeaderColor="warning"
                        tableHead={[
                          "Code",
                          "Label",
                          "Link",
                          "Description",
                          "Destination",
                          "Actions",
                        ]}
                        tableData={tableData.data}
                      />
                      <Spinner centerWindow={true} />
                    </div>
                  ) : (
                    <ActionableDataTable
                      tableHeaderColor="warning"
                      tableHead={[
                        "Code",
                        "Label",
                        "Link",
                        "Description",
                        "Destination",
                        "Actions",
                      ]}
                      tableData={results.data}
                      pagination={true}
                      paginationConfig={{
                        collectionData: linkTableRawData,
                        handlePageChange: (payload) => {
                          console.log(debouncedLinkTable);
                          setLinkTableFilter(
                            paginationHelper.generateFilterQueryStringFromFilterOptions(
                              [
                                {
                                  filterKey: "page",
                                  filterValue: payload.selected,
                                  type: "simpleKeyValue",
                                },
                              ]
                            )
                          );
                        },
                      }}
                    />
                  )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fade>
      </>
    );
  }
  
  // the "create" context view
  if (uiContext === 'create') {
    return (
      <>
      <Fade in={true} timeout={1000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">Create New Redirection Link</CardIcon>
              </CardHeader>
              <br />
              <CardBody>
                <GridContainer
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="flex-end"
                >
                  <GridItem>
                    
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1}>
                    <Button color={"warning"} link={true} size={"lg"} onClick={() => {
                      setUiContextData({
                        code: '',
                        label: '',
                        link: '',
                        description: '',
                        destination: '',
                      });
                      setUiContext('list');
                    }}>
                      <span className="material-icons">back</span> Cancel
                    </Button>
                  </GridItem>
                </GridContainer>
                <CreateForm
                  uiContextData={uiContextData}
                  onCreateSuccess={(response) => {
                    alert('Success');
                    window.location.reload();
                  }}
                  onCreateError={(response) => {
                    alert(`Error: ${response.reason}` );
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fade>
      </>
    )
  }
  
  // the "update" context view
  if (uiContext === 'update') {
    return (
      <>
      <Fade in={true} timeout={1000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">Update Redirection Link</CardIcon>
              </CardHeader>
              <br />
              <CardBody>
                <GridContainer
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="flex-end"
                >
                  <GridItem>
                  
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1}>
                    <Button color={"warning"} link={true} size={"lg"} onClick={() => {
                      setUiContextData({
                        code: '',
                        label: '',
                        link: '',
                        description: '',
                        destination: '',
                      });
                      setUiContext('list');
                    }}>
                      <span className="material-icons">back</span> Cancel
                    </Button>
                  </GridItem>
                </GridContainer>
                <UpdateForm
                  uiContextData={uiContextData}
                  onUpdateSuccess={(response) => {
                    alert('Success');
                    window.location.reload();
                  }}
                  onUpdateError={(response) => {
                    alert(`Error: ${response.reason}` );
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fade>
      </>
    )
  }
  
  
};

export default RedirectionServiceUi;