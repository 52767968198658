import {
  managementRequestedRetailSponsorManagers,
  managementReceivedRetailSponsorManagers,
} from "../../../../redux/reducer";

import paginationHelper from '../../../../utilities/paginationHelper';

import { getAllRetailSponsorManagers } from '../../../../services/CurbCycleApi';

export const pageChangeRetailSponsorManagersWithFiltersThunk = (retailSponsorUuid, filtersAsQueryString) => dispatch => {
  dispatch(managementRequestedRetailSponsorManagers());
  getAllRetailSponsorManagers(retailSponsorUuid, filtersAsQueryString)
    .then(result => {
      dispatch(managementReceivedRetailSponsorManagers(result));
    })
    .catch(() => {
      dispatch(
        managementReceivedRetailSponsorManagers(
          paginationHelper.getDefaultCollectionShape()
        )
      );
    });
};

export default pageChangeRetailSponsorManagersWithFiltersThunk;
