import {
  managementRequestedLGAManagers,
  managementReceivedLGAManagers,
} from "../../../../redux/reducer";

import paginationHelper from '../../../../utilities/paginationHelper';

import { getAllLGAManagers } from '../../../../services/CurbCycleApi';

export const pageChangeLGAManagersWithFiltersThunk = (lgaUuid, filtersAsQueryString) => dispatch => {
  dispatch(managementRequestedLGAManagers());
  getAllLGAManagers(lgaUuid, filtersAsQueryString)
    .then(result => {
      dispatch(managementReceivedLGAManagers(result));
    })
    .catch(() => {
      dispatch(
        managementReceivedLGAManagers(
          paginationHelper.getDefaultCollectionShape()
        )
      );
    });
};

export default pageChangeLGAManagersWithFiltersThunk;
