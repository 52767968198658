function map (statusName) {
  switch(statusName) {
    case 'active':
      return 'verified_user';
    case 'inactive':
      return 'blocked';
    default:
      return statusName;
  }
}

export default { map }