import React, { useState } from "react";
import PropTypes from "prop-types";
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TablePagination from "@material-ui/core/TablePagination";
import FilterListIcon from "@material-ui/icons/FilterList";
import Toolbar from "@material-ui/core/Toolbar";
import uuid from "../../../utilities/uuid";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment key={row.uuid}>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.reference}
        </TableCell>
        <TableCell>{`${row.mrf_name}`}</TableCell>
        <TableCell>{`${row.material_type}`}</TableCell>
        <TableCell>{`${row.weight_in_g / 1000}`}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Images
              </Typography>
              {row.images.map((historyRow) => (
                <img
                  key={uuid.uuid()}
                  src={historyRow}
                  style={{
                    maxWidth: "250px",
                  }}
                />
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function actionableDataGridConfigurationHelper() {
  return {
    mapFilters(configuration) {
      // const theFilters = configuration.headers.map((header) => (
      //     <TableCell>{header}</TableCell>
      // ));
      return (
        <Toolbar>
          <IconButton style={{ marginLeft: "90%" }} aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Toolbar>
      );
    },
    mapHeaders(configuration) {
      const theHeaders = configuration.headers.map((header) => (
        <TableCell key={uuid.uuid()}>{header}</TableCell>
      ));
      return (
        <TableHead>
          <TableRow>
            <TableCell />
            {theHeaders}
          </TableRow>
        </TableHead>
      );
    },
    mapRows(configuration) {
      const theRows = configuration.rows.data.map((row) => (
        <Row key={uuid.uuid()} row={row} />
      ));
      return [theRows];
    },
    mapPaginator(configuration) {
      return (
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={configuration.total_row_count}
          rowsPerPage={20}
          page={1 - 1}
          onChangePage={() => {}}
          onChangeRowsPerPage={() => {}}
        />
      );
    },
  };
}

Row.propTypes = {
  row: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.any),
    material_type: PropTypes.string,
    mrf_name: PropTypes.string,
    reference: PropTypes.any,
    uuid: PropTypes.string.isRequired,
    weight_in_g: PropTypes.number,
  }),
};

export default actionableDataGridConfigurationHelper;
