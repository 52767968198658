import React from "react";
import PropTypes from "prop-types";
import { Chart } from "react-google-charts";

function BaleWeightScannedInPerDay({
  awesomeAnalyticsData,
  onSelectBaleDateCallback,
}) {
  // if (undefined === awesomeAnalyticsData) {
  //   return null;
  // }
  //
  // if (undefined === awesomeAnalyticsData.individual_dates_min_to_max) {
  //   return null;
  // }

  // console.log({
  //   "BaleWeightScannedInPerDay": {
  //     "awesomeAnalyticsData": awesomeAnalyticsData
  //   }
  // });

  let dataArray = [["Date", "Weight in kg"]];

  let theCallback = onSelectBaleDateCallback;

  let startGraph = false;

  awesomeAnalyticsData.individual_dates_min_to_max.forEach((item, index) => {
    if (item === "02/01/2021") {
      startGraph = true;
    }

    if (true === startGraph) {
      dataArray.push([
        item,
        undefined ===
        awesomeAnalyticsData.total_bales_scanned_in_for_dates[item]
          ? 0
          : awesomeAnalyticsData.total_bales_scanned_in_for_dates[item]
              .total_weight_of_bales / 1000,
      ]);
    }
  });

  const TICK_INTERVAL = 7;

  var ticks = [];
  for (var i = 0; i < dataArray.length; i++) {
    if (i % TICK_INTERVAL === 0) {
      ticks.push(dataArray[i]);
    }
  }

  return (
    <Chart
      height={`45vh`}
      chartType="ColumnChart"
      loader={<div>Loading Chart</div>}
      data={dataArray}
      options={{
        title: "",
        chartArea: { width: "80%" },
        hAxis: {
          title: "Date",
          format: "dd-MMM-yyyy",
          minValue: 0,
          ticks: ticks,
          slantedText: true,
        },
        vAxis: {
          title: "Weight in kg",
        },
        colors: ["#F05573", "#C3B493", "#686E84", "#5C7379"],
      }}
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            const selectionValues = dataArray[selection[0].row + 1];
            // selectionValues[1] is the weight which should be > 0
            // selectionValues[0] is the date we want to drill into
            if (selectionValues[1] > 0) {
              theCallback(selectionValues[0]);
            }
          },
        },
      ]}
    />
  );
}

BaleWeightScannedInPerDay.propTypes = {
  awesomeAnalyticsData: PropTypes.shape({
    individual_dates_min_to_max: PropTypes.arrayOf(PropTypes.any.isRequired,).isRequired,
    total_bales_scanned_in_for_dates: PropTypes.arrayOf(PropTypes.any.isRequired,).isRequired,
  }),
  onSelectBaleDateCallback: PropTypes.func.isRequired,
};

export default BaleWeightScannedInPerDay;
