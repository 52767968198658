import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
// core components
// import Journey Flows
import Auth from "./pages/JourneyFlows/Auth";
import Initialising from "./pages/JourneyFlows/Initialising";
import Unauthorized from "./pages/JourneyFlows/Unauthorized";
import CurbCycleDevelopmentRouter from "./pages/JourneyFlows/CurbCycleDevelopment/Router";
import CurbCycleSupportRouter from "./pages/JourneyFlows/CurbCycleSupport/Router";
import CurbCycleManagementRouter from "./pages/JourneyFlows/CurbCycleManagement/Router";
import MrfManagementRouter from "./pages/JourneyFlows/MrfManagement/Router";
import LgaRouter from "./pages/JourneyFlows/LGA/Router";
import RetailSponsorRouter from "./pages/JourneyFlows/RetailSponsor/Router";

import { connect } from "react-redux";

function AppRouter({
  isInitialising,
  isLoggedIn,
  isCurbCycleManagementUser,
  isCurbCycleDevelopmentTeamUser,
  isCurbCycleSupportTeamUser,
  isMrfManagementUser,
  isRetailSponsorUser,
  isLgaUser,
}) {
  const hist = createBrowserHistory();
  let router;

  useEffect(() => {
    // console.log(isLoggedIn);
  }, [isLoggedIn]);

  if (isInitialising) {
    router = <Initialising />;
    return router;
  }

  if (!isLoggedIn) {
    router = <Auth />;
  }

  if (isLoggedIn) {
    if (isCurbCycleSupportTeamUser) {
      router = <CurbCycleSupportRouter history={hist} />;
    } else if (isCurbCycleDevelopmentTeamUser) {
      router = <CurbCycleDevelopmentRouter history={hist} />;
    } else if (isCurbCycleManagementUser) {
      router = <CurbCycleManagementRouter history={hist} />;
    } else if (isMrfManagementUser) {
      router = <MrfManagementRouter history={hist} />;
    } else if (isRetailSponsorUser) {
      router = <RetailSponsorRouter history={hist} />;
    } else if (isLgaUser) {
      router = <LgaRouter history={hist} />;
    } else {
      router = <Unauthorized />;
    }
  }

  return router;
}

function mapStateToProps(state) {
  return {
    isInitialising: state.isInitialising,
    isLoggedIn: state.isLoggedIn,
    isCurbCycleManagementUser: state.isCurbCycleManagementUser,
    isCurbCycleDevelopmentTeamUser: state.isCurbCycleDevelopmentTeamUser,
    isCurbCycleSupportTeamUser: state.isCurbCycleSupportTeamUser,
    isMrfOperatorUser: state.isMrfOperatorUser,
    isMrfManagementUser: state.isMrfManagementUser,
    isLgaUser: state.isLgaUser,
    isRetailSponsorUser: state.isRetailSponsorUser,
  };
}

export default connect(mapStateToProps)(AppRouter);
