import React from "react";
import PropTypes from "prop-types";
import paginationHelper from "../../utilities/paginationHelper";
function Pagination({ paginationConfig }) {
  const collectionData =
    undefined === paginationConfig.collectionData ||
    undefined === paginationConfig.collectionData.last_page
      ? paginationHelper.getDefaultCollectionShape()
      : paginationConfig.collectionData;

  // console.log(collectionData);

  return (
    <div style={{ color: "#ffffff", padding: "15px" }}>
      <span
        // onMouseOver=""  ? // expects a function instead gets a string
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (collectionData.current_page > 1) {
            paginationConfig.handlePageChange({
              selected: collectionData.current_page - 1,
            });
          }
        }}
      >
        &#x276E; &#x276E;
      </span>

      <span style={{ margin: "100px" }}>
        Page {paginationConfig.collectionData.current_page} of{" "}
        {paginationConfig.collectionData.last_page}
      </span>

      <span
        // onMouseOver="" ? // expects a function instead gets a string
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (collectionData.current_page < collectionData.last_page) {
            paginationConfig.handlePageChange({
              selected: collectionData.current_page + 1,
            });
          }
        }}
      >
        &#x276F; &#x276F;
      </span>
    </div>
    // <ReactPaginate
    //   pageCount={collectionData.last_page}
    //   initialPage={0}
    //   pageRangeDisplayed={0}
    //   marginPagesDisplayed={0}
    //   previousLabel="&#x276E;"
    //   nextLabel="&#x276F;"
    //   containerClassName="data-table-pagination-container"
    //   activeClassName="active-data-table-page-number"
    //   disabledClassName="disabled"
    //   onPageChange={(payload) => {
    //     // dirty pagination hack...
    //     if (payload.selected === 0 && collectionData.last_page === 1) {
    //       paginationConfig.handlePageChange(payload)
    //       return;
    //     } else if (payload.selected === 1 && collectionData.last_page === 1) {
    //       // nothing...
    //       return;
    //     } else if (payload.selected === 1 && collectionData.last_page === 2) {
    //       paginationConfig.handlePageChange(payload)
    //       return;
    //     }
    //
    //     if ((collectionData.last_page - 1) === (payload.selected)) {
    //       // do nothing
    //       return;
    //     }
    //
    //     paginationConfig.handlePageChange(payload);
    //   }}
    //   disableInitialCallback={true}
    // />
  );
}

Pagination.propTypes = {
  paginationConfig: PropTypes.shape({
    collectionData: PropTypes.shape({
      last_page: PropTypes.any,
      current_page: PropTypes.any,
    }),
    handlePageChange: PropTypes.func,
  }),
};

export default Pagination;
