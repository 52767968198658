import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  formatTime,
  strToLowerWithCap,
} from "../../../../../utilities/helpers/helpers";
// @material-ui/core
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { DataGrid } from "@material-ui/data-grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import BackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  app: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  appBar: {
    alignItems: "space-between",
    paddingRight: theme.spacing(2),
  },
  content: {
    margin: theme.spacing(12, 0),
    padding: "8px 100px 0px 40px",
  },
}));

const PointsHistoryModal = ({ data, handleClosePoints, showPointsModal }) => {
  console.log("data", data);
  const classes = useStyles();
  const tableData = {
    columns: [
      {
        field: "id",
        headerName: "Id",
        width: 1,
        hide: true,
        editable: false,
      },
      {
        field: "points",
        headerName: "Points",
        flex: 1,
        editable: false,
      },
      {
        field: "date",
        headerName: "Date/Time",
        flex: 2,
        editable: false,
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        editable: false,
      },
      {
        field: "statusReason",
        headerName: "Status Reason",
        flex: 1,
        editable: false,
      },
      {
        field: "milestone",
        headerName: "Milestone",
        flex: 3,
        editable: false,
      },
    ],
    rows: data
      ? data.map((x, index) => {
          return {
            id: index,
            status: strToLowerWithCap(x.status),
            points: x.points,
            date: `${formatTime(x.metadata.timestamp)} (${moment(
              x.metadata.timestamp
            ).fromNow()})`,
            statusReason: strToLowerWithCap(x.status_reason),
            milestone: x.metadata.journal,
          };
        })
      : [],
  };

  // console.log("data", data);

  return (
    <>
      <Dialog
        fullScreen
        open={showPointsModal}
        onClose={handleClosePoints}
        aria-labelledby="Points History"
        aria-describedby="Curby Points History"
      >
        <AppBar>
          <Toolbar className={classes.appBar}>
            <Typography variant="h5" className={classes.title}>
              {strToLowerWithCap(data[0].journal_type)}
            </Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClosePoints}
              aria-label="close"
            >
              <BackIcon />
              <Typography className={classes.title}>BACK</Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.content}>
          <DataGrid disableSelectionOnClick autoHeight {...tableData} />
        </DialogContent>
      </Dialog>
    </>
  );
};

PointsHistoryModal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  handleClosePoints: PropTypes.func,
  showPointsModal: PropTypes.bool.isRequired,
};

export default PointsHistoryModal;
