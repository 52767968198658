import React, { useEffect, createRef, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
// import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import CardBody from "../../../../components/Card/CardBody.js";
import AverageMonthlyBagCollectionByPostcode from "./components/Charts/AverageMonthlyBagCollectionByPostcode";
import NumberOfTrialSignUpsByPostcode from "./components/Charts/NumberOfTrialSignUpsByPostcode";
import BaleWeightScannedInPerDayWithBalePhotoModalController from "./components/Charts/BaleWeightScannedInPerDayWithBalePhotoModalController";
import MvpTrialParticipation from "./components/Charts/MvpTrialParticipation";
import BigNumberProgressBar from "./components/Charts/BigNumber/BigNumberProgressBar";
import styles from "./assets/jss/adminStyle.js";
import LogoutAvatar from "components/Sidebar/components/LogoutAvatar";
import curbCycleLogo from "./assets/ccc-curbcycle-logo.png";
import logo from "./assets/ccc-logo.png";
import fetchAnalyticsThunk from "../../../../thunks/LGA/fetchAnalyticsThunk";
import WhereDidThePlasticGo from "./../../../../components/DashboardComponents/WhereDidThePlasticGo/WhereDidThePlasticGo";
import PostMvpParticipationMap from "./../../../../components/DashboardComponents/Map/AwesomeMapComponent/PostMvpParticipationMap";
import InteractivityIndicator from "./../../../../components/DashboardComponents/InteractivityIndicator";
import {getLgaAnalytics, getLgaSummaryAnalytics, getLgaPostCodeAnalytics, getLGAQuicksightUrl, getAllRelevantAccountDataForCurbyApp} from "services/CurbCycleApi";
import UserStatsTable from "components/DashboardComponents/UserStatsTable";
import MrfStatsTable from "components/DashboardComponents/MrfStatsTable";
import PostCodeStatsTable from "components/DashboardComponents/PostCodeStatsTable";
import StewardStatsTable from "components/DashboardComponents/StewardStatsTable";
import GenericBarGraph from "components/DashboardComponents/GenericBarGraph";
import GenericScanBarGraph from "components/DashboardComponents/GenericScanBarGraph";
import GenericBaleBarGraph from "components/DashboardComponents/GenericBaleBarGraph.js";
import SignupsByProgramBarGraph from "components/DashboardComponents/SignupsByProgramBarGraph";
import { Divider } from "@material-ui/core";
import OperationalUpdates from "components/DashboardComponents/OperationalUpdates.js";
import Spinner from "components/Spinner/Spinner.js";
import PostCodeParticipation from "components/DashboardComponents/PostCodeParticipation.js";

import Dashboard from "components/DashboardComponents/Dashboard.js";
let ps;

const useStyles = makeStyles(styles);

const balesChartTooltipImage = require("./../../../../components/DashboardComponents/clickableBarImage.png");

function MainLayout() {
  // styles
  const classes = useStyles();
  const mainPanel = createRef();
  const [dashUrl, setDashUrl] = useState(null);
  const [postMvpLgaMapDataValue, setPostMvpLgaMapDataValue] = useState(null);
  const [theParticipationData, setTheParticipationData] = useState(null);
  const [theRelevantDataObject, setTheRelevantDataObject] = useState(null);
  const [
    thePostMvpParticipationData,
    setThePostMvpParticipationData,
  ] = useState(null);
  const [
    thePostMvpRelevantDataObject,
    setThePostMvpRelevantDataObject,
  ] = useState(null);
  const [monthlyAnalyticsData, setAnalyticsData] = useState(null);
  const [summaryAnalyticsData, setSummaryAnalyticsData] = useState(null);
  const [postCodeAnalyticsData, setPostCodeAnalyticsData] = useState(null);


  useEffect(() => {

    getLGAQuicksightUrl().then((data) => {
      setDashUrl(data.EmbedUrl)
    
  })


    
  }, []);

  var options = {};

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      // setMobileOpen(false);
    }
  };
 
// if (!dashUrl){
//   return (
//     <div>loading</div>
//   )
// }

  return (
    <div
      id="productStewardshipHeadingArea"
      className={classes.wrapper}
      style={{
        backgroundColor: "#04427f",
        width: "100%",
      }}
    >
      <div
        style={{
          zIndex: 100000,
          position: "fixed",
          top: "1em",
          paddingTop: "15px",
          marginTop: "40",
          width: "10%",
          marginLeft: "85%",
          height: "50px",
        }}
      >
        <LogoutAvatar>N</LogoutAvatar>
      </div>

      <div >
        <div
          style={{
            display: "flex",
            flexFlow: "row wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
            }}
          >
            <img
              src={curbCycleLogo}
              alt=""
              style={{
                maxWidth: "170px",
                paddingTop: "1em",
                paddingLeft: "1em",
                paddingRight: "1em",
              }}
            />
            <img
              src={logo}
              alt=""
              style={{ maxWidth: "170px", padding: "1em" }}
            />
          </div>

          <div
            style={{
              display: "inline",
            }}
          >
            <h1
              style={{
                color: "#fff",
                fontSize: "2.5em",
                fontWeight: "500",
                paddingTop: "2.5em",
                paddingBottom: "0",
                marginBottom: "0",
              }}
            >
              Central Coast Council
            </h1>

          </div>
        </div>
      </div>

      <div
        className={classes.mainPanel}
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#eeeeee",
          textAlign: "center",
          // backgroundImage:
          //   "linear-gradient(#eeeeee, #eeeeee 2%, #eeeeee 15.5%, #eeeeee)",
        }}
      >
        <GridContainer
          style={{
            width: "100%",
            height: "140%",
          }}
          id="productStewardshipHeadingBox"
        >
          <GridItem xs={12} sm={12} md={12}>
            <h4
              style={{ textAlign: "center",  fontWeight: "400" }}
            >
              Curby Statistics
            </h4>
            <Dashboard dashUrl={dashUrl}/>
          </GridItem>
        </GridContainer>

        <br style={{ clear: "both" }} />
        <div id=""></div>
      </div>
    </div>
  );
}


export default MainLayout;
