import {
  managementRequestedAddLGAManager,
  managementCompletedAddLGAManager,
} from "../../../../../redux/reducer";

import { addLGAManager } from '../../../../../services/CurbCycleApi';

export const addLGAManagerThunk = (payload, lgaUuid, setIsSaving, setWasSuccessful) => dispatch => {
  dispatch(managementRequestedAddLGAManager());
  setIsSaving(true);
  addLGAManager(lgaUuid, payload)
    .then(result => {
      dispatch(managementCompletedAddLGAManager(result));
      setIsSaving(false);
      setWasSuccessful(true);
    })
    .catch(() => {
      dispatch(managementCompletedAddLGAManager({}));
      setIsSaving(false);
    });
};

export default addLGAManagerThunk;
