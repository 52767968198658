import React, { useState } from "react";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
// custom components
import GridItem from "../../layouts/components/Grid/GridItem.js";
import GridContainer from "../../layouts/components/Grid/GridContainer.js";
import Card from "../../layouts/components/Card/Card";
import CardHeader from "../../layouts/components/Card/CardHeader.js";
import CardBody from "../../layouts/components/Card/CardBody.js";
import InventoryAnalyticsSummaryWithDetail from "./InventoryAnalyticsSummary/InventoryAnalyticsSummaryWithDetail";
import InventoryMaterialTypeDataTable from "./InventoryMaterialTypeDataTable/InventoryMaterialTypeDataTable";
import InventoryMaterialTypeSummary from "./InventoryMaterialTypeSummary/InventoryMaterialTypeSummary";

export default function InventorySummaryWithDataTableDetail({ materialTypes }) {
  const [moreDetailedClicked, setMoreDetailClicked] = useState(false);
  const [materialType, setMaterialType] = useState(undefined);

  const callback = (theMaterialType) => {
    setMoreDetailClicked(true);
    setMaterialType(theMaterialType);
  };

  const theCardHeaderStyle = {
    fontFamily: "nunito",
    fontSize: "2.0em",
    padding: 30,
  };

  if (moreDetailedClicked === false) {
    return (
      <>
        <Fade in={true} timeout={3000}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary" style={theCardHeaderStyle}>
                  Inventory Summary
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <InventoryAnalyticsSummaryWithDetail />
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={12}>
                      <PredictedForecastChart />
                    </GridItem> */}
                    <GridItem xs={12} sm={12} md={12}>
                      <InventoryMaterialTypeSummary
                        materialType={materialType}
                        materialTypes={materialTypes}
                        backAction={() => {
                          setMoreDetailClicked(false);
                        }}
                        onClickedAction={callback}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Fade>
      </>
    );
  }

  if (moreDetailedClicked === true) {
    return (
      <>
        <Fade in={true} timeout={3000}>
          <InventoryMaterialTypeDataTable
            materialType={materialType}
            materialTypes={materialTypes}
            backAction={() => {
              setMoreDetailClicked(false);
            }}
            // materialTypeFriendlyName={materialTypeFriendlyName}
          />
        </Fade>
      </>
    );
  }
}
