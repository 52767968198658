import {
  managementRequestedAddMrf,
  managementCompletedAddMrf,
} from "../../../redux/reducer";

import { addMrf } from '../../../services/CurbCycleApi';

export const addMrfThunk = (payload, setIsSaving, setWasSuccessful) => dispatch => {
  dispatch(managementRequestedAddMrf());
  setIsSaving(true);
  addMrf(payload)
    .then(result => {
      dispatch(managementCompletedAddMrf(result));
      setIsSaving(false);
      setWasSuccessful(true);
    })
    .catch(() => {
      dispatch(managementCompletedAddMrf({}));
      setIsSaving(false);
    });
};

export default addMrfThunk;
