import {
  managementRequestedFetchMrfAccessPin,
  managementCompletedFetchMrfAccessPin,
} from "../../../../redux/reducer";

import { fetchMrfAccessPin } from '../../../../services/CurbCycleApi';

export const fetchMrfAccessPinThunk = (payload) => dispatch => {
  dispatch(managementRequestedFetchMrfAccessPin());
  fetchMrfAccessPin(payload)
    .then(result => {
      dispatch(managementCompletedFetchMrfAccessPin(result));
      alert(result.pin);
    })
    .catch(() => {
      dispatch(managementCompletedFetchMrfAccessPin({
        pin: 'error'
      }));
    });
};

export default fetchMrfAccessPinThunk;
