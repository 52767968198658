import {
  managementRequestedAddMrfOperator,
  managementCompletedAddMrfOperator,
} from "../../../../../redux/reducer";

import { addMrfOperator } from '../../../../../services/CurbCycleApi';

export const addMrfOperatorThunk = (payload, mrfUuid, setIsSaving, setWasSuccessful) => dispatch => {
  dispatch(managementRequestedAddMrfOperator());
  setIsSaving(true);
  addMrfOperator(mrfUuid, payload)
    .then(result => {
      dispatch(managementCompletedAddMrfOperator(result));
      setIsSaving(false);
      setWasSuccessful(true);
    })
    .catch(() => {
      dispatch(managementCompletedAddMrfOperator({}));
      setIsSaving(false);
    });
};

export default addMrfOperatorThunk;