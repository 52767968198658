import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

function AdminSettings() {
  const options = [
    {
      name: "Material Type Journeys",
      path: "admin-for-material-type-journeys",
    },
    { name: "Experiments", path: "admin-for-experiments" },
  ];

  return options.map((option) => (
    <Link key={option.name} to={option.path}>
      <Button onClick={() => {}}>{option.name}</Button>
    </Link>
  ));
}

export default AdminSettings;
