module.exports = {
  component: 'vfb-mb-form-element-generic-input-field',
  elementPrefix: '',
  name: "MB: Generic Input Field - can specify alternative type (email, number, password)",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'placeholder_text',
      value: 'Some placeholder'
    },
    {
      type: 'literal',
      variable: 'type',
      value: 'text-val'
    },
    {
      type: 'literal',
      variable: 'validation_spec',
      value: 'required'
    },
    {
      type: 'literal',
      variable: 'pre_text',
      value: ''
    },
    {
      type: 'literal',
      variable: 'post_text',
      value: ''
    },
    {
      type: 'literal',
      variable: 'tooltip_content',
      value: ''
    },
    {
      type: 'literal',
      variable: 'format',
      value: ''
    }
  ]
}
