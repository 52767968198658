
module.exports = {
  component: 'vfb-mb-form-component-time-best-time-to-contact',
  elementPrefix: '',
  name: "MB: Best time to contact field - can specify row options via comma separated values",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'placeholder_text',
      value: 'Some placeholder'
    },
    {
      type: 'literal',
      variable: 'raw_options',
      value: 'option1,option2'
    }
  ]
}
