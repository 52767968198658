import {
  managementRequestedMrfManagers,
  managementReceivedMrfManagers,
} from "../../../../redux/reducer";

import paginationHelper from '../../../../utilities/paginationHelper';

import { getAllMrfManagers } from '../../../../services/CurbCycleApi';

export const pageChangeMrfManagersWithFiltersThunk = (mrfUuid, filtersAsQueryString) => dispatch => {
  dispatch(managementRequestedMrfManagers());
  getAllMrfManagers(mrfUuid, filtersAsQueryString)
    .then(result => {
      dispatch(managementReceivedMrfManagers(result));
    })
    .catch(() => {
      dispatch(
        managementReceivedMrfManagers(
          paginationHelper.getDefaultCollectionShape()
        )
      );
    });
};

export default pageChangeMrfManagersWithFiltersThunk;
