import {
  mrfManagementRequestedAddMrfOperator,
  mrfManagementCompletedAddMrfOperator,
} from "../../../../../redux/reducer";

import { addMrfOperatorToMyMrf } from '../../../../../services/CurbCycleApi';

export const addMrfOperatorThunk = (payload, setIsSaving, setWasSuccessful) => dispatch => {
  dispatch(mrfManagementRequestedAddMrfOperator());
  setIsSaving(true);
  addMrfOperatorToMyMrf(payload)
    .then(result => {
      dispatch(mrfManagementCompletedAddMrfOperator(result));
      setIsSaving(false);
      setWasSuccessful(true);
    })
    .catch(() => {
      dispatch(mrfManagementCompletedAddMrfOperator({}));
      setIsSaving(false);
    });
};

export default addMrfOperatorThunk;
