import {
  managementRequestedMrfs,
  managementReceivedMrfs,
} from "../../../../redux/reducer";

import paginationHelper from '../../../../utilities/paginationHelper';

import { getAllMrfs } from '../../../../services/CurbCycleApi';

export const pageChangeMrfsWithFiltersThunk = (filtersAsQueryString) => dispatch => {
  dispatch(managementRequestedMrfs());
  getAllMrfs(filtersAsQueryString)
    .then(result => {
      dispatch(managementReceivedMrfs(result));
    })
    .catch(() => {
      dispatch(
        managementReceivedMrfs(
          paginationHelper.getDefaultCollectionShape()
        )
      );
    });
};

export default pageChangeMrfsWithFiltersThunk;
