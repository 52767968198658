import React from "react";
import PropTypes from "prop-types";
import { Chart } from "react-google-charts";

function TonnageTargetVsTime({awesomeAnalyticsData}) {
  
  // console.log(awesomeAnalyticsData.total_bales_scanned_in_for_dates);
  // console.log((awesomeAnalyticsData.total_weight_of_bales_scanned_in / 1000) / 1000);
  
  let dataArray = [
    [
      'Month',
      'Monthly tonnes Collected',
      'Total tonnes Collected',
      '1% of Target',
      'Monthly tonnes Recycled',
      'Total tonnes Recycled'
    ]
  ];
  
  awesomeAnalyticsData.tonnage_offset_data_frame.forEach(
    (item) => {
      dataArray.push([
        item['Month'],
        item['Monthly tonnes Collected'],
        item['Total tonnes Collected'],
        7.50,
        item['Monthly tonnes Recycled'],
        item['Total tonnes Recycled'],
      ])
    }
  );
  
  
  return (
    <Chart
      height={`35vh`}
      chartType="LineChart"
      loader={<div>Loading Chart</div>}
      data={dataArray}
      options={{
        title: '',
        chartArea: { width: '80%', height: '80%' },
        hAxis: {
          title: 'Month',
          format: 'MMM-yyyy',
          gridlines: {color: 'transparent'}
        },
        vAxes: {
          0: {
            title: 'Monthly tonnes',
            gridlines: {color: 'transparent'}
          },
          1: {
            title: 'Total tonnes',
            gridlines: {color: 'transparent'}
          },
        },
        seriesType: 'line',
        interpolateNulls: false,
        series: {
          0: {
            targetAxisIndex: 0,
            type: 'bars',
            dataOpacity: 0.3,
            color: '#7f6d5e'
          },
          1: {
            targetAxisIndex: 1,
            color: '#5C7379'
          },
          2: {
            targetAxisIndex: 1,
            type: 'line',
            lineDashStyle: [2, 2],
            color: '#F05573'
          },
          3: {
            targetAxisIndex: 0,
            type: 'bars',
            dataOpacity: 0.3,
            color: '#686E84',
          },
        },
        trendlines: {
          1: {
            type: 'linear',
            color: '#5C7379',
            opacity: 0.3,
            lineWidth: 3,
            visibleInLegend: true,
            labelInLegend: "Total tonnes collected trend"
          }
        }
      }}
      // For tests
      rootProps={{ 'data-testid': '1' }}
    />
  );
}

TonnageTargetVsTime.propTypes = {
  awesomeAnalyticsData: PropTypes.shape({
    tonnage_offset_data_frame: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  })
};

export default TonnageTargetVsTime;
