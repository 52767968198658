import {
  managementRequestedEditRetailSponsor,
  managementCompletedEditRetailSponsor,
} from "../../../redux/reducer";

import { updateRetailSponsor } from '../../../services/CurbCycleApi';

export const editRetailSponsorThunk = (payload, uuid, setIsSaving, setWasSuccessful) => dispatch => {
  dispatch(managementRequestedEditRetailSponsor());
  setIsSaving(true);
  updateRetailSponsor(uuid, payload)
    .then(result => {
      dispatch(managementCompletedEditRetailSponsor(result));
      setIsSaving(false);
      setWasSuccessful(true);
    })
    .catch(() => {
      dispatch(managementCompletedEditRetailSponsor({}));
      setIsSaving(false);
    });
};

export default editRetailSponsorThunk;
