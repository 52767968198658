import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import { DataGrid } from "@material-ui/data-grid";
import { ColorPicker } from "material-ui-color";
// core components
import GridContainer from "../../../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../../../components/Grid/GridItem";
// custom components
import Card from "../../../layouts/components/Card/Card";
import CardIcon from "../../../layouts/components/Card/CardIcon.js";
import CardHeader from "../../../layouts/components/Card/CardHeader.js";
import CardBody from "../../../layouts/components/Card/CardBody.js";

// helpers and utils
import materialTypeHelper from "utilities/materialTypeHelper.js";

export default function Materials(props) {
  const { materialTypes, setMaterialTypes } = props;
  const [allMaterials, setAllMaterials] = useState([]);

  useEffect(() => {
    const materialsList = materialTypeHelper.getMaterialTypes();
    if (materialTypes) {
      const notMrfMaterialTypes = materialsList.filter(
        (x) => !materialTypes.map((x) => x.name).includes(x)
      );
      const notMrfMaterialTypesData = notMrfMaterialTypes.map((x) => {
        return {
          id: x,
          name: x,
          baleTagColour: "",
          indicativeDryWeightPerBale: "",
          indicativeWetWeightPerBale: "",
        };
      });
      setAllMaterials([...materialTypes, ...notMrfMaterialTypesData]);
    }
  }, [materialTypes]);

  const updateMaterialType = (event) => {
    let itemToUpdate = (materialTypes || []).find((x) => x.name === event.id);
    itemToUpdate[event.field] = +event.props.value;

    setMaterialTypes([...materialTypes]);
  };

  const changeColour = (event, id) => {
    let itemToUpdate = (materialTypes || []).find((x) => x.name === id);
    itemToUpdate.baleTagColour = `#${event.hex}`;

    setMaterialTypes([...materialTypes]);
  };

  const handleCheckboxClick = (event) => {
    // initial load
    if (materialTypes?.length === event?.selectionModel?.length) {
      // do nothing
      return;
    }

    let updatedMaterialTypes;
    // remove item
    if (materialTypes?.length > event?.selectionModel?.length) {
      updatedMaterialTypes = materialTypes.filter((item) =>
        event?.selectionModel?.includes(item.name)
      );

      setMaterialTypes(updatedMaterialTypes);
    }

    // add item
    if (materialTypes?.length < event?.selectionModel?.length) {
      const newMaterialType = event.selectionModel.find(
        (item) => !materialTypes.find((x) => x.name === item)
      );

      setMaterialTypes([
        ...materialTypes,
        {
          name: newMaterialType,
          baleTagColour: "",
          indicativeDryWeightPerBale: "",
          indicativeWetWeightPerBale: "",
        },
      ]);
    }
  };

  const materialsData = {
    columns: [
      {
        field: "id",
        headerName: "Id",
        width: 1,
        hide: true,
        editable: false,
      },
      {
        field: "materialType",
        headerName: "Material Type",
        width: 300,
        editable: false,
      },
      {
        field: "indicativeDryWeightPerBale",
        headerName: "Dry Weight per Bale",
        flex: 1,
        editable: true,
      },
      {
        field: "indicativeWetWeightPerBale",
        headerName: "Wet Weight per Bale",
        flex: 1,
        editable: true,
      },
      {
        field: "baleTagColour",
        headerName: "Bale Tag Colour",
        flex: 1,
        editable: true,
        renderCell: (item) => {
          return (
            <ColorPicker
              onChange={(event) => {
                changeColour(event, item.id);
              }}
              hideTextfield
              value={item.value}
            />
          );
        },
      },
    ],
    rows: allMaterials
      ? allMaterials.map((x) => {
          return {
            id: x.name,
            materialType: materialTypeHelper.getMaterialName(x.name),
            baleTagColour: x.baleTagColour,
            indicativeDryWeightPerBale: x.indicativeDryWeightPerBale,
            indicativeWetWeightPerBale: x.indicativeWetWeightPerBale,
          };
        })
      : [],
  };

  const selectedItems = materialTypes?.map((x) => {
    return x.name;
  });

  return (
    <>
      <Card>
        <CardHeader stats icon>
          <CardIcon color="gray">Materials</CardIcon>
        </CardHeader>
        <GridContainer
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
        ></GridContainer>
        <CardBody style={{ marginBottom: 30 }}>
          <GridContainer
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            style={{ width: "100%", height: "100%" }}
          >
            <GridItem xs={12} sm={12} md={12}>
              {allMaterials && (
                <div style={{ marginTop: "20px", width: "100%" }}>
                  <DataGrid
                    checkboxSelection
                    disableSelectionOnClick
                    autoHeight
                    sortModel={[
                      {
                        field: "materialType",
                        sort: "asc",
                      },
                    ]}
                    pageSize={6}
                    hideFooterRowCount
                    selectionModel={selectedItems}
                    onSelectionModelChange={(e) => handleCheckboxClick(e)}
                    onEditCellChangeCommitted={(e) => updateMaterialType(e)}
                    {...materialsData}
                  />
                </div>
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
}

Materials.propTypes = {
  materialTypes: PropTypes.arrayOf({
    name: PropTypes.string.isRequired,
    baleTagColour: PropTypes.string.isRequired,
    indicativeDryWeightPerBale: PropTypes.number.isRequired,
    indicativeWetWeightPerBale: PropTypes.number.isRequired,
  }),
  setMaterialTypes: PropTypes.func.isRequired,
};
