module.exports = {
  component: 'vfb-form-component-amex-gcp-american-express-corporate-card-selection',
  elementPrefix: '',
  name: "American Express Corporate Card - type and liability selection (amex)",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: ''
    },
    {
      type: 'literal',
      variable: 'label',
      value: ''
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'corporate_card_type_and_liability_selection'
    }
  ]
}
