import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export default function ContentCreationForm({
  contentTypes,
  selectedContentType,
  contentTypeSelectionCallback,
}) {
  const [value, setValue] = useState(selectedContentType);

  let getTheContentTypeOptions = () => {
    // let theContentTypeOptions = undefined;
    // theContentTypeOptions = Object.keys(contentTypes).forEach((item) => (
    //   <FormControlLabel
    //     value={contentTypes[item].valueSelectionKey}
    //     control={<Radio />}
    //     label={contentTypes[item].displayName}
    //   />
    // ));
  };

  useEffect(() => {
    if (undefined !== contentTypes) {
      getTheContentTypeOptions();
    }
  }, [contentTypes]);

  const handleChange = (event) => {
    setValue(event.target.value);
    contentTypeSelectionCallback(value);
  };

  if (undefined === contentTypes) {
    return null;
  }

  Object.keys(contentTypes).forEach((item) => {
    console.log({
      item: item,
      value: contentTypes[item].valueSelectionKey,
      label: contentTypes[item].displayName,
    });
  });

  return (
    <div style={{ margin: 20, fontSize: "0.9em" }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Content Type</FormLabel>
        <RadioGroup
          aria-label="content type"
          name="contentType"
          value={value}
          onChange={handleChange}
        >
          {Object.keys(contentTypes).map((item) => (
            <FormControlLabel
              style={{ fontSize: "0.9em" }}
              value={contentTypes[item].valueSelectionKey}
              key={contentTypes[item].valueSelectionKey}
              control={
                <Radio
                  style={{ width: "24px", height: "24px", fontSize: "0.9em" }}
                />
              }
              label={contentTypes[item].displayName}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

ContentCreationForm.propTypes = {
  contentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      valueSelectionKey: PropTypes.any,
      displayName: PropTypes.string,
    })
  ),
  selectedContentType: PropTypes.any,
  contentTypeSelectionCallback: PropTypes.func,
};
