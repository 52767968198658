module.exports = {
  component: 'vfb-mb-content-section-header-h1',
  elementPrefix: '',
  name: "MB: Customer Facing Content Section Header - sits above sections",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'content_section_header_title',
      value: 'EXAMPLE'
    }
  ]
}
