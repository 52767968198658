import {
  mrfManagementRequestedFetchMrfAccessPin,
  mrfManagementCompletedFetchMrfAccessPin,
} from "../../../../redux/reducer";

import { fetchMyMrfAccessPin } from '../../../../services/CurbCycleApi';

export const fetchMrfAccessPinThunk = () => dispatch => {
  dispatch(mrfManagementRequestedFetchMrfAccessPin());
  fetchMyMrfAccessPin()
    .then(result => {
      dispatch(mrfManagementCompletedFetchMrfAccessPin(result));
      alert(result.pin);
    })
    .catch(() => {
      dispatch(mrfManagementCompletedFetchMrfAccessPin({
        pin: 'error'
      }));
    });
};

export default fetchMrfAccessPinThunk;
