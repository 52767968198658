/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import People from "@material-ui/icons/People";
import VpnKey from "@material-ui/icons/VpnKey";

const dashboardRoutes = [
  // accounts
  {
    path: "/accounts",
    name: "Accounts",
    icon: People,
    layout: "/management",
    show: true,
  },
  {
    path: "/account-summary/:uuid",
    name: "Accounts",
    icon: People,
    layout: "/management",
    show: false,
  },
  {
    path: "/account-crm-integration-settings/:uuid",
    name: "Accounts",
    icon: People,
    layout: "/management",
    show: false,
  },
  {
    path: "/material-type/:uuid",
    name: "Manage Material Journeys Page",
    layout: "/management",
    show: false,
  },
  {
    path: "/enabled-lga-engagement-options/:uuid",
    name: "Enable LGA Engagement Page",
    layout: "/management",
    show: false,
  },
  {
    path: "/secret",
    name: "One Time Secret",
    icon: VpnKey,
    layout: "/management",
    show: true,
  },
];

export default dashboardRoutes;
