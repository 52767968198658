import {
  accountDataSyncedFromBackend,
  userSignedInSuccessfully,
  userIsCurbCycleManagementUser,
  userIsCurbCycleDevelopmentTeamUser,
  userIsCurbCycleSupportTeamUser,
  userIsMrfManagementUser,
  mrfAccountInfoRetrieved,
  mrfAccountActivitiesRetrieved,
  userIsMrfOperatorUser,
  userIsLgaUser,
  userIsRetailSponsorUser,
  userIsFinishedSignUp,
  userIsWaitlisted,
  selectActiveProfile,
} from "../../redux/reducer";

import {
  respondToOtpChallenge,
  storeAuthData,
  getAccount,
  getMyMrfAccountInfo,
  getMyMrfAccountActivities,
} from "../../services/CurbCycleApi";

export const verifyOtpThunk = (
  email,
  passcode,
  setIsLoading,
  setOtpRequested,
  setOtpVerified,
  setSubmitting
) => (dispatch) => {
  setIsLoading(true);
  setSubmitting(true);

  respondToOtpChallenge(email, passcode)
    .then((result) => {
      // console.log(result, 'src/thunks/Auth/verifyOtpThunk.js:31');
      storeAuthData(result);
      // console.log(result, 'src/thunks/Auth/verifyOtpThunk.js:34');
      getAccount().then((response) => {
        // console.log(response, 'src/thunks/Auth/verifyOtpThunk.js:38');
        if (result.aclGroups.includes("CurbCycle Management")) {
          // console.log(response, 'src/thunks/Auth/verifyOtpThunk.js:40');
          dispatch(accountDataSyncedFromBackend(response));
          dispatch(userIsCurbCycleManagementUser());
          dispatch(userSignedInSuccessfully());
        } else if (result.aclGroups.includes("CurbCycle Development")) {
          dispatch(accountDataSyncedFromBackend(response));
          dispatch(userIsCurbCycleDevelopmentTeamUser());
          dispatch(userSignedInSuccessfully());
        } else if (result.aclGroups.includes("CurbCycle Support")) {
          dispatch(accountDataSyncedFromBackend(response));
          dispatch(userIsCurbCycleSupportTeamUser());
          dispatch(userSignedInSuccessfully());
        } else if (result.aclGroups.includes("MRF Manager")) {
          dispatch(accountDataSyncedFromBackend(response));
          dispatch(userIsMrfManagementUser());
          dispatch(userSignedInSuccessfully());
        } else if (result.aclGroups.includes("MRF Operator")) {
          getMyMrfAccountInfo().then((mrfAccountInfo) => {
            getMyMrfAccountActivities().then(
              (mrfAccountActivities) => {
                dispatch(mrfAccountActivitiesRetrieved(mrfAccountActivities));
                dispatch(accountDataSyncedFromBackend(response));
                dispatch(userIsMrfOperatorUser());
                dispatch(mrfAccountInfoRetrieved(mrfAccountInfo));
                dispatch(userSignedInSuccessfully());
              }
            );
          });
        } else if (result.aclGroups.includes("Local Government Authority")) {
          dispatch(accountDataSyncedFromBackend(response));
          dispatch(userIsLgaUser());
          dispatch(userSignedInSuccessfully());
        } else if (result.aclGroups.includes("Retail Sponsor")) {
          dispatch(accountDataSyncedFromBackend(response));
          dispatch(userIsRetailSponsorUser());
          dispatch(userSignedInSuccessfully());
        } else {
          // console.log(response, 'src/thunks/Auth/verifyOtpThunk.js:78');
          // save the account information to the store
          dispatch(accountDataSyncedFromBackend(response));
          dispatch(userSignedInSuccessfully());
          // check for metadata we can use to manage their journey
          if (undefined !== response.curby_app_user_metadata) {
            if (
              undefined !== response.curby_app_user_metadata.isFinishedSignup &&
              response.curby_app_user_metadata.isFinishedSignup
            ) {
              dispatch(userIsFinishedSignUp());
              dispatch(selectActiveProfile(response.profiles[0]));
              if (
                undefined !== response.curby_app_user_metadata.isWaitlisted &&
                response.curby_app_user_metadata.isWaitlisted
              ) {
                dispatch(userIsWaitlisted());
              } else {
                // nothing
                // console.log('nothing', 'src/thunks/Auth/verifyOtpThunk.js:91');
              }
            } else if (
              undefined !== response.curby_app_user_metadata.isWaitlisted &&
              response.curby_app_user_metadata.isWaitlisted
            ) {
              dispatch(userIsWaitlisted());
            } else {
              // nothing
              // console.log('nothing', 'src/thunks/Auth/verifyOtpThunk.js:97');
            }
          } else {
            if (undefined !== response.profiles[0]) {
              dispatch(
                selectActiveProfile({
                  uuid: "testing",
                })
              );
            }
          }
        }
        // we got all the account data and stored auth to state
        // setIsLoading(true);
        // setSubmitting(false);
        setOtpVerified(true);
      });
    })
    .catch(() => {
      setIsLoading(false);
      setSubmitting(false);
      setOtpVerified(false);
    });
};

export default verifyOtpThunk;
