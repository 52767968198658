import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
// import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../layouts/components/Grid/GridItem.js";
import GridContainer from "../../layouts/components/Grid/GridContainer.js";
import CustomTabs from "../../layouts/components/CustomTabs/CustomTabs.js";
// import ActionableDataTable from "../../components/ActionableDataTable/ActionableDataTable";
import Card from "../../layouts/components/Card/Card.js";
import CardHeader from "../../layouts/components/Card/CardHeader.js";
import CardIcon from "../../layouts/components/Card/CardIcon";
import CardBody from "../../layouts/components/Card/CardBody.js";
// import CardFooter from "../../components/Card/CardFooter.js";
import Button from "../../layouts/components/CustomButtons/Button";
import { useParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
// import styles from "../../../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import resetMrfAccessPinThunk from "../../../../../thunks/MrfOperations/Management/Security/resetMrfAccessPinThunk";
import fetchMrfAccessPinThunk from "../../../../../thunks/MrfOperations/Management/Security/fetchMrfAccessPinThunk";

// const useStyles = makeStyles(styles);

function Security({ isResettingMrfAccessPin }) {
  // const classes = useStyles();
  const dispatch = useDispatch();
  // const history = useHistory();
  const { uuid } = useParams();

  const theCardHeaderStyle = {
    fontFamily: "nunito",
    fontSize: "2.0em",
    padding: 30,
  };

  return (
    <>
      <Fade in={true} timeout={3000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title="Security Settings:"
              headerColor="primary"
              tabs={[
                {
                  tabName: "Access Pin",
                  tabContent: (
                    <Card loading={isResettingMrfAccessPin}>
                      <CardHeader color="primary" stats icon>
                        <CardIcon color="primary">
                          Manage SMRF Access Pin
                        </CardIcon>
                      </CardHeader>
                      <CardBody>
                        <br />
                        <br />
                        <br />

                        <div>
                          <Button
                            style={{ margin: "0 auto", alignContent: "center" }}
                            color={"info"}
                            type="submit"
                            disabled={isResettingMrfAccessPin}
                            onClick={() => {
                              dispatch(fetchMrfAccessPinThunk(uuid));
                            }}
                          >
                            Show SMRF Access PIN
                          </Button>
                        </div>

                        <br />

                        <div>
                          <Button
                            style={{ margin: "0 auto", alignContent: "center" }}
                            color={"danger"}
                            type="submit"
                            disabled={isResettingMrfAccessPin}
                            onClick={() => {
                              dispatch(resetMrfAccessPinThunk());
                            }}
                          >
                            Reset SMRF Access PIN
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </Fade>
    </>
  );
}

function mapStateToProps(state) {
  return {
    isResettingMrfAccessPin:
      state.journeyFlows.CurbCycleManagement.mrfs.isResettingMrfAccessPin,
  };
}

Security.propTypes = {
  isResettingMrfAccessPin: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Security);
