import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import Fade from "@material-ui/core/Fade";
import paginationHelper from "../../../../../utilities/paginationHelper";
import pageChangeLGAManagersWithFiltersThunk from "../../../../../thunks/Management/LGAs/DataPagination/pageChangeLGAManagersWithFiltersThunk";
import useDebounce from "../../../../../hooks/useDebounce";
import CustomTabs from "../../../../../components/CustomTabs/CustomTabs.js";
import GridItem from "../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../components/Grid/GridContainer.js";
import ActionableDataTable from "../../../../../components/ActionableDataTable/ActionableDataTable";
import Card from "../../../../../components/Card/Card.js";
import CardHeader from "../../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../../components/Card/CardIcon.js";
import CardBody from "../../../../../components/Card/CardBody.js";
import Button from "../../../../../components/CustomButtons/Button";

function People({
  fetchingLGAManagers,
  lgaManagersTableData,
  lgaManagersTableRawData,
}) {
  const dispatch = useDispatch();

  const history = useHistory();

  const { uuid } = useParams();

  const [
    lgaManagerTableFilterOptions,
    setLGAManagerTableFilterOptions,
  ] = useState(undefined);
  const [
    lgaManagerTableFilterForStatus,
    setLGAManagerTableFilterForStatus,
  ] = useState(undefined);
  const debouncedLGAManagerTableFilterOptions = useDebounce(
    lgaManagerTableFilterOptions,
    400
  );
  const debouncedLGAManagerTableStatus = useDebounce(
    lgaManagerTableFilterForStatus,
    300
  );

  useEffect(() => {
    if (undefined === debouncedLGAManagerTableFilterOptions) {
      return;
    }
    dispatch(
      pageChangeLGAManagersWithFiltersThunk(
        uuid,
        paginationHelper.generateFilterQueryStringFromFilterOptions(
          debouncedLGAManagerTableFilterOptions
        )
      )
    );
  }, [debouncedLGAManagerTableFilterOptions]);

  useEffect(() => {
    const handler = setTimeout(
      paginationHelper.handleAddSimpleKeyValueFilterOption(
        "status",
        debouncedLGAManagerTableStatus,
        setLGAManagerTableFilterOptions,
        debouncedLGAManagerTableFilterOptions
      ),
      500
    );

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedLGAManagerTableStatus]);

  return (
    <Fade in={true} timeout={2000}>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title="People:"
              headerColor="success"
              tabs={[
                {
                  tabName: "Managers",
                  tabContent: (
                    <Fade in={true} timeout={3000}>
                      <Card loading={fetchingLGAManagers}>
                        <CardHeader color="success" stats icon>
                          <CardIcon color="success">
                            Manage LGA Managers
                          </CardIcon>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={9}>
                              <div className="data-table-filter-controls">
                                <form>
                                  <p>Status</p>
                                  <input
                                    type="radio"
                                    name="status"
                                    value="active"
                                    defaultChecked={
                                      debouncedLGAManagerTableStatus ===
                                      "active"
                                    }
                                    onClick={() => {
                                      setLGAManagerTableFilterForStatus(
                                        "active"
                                      );
                                      paginationHelper.handleAddSimpleKeyValueFilterOption(
                                        "status",
                                        "active",
                                        setLGAManagerTableFilterOptions,
                                        debouncedLGAManagerTableFilterOptions
                                      );
                                    }}
                                  />
                                  <label>Active</label>
                                  <input
                                    type="radio"
                                    name="status"
                                    value="inactive"
                                    defaultChecked={
                                      debouncedLGAManagerTableStatus ===
                                      "inactive"
                                    }
                                    onClick={() => {
                                      setLGAManagerTableFilterForStatus(
                                        "inactive"
                                      );
                                      paginationHelper.handleAddSimpleKeyValueFilterOption(
                                        "status",
                                        "inactive",
                                        setLGAManagerTableFilterOptions,
                                        debouncedLGAManagerTableFilterOptions
                                      );
                                    }}
                                  />
                                  <label>Inactive</label>
                                </form>
                              </div>
                            </GridItem>
                            {/*<GridItem xs={6} sm={6} md={6}>*/}

                            {/*</GridItem>*/}
                            <GridItem xs={1} sm={1} md={1}>
                              <Button
                                color={"warning"}
                                link={true}
                                onClick={() => {
                                  history.push(
                                    `/management/lga-management/lga/${uuid}/add-lga-manager`
                                  );
                                }}
                              >
                                <span className="material-icons">add</span> Add
                                LGA Manager
                              </Button>
                            </GridItem>
                          </GridContainer>
                          <ActionableDataTable
                            tableHeaderColor="warning"
                            tableHead={["Username", "Status"]}
                            tableData={lgaManagersTableData}
                            pagination={true}
                            paginationConfig={{
                              collectionData: lgaManagersTableRawData,
                              handlePageChange: (payload) => {
                                setLGAManagerTableFilterOptions(
                                  paginationHelper.addSimpleKeyValueFilterOption(
                                    debouncedLGAManagerTableFilterOptions,
                                    "page",
                                    payload.selected,
                                    true,
                                    false
                                  )
                                );
                              },
                            }}
                          />
                        </CardBody>
                      </Card>
                    </Fade>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    </Fade>
  );
}

function mapStateToProps(state) {
  return {
    fetchingLGAManagers:
      state.journeyFlows.CurbCycleManagement.lgas.lgaManagers.fetching,
    lgaManagersTableRawData:
      state.journeyFlows.CurbCycleManagement.lgas.lgaManagers.lgaManagers,
    lgaManagersTableData: state.journeyFlows.CurbCycleManagement.lgas.lgaManagers.lgaManagers.data.map(
      (item) => {
        return [
          {
            display: item.email,
          },
          {
            display: item.status,
          },
        ];
      }
    ),
  };
}

People.propTypes = {
  fetchingLGAManagers: PropTypes.bool.isRequired,
  lgaManagersTableData: PropTypes.any.isRequired,
  lgaManagersTableRawData: PropTypes.any.isRequired,
};

export default connect(mapStateToProps)(People);
