module.exports = {
  component: 'vfb-form-component-organisations-abn-lookup',
  elementPrefix: '',
  name: "ABN lookup - typeahead component designed with specific behaviour related to selecting a company",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'element_name_prefix',
      value: 'EXAMPLE'
    }
  ]
}
