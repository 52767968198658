module.exports = {
  component: 'vfb-mb-card-image',
  elementPrefix: '',
  name: "MB: Customer Facing Card Image",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'image_path',
      value: '/static/_images/cards/corp-card-gold.jpg'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'card_image'
    }
  ]
}
