import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomTabs from "../../../../../../components/CustomTabs/CustomTabs.js";
import GridItem from "../../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../../components/Grid/GridContainer.js";
import ActionableDataTable from "../../../../../../components/ActionableDataTable/ActionableDataTable";
import Card from "../../../../../../components/Card/Card.js";
import CardHeader from "../../../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../../../components/Card/CardIcon.js";
import CardBody from "../../../../../../components/Card/CardBody.js";
import Fade from "@material-ui/core/Fade";
import Button from "../../../../../../components/CustomButtons/Button";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import paginationHelper from "../../../../../../utilities/paginationHelper";
import pageChangeRetailSponsorManagersWithFiltersThunk from "../../../../../../thunks/Management/RetailSponsors/DataPagination/pageChangeRetailSponsorManagersWithFiltersThunk";
import useDebounce from "../../../../../../hooks/useDebounce";

function People({
  fetchingRetailSponsorManagers,
  retailSponsorManagersTableData,
  retailSponsorManagersTableRawData,
}) {
  const dispatch = useDispatch();

  const history = useHistory();

  const { uuid } = useParams();

  const [
    retailSponsorManagerTableFilterOptions,
    setRetailSponsorManagerTableFilterOptions,
  ] = useState(undefined);
  const [
    retailSponsorManagerTableFilterForStatus,
    setRetailSponsorManagerTableFilterForStatus,
  ] = useState(undefined);
  const debouncedRetailSponsorManagerTableFilterOptions = useDebounce(
    retailSponsorManagerTableFilterOptions,
    400
  );
  const debouncedRetailSponsorManagerTableStatus = useDebounce(
    retailSponsorManagerTableFilterForStatus,
    300
  );

  useEffect(() => {
    if (undefined === debouncedRetailSponsorManagerTableFilterOptions) {
      return;
    }
    dispatch(
      pageChangeRetailSponsorManagersWithFiltersThunk(
        uuid,
        paginationHelper.generateFilterQueryStringFromFilterOptions(
          debouncedRetailSponsorManagerTableFilterOptions
        )
      )
    );
  }, [debouncedRetailSponsorManagerTableFilterOptions, dispatch, uuid]);

  useEffect(() => {
    const handler = setTimeout(
      paginationHelper.handleAddSimpleKeyValueFilterOption(
        "status",
        debouncedRetailSponsorManagerTableStatus,
        setRetailSponsorManagerTableFilterOptions,
        debouncedRetailSponsorManagerTableFilterOptions
      ),
      500
    );

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedRetailSponsorManagerTableStatus]);

  return (
    <Fade in={true} timeout={2000}>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title="People:"
              headerColor="success"
              tabs={[
                {
                  tabName: "Managers",
                  tabContent: (
                    <Fade in={true} timeout={3000}>
                      <Card loading={fetchingRetailSponsorManagers}>
                        <CardHeader color="success" stats icon>
                          <CardIcon color="success">
                            Manage Retail Sponsor Managers
                          </CardIcon>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={9}>
                              <div className="data-table-filter-controls">
                                <form>
                                  <p>Status</p>
                                  <input
                                    type="radio"
                                    name="status"
                                    value="active"
                                    defaultChecked={
                                      debouncedRetailSponsorManagerTableStatus ===
                                      "active"
                                    }
                                    onClick={() => {
                                      setRetailSponsorManagerTableFilterForStatus(
                                        "active"
                                      );
                                      paginationHelper.handleAddSimpleKeyValueFilterOption(
                                        "status",
                                        "active",
                                        setRetailSponsorManagerTableFilterOptions,
                                        debouncedRetailSponsorManagerTableFilterOptions
                                      );
                                    }}
                                  />
                                  <label>Active</label>
                                  <input
                                    type="radio"
                                    name="status"
                                    value="inactive"
                                    defaultChecked={
                                      debouncedRetailSponsorManagerTableStatus ===
                                      "inactive"
                                    }
                                    onClick={() => {
                                      setRetailSponsorManagerTableFilterForStatus(
                                        "inactive"
                                      );
                                      paginationHelper.handleAddSimpleKeyValueFilterOption(
                                        "status",
                                        "inactive",
                                        setRetailSponsorManagerTableFilterOptions,
                                        debouncedRetailSponsorManagerTableFilterOptions
                                      );
                                    }}
                                  />
                                  <label>Inactive</label>
                                </form>
                              </div>
                            </GridItem>
                            {/*<GridItem xs={6} sm={6} md={6}>*/}

                            {/*</GridItem>*/}
                            <GridItem xs={1} sm={1} md={1}>
                              <Button
                                color={"warning"}
                                link={true}
                                onClick={() => {
                                  history.push(
                                    `/management/retail-sponsor-management/retail-sponsor/${uuid}/add-retail-sponsor-manager`
                                  );
                                }}
                              >
                                <span className="material-icons">add</span> Add
                                Retail Sponsor Manager
                              </Button>
                            </GridItem>
                          </GridContainer>
                          <ActionableDataTable
                            tableHeaderColor="warning"
                            tableHead={["Username", "Status"]}
                            tableData={retailSponsorManagersTableData}
                            pagination={true}
                            paginationConfig={{
                              collectionData: retailSponsorManagersTableRawData,
                              handlePageChange: (payload) => {
                                setRetailSponsorManagerTableFilterOptions(
                                  paginationHelper.addSimpleKeyValueFilterOption(
                                    debouncedRetailSponsorManagerTableFilterOptions,
                                    "page",
                                    payload.selected,
                                    true,
                                    false
                                  )
                                );
                              },
                            }}
                          />
                        </CardBody>
                      </Card>
                    </Fade>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    </Fade>
  );
}

function mapStateToProps(state) {
  return {
    fetchingRetailSponsorManagers:
      state.journeyFlows.CurbCycleManagement.retailSponsors
        .retailSponsorManagers.fetching,
    retailSponsorManagersTableRawData:
      state.journeyFlows.CurbCycleManagement.retailSponsors
        .retailSponsorManagers.retailSponsorManagers,
    retailSponsorManagersTableData: state.journeyFlows.CurbCycleManagement.retailSponsors.retailSponsorManagers.retailSponsorManagers.data.map(
      (item) => {
        return [
          {
            display: item.email,
          },
          {
            display: item.status,
          },
        ];
      }
    ),
  };
}

People.propTypes = {
  fetchingRetailSponsorManagers: PropTypes.bool.isRequired,
  retailSponsorManagersTableData: PropTypes.any.isRequired,
  retailSponsorManagersTableRawData: PropTypes.any.isRequired,
};

export default connect(mapStateToProps)(People);
