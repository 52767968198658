import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
// @material-ui/core
// import { makeStyles } from "@material-ui/core/styles";
// import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
// core components
import GridItem from "../../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../../components/Grid/GridContainer.js";
// custom components
import Card from "../../../layouts/components/Card/Card";
import CardIcon from "../../../layouts/components/Card/CardIcon.js";
import CardHeader from "../../../layouts/components/Card/CardHeader.js";
import CardBody from "../../../layouts/components/Card/CardBody.js";

export default function SiteDetails(props) {
  const { configData, setName, setSites } = props;
  const [inputName, setInputName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (configData && configData.sites) {
      setInputName(configData.displayName);
      setAddress(configData.sites[0].address);
      setPhoneNumber(configData.sites[0].phoneNumber);
    }
  }, [configData]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const debouncedHandleNameChange = useCallback(
    debounce((e) => handleNameChange(e), 500),
    [configData]
  );

  const handleNameInputChange = (e) => {
    e.persist();
    setInputName(e.target.value);
    debouncedHandleNameChange(e);
  };

  const handleAddressChange = (e) => {
    // TODO: refactor to use in case of multiple sites
    if (configData?.sites) {
      const updatedSite = { ...configData.sites[0], address: e.target.value };
      setSites([updatedSite]);
    }
  };

  const debouncedHandleAddressChange = useCallback(
    debounce((e) => handleAddressChange(e), 500),
    [configData]
  );

  const handleAddressInputChange = (e) => {
    e.persist();
    setAddress(e.target.value);
    debouncedHandleAddressChange(e);
  };

  const handlePhoneChange = (e) => {
    // TODO: refactor to use in case of multiple sites
    if (configData?.sites) {
      const updatedData = {
        ...configData.sites[0],
        phoneNumber: e.target.value,
      };
      setSites([updatedData]);
    }
  };

  const debouncedHandlePhoneChange = useCallback(
    debounce((e) => handlePhoneChange(e), 500),
    [configData]
  );

  const handlePhoneInputChange = (e) => {
    e.persist();
    setPhoneNumber(e.target.value);
    debouncedHandlePhoneChange(e);
  };

  return (
    <>
      <Card>
        <CardHeader stats icon>
          <CardIcon color="gray">SMRF Display Name</CardIcon>
        </CardHeader>
        <CardBody
          style={{
            marginBottom: 30,
          }}
        >
          <GridContainer
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            style={{ width: "100%" }}
          >
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                style={{
                  marginLeft: 0,
                  marginRight: 30,
                  marginTop: "15px",
                }}
                required
                label="Display Name"
                value={inputName}
                onChange={(e) => handleNameInputChange(e)}
                error={inputName === ""}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <Card style={{ marginTop: "55px" }}>
        <CardHeader stats icon>
          <CardIcon color="gray">Sites</CardIcon>
        </CardHeader>
        <GridContainer
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
          style={{ width: "100%" }}
        ></GridContainer>
        <CardBody
          style={{
            marginBottom: 30,
          }}
        >
          <GridContainer
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            style={{ width: "100%" }}
          >
            <GridItem xs={12} sm={12} md={12} style={{ display: "flex" }}>
              <TextField
                style={{
                  width: "45%",
                }}
                required
                multiline
                label="Address"
                value={address}
                onChange={(e) => handleAddressInputChange(e)}
                error={address === ""}
              />
              <TextField
                style={{
                  marginLeft: 30,
                  width: "45%",
                }}
                required
                label="Phone Number"
                value={phoneNumber}
                onChange={(e) => handlePhoneInputChange(e)}
                error={phoneNumber === ""}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
}

SiteDetails.propTypes = {
  configData: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    sites: PropTypes.arrayOf({
      address: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string.isRequired,
    })
  }),
  setName: PropTypes.func.isRequired,
  setSites: PropTypes.func.isRequired,
};
