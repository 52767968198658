import React from "react";
// custom components
import GridItem from "../../../../../layouts/components/Grid/GridItem.js";
import GridContainer from "../../../../../layouts/components/Grid/GridContainer.js";
import Card from "../../../../../layouts/components/Card/Card";
import CardHeader from "../../../../../layouts/components/Card/CardHeader.js";
import CardIcon from "../../../../../layouts/components/Card/CardIcon.js";
import CardBody from "../../../../../layouts/components/Card/CardBody.js";
// import Spinner from "../../../../../layouts/components/Spinner/Spinner";
// import ActionableDataTable from "../../../../../layouts/components/ActionableDataTable/ActionableDataTable";
// project tools
import moment from "moment";
import uuid from "utilities/uuid";
import {
  grayColor,
  successColor,
} from "assets/jss/material-dashboard-react-im";

export default function BatchDetailsModal(props) {
  const { modalData } = props;
  // const data = { data: [] };

  const imgStyle = {
    padding: 5,
    cursor: "pointer",
  };
  // =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

  const date = moment(modalData[5]).format("dddd, Do MMM YYYY");
  const time = moment(modalData[5]).format("hh:mm A");

  if (modalData !== undefined) {
    return (
      <>
        <Card>
          <div
            style={{
              position: "absolute",
              left: 200,
              top: 10,
              fontSize: "16px",
              fontWeight: 400,
            }}
          >
            Scanned out on {date} at {time}
          </div>
          <div
            style={{
              position: "absolute",
              right: 50,
              top: 10,
              fontSize: "16px",
              fontWeight: 400,
            }}
          >
            Vehicle Registration {modalData[3].toUpperCase()}
          </div>
          <CardHeader color="gray" stats icon>
            <CardIcon color="gray">{modalData[0]}</CardIcon>
          </CardHeader>
          <CardBody>
            <GridContainer
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <GridItem style={{ padding: 0 }}>
                <h3 style={{ margin: 0 }}>
                  Bale Count
                  <span style={{ color: successColor[3] }}>
                    {" "}
                    ({modalData[4]})
                  </span>
                </h3>
              </GridItem>
            </GridContainer>
            <GridContainer
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <GridItem xs={12} sm={12} md={12}>
                <p style={{ fontSize: "15px" }}>
                  click on an image to open in full size window
                </p>
              </GridItem>
              {!modalData[1] ? (
                <GridItem xs={12} sm={12} md={12}>
                  <i style={{ color: grayColor[3] }}>
                    No shipment images on file for this batch
                  </i>
                </GridItem>
              ) : (
                modalData[1].map((item) => {
                  return (
                    <GridItem key={uuid.uuid()}>
                      <img
                        key={uuid.uuid()}
                        style={imgStyle}
                        src={`https://curbcycle-assets.s3-ap-southeast-2.amazonaws.com/${item}`}
                        alt="Shipment"
                        height="300"
                        name="Shipment images"
                        onClick={() =>
                          window.open(
                            `https://curbcycle-assets.s3-ap-southeast-2.amazonaws.com/${item}`,
                            "_blank"
                          )
                        }
                      />
                    </GridItem>
                  );
                })
              )}

              {!modalData[2] ? (
                <i style={{ color: grayColor[3] }}>
                  No transport images on file for this batch
                </i>
              ) : (
                modalData[2].map((item) => {
                  return (
                    <GridItem key={uuid.uuid()}>
                      <img
                        key={uuid.uuid()}
                        style={imgStyle}
                        src={`https://curbcycle-assets.s3-ap-southeast-2.amazonaws.com/${item}`}
                        alt="Transport"
                        height="300"
                        name="Transport images"
                        onClick={() =>
                          window.open(
                            `https://curbcycle-assets.s3-ap-southeast-2.amazonaws.com/${item}`,
                            "_blank"
                          )
                        }
                      />
                    </GridItem>
                  );
                })
              )}
            </GridContainer>
            {/* <GridContainer>
              <ActionableDataTable
                tableHeaderColor="success"
                tableHead={["Images", "Bale Codes", "Weight"]}
                tableData={data.data}
              />
            </GridContainer> */}
          </CardBody>
        </Card>
      </>
    );
  }
}
