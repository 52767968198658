import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ContentTree } from "../lib";

import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CustomTreeItem from "./TreeView/CustomTreeItem";

const useStyles = makeStyles({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
});

function ContentTreeNavigationComponent({
  config,
  onContentTreeContextChangeCallback,
}) {
  const crudManager = ContentTree.contentCrudManager();
  const [contentStorageName, setContentStorageName] = useState(
    crudManager.contentService.getContentStorageName()
  );
  const [contentTree, setContentTree] = useState(
    crudManager.contentService.getContentTree()
  );
  const [contentTreeContext, setContentTreeContext] = useState(undefined);

  useEffect(() => {
    if (undefined === contentTree || undefined === contentStorageName) {
      console.log("setting defaults");
      localStorage.setItem("current-project-prefix", "temporary");
      setContentStorageName(crudManager.contentService.getContentStorageName());
      crudManager.contentService.updateContentTree(
        crudManager.contentService.getTemplateForNewContentTree()
      );
      setContentTree(crudManager.contentService.getContentTree());
    }
  }, []);

  useEffect(() => {
    console.log({ contentTreeContext: contentTreeContext });
    onContentTreeContextChangeCallback(contentTreeContext);
  }, [contentTreeContext]);

  const classes = useStyles();

  if (undefined === contentTree) {
    return null;
  }

  const setTheContentTreeContext = (nodeId, pathIdentifier) => {
    console.log(nodeId, pathIdentifier);
    setContentTreeContext(crudManager.getFromTreeByRelPath(pathIdentifier));
  };

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {contentTree["_pages_"].map((page, pageIndex) => (
        <CustomTreeItem
          pathIdentifier={`_pages_::${pageIndex}`}
          key={page.uuid}
          uuid={page.uuid}
          label={page.name}
          onSelectItemCallback={setTheContentTreeContext}
        >
          {page["content-sections"].map(
            (contentSection, contentSectionIndex) => (
              <CustomTreeItem
                pathIdentifier={`_pages_::${pageIndex}::content-sections::${contentSectionIndex}`}
                key={contentSection.uuid}
                uuid={contentSection.uuid}
                label={contentSection.name}
                onSelectItemCallback={setTheContentTreeContext}
              >
                {contentSection["containers"].map(
                  (container, containerIndex) => (
                    <CustomTreeItem
                      pathIdentifier={`_pages_::${pageIndex}::content-sections::${contentSectionIndex}::containers::${containerIndex}`}
                      key={container.uuid}
                      uuid={container.uuid}
                      label={container.name}
                      onSelectItemCallback={setTheContentTreeContext}
                    >
                      {container["content"].map((content, contentIndex) => (
                        <CustomTreeItem
                          pathIdentifier={`_pages_::${pageIndex}::content-sections::${contentSectionIndex}::containers::${containerIndex}::content::${contentIndex}`}
                          key={content.uuid}
                          uuid={content.uuid}
                          label={content.name}
                          onSelectItemCallback={setTheContentTreeContext}
                        />
                      ))}
                    </CustomTreeItem>
                  )
                )}
              </CustomTreeItem>
            )
          )}
        </CustomTreeItem>
      ))}
    </TreeView>
  );
}

ContentTreeNavigationComponent.propTypes = {
  config: PropTypes.any,
  contentTreeContext: PropTypes.any.isRequired,
  onContentTreeContextChangeCallback: PropTypes.func.isRequired,
};

export default ContentTreeNavigationComponent;
