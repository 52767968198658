import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import geoJsonData from "./geoJsonData";
import { K_SIZE } from "./jss/maps";

function IQRMap({ mapAccessorCallback, awesomeAnalyticsData }) {
  const defaultOptions = {
    mapId: "7ec42b1f011dc285",
    zoom: 0,
    center: {
      lat: -28.309356,
      lng: 140.309208,
    },
  };

  const createMapOptions = (maps) => {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL,
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
    };
  };

  const handleApiLoaded = (map, maps, awesomeAnalyticsData) => {
    mapAccessorCallback(map);

    const doAllTheInitalisationStuff = async (
      theMap,
      theMaps,
      theAnalyticsData
    ) => {
      let country = map.data.addGeoJson(geoJsonData.au.levels.country, {
        idPropertyName: "AUS_CODE16",
      });

      let features = map.data.addGeoJson(geoJsonData.au.levels.lga, {
        idPropertyName: "LGA_CODE20",
      });

      let postCodes = map.data.addGeoJson(geoJsonData.au.levels.post_codes, {
        idPropertyName: "POA_CODE16",
      });

      let states = map.data.addGeoJson(geoJsonData.au.levels.state, {
        idPropertyName: "STE_CODE16",
      });
      // let states ={};

      return {
        iqr_area: features,
        postal_codes: postCodes,
        states: states,
        country: country,
      };
    };

    const styleBasedOnMap = (theMap) => {
      theMap.data.setStyle((feature) => {
        console.log({
          feature: feature,
          zoom: theMap.getZoom(),
        });

        // handle style if the feature is a postcode
        if (Object.prototype.hasOwnProperty.call(feature.i, "POA_CODE16")) {
          console.log(feature.i["POA_CODE16"]);
          return {
            strokeWeight: 0.1,
            strokeColor: "#fff17e",
            zIndex: 4,
            fillColor: "hsl(" + 5 + "," + 69 + "%," + 54 + "%)",
            fillOpacity: 0.75,
            visible: true,
          };
        } else if (
          Object.prototype.hasOwnProperty.call(feature.i, "AUS_CODE16")
        ) {
          // handle style if the feature is a country
          // AUS_CODE16
          return {
            strokeWeight: 2,
            strokeColor: "#262629",
            zIndex: 1,
            fillColor: "#317647",
            fillOpacity: 0.2,
            visible: true,
          };
        } else if (
          Object.prototype.hasOwnProperty.call(feature.i, "STE_CODE16")
        ) {
          // handle style if the feature is a state

          if (feature.i["STE_CODE16"] === "1") {
            return {
              strokeWeight: 1.2,
              strokeColor: "#282828",
              zIndex: 2,
              fillColor: "#ecedff",
              fillOpacity: 0.5,
              visible: true,
            };
          } else {
            console.log({
              nope: feature.i["STE_CODE16"],
            });
          }

          return {
            strokeWeight: 1,
            strokeColor: "#2a2a2d",
            zIndex: 2,
            fillColor: "hsl(" + 151 + "," + 69 + "%," + 1 + "%, 0.4)",
            fillOpacity: 0.1,
            visible: true,
          };
        }

        // handle style if the feature is anything else
        return {
          strokeWeight: 0.1,
          strokeColor: "#fff17e",
          zIndex: 3,
          fillColor: "hsl(" + 151 + "," + 81 + "%," + 34 + "%)",
          fillOpacity: 0.75,
          visible: true,
        };
      });
    };

    const getMap = () => {
      return map;
    };

    doAllTheInitalisationStuff(map, maps, awesomeAnalyticsData).then(
      (features) => {
        map.data.revertStyle();
        setTimeout(() => {
          styleBasedOnMap(getMap());
        }, 200);

        // zoom actions
        getMap().data.addListener("zoom_changed", () => {
          console.log({
            mapZoom: map.getZoom(),
          });
          setTimeout(() => {
            styleBasedOnMap(getMap());
          }, 200);
        });

        // click actions
        map.data.addListener("click", (event) => {
          let feature = event.feature;

          // handle style if the feature is a postcode
          if (Object.prototype.hasOwnProperty.call(feature.i, "POA_CODE16")) {
            map.setZoom(10);
            map.setCenter({
              lat: -33.309356,
              lng: 151.309208,
            });
          } else if (
            Object.prototype.hasOwnProperty.call(feature.i, "AUS_CODE16")
          ) {
            // handle style if the feature is a country
            map.setZoom(0);
            map.setCenter({
              lat: -28.309356,
              lng: 140.309208,
            });
          } else if (
            Object.prototype.hasOwnProperty.call(feature.i, "STE_CODE16")
          ) {
            // handle style if the feature is a state

            if (feature.i["STE_CODE16"] === "1") {
              map.setZoom(10);
              map.setCenter({
                lat: -33.309356,
                lng: 151.309208,
              });
            } else {
              // nothing to do
            }
          } else {
            map.setZoom(3);
            map.setCenter({
              lat: -28.309356,
              lng: 140.309208,
            });
          }
        });
      }
    );

    // hover actions
    map.data.addListener("mouseover", (event) => {
      let feature = event.feature;

      // handle style if the feature is a postcode
      if (Object.prototype.hasOwnProperty.call(feature.i, "POA_CODE16")) {
        map.data.overrideStyle(feature, {
          strokeWeight: 2,
        });
      } else if (
        Object.prototype.hasOwnProperty.call(feature.i, "AUS_CODE16")
      ) {
        // handle style if the feature is a country
        map.data.overrideStyle(feature, {
          strokeWeight: 2,
        });
      } else if (
        Object.prototype.hasOwnProperty.call(feature.i, "STE_CODE16")
      ) {
        // handle style if the feature is a state

        if (feature.i["STE_CODE16"] === "1") {
          map.data.overrideStyle(feature, {
            strokeWeight: 4,
            strokeColor: "#585853",
          });
        } else {
          map.data.overrideStyle(feature, {
            strokeWeight: 1,
            strokeColor: "#16150b",
          });
        }
      } else {
        map.data.overrideStyle(feature, {
          strokeWeight: 0.1,
        });
      }
    });

    map.data.addListener("mouseout", (event) => {
      map.data.revertStyle();
    });
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "500px", width: "100%", borderRadius: "10px" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={defaultOptions.center}
        defaultZoom={defaultOptions.zoom}
        mapId={defaultOptions.mapId}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) =>
          handleApiLoaded(map, maps, awesomeAnalyticsData)
        }
        options={createMapOptions}
        hoverDistance={K_SIZE / 2}
      ></GoogleMapReact>
    </div>
  );
}

IQRMap.propTypes = {
  awesomeAnalyticsData: PropTypes.shape(PropTypes.any),
  mapAccessorCallback: PropTypes.func
}

export default IQRMap;
