// Journeys
export const JOURNEY_NEW_ACCOUNT_FLOW = "JOURNEY_NEW_ACCOUNT_FLOW";
export const JOURNEY_EXISTING_ACCOUNT_FLOW = "JOURNEY_EXISTING_ACCOUNT_FLOW";
export const JOURNEY_CURBCYCLE_SUPPORT_FLOW =
  "JOURNEY_CURBCYCLE_SUPPORT_FLOW";
export const JOURNEY_CURBCYCLE_MANAGEMENT_FLOW =
  "JOURNEY_CURBCYCLE_MANAGEMENT_FLOW";
export const JOURNEY_CURBCYCLE_DEVELOPMENT_FLOW =
  "JOURNEY_CURBCYCLE_DEVELOPMENT_FLOW";
export const JOURNEY_MRF_OPERATOR_FLOW = "JOURNEY_MRF_OPERATOR_FLOW";
export const JOURNEY_MRF_MANAGER_FLOW = "JOURNEY_MRF_MANAGER_FLOW";
export const JOURNEY_RETAIL_SPONSOR_FLOW = "JOURNEY_RETAIL_SPONSOR_FLOW";
export const JOURNEY_LGA_FLOW = "JOURNEY_LGA_FLOW";

// Journeys - Default experience states for the app
export const JOURNEY_DEFAULT_EXPERIENCE_STATES = {
  JOURNEY_NEW_ACCOUNT_FLOW: {
    experience: {
      first_use: true,
      journey: JOURNEY_NEW_ACCOUNT_FLOW,
      data: {
        actionContext: {},
        actionData: {},
      },
    },
  },
  JOURNEY_EXISTING_ACCOUNT_FLOW: {
    experience: {
      first_use: false,
      journey: JOURNEY_EXISTING_ACCOUNT_FLOW,
      data: {
        actionContext: {
          refreshAuth: true,
        },
        actionData: {},
      },
    },
  },
  JOURNEY_CURBCYCLE_SUPPORT_FLOW: {
    experience: {
      first_use: true,
      journey: JOURNEY_CURBCYCLE_SUPPORT_FLOW,
      data: {
        actionContext: {
          refreshAuth: true,
        },
        actionData: {},
      },
    },
  },
  JOURNEY_CURBCYCLE_DEVELOPMENT_FLOW: {
    experience: {
      first_use: true,
      journey: JOURNEY_CURBCYCLE_DEVELOPMENT_FLOW,
      data: {
        actionContext: {
          refreshAuth: true,
        },
        actionData: {},
      },
    },
  },
  JOURNEY_CURBCYCLE_MANAGEMENT_FLOW: {
    experience: {
      first_use: true,
      journey: JOURNEY_CURBCYCLE_MANAGEMENT_FLOW,
      data: {
        actionContext: {
          refreshAuth: true,
        },
        actionData: {},
      },
    },
  },
  JOURNEY_MRF_OPERATOR_FLOW: {
    experience: {
      first_use: true,
      journey: JOURNEY_MRF_OPERATOR_FLOW,
      data: {
        actionContext: {
          refreshAuth: true,
        },
        actionData: {},
      },
    },
  },
  JOURNEY_MRF_MANAGER_FLOW: {
    experience: {
      first_use: true,
      journey: JOURNEY_MRF_MANAGER_FLOW,
      data: {
        actionContext: {
          refreshAuth: true,
        },
        actionData: {},
      },
    },
  },
  JOURNEY_RETAIL_SPONSOR_FLOW: {
    experience: {
      first_use: true,
      journey: JOURNEY_RETAIL_SPONSOR_FLOW,
      data: {
        actionContext: {
          refreshAuth: true,
        },
        actionData: {},
      },
    },
  },
  JOURNEY_LGA_FLOW: {
    experience: {
      first_use: true,
      journey: JOURNEY_LGA_FLOW,
      data: {
        actionContext: {
          refreshAuth: true,
        },
        actionData: {},
      },
    },
  },
};

/* Reset initial state */
export const ACTION_RESET_INITIAL_STATE = "ACTION_RESET_INITIAL_STATE";
export const resetInitialState = () => ({
  type: ACTION_RESET_INITIAL_STATE,
  payload: {},
});

/* Set app state as initialised */
export const ACTION_SET_APP_INITIALISED = "ACTION_SET_APP_INITIALISED";
export const setAppInitialised = () => ({
  type: ACTION_SET_APP_INITIALISED,
  payload: {},
});

/* Retail Sponsor */
export const ACTION_RETAIL_SPONSOR_REQUESTED_ANALYTICS =
  "ACTION_RETAIL_SPONSOR_REQUESTED_ANALYTICS";
export const retailSponsorRequestedAnalytics = () => ({
  type: ACTION_RETAIL_SPONSOR_REQUESTED_ANALYTICS,
  payload: {},
});
export const ACTION_RETAIL_SPONSOR_RECEIVED_ANALYTICS =
  "ACTION_RETAIL_SPONSOR_RECEIVED_ANALYTICS";
export const retailSponsorReceivedAnalytics = (payload) => ({
  type: ACTION_RETAIL_SPONSOR_RECEIVED_ANALYTICS,
  payload: payload,
});

/* LGA */
export const ACTION_LGA_REQUESTED_ANALYTICS = "ACTION_LGA_REQUESTED_ANALYTICS";
export const lgaRequestedAnalytics = () => ({
  type: ACTION_LGA_REQUESTED_ANALYTICS,
  payload: {},
});
export const ACTION_LGA_RECEIVED_ANALYTICS = "ACTION_LGA_RECEIVED_ANALYTICS";
export const lgaReceivedAnalytics = (payload) => ({
  type: ACTION_LGA_RECEIVED_ANALYTICS,
  payload: payload,
});

/* Management */
export const ACTION_MANAGEMENT_REQUESTED_ACCOUNTS =
  "ACTION_MANAGEMENT_REQUESTED_ACCOUNTS";
export const managementRequestedAccounts = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_ACCOUNTS,
  payload: {},
});
export const ACTION_MANAGEMENT_RECEIVED_ACCOUNTS =
  "ACTION_MANAGEMENT_RECEIVED_ACCOUNTS";
export const managementReceivedAccounts = (payload) => ({
  type: ACTION_MANAGEMENT_RECEIVED_ACCOUNTS,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_ANALYTICS =
  "ACTION_MANAGEMENT_REQUESTED_ANALYTICS";
export const managementRequestedAnalytics = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_ANALYTICS,
  payload: {},
});
export const ACTION_MANAGEMENT_RECEIVED_ANALYTICS =
  "ACTION_MANAGEMENT_RECEIVED_ANALYTICS";
export const managementReceivedAnalytics = (payload) => ({
  type: ACTION_MANAGEMENT_RECEIVED_ANALYTICS,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_CURBY_CODES =
  "ACTION_MANAGEMENT_REQUESTED_CURBY_CODES";
export const managementRequestedCurbyCodes = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_CURBY_CODES,
  payload: {},
});
export const ACTION_MANAGEMENT_RECEIVED_CURBY_CODES =
  "ACTION_MANAGEMENT_RECEIVED_CURBY_CODES";
export const managementReceivedCurbyCodes = (payload) => ({
  type: ACTION_MANAGEMENT_RECEIVED_CURBY_CODES,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_MRFS =
  "ACTION_MANAGEMENT_REQUESTED_MRFS";
export const managementRequestedMrfs = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_MRFS,
  payload: {},
});
export const ACTION_MANAGEMENT_RECEIVED_MRFS =
  "ACTION_MANAGEMENT_RECEIVED_MRFS";
export const managementReceivedMrfs = (payload) => ({
  type: ACTION_MANAGEMENT_RECEIVED_MRFS,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_FIRST_VALID_MRF_MANAGER =
  "ACTION_MANAGEMENT_REQUESTED_FIRST_VALID_MRF_MANAGER";
export const managementRequestedFirstValidMrfManager = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_FIRST_VALID_MRF_MANAGER,
  payload: {},
});
export const ACTION_MANAGEMENT_RECEIVED_FIRST_VALID_MRF_MANAGER =
  "ACTION_MANAGEMENT_RECEIVED_FIRST_VALID_MRF_MANAGER";
export const managementReceivedFirstValidMrfManager = (payload) => ({
  type: ACTION_MANAGEMENT_RECEIVED_FIRST_VALID_MRF_MANAGER,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_MRF_MANAGERS =
  "ACTION_MANAGEMENT_REQUESTED_MRF_MANAGERS";
export const managementRequestedMrfManagers = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_MRF_MANAGERS,
  payload: {},
});
export const ACTION_MANAGEMENT_RECEIVED_MRF_MANAGERS =
  "ACTION_MANAGEMENT_RECEIVED_MRF_MANAGERS";
export const managementReceivedMrfManagers = (payload) => ({
  type: ACTION_MANAGEMENT_RECEIVED_MRF_MANAGERS,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_MRF_OPERATORS =
  "ACTION_MANAGEMENT_REQUESTED_MRF_OPERATORS";
export const managementRequestedMrfOperators = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_MRF_OPERATORS,
  payload: {},
});
export const ACTION_MANAGEMENT_RECEIVED_MRF_OPERATORS =
  "ACTION_MANAGEMENT_RECEIVED_MRF_OPERATORS";
export const managementReceivedMrfOperators = (payload) => ({
  type: ACTION_MANAGEMENT_RECEIVED_MRF_OPERATORS,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_ADD_MRF =
  "ACTION_MANAGEMENT_REQUESTED_ADD_MRF";
export const managementRequestedAddMrf = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_ADD_MRF,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_ADD_MRF =
  "ACTION_MANAGEMENT_COMPLETED_ADD_MRF";
export const managementCompletedAddMrf = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_ADD_MRF,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_EDIT_MRF =
  "ACTION_MANAGEMENT_REQUESTED_EDIT_MRF";
export const managementRequestedEditMrf = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_EDIT_MRF,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_EDIT_MRF =
  "ACTION_MANAGEMENT_COMPLETED_EDIT_MRF";
export const managementCompletedEditMrf = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_EDIT_MRF,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_ADD_MRF_MANAGER =
  "ACTION_MANAGEMENT_REQUESTED_ADD_MRF_MANAGER";
export const managementRequestedAddMrfManager = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_ADD_MRF_MANAGER,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_ADD_MRF_MANAGER =
  "ACTION_MANAGEMENT_COMPLETED_ADD_MRF_MANAGER";
export const managementCompletedAddMrfManager = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_ADD_MRF_MANAGER,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_EDIT_MRF_MANAGER =
  "ACTION_MANAGEMENT_REQUESTED_EDIT_MRF_MANAGER";
export const managementRequestedEditMrfManager = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_EDIT_MRF_MANAGER,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_EDIT_MRF_MANAGER =
  "ACTION_MANAGEMENT_COMPLETED_EDIT_MRF_MANAGER";
export const managementCompletedEditMrfManager = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_EDIT_MRF_MANAGER,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_ADD_MRF_OPERATOR =
  "ACTION_MANAGEMENT_REQUESTED_ADD_MRF_OPERATOR";
export const managementRequestedAddMrfOperator = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_ADD_MRF_OPERATOR,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_ADD_MRF_OPERATOR =
  "ACTION_MANAGEMENT_COMPLETED_ADD_MRF_OPERATOR";
export const managementCompletedAddMrfOperator = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_ADD_MRF_OPERATOR,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_EDIT_MRF_OPERATOR =
  "ACTION_MANAGEMENT_REQUESTED_EDIT_MRF_OPERATOR";
export const managementRequestedEditMrfOperator = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_EDIT_MRF_OPERATOR,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_EDIT_MRF_OPERATOR =
  "ACTION_MANAGEMENT_COMPLETED_EDIT_MRF_OPERATOR";
export const managementCompletedEditMrfOperator = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_EDIT_MRF_OPERATOR,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_RESET_MRF_ACCESS_PIN =
  "ACTION_MANAGEMENT_REQUESTED_RESET_MRF_ACCESS_PIN";
export const managementRequestedResetMrfAccessPin = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_RESET_MRF_ACCESS_PIN,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_RESET_MRF_ACCESS_PIN =
  "ACTION_MANAGEMENT_COMPLETED_RESET_MRF_ACCESS_PIN";
export const managementCompletedResetMrfAccessPin = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_RESET_MRF_ACCESS_PIN,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_FETCH_MRF_ACCESS_PIN =
  "ACTION_MANAGEMENT_REQUESTED_FETCH_MRF_ACCESS_PIN";
export const managementRequestedFetchMrfAccessPin = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_FETCH_MRF_ACCESS_PIN,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_FETCH_MRF_ACCESS_PIN =
  "ACTION_MANAGEMENT_COMPLETED_FETCH_MRF_ACCESS_PIN";
export const managementCompletedFetchMrfAccessPin = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_FETCH_MRF_ACCESS_PIN,
  payload: payload,
});

// retail sponsors admin
export const ACTION_MANAGEMENT_REQUESTED_RETAIL_SPONSORS =
  "ACTION_MANAGEMENT_REQUESTED_RETAIL_SPONSORS";
export const managementRequestedRetailSponsors = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_RETAIL_SPONSORS,
  payload: {},
});
export const ACTION_MANAGEMENT_RECEIVED_RETAIL_SPONSORS =
  "ACTION_MANAGEMENT_RECEIVED_RETAIL_SPONSORS";
export const managementReceivedRetailSponsors = (payload) => ({
  type: ACTION_MANAGEMENT_RECEIVED_RETAIL_SPONSORS,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_RETAIL_SPONSOR_MANAGERS =
  "ACTION_MANAGEMENT_REQUESTED_RETAIL_SPONSOR_MANAGERS";
export const managementRequestedRetailSponsorManagers = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_RETAIL_SPONSOR_MANAGERS,
  payload: {},
});
export const ACTION_MANAGEMENT_RECEIVED_RETAIL_SPONSOR_MANAGERS =
  "ACTION_MANAGEMENT_RECEIVED_RETAIL_SPONSOR_MANAGERS";
export const managementReceivedRetailSponsorManagers = (payload) => ({
  type: ACTION_MANAGEMENT_RECEIVED_RETAIL_SPONSOR_MANAGERS,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_ADD_RETAIL_SPONSOR =
  "ACTION_MANAGEMENT_REQUESTED_ADD_RETAIL_SPONSOR";
export const managementRequestedAddRetailSponsor = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_ADD_RETAIL_SPONSOR,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_ADD_RETAIL_SPONSOR =
  "ACTION_MANAGEMENT_COMPLETED_ADD_RETAIL_SPONSOR";
export const managementCompletedAddRetailSponsor = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_ADD_RETAIL_SPONSOR,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_EDIT_RETAIL_SPONSOR =
  "ACTION_MANAGEMENT_REQUESTED_EDIT_RETAIL_SPONSOR";
export const managementRequestedEditRetailSponsor = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_EDIT_RETAIL_SPONSOR,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_EDIT_RETAIL_SPONSOR =
  "ACTION_MANAGEMENT_COMPLETED_EDIT_RETAIL_SPONSOR";
export const managementCompletedEditRetailSponsor = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_EDIT_RETAIL_SPONSOR,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_ADD_RETAIL_SPONSOR_MANAGER =
  "ACTION_MANAGEMENT_REQUESTED_ADD_RETAIL_SPONSOR_MANAGER";
export const managementRequestedAddRetailSponsorManager = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_ADD_RETAIL_SPONSOR_MANAGER,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_ADD_RETAIL_SPONSOR_MANAGER =
  "ACTION_MANAGEMENT_COMPLETED_ADD_RETAIL_SPONSOR_MANAGER";
export const managementCompletedAddRetailSponsorManager = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_ADD_RETAIL_SPONSOR_MANAGER,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_EDIT_RETAIL_SPONSOR_MANAGER =
  "ACTION_MANAGEMENT_REQUESTED_EDIT_RETAIL_SPONSOR_MANAGER";
export const managementRequestedEditRetailSponsorManager = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_EDIT_RETAIL_SPONSOR_MANAGER,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_EDIT_RETAIL_SPONSOR_MANAGER =
  "ACTION_MANAGEMENT_COMPLETED_EDIT_RETAIL_SPONSOR_MANAGER";
export const managementCompletedEditRetailSponsorManager = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_EDIT_RETAIL_SPONSOR_MANAGER,
  payload: payload,
});

// lgas admin
export const ACTION_MANAGEMENT_REQUESTED_LGAS =
  "ACTION_MANAGEMENT_REQUESTED_LGAS";
export const managementRequestedLGAs = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_LGAS,
  payload: {},
});
export const ACTION_MANAGEMENT_RECEIVED_LGAS =
  "ACTION_MANAGEMENT_RECEIVED_LGAS";
export const managementReceivedLGAs = (payload) => ({
  type: ACTION_MANAGEMENT_RECEIVED_LGAS,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_LGA_MANAGERS =
  "ACTION_MANAGEMENT_REQUESTED_LGA_MANAGERS";
export const managementRequestedLGAManagers = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_LGA_MANAGERS,
  payload: {},
});
export const ACTION_MANAGEMENT_RECEIVED_LGA_MANAGERS =
  "ACTION_MANAGEMENT_RECEIVED_LGA_MANAGERS";
export const managementReceivedLGAManagers = (payload) => ({
  type: ACTION_MANAGEMENT_RECEIVED_LGA_MANAGERS,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_ADD_LGA =
  "ACTION_MANAGEMENT_REQUESTED_ADD_LGA";
export const managementRequestedAddLGA = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_ADD_LGA,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_ADD_LGA =
  "ACTION_MANAGEMENT_COMPLETED_ADD_LGA";
export const managementCompletedAddLGA = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_ADD_LGA,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_EDIT_LGA =
  "ACTION_MANAGEMENT_REQUESTED_EDIT_LGA";
export const managementRequestedEditLGA = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_EDIT_LGA,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_EDIT_LGA =
  "ACTION_MANAGEMENT_COMPLETED_EDIT_LGA";
export const managementCompletedEditLGA = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_EDIT_LGA,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_ADD_LGA_MANAGER =
  "ACTION_MANAGEMENT_REQUESTED_ADD_LGA_MANAGER";
export const managementRequestedAddLGAManager = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_ADD_LGA_MANAGER,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_ADD_LGA_MANAGER =
  "ACTION_MANAGEMENT_COMPLETED_ADD_LGA_MANAGER";
export const managementCompletedAddLGAManager = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_ADD_LGA_MANAGER,
  payload: payload,
});
export const ACTION_MANAGEMENT_REQUESTED_EDIT_LGA_MANAGER =
  "ACTION_MANAGEMENT_REQUESTED_EDIT_LGA_MANAGER";
export const managementRequestedEditLGAManager = () => ({
  type: ACTION_MANAGEMENT_REQUESTED_EDIT_LGA_MANAGER,
  payload: {},
});
export const ACTION_MANAGEMENT_COMPLETED_EDIT_LGA_MANAGER =
  "ACTION_MANAGEMENT_COMPLETED_EDIT_LGA_MANAGER";
export const managementCompletedEditLGAManager = (payload) => ({
  type: ACTION_MANAGEMENT_COMPLETED_EDIT_LGA_MANAGER,
  payload: payload,
});

/* MRF Management */
export const ACTION_MRF_MANAGEMENT_REQUESTED_ANALYTICS =
  "ACTION_MRF_MANAGEMENT_REQUESTED_ANALYTICS";
export const mrfManagementRequestedAnalytics = () => ({
  type: ACTION_MRF_MANAGEMENT_REQUESTED_ANALYTICS,
  payload: {},
});
export const ACTION_MRF_MANAGEMENT_RECEIVED_ANALYTICS =
  "ACTION_MRF_MANAGEMENT_RECEIVED_ANALYTICS";
export const mrfManagementReceivedAnalytics = (payload) => ({
  type: ACTION_MRF_MANAGEMENT_RECEIVED_ANALYTICS,
  payload: payload,
});
export const ACTION_MRF_MANAGEMENT_REQUESTED_CURBY_CODES =
  "ACTION_MRF_MANAGEMENT_REQUESTED_CURBY_CODES";
export const mrfManagementRequestedCurbyCodes = () => ({
  type: ACTION_MRF_MANAGEMENT_REQUESTED_CURBY_CODES,
  payload: {},
});
export const ACTION_MRF_MANAGEMENT_RECEIVED_CURBY_CODES =
  "ACTION_MRF_MANAGEMENT_RECEIVED_CURBY_CODES";
export const mrfManagementReceivedCurbyCodes = (payload) => ({
  type: ACTION_MRF_MANAGEMENT_RECEIVED_CURBY_CODES,
  payload: payload,
});
export const ACTION_MRF_MANAGEMENT_REQUESTED_MRFS =
  "ACTION_MRF_MANAGEMENT_REQUESTED_MRFS";
export const mrfManagementRequestedMrfs = () => ({
  type: ACTION_MRF_MANAGEMENT_REQUESTED_MRFS,
  payload: {},
});
export const ACTION_MRF_MANAGEMENT_RECEIVED_MRFS =
  "ACTION_MRF_MANAGEMENT_RECEIVED_MRFS";
export const mrfManagementReceivedMrfs = (payload) => ({
  type: ACTION_MRF_MANAGEMENT_RECEIVED_MRFS,
  payload: payload,
});
export const ACTION_MRF_MANAGEMENT_REQUESTED_FIRST_VALID_MRF_MANAGER =
  "ACTION_MRF_MANAGEMENT_REQUESTED_FIRST_VALID_MRF_MANAGER";
export const mrfManagementRequestedFirstValidMrfManager = () => ({
  type: ACTION_MRF_MANAGEMENT_REQUESTED_FIRST_VALID_MRF_MANAGER,
  payload: {},
});
export const ACTION_MRF_MANAGEMENT_RECEIVED_FIRST_VALID_MRF_MANAGER =
  "ACTION_MRF_MANAGEMENT_RECEIVED_FIRST_VALID_MRF_MANAGER";
export const mrfManagementReceivedFirstValidMrfManager = (payload) => ({
  type: ACTION_MRF_MANAGEMENT_RECEIVED_FIRST_VALID_MRF_MANAGER,
  payload: payload,
});
export const ACTION_MRF_MANAGEMENT_REQUESTED_MRF_MANAGERS =
  "ACTION_MRF_MANAGEMENT_REQUESTED_MRF_MANAGERS";
export const mrfManagementRequestedMrfManagers = () => ({
  type: ACTION_MRF_MANAGEMENT_REQUESTED_MRF_MANAGERS,
  payload: {},
});
export const ACTION_MRF_MANAGEMENT_RECEIVED_MRF_MANAGERS =
  "ACTION_MRF_MANAGEMENT_RECEIVED_MRF_MANAGERS";
export const mrfManagementReceivedMrfManagers = (payload) => ({
  type: ACTION_MRF_MANAGEMENT_RECEIVED_MRF_MANAGERS,
  payload: payload,
});
export const ACTION_MRF_MANAGEMENT_REQUESTED_MRF_OPERATORS =
  "ACTION_MRF_MANAGEMENT_REQUESTED_MRF_OPERATORS";
export const mrfManagementRequestedMrfOperators = () => ({
  type: ACTION_MRF_MANAGEMENT_REQUESTED_MRF_OPERATORS,
  payload: {},
});
export const ACTION_MRF_MANAGEMENT_RECEIVED_MRF_OPERATORS =
  "ACTION_MRF_MANAGEMENT_RECEIVED_MRF_OPERATORS";
export const mrfManagementReceivedMrfOperators = (payload) => ({
  type: ACTION_MRF_MANAGEMENT_RECEIVED_MRF_OPERATORS,
  payload: payload,
});
export const ACTION_MRF_MANAGEMENT_REQUESTED_ADD_MRF =
  "ACTION_MRF_MANAGEMENT_REQUESTED_ADD_MRF";
export const mrfManagementRequestedAddMrf = () => ({
  type: ACTION_MRF_MANAGEMENT_REQUESTED_ADD_MRF,
  payload: {},
});
export const ACTION_MRF_MANAGEMENT_COMPLETED_ADD_MRF =
  "ACTION_MRF_MANAGEMENT_COMPLETED_ADD_MRF";
export const mrfManagementCompletedAddMrf = (payload) => ({
  type: ACTION_MRF_MANAGEMENT_COMPLETED_ADD_MRF,
  payload: payload,
});
export const ACTION_MRF_MANAGEMENT_REQUESTED_EDIT_MRF =
  "ACTION_MRF_MANAGEMENT_REQUESTED_EDIT_MRF";
export const mrfManagementRequestedEditMrf = () => ({
  type: ACTION_MRF_MANAGEMENT_REQUESTED_EDIT_MRF,
  payload: {},
});
export const ACTION_MRF_MANAGEMENT_COMPLETED_EDIT_MRF =
  "ACTION_MRF_MANAGEMENT_COMPLETED_EDIT_MRF";
export const mrfManagementCompletedEditMrf = (payload) => ({
  type: ACTION_MRF_MANAGEMENT_COMPLETED_EDIT_MRF,
  payload: payload,
});
export const ACTION_MRF_MANAGEMENT_REQUESTED_ADD_MRF_MANAGER =
  "ACTION_MRF_MANAGEMENT_REQUESTED_ADD_MRF_MANAGER";
export const mrfManagementRequestedAddMrfManager = () => ({
  type: ACTION_MRF_MANAGEMENT_REQUESTED_ADD_MRF_MANAGER,
  payload: {},
});
export const ACTION_MRF_MANAGEMENT_COMPLETED_ADD_MRF_MANAGER =
  "ACTION_MRF_MANAGEMENT_COMPLETED_ADD_MRF_MANAGER";
export const mrfManagementCompletedAddMrfManager = (payload) => ({
  type: ACTION_MRF_MANAGEMENT_COMPLETED_ADD_MRF_MANAGER,
  payload: payload,
});
export const ACTION_MRF_MANAGEMENT_REQUESTED_EDIT_MRF_MANAGER =
  "ACTION_MRF_MANAGEMENT_REQUESTED_EDIT_MRF_MANAGER";
export const mrfManagementRequestedEditMrfManager = () => ({
  type: ACTION_MRF_MANAGEMENT_REQUESTED_EDIT_MRF_MANAGER,
  payload: {},
});
export const ACTION_MRF_MANAGEMENT_COMPLETED_EDIT_MRF_MANAGER =
  "ACTION_MRF_MANAGEMENT_COMPLETED_EDIT_MRF_MANAGER";
export const mrfManagementCompletedEditMrfManager = (payload) => ({
  type: ACTION_MRF_MANAGEMENT_COMPLETED_EDIT_MRF_MANAGER,
  payload: payload,
});
export const ACTION_MRF_MANAGEMENT_REQUESTED_ADD_MRF_OPERATOR =
  "ACTION_MRF_MANAGEMENT_REQUESTED_ADD_MRF_OPERATOR";
export const mrfManagementRequestedAddMrfOperator = () => ({
  type: ACTION_MRF_MANAGEMENT_REQUESTED_ADD_MRF_OPERATOR,
  payload: {},
});
export const ACTION_MRF_MANAGEMENT_COMPLETED_ADD_MRF_OPERATOR =
  "ACTION_MRF_MANAGEMENT_COMPLETED_ADD_MRF_OPERATOR";
export const mrfManagementCompletedAddMrfOperator = (payload) => ({
  type: ACTION_MRF_MANAGEMENT_COMPLETED_ADD_MRF_OPERATOR,
  payload: payload,
});
export const ACTION_MRF_MANAGEMENT_REQUESTED_EDIT_MRF_OPERATOR =
  "ACTION_MRF_MANAGEMENT_REQUESTED_EDIT_MRF_OPERATOR";
export const mrfManagementRequestedEditMrfOperator = () => ({
  type: ACTION_MRF_MANAGEMENT_REQUESTED_EDIT_MRF_OPERATOR,
  payload: {},
});
export const ACTION_MRF_MANAGEMENT_COMPLETED_EDIT_MRF_OPERATOR =
  "ACTION_MRF_MANAGEMENT_COMPLETED_EDIT_MRF_OPERATOR";
export const mrfManagementCompletedEditMrfOperator = (payload) => ({
  type: ACTION_MRF_MANAGEMENT_COMPLETED_EDIT_MRF_OPERATOR,
  payload: payload,
});
export const ACTION_MRF_MANAGEMENT_REQUESTED_RESET_MRF_ACCESS_PIN =
  "ACTION_MRF_MANAGEMENT_REQUESTED_RESET_MRF_ACCESS_PIN";
export const mrfManagementRequestedResetMrfAccessPin = () => ({
  type: ACTION_MRF_MANAGEMENT_REQUESTED_RESET_MRF_ACCESS_PIN,
  payload: {},
});
export const ACTION_MRF_MANAGEMENT_COMPLETED_RESET_MRF_ACCESS_PIN =
  "ACTION_MRF_MANAGEMENT_COMPLETED_RESET_MRF_ACCESS_PIN";
export const mrfManagementCompletedResetMrfAccessPin = (payload) => ({
  type: ACTION_MRF_MANAGEMENT_COMPLETED_RESET_MRF_ACCESS_PIN,
  payload: payload,
});
export const ACTION_MRF_MANAGEMENT_REQUESTED_FETCH_MRF_ACCESS_PIN =
  "ACTION_MRF_MANAGEMENT_REQUESTED_FETCH_MRF_ACCESS_PIN";
export const mrfManagementRequestedFetchMrfAccessPin = () => ({
  type: ACTION_MRF_MANAGEMENT_REQUESTED_FETCH_MRF_ACCESS_PIN,
  payload: {},
});
export const ACTION_MRF_MANAGEMENT_COMPLETED_FETCH_MRF_ACCESS_PIN =
  "ACTION_MRF_MANAGEMENT_COMPLETED_FETCH_MRF_ACCESS_PIN";
export const mrfManagementCompletedFetchMrfAccessPin = (payload) => ({
  type: ACTION_MRF_MANAGEMENT_COMPLETED_FETCH_MRF_ACCESS_PIN,
  payload: payload,
});

/* Journey Actions: New account Flow */
export const ACTION_INITIALISE_EXPERIENCE_BASED_ON_STORED_APP_STATE =
  "ACTION_INITIALISE_EXPERIENCE_BASED_ON_STORED_APP_STATE";
export const initialiseExperienceBasedOnStoredAppState = (authData) => ({
  type: ACTION_INITIALISE_EXPERIENCE_BASED_ON_STORED_APP_STATE,
  payload: authData,
});

// Register new account (must have email address plus first and last name)
export const ACTION_ACCOUNT_CREATION_CREATED_NEW_ACCOUNT =
  "ACTION_ACCOUNT_CREATION_CREATED_NEW_ACCOUNT";
export const createdNewAccount = (payload) => ({
  type: ACTION_ACCOUNT_CREATION_CREATED_NEW_ACCOUNT,
  payload: payload,
});

// Store temporary email address for convenience of the user
export const ACTION_STORE_TEMPORARY_EMAIL_ADDRESS =
  "ACTION_STORE_TEMPORARY_EMAIL_ADDRESS";
export const storeTemporaryEmailAddress = (payload) => ({
  type: ACTION_STORE_TEMPORARY_EMAIL_ADDRESS,
  payload: payload,
});

export const ACTION_ACCOUNT_CREATION_SET_VERIFICATION_URL_FOR_OTP =
  "ACTION_ACCOUNT_CREATION_SET_VERIFICATION_URL_FOR_OTP";
export const accountCreationFlowSetVerificationUrlForOTP = (payload) => ({
  type: ACTION_ACCOUNT_CREATION_SET_VERIFICATION_URL_FOR_OTP,
  payload: payload,
});

export const ACTION_ACCOUNT_CREATION_VERIFIED_OTP_FOR_TOKENS =
  "ACTION_ACCOUNT_CREATION_VERIFIED_OTP_FOR_TOKENS";
export const accountCreationFlowVerifiedOTPForToken = (payload) => ({
  type: ACTION_ACCOUNT_CREATION_VERIFIED_OTP_FOR_TOKENS,
  payload: payload,
});

// Added profiles
export const ACTION_ACCOUNT_CREATION_ADDED_PROFILES =
  "ACTION_ACCOUNT_CREATION_ADDED_PROFILES";
export const accountCreationFlowAddedProfiles = (payload) => ({
  type: ACTION_ACCOUNT_CREATION_ADDED_PROFILES,
  payload: payload,
});

// Edited profile
export const ACTION_ACCOUNT_PROFILE_EDITED_PROFILE =
  "ACTION_ACCOUNT_PROFILE_EDITED_PROFILE";
export const accountProfileEditedProfile = (payload) => ({
  type: ACTION_ACCOUNT_PROFILE_EDITED_PROFILE,
  payload: payload,
});

// Selected active profile
export const ACTION_SELECT_ACTIVE_PROFILE = "ACTION_SELECT_ACTIVE_PROFILE";
export const selectActiveProfile = (payload) => ({
  type: ACTION_SELECT_ACTIVE_PROFILE,
  payload: payload,
});

// Added reminders
export const ACTION_ACCOUNT_CREATION_ADDED_REMINDERS =
  "ACTION_ACCOUNT_CREATION_ADDED_REMINDERS";
export const accountCreationFlowAddedReminders = (payload) => ({
  type: ACTION_ACCOUNT_CREATION_ADDED_REMINDERS,
  payload: payload,
});

// Edited reminder
export const ACTION_ACCOUNT_PROFILE_EDITED_REMINDER =
  "ACTION_ACCOUNT_PROFILE_EDITED_REMINDER";
export const accountProfileEditedReminder = (payload) => ({
  type: ACTION_ACCOUNT_PROFILE_EDITED_REMINDER,
  payload: payload,
});

// Updated account name
export const ACTION_ACCOUNT_CREATION_UPDATED_ACCOUNT_NAME =
  "ACTION_ACCOUNT_CREATION_UPDATED_ACCOUNT_NAME";
export const accountCreationFlowUpdatedAccountName = (payload) => ({
  type: ACTION_ACCOUNT_CREATION_UPDATED_ACCOUNT_NAME,
  payload: payload,
});

// store temporary account name data for user convenience
export const ACTION_STORE_TEMPORARY_ACCOUNT_NAME =
  "ACTION_STORE_TEMPORARY_ACCOUNT_NAME";
export const storeTemporaryAccountName = (payload) => ({
  type: ACTION_STORE_TEMPORARY_ACCOUNT_NAME,
  payload: payload,
});

// Registered new home address
export const ACTION_ACCOUNT_CREATION_ADDED_NEW_ADDRESS =
  "ACTION_ACCOUNT_CREATION_ADDED_NEW_ADDRESS";
export const accountCreationFlowAddedNewAddress = (payload) => ({
  type: ACTION_ACCOUNT_CREATION_ADDED_NEW_ADDRESS,
  payload: payload,
});

// Selected new home address
export const ACTION_ACCOUNT_CREATION_SELECTED_NEW_ADDRESS =
  "ACTION_ACCOUNT_CREATION_SELECTED_NEW_ADDRESS";
export const accountCreationFlowSelectedAddress = (payload) => ({
  type: ACTION_ACCOUNT_CREATION_SELECTED_NEW_ADDRESS,
  payload: payload,
});

// Checked if the user should be waitlisted
export const ACTION_ACCOUNT_CREATION_WAITLIST_CRITERIA_CHECKED =
  "ACTION_ACCOUNT_CREATION_WAITLIST_CRITERIA_CHECKED";
export const accountCreationFlowWaitlistCriteriaChecked = (payload) => ({
  type: ACTION_ACCOUNT_CREATION_WAITLIST_CRITERIA_CHECKED,
  payload: payload,
});

// Auth expired so cater for re-authentication and redirection on success
export const ACTION_REQUEST_NEW_OTP_AND_REDIRECT_ON_SUCCESS =
  "ACTION_REQUEST_NEW_OTP_AND_REDIRECT_ON_SUCCESS";
export const requestNewOtpAndRedirectOnSuccess = (payload) => ({
  type: ACTION_REQUEST_NEW_OTP_AND_REDIRECT_ON_SUCCESS,
  payload: payload,
});

// Account data synced from the backend
export const ACTION_ACCOUNT_DATA_SYNCED_FROM_BACKEND =
  "ACTION_ACCOUNT_DATA_SYNCED_FROM_BACKEND";
export const accountDataSyncedFromBackend = (payload) => ({
  type: ACTION_ACCOUNT_DATA_SYNCED_FROM_BACKEND,
  payload: payload,
});

// All Additional relevant data synced from the backend
export const ACTION_ALL_ADDITIONAL_RELEVANT_DATA_SYNCED_FROM_BACKEND =
  "ACTION_ALL_ADDITIONAL_RELEVANT_DATA_SYNCED_FROM_BACKEND";
export const additionalRelevantDataSyncedFromBackend = (payload) => ({
  type: ACTION_ALL_ADDITIONAL_RELEVANT_DATA_SYNCED_FROM_BACKEND,
  payload: payload,
});

// User signed in
export const ACTION_USER_SIGNED_IN_SUCCESSFULLY =
  "ACTION_USER_SIGNED_IN_SUCCESSFULLY";
export const userSignedInSuccessfully = (payload) => ({
  type: ACTION_USER_SIGNED_IN_SUCCESSFULLY,
  payload: payload,
});

// User signed in
export const ACTION_STORE_PUSH_NOTIFICATION_TOKEN =
  "ACTION_STORE_PUSH_NOTIFICATION_TOKEN";
export const storePushNotificationToken = (payload) => ({
  type: ACTION_STORE_PUSH_NOTIFICATION_TOKEN,
  payload: payload,
});

// User should upgrade to latest app version
export const ACTION_USER_USING_OLD_VERSION_OF_APP =
  "ACTION_USER_USING_OLD_VERSION_OF_APP";
export const userShouldUpgradeAppVersion = (payload) => ({
  type: ACTION_USER_USING_OLD_VERSION_OF_APP,
  payload: payload,
});

// User is using latest version of the app
export const ACTION_USER_USING_LATEST_VERSION_OF_APP =
  "ACTION_USER_USING_LATEST_VERSION_OF_APP";
export const setAppStoreLinks = (payload) => ({
  type: ACTION_USER_USING_LATEST_VERSION_OF_APP,
  payload: payload,
});

// User is using latest version of the app
export const ACTION_SET_APP_STORE_LINKS = "ACTION_SET_APP_STORE_LINKS";
export const userUsingLatestAppVersion = (payload) => ({
  type: ACTION_SET_APP_STORE_LINKS,
  payload: payload,
});

// MRF
export const ACTION_MRF_SCANNED_BAG_TO_CURRENT_BATCH =
  "ACTION_MRF_SCANNED_BAG_TO_CURRENT_BATCH";
export const scanBagToCurrentBatch = (payload) => ({
  type: ACTION_MRF_SCANNED_BAG_TO_CURRENT_BATCH,
  payload: payload,
});

export const ACTION_MRF_UPDATED_CURRENT_BATCH_PAYLOAD =
  "ACTION_MRF_UPDATED_CURRENT_BATCH_PAYLOAD";
export const mrfUpdatedCurrentBatchPayload = (payload) => ({
  type: ACTION_MRF_UPDATED_CURRENT_BATCH_PAYLOAD,
  payload: payload,
});

export const ACTION_MRF_CANCELLED_SCANNING_CURRENT_BATCH =
  "ACTION_MRF_CANCELLED_SCANNING_CURRENT_BATCH";
export const mrfCancelledScanningCurrentBatch = (payload) => ({
  type: ACTION_MRF_CANCELLED_SCANNING_CURRENT_BATCH,
  payload: payload,
});

export const ACTION_MRF_CONFIRMED_TAGS_RECEIVED_WITH_WEIGHT =
  "ACTION_MRF_CONFIRMED_TAGS_RECEIVED_WITH_WEIGHT";
export const mrfConfirmedTagsReceivedWithWeight = (payload) => ({
  type: ACTION_MRF_CONFIRMED_TAGS_RECEIVED_WITH_WEIGHT,
  payload: payload,
});

export const ACTION_MRF_ACCOUNT_INFO_RETRIEVED =
  "ACTION_MRF_ACCOUNT_INFO_RETRIEVED";
export const mrfAccountInfoRetrieved = (payload) => ({
  type: ACTION_MRF_ACCOUNT_INFO_RETRIEVED,
  payload: payload,
});

export const ACTION_MRF_ACCOUNT_ACTIVITIES_RETRIEVED =
  "ACTION_MRF_ACCOUNT_ACTIVITIES_RETRIEVED";
export const mrfAccountActivitiesRetrieved = (payload) => ({
  type: ACTION_MRF_ACCOUNT_ACTIVITIES_RETRIEVED,
  payload: payload,
});

// CurbCycle Management
export const ACTION_USER_IS_CURBCYCLE_MANAGEMENT_USER =
  "ACTION_USER_IS_CURBCYCLE_MANAGEMENT_USER";
export const userIsCurbCycleManagementUser = (payload) => ({
  type: ACTION_USER_IS_CURBCYCLE_MANAGEMENT_USER,
  payload: payload,
});

// CurbCycle Development
export const ACTION_USER_IS_CURBCYCLE_DEVELOPMENT_TEAM_USER =
  "ACTION_USER_IS_CURBCYCLE_DEVELOPMENT_TEAM_USER";
export const userIsCurbCycleDevelopmentTeamUser = (payload) => ({
  type: ACTION_USER_IS_CURBCYCLE_DEVELOPMENT_TEAM_USER,
  payload: payload,
});

// CurbCycle Support
export const ACTION_USER_IS_CURBCYCLE_SUPPORT_TEAM_USER =
  "ACTION_USER_IS_CURBCYCLE_SUPPORT_TEAM_USER";
export const userIsCurbCycleSupportTeamUser = (payload) => ({
  type: ACTION_USER_IS_CURBCYCLE_SUPPORT_TEAM_USER,
  payload: payload,
});

// MRF Manager
export const ACTION_USER_IS_MRF_MANAGEMENT_USER =
  "ACTION_USER_IS_MRF_MANAGEMENT_USER";
export const userIsMrfManagementUser = (payload) => ({
  type: ACTION_USER_IS_MRF_MANAGEMENT_USER,
  payload: payload,
});

// MRF Operator
export const ACTION_USER_IS_MRF_OPERATOR_USER =
  "ACTION_USER_IS_MRF_OPERATOR_USER";
export const userIsMrfOperatorUser = (payload) => ({
  type: ACTION_USER_IS_MRF_OPERATOR_USER,
  payload: payload,
});

// LGA
export const ACTION_USER_IS_LGA_USER = "ACTION_USER_IS_LGA_USER";
export const userIsLgaUser = (payload) => ({
  type: ACTION_USER_IS_LGA_USER,
  payload: payload,
});

// Retail Sponsor
export const ACTION_USER_IS_RETAIL_SPONSOR_USER =
  "ACTION_USER_IS_RETAIL_SPONSOR_USER";
export const userIsRetailSponsorUser = (payload) => ({
  type: ACTION_USER_IS_RETAIL_SPONSOR_USER,
  payload: payload,
});

// Account Profile
export const ACTION_USER_IS_ACCOUNT_PROFILE_USER =
  "ACTION_USER_IS_ACCOUNT_PROFILE_USER";
export const userIsAccountProfileUser = (payload) => ({
  type: ACTION_USER_IS_ACCOUNT_PROFILE_USER,
  payload: payload,
});

// User signed out
export const ACTION_USER_SIGNED_OUT = "ACTION_USER_SIGNED_OUT";
export const userSignedOut = (payload) => ({
  type: ACTION_USER_SIGNED_OUT,
  payload: payload,
});

// User is not waitlisted
export const ACTION_USER_IS_NOT_WAITLISTED = "ACTION_USER_IS_NOT_WAITLISTED";
export const userIsNotWaitlisted = (payload) => ({
  type: ACTION_USER_IS_NOT_WAITLISTED,
  payload: payload,
});

// User is waitlisted
export const ACTION_USER_IS_WAITLISTED = "ACTION_USER_IS_WAITLISTED";
export const userIsWaitlisted = (payload) => ({
  type: ACTION_USER_IS_WAITLISTED,
  payload: payload,
});

// User is finished sign up
export const ACTION_USER_FINISHED_SIGN_UP = "ACTION_USER_FINISHED_SIGN_UP";
export const userIsFinishedSignUp = (payload) => ({
  type: ACTION_USER_FINISHED_SIGN_UP,
  payload: payload,
});

/* UI notifications */
export const UI_NOTIFICATION_BACKGROUND_PROCESSING_PROGRESS =
  "UI_NOTIFICATION_BACKGROUND_PROCESSING_PROGRESS";
export const uiNotifyBackgroundProcessingProgress = (payload) => ({
  type: UI_NOTIFICATION_BACKGROUND_PROCESSING_PROGRESS,
  payload: payload,
});

export const UI_NOTIFICATION_BACKGROUND_PROCESSING_FAILURE =
  "UI_NOTIFICATION_BACKGROUND_PROCESSING_FAILURE";
export const uiNotifyBackgroundProcessingFailure = (payload) => ({
  type: UI_NOTIFICATION_BACKGROUND_PROCESSING_FAILURE,
  payload: payload,
});

export const UI_NOTIFICATION_BACKGROUND_PROCESSING_FAILURE_CLEAR =
  "UI_NOTIFICATION_BACKGROUND_PROCESSING_FAILURE_CLEAR";
export const uiNotifyBackgroundProcessingFailureClear = (payload) => ({
  type: UI_NOTIFICATION_BACKGROUND_PROCESSING_FAILURE_CLEAR,
  payload: payload,
});

export const UI_NOTIFICATION_BACKGROUND_PROCESSING_SHOULD_ALLOW_RETRY =
  "UI_NOTIFICATION_BACKGROUND_PROCESSING_SHOULD_ALLOW_RETRY";
export const uiNotifyBackgroundProcessingShouldAllowRetry = (payload) => ({
  type: UI_NOTIFICATION_BACKGROUND_PROCESSING_SHOULD_ALLOW_RETRY,
  payload: payload,
});

const initialState = {
  appStoreLinks: {
    androidStoreLink: "",
    appleStoreLink: "",
  },
  isInitialising: true,
  isLoggedIn: false,
  isWaitlisted: false,
  isFinishedSignup: false,
  isAccountProfileUser: false,
  isCurbCycleManagementUser: false,
  isCurbCycleDevelopmentTeamUser: false,
  isCurbCycleSupportTeamUser: false,
  isMrfManagementUser: false,
  isMrfOperatorUser: false,
  isLgaUser: false,
  isRetailSponsorUser: false,
  shouldUpgradeApp: false,
  pushNotificationToken: "",
  journeyFlows: {
    CurbCycleManagement: {
      accounts: {
        fetching: false,
        accounts: {
          current_page: 1,
          data: [],
          first_page_url: null,
          from: 1,
          last_page: 1,
          last_page_url: null,
          next_page_url: null,
          path: null,
          per_page: 15,
          prev_page_url: null,
          to: 1,
          total: 1,
        },
      },
      curby_codes: {
        fetching: false,
        curby_codes: {
          current_page: 1,
          data: [],
          first_page_url: null,
          from: 1,
          last_page: 1,
          last_page_url: null,
          next_page_url: null,
          path: null,
          per_page: 15,
          prev_page_url: null,
          to: 1,
          total: 1,
        },
      },
      mrfs: {
        accessPin: null,
        addMrf: {
          saving: false,
        },
        editMrf: {
          saving: false,
        },
        addMrfManager: {
          saving: false,
        },
        editMrfManager: {
          saving: false,
        },
        addMrfOperator: {
          fetchingFirstValidMrfManagerUuid: false,
          initialManagerUuid: null,
          saving: false,
        },
        editMrfOperator: {
          saving: false,
        },
        fetching: false,
        isResettingMrfAccessPin: false,
        mrfs: {
          current_page: 1,
          data: [],
          first_page_url: null,
          from: 1,
          last_page: 1,
          last_page_url: null,
          next_page_url: null,
          path: null,
          per_page: 15,
          prev_page_url: null,
          to: 1,
          total: 1,
        },
        mrfOperators: {
          fetching: false,
          mrfOperators: {
            current_page: 1,
            data: [],
            first_page_url: null,
            from: 1,
            last_page: 1,
            last_page_url: null,
            next_page_url: null,
            path: null,
            per_page: 15,
            prev_page_url: null,
            to: 1,
            total: 1,
          },
        },
        mrfManagers: {
          fetching: false,
          mrfManagers: {
            current_page: 1,
            data: [],
            first_page_url: null,
            from: 1,
            last_page: 1,
            last_page_url: null,
            next_page_url: null,
            path: null,
            per_page: 15,
            prev_page_url: null,
            to: 1,
            total: 1,
          },
        },
      },
      retailSponsors: {
        addRetailSponsor: {
          saving: false,
        },
        editRetailSponsor: {
          saving: false,
        },
        addRetailSponsorManager: {
          saving: false,
        },
        editRetailSponsorManager: {
          saving: false,
        },
        fetching: false,
        retailSponsors: {
          current_page: 1,
          data: [],
          first_page_url: null,
          from: 1,
          last_page: 1,
          last_page_url: null,
          next_page_url: null,
          path: null,
          per_page: 15,
          prev_page_url: null,
          to: 1,
          total: 1,
        },
        retailSponsorManagers: {
          fetching: false,
          retailSponsorManagers: {
            current_page: 1,
            data: [],
            first_page_url: null,
            from: 1,
            last_page: 1,
            last_page_url: null,
            next_page_url: null,
            path: null,
            per_page: 15,
            prev_page_url: null,
            to: 1,
            total: 1,
          },
        },
      },
      lgas: {
        addLGA: {
          saving: false,
        },
        editLGA: {
          saving: false,
        },
        addLGAManager: {
          saving: false,
        },
        editLGAManager: {
          saving: false,
        },
        fetching: false,
        lgas: {
          current_page: 1,
          data: [],
          first_page_url: null,
          from: 1,
          last_page: 1,
          last_page_url: null,
          next_page_url: null,
          path: null,
          per_page: 15,
          prev_page_url: null,
          to: 1,
          total: 1,
        },
        lgaManagers: {
          fetching: false,
          lgaManagers: {
            current_page: 1,
            data: [],
            first_page_url: null,
            from: 1,
            last_page: 1,
            last_page_url: null,
            next_page_url: null,
            path: null,
            per_page: 15,
            prev_page_url: null,
            to: 1,
            total: 1,
          },
        },
      },
      scannedMrfBatchDataCollection: {},
      managementAnalytics: {
        fetching: false,
        user_activity: {
          scans_using_curby_app: {
            most_recent_scan: {},
            total: 0,
            scans_by_date: [],
          },
        },
        total_signups: 0,
        total_waitlisted: 0,
        total_completed_signup: 0,
        total_users_who_scanned_within_last_14_days: 0,
        total_users_who_scanned_within_last_28_days: 0,
        total_users_who_scanned_more_than_28_days_ago: 0,
        full_scan_data: {
          individual_dates_min_to_max: [],
          total: 0,
          scans_by_date: {},
        },
        scans_over_last_24hrs: {
          total: 0,
          data: [],
        },
        new_account_registrations_last_24hrs: {
          total: 0,
          data: [],
        },
        participation_rate_view_data: [],
      },
    },
    MrfManagement: {
      curby_codes: {
        fetching: false,
        curby_codes: {
          current_page: 1,
          data: [],
          first_page_url: null,
          from: 1,
          last_page: 1,
          last_page_url: null,
          next_page_url: null,
          path: null,
          per_page: 15,
          prev_page_url: null,
          to: 1,
          total: 1,
        },
      },
      mrfs: {
        accessPin: null,
        addMrf: {
          saving: false,
        },
        editMrf: {
          saving: false,
        },
        addMrfManager: {
          saving: false,
        },
        editMrfManager: {
          saving: false,
        },
        addMrfOperator: {
          fetchingFirstValidMrfManagerUuid: false,
          initialManagerUuid: null,
          saving: false,
        },
        editMrfOperator: {
          saving: false,
        },
        fetching: false,
        isResettingMrfAccessPin: false,
        mrfs: {
          current_page: 1,
          data: [],
          first_page_url: null,
          from: 1,
          last_page: 1,
          last_page_url: null,
          next_page_url: null,
          path: null,
          per_page: 15,
          prev_page_url: null,
          to: 1,
          total: 1,
        },
        mrfOperators: {
          fetching: false,
          mrfOperators: {
            current_page: 1,
            data: [],
            first_page_url: null,
            from: 1,
            last_page: 1,
            last_page_url: null,
            next_page_url: null,
            path: null,
            per_page: 15,
            prev_page_url: null,
            to: 1,
            total: 1,
          },
        },
        mrfManagers: {
          fetching: false,
          mrfManagers: {
            current_page: 1,
            data: [],
            first_page_url: null,
            from: 1,
            last_page: 1,
            last_page_url: null,
            next_page_url: null,
            path: null,
            per_page: 15,
            prev_page_url: null,
            to: 1,
            total: 1,
          },
        },
      },
      scannedMrfBatchDataCollection: {},
      managementAnalytics: {
        fetching: false,
        total_bags_scanned: 0,
        total_bags_scanned_for_dates: {},
        total_bags_manually_entered_for_dates: {},
        total_bags_scanned_by_postcode_sorted_output: {},
        total_bags_scanned_by_postcode_for_dates: {},
        individual_dates_min_to_max: [],
      },
    },
    MrfOperations: {
      mrf: {
        uuid: "",
        operator_uuid: "",
        manager_uuid: "",
      },
      mrf_operator: {
        mrf_operator_activities: [],
      },
      currentBatch: {
        payload: {},
        allowedToSkipWeightEntry: {
          allowed: false,
          reason: "",
        },
        bags: 0,
        weight_in_g: 0,
        batchDetail: {
          bags: [],
        },
      },
    },
    RetailSponsor: {
      analytics: {
        fetching: false,
        min_date_unix: 0,
        max_date_unix: 0,
        total_bales_scanned_in: 0,
        total_bales_scanned_in_for_dates: {},
        total_bags_manually_entered_for_dates: {},
        total_weight_of_bales_scanned_in: 0,
        tonnage_offset_data_frame: [],
        average_bag_weight: 0,
        total_bags_scanned: 0,
        total_bags_scanned_for_dates: {},
        total_bags_scanned_by_postcode_sorted_output: {},
        total_bags_scanned_by_postcode_for_dates: {},
        individual_dates_min_to_max: [],
        participation: {},
        d: {},
      },
    },
    Lga: {
      analytics: {
        fetching: false,
        min_date_unix: 0,
        max_date_unix: 0,
        total_bales_scanned_in: 0,
        total_bales_scanned_in_for_dates: {},
        total_bags_manually_entered_for_dates: {},
        total_weight_of_bales_scanned_in: 0,
        tonnage_offset_data_frame: [],
        average_bag_weight: 0,
        total_bags_scanned: 0,
        total_bags_scanned_for_dates: {},
        total_bags_scanned_by_postcode_sorted_output: {},
        total_bags_scanned_by_postcode_for_dates: {},
        individual_dates_min_to_max: [],
        participation: {},
        d: {},
      },
    },
  },
  activeProfile: {
    uuid: "",
    name: "",
    avatar: "curby",
  },
  // will fetch updated values for this from the backend on initialisation to allow for overrides without releasing a new version
  appRules: {
    // if a newer version is available based on data returned by the backend we can prompt the user to update the app
    currentAppVersion: "0.0.1",
    // account profile rules
    accountProfile: {
      maxAllowedProfiles: 5,
    },
    // intending to initialise these values as required based on user type successfully authenticating
    mrfOperations: {},
    lgaOperations: {},
    retailSponsorOperations: {},
    managementOperations: {},
  },
  backgroundProcessing: {
    processing: false,
  },
  backgroundProcessingMessage: {
    message: "",
  },
  backgroundProcessingFailureMessage: {
    message: "",
  },
  backgroundProcessingFailure: {
    failed: false,
  },
  backgroundProcessingShouldRetry: {
    accountProfileEditProfile: false,
    accountCreationUsingEmail: false,
    accountCreationUpdateAccountName: false,
    accountCreationUpdateName: false,
    accountCreationAddProfiles: false,
    accountCreationAddReminders: false,
    accountCreationAddAddress: false,
    accountCreationAddAddressLgaParticipation: false,
    loginRequestingNewOtpToEmail: false,
    loginRespondingToOtpChallenge: false,
  },
  journey: {
    experience: {
      journey: null,
    },
  },
  auth: {
    aclGroups: [null],
    idToken: "",
    token: "",
  },
  authRequired: {
    authRequired: false,
    redirectOnSuccessTo: "",
  },
  accountData: {
    email: "",
  },
  tempAccountName: {
    name: "",
    first_name: "",
    last_name: "",
  },
  tempAddress: {
    formatted_address: "",
    address_components: "",
    point_coordinate_lat: "",
    point_coordinate_long: "",
  },
  tempEmail: {
    email: "",
  },
  waitlist: {
    waitlistStatusHasBeenVerified: false,
    shouldBeOnWaitlist: true,
  },
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_SET_APP_INITIALISED:
      state.isInitialising = false;
      return {
        ...state,
      };

    /** Retail Sponsor */
    // analytics
    case ACTION_RETAIL_SPONSOR_REQUESTED_ANALYTICS:
      state.journeyFlows.RetailSponsor.analytics.fetching = true;
      return {
        ...state,
      };
    case ACTION_RETAIL_SPONSOR_RECEIVED_ANALYTICS:
      state.journeyFlows.RetailSponsor.analytics.fetching = false;
      /**
       * min_date_unix: 0,
       max_date_unix: 0,
       total_bales_scanned_in: 0,
       total_bales_scanned_in_for_dates: {},
       average_bag_weight: 0,
       total_bags_scanned: 0,
       total_bags_scanned_for_dates: {},
       total_bags_scanned_by_postcode_sorted_output: {},
       total_bags_scanned_by_postcode_for_dates: {},
       individual_dates_min_to_max: [],
       participation: {},
       * @type {*}
       */

      state.journeyFlows.RetailSponsor.analytics.min_date_unix =
        action.payload.min_date_unix;
      state.journeyFlows.RetailSponsor.analytics.max_date_unix =
        action.payload.max_date_unix;
      state.journeyFlows.RetailSponsor.analytics.total_bales_scanned_in =
        action.payload.total_bales_scanned_in;
      state.journeyFlows.RetailSponsor.analytics.total_bales_scanned_in_for_dates =
        action.payload.total_bales_scanned_in_for_dates;
      state.journeyFlows.RetailSponsor.analytics.total_weight_of_bales_scanned_in =
        action.payload.total_weight_of_bales_scanned_in;
      state.journeyFlows.RetailSponsor.analytics.tonnage_offset_data_frame =
        action.payload.tonnage_offset_data_frame;
      state.journeyFlows.RetailSponsor.analytics.average_bag_weight =
        action.payload.average_bag_weight;
      state.journeyFlows.RetailSponsor.analytics.total_bags_scanned =
        action.payload.total_bags_scanned;
      state.journeyFlows.RetailSponsor.analytics.total_bags_scanned_for_dates =
        action.payload.total_bags_scanned_for_dates;
      state.journeyFlows.RetailSponsor.analytics.total_bags_scanned_by_postcode_sorted_output =
        action.payload.total_bags_scanned_by_postcode_sorted_output;
      state.journeyFlows.RetailSponsor.analytics.total_bags_scanned_by_postcode_for_dates =
        action.payload.total_bags_scanned_by_postcode_for_dates;
      state.journeyFlows.RetailSponsor.analytics.individual_dates_min_to_max =
        action.payload.individual_dates_min_to_max;
      state.journeyFlows.RetailSponsor.analytics.participation =
        action.payload.participation;
      state.journeyFlows.RetailSponsor.analytics.average_bale_condition_score =
        action.payload.average_bale_condition_score;
      state.journeyFlows.RetailSponsor.analytics.total_bags_manually_entered_for_dates =
        action.payload.total_bags_manually_entered_for_dates;
      state.journeyFlows.RetailSponsor.analytics.d = action.payload.d;
      return {
        ...state,
      };

    /** LGA */
    // analytics
    case ACTION_LGA_REQUESTED_ANALYTICS:
      state.journeyFlows.Lga.analytics.fetching = true;
      return {
        ...state,
      };
    case ACTION_LGA_RECEIVED_ANALYTICS:
      state.journeyFlows.Lga.analytics.fetching = false;
      /**
       * min_date_unix: 0,
       max_date_unix: 0,
       total_bales_scanned_in: 0,
       total_bales_scanned_in_for_dates: {},
       average_bag_weight: 0,
       total_bags_scanned: 0,
       total_bags_scanned_for_dates: {},
       total_bags_scanned_by_postcode_sorted_output: {},
       total_bags_scanned_by_postcode_for_dates: {},
       individual_dates_min_to_max: [],
       participation: {},
       * @type {*}
       */

      state.journeyFlows.Lga.analytics.min_date_unix =
        action.payload.min_date_unix;
      state.journeyFlows.Lga.analytics.max_date_unix =
        action.payload.max_date_unix;
      state.journeyFlows.Lga.analytics.total_bales_scanned_in =
        action.payload.total_bales_scanned_in;
      state.journeyFlows.Lga.analytics.total_bales_scanned_in_for_dates =
        action.payload.total_bales_scanned_in_for_dates;
      state.journeyFlows.Lga.analytics.total_weight_of_bales_scanned_in =
        action.payload.total_weight_of_bales_scanned_in;
      state.journeyFlows.Lga.analytics.tonnage_offset_data_frame =
        action.payload.tonnage_offset_data_frame;
      state.journeyFlows.Lga.analytics.average_bag_weight =
        action.payload.average_bag_weight;
      state.journeyFlows.Lga.analytics.total_bags_scanned =
        action.payload.total_bags_scanned;
      state.journeyFlows.Lga.analytics.total_bags_scanned_for_dates =
        action.payload.total_bags_scanned_for_dates;
      state.journeyFlows.Lga.analytics.total_bags_scanned_by_postcode_sorted_output =
        action.payload.total_bags_scanned_by_postcode_sorted_output;
      state.journeyFlows.Lga.analytics.total_bags_scanned_by_postcode_for_dates =
        action.payload.total_bags_scanned_by_postcode_for_dates;
      state.journeyFlows.Lga.analytics.individual_dates_min_to_max =
        action.payload.individual_dates_min_to_max;
      state.journeyFlows.Lga.analytics.participation =
        action.payload.participation;
      state.journeyFlows.Lga.analytics.average_bale_condition_score =
        action.payload.average_bale_condition_score;
      state.journeyFlows.Lga.analytics.total_bags_manually_entered_for_dates =
        action.payload.total_bags_manually_entered_for_dates;
      state.journeyFlows.Lga.analytics.d = action.payload.d;
      return {
        ...state,
      };

    /** CurbCycle Management */
    // accounts admin
    case ACTION_MANAGEMENT_REQUESTED_ACCOUNTS:
      state.journeyFlows.CurbCycleManagement.accounts.fetching = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_RECEIVED_ACCOUNTS:
      state.journeyFlows.CurbCycleManagement.accounts.fetching = false;
      state.journeyFlows.CurbCycleManagement.accounts.accounts = action.payload;
      return {
        ...state,
      };

    // analytics
    case ACTION_MANAGEMENT_REQUESTED_ANALYTICS:
      state.journeyFlows.CurbCycleManagement.managementAnalytics.fetching = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_RECEIVED_ANALYTICS:
      state.journeyFlows.CurbCycleManagement.managementAnalytics.fetching = false;
      state.journeyFlows.CurbCycleManagement.managementAnalytics.user_activity =
        action.payload.user_activity;
      state.journeyFlows.CurbCycleManagement.managementAnalytics.total_signups =
        action.payload.total_signups;
      state.journeyFlows.CurbCycleManagement.managementAnalytics.total_waitlisted =
        action.payload.total_waitlisted;
      state.journeyFlows.CurbCycleManagement.managementAnalytics.total_completed_signup =
        action.payload.total_completed_signup;
      state.journeyFlows.CurbCycleManagement.managementAnalytics.total_users_who_scanned_within_last_14_days =
        action.payload.total_users_who_scanned_within_last_14_days;
      state.journeyFlows.CurbCycleManagement.managementAnalytics.total_users_who_scanned_within_last_28_days =
        action.payload.total_users_who_scanned_within_last_28_days;
      state.journeyFlows.CurbCycleManagement.managementAnalytics.total_users_who_scanned_more_than_28_days_ago =
        action.payload.total_users_who_scanned_more_than_28_days_ago;
      state.journeyFlows.CurbCycleManagement.managementAnalytics.full_scan_data =
        action.payload.full_scan_data;
      state.journeyFlows.CurbCycleManagement.managementAnalytics.scans_over_last_24hrs =
        action.payload.scans_over_last_24hrs;
      state.journeyFlows.CurbCycleManagement.managementAnalytics.new_account_registrations_last_24hrs =
        action.payload.new_account_registrations_last_24hrs;
      state.journeyFlows.CurbCycleManagement.managementAnalytics.participation_rate_view_data =
        action.payload.participation_rate_view_data;
      return {
        ...state,
      };

    // curby code admin
    case ACTION_MANAGEMENT_REQUESTED_CURBY_CODES:
      state.journeyFlows.CurbCycleManagement.curby_codes.fetching = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_RECEIVED_CURBY_CODES:
      state.journeyFlows.CurbCycleManagement.curby_codes.fetching = false;
      state.journeyFlows.CurbCycleManagement.curby_codes.curby_codes =
        action.payload;
      return {
        ...state,
      };

    // mrf admin
    case ACTION_MANAGEMENT_REQUESTED_MRFS:
      state.journeyFlows.CurbCycleManagement.mrfs.fetching = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_RECEIVED_MRFS:
      state.journeyFlows.CurbCycleManagement.mrfs.fetching = false;
      state.journeyFlows.CurbCycleManagement.mrfs.mrfs = action.payload;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_FIRST_VALID_MRF_MANAGER:
      state.journeyFlows.CurbCycleManagement.mrfs.addMrfOperator.fetchingFirstValidMrfManagerUuid = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_RECEIVED_FIRST_VALID_MRF_MANAGER:
      state.journeyFlows.CurbCycleManagement.mrfs.addMrfOperator.fetchingFirstValidMrfManagerUuid = false;
      state.journeyFlows.CurbCycleManagement.mrfs.addMrfOperator.initialManagerUuid =
        action.payload.uuid;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_MRF_MANAGERS:
      state.journeyFlows.CurbCycleManagement.mrfs.mrfManagers.fetching = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_RECEIVED_MRF_MANAGERS:
      state.journeyFlows.CurbCycleManagement.mrfs.mrfManagers.fetching = false;
      state.journeyFlows.CurbCycleManagement.mrfs.mrfManagers.mrfManagers =
        action.payload;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_MRF_OPERATORS:
      state.journeyFlows.CurbCycleManagement.mrfs.mrfOperators.fetching = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_RECEIVED_MRF_OPERATORS:
      state.journeyFlows.CurbCycleManagement.mrfs.mrfOperators.fetching = false;
      state.journeyFlows.CurbCycleManagement.mrfs.mrfOperators.mrfOperators =
        action.payload;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_ADD_MRF:
      state.journeyFlows.CurbCycleManagement.mrfs.addMrf.saving = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_ADD_MRF:
      state.journeyFlows.CurbCycleManagement.mrfs.addMrf.saving = false;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_ADD_MRF_MANAGER:
      state.journeyFlows.CurbCycleManagement.mrfs.addMrfManager.saving = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_ADD_MRF_MANAGER:
      state.journeyFlows.CurbCycleManagement.mrfs.addMrfManager.saving = false;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_ADD_MRF_OPERATOR:
      state.journeyFlows.CurbCycleManagement.mrfs.addMrfOperator.saving = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_ADD_MRF_OPERATOR:
      state.journeyFlows.CurbCycleManagement.mrfs.addMrfOperator.saving = false;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_EDIT_MRF_MANAGER:
      state.journeyFlows.CurbCycleManagement.mrfs.editMrfManager.saving = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_EDIT_MRF_MANAGER:
      state.journeyFlows.CurbCycleManagement.mrfs.editMrfManager.saving = false;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_EDIT_MRF_OPERATOR:
      state.journeyFlows.CurbCycleManagement.mrfs.editMrfOperator.saving = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_EDIT_MRF_OPERATOR:
      state.journeyFlows.CurbCycleManagement.mrfs.editMrfOperator.saving = false;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_EDIT_MRF:
      state.journeyFlows.CurbCycleManagement.mrfs.editMrf.saving = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_EDIT_MRF:
      state.journeyFlows.CurbCycleManagement.mrfs.editMrf.saving = false;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_RESET_MRF_ACCESS_PIN:
      state.journeyFlows.CurbCycleManagement.mrfs.isResettingMrfAccessPin = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_RESET_MRF_ACCESS_PIN:
      state.journeyFlows.CurbCycleManagement.mrfs.isResettingMrfAccessPin = false;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_FETCH_MRF_ACCESS_PIN:
      state.journeyFlows.CurbCycleManagement.mrfs.isFetchingMrfAccessPin = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_FETCH_MRF_ACCESS_PIN:
      state.journeyFlows.CurbCycleManagement.mrfs.isFetchingMrfAccessPin = false;
      state.journeyFlows.CurbCycleManagement.mrfs.accessPin =
        action.payload.pin;
      return {
        ...state,
      };

    // retail sponsors admin
    case ACTION_MANAGEMENT_REQUESTED_RETAIL_SPONSORS:
      state.journeyFlows.CurbCycleManagement.retailSponsors.fetching = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_RECEIVED_RETAIL_SPONSORS:
      state.journeyFlows.CurbCycleManagement.retailSponsors.fetching = false;
      state.journeyFlows.CurbCycleManagement.retailSponsors.retailSponsors =
        action.payload;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_RETAIL_SPONSOR_MANAGERS:
      state.journeyFlows.CurbCycleManagement.retailSponsors.retailSponsorManagers.fetching = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_RECEIVED_RETAIL_SPONSOR_MANAGERS:
      state.journeyFlows.CurbCycleManagement.retailSponsors.retailSponsorManagers.fetching = false;
      state.journeyFlows.CurbCycleManagement.retailSponsors.retailSponsorManagers.retailSponsorManagers =
        action.payload;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_ADD_RETAIL_SPONSOR:
      state.journeyFlows.CurbCycleManagement.retailSponsors.addRetailSponsor.saving = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_ADD_RETAIL_SPONSOR:
      state.journeyFlows.CurbCycleManagement.retailSponsors.addRetailSponsor.saving = false;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_ADD_RETAIL_SPONSOR_MANAGER:
      state.journeyFlows.CurbCycleManagement.retailSponsors.addRetailSponsorManager.saving = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_ADD_RETAIL_SPONSOR_MANAGER:
      state.journeyFlows.CurbCycleManagement.retailSponsors.addRetailSponsorManager.saving = false;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_EDIT_RETAIL_SPONSOR_MANAGER:
      state.journeyFlows.CurbCycleManagement.retailSponsors.editRetailSponsorManager.saving = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_EDIT_RETAIL_SPONSOR_MANAGER:
      state.journeyFlows.CurbCycleManagement.retailSponsors.editRetailSponsorManager.saving = false;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_EDIT_RETAIL_SPONSOR:
      state.journeyFlows.CurbCycleManagement.retailSponsors.editRetailSponsor.saving = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_EDIT_RETAIL_SPONSOR:
      state.journeyFlows.CurbCycleManagement.retailSponsors.editRetailSponsor.saving = false;
      return {
        ...state,
      };

    // lgas admin
    case ACTION_MANAGEMENT_REQUESTED_LGAS:
      state.journeyFlows.CurbCycleManagement.lgas.fetching = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_RECEIVED_LGAS:
      state.journeyFlows.CurbCycleManagement.lgas.fetching = false;
      state.journeyFlows.CurbCycleManagement.lgas.lgas = action.payload;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_LGA_MANAGERS:
      state.journeyFlows.CurbCycleManagement.lgas.lgaManagers.fetching = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_RECEIVED_LGA_MANAGERS:
      state.journeyFlows.CurbCycleManagement.lgas.lgaManagers.fetching = false;
      state.journeyFlows.CurbCycleManagement.lgas.lgaManagers.lgaManagers =
        action.payload;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_ADD_LGA:
      state.journeyFlows.CurbCycleManagement.lgas.addLGA.saving = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_ADD_LGA:
      state.journeyFlows.CurbCycleManagement.lgas.addLGA.saving = false;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_ADD_LGA_MANAGER:
      state.journeyFlows.CurbCycleManagement.lgas.addLGAManager.saving = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_ADD_LGA_MANAGER:
      state.journeyFlows.CurbCycleManagement.lgas.addLGAManager.saving = false;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_EDIT_LGA_MANAGER:
      state.journeyFlows.CurbCycleManagement.lgas.editLGAManager.saving = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_EDIT_LGA_MANAGER:
      state.journeyFlows.CurbCycleManagement.lgas.editLGAManager.saving = false;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_REQUESTED_EDIT_LGA:
      state.journeyFlows.CurbCycleManagement.lgas.editLGA.saving = true;
      return {
        ...state,
      };
    case ACTION_MANAGEMENT_COMPLETED_EDIT_LGA:
      state.journeyFlows.CurbCycleManagement.lgas.editLGA.saving = false;
      return {
        ...state,
      };

    /** MRF Management */
    case ACTION_MRF_MANAGEMENT_REQUESTED_ANALYTICS:
      state.journeyFlows.MrfManagement.managementAnalytics.fetching = true;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_RECEIVED_ANALYTICS:
      state.journeyFlows.MrfManagement.managementAnalytics.fetching = false;
      state.journeyFlows.MrfManagement.managementAnalytics.scans_over_last_24hrs =
        action.payload.scans_over_last_24hrs;
      state.journeyFlows.MrfManagement.managementAnalytics.new_account_registrations_last_24hrs =
        action.payload.new_account_registrations_last_24hrs;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_REQUESTED_CURBY_CODES:
      state.journeyFlows.MrfManagement.curby_codes.fetching = true;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_RECEIVED_CURBY_CODES:
      console.log(action.payload);
      state.journeyFlows.MrfManagement.curby_codes.fetching = false;
      state.journeyFlows.MrfManagement.curby_codes.curby_codes = action.payload;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_REQUESTED_MRFS:
      state.journeyFlows.MrfManagement.mrfs.fetching = true;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_RECEIVED_MRFS:
      console.log(action.payload);
      state.journeyFlows.MrfManagement.mrfs.fetching = false;
      state.journeyFlows.MrfManagement.mrfs.mrfs = action.payload;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_REQUESTED_FIRST_VALID_MRF_MANAGER:
      state.journeyFlows.MrfManagement.mrfs.addMrfOperator.fetchingFirstValidMrfManagerUuid = true;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_RECEIVED_FIRST_VALID_MRF_MANAGER:
      state.journeyFlows.MrfManagement.mrfs.addMrfOperator.fetchingFirstValidMrfManagerUuid = false;
      state.journeyFlows.MrfManagement.mrfs.addMrfOperator.initialManagerUuid =
        action.payload.uuid;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_REQUESTED_MRF_MANAGERS:
      state.journeyFlows.MrfManagement.mrfs.mrfManagers.fetching = true;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_RECEIVED_MRF_MANAGERS:
      state.journeyFlows.MrfManagement.mrfs.mrfManagers.fetching = false;
      state.journeyFlows.MrfManagement.mrfs.mrfManagers.mrfManagers =
        action.payload;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_REQUESTED_MRF_OPERATORS:
      state.journeyFlows.MrfManagement.mrfs.mrfOperators.fetching = true;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_RECEIVED_MRF_OPERATORS:
      state.journeyFlows.MrfManagement.mrfs.mrfOperators.fetching = false;
      state.journeyFlows.MrfManagement.mrfs.mrfOperators.mrfOperators =
        action.payload;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_REQUESTED_ADD_MRF:
      state.journeyFlows.MrfManagement.mrfs.addMrf.saving = true;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_COMPLETED_ADD_MRF:
      state.journeyFlows.MrfManagement.mrfs.addMrf.saving = false;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_REQUESTED_ADD_MRF_MANAGER:
      state.journeyFlows.MrfManagement.mrfs.addMrfManager.saving = true;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_COMPLETED_ADD_MRF_MANAGER:
      state.journeyFlows.MrfManagement.mrfs.addMrfManager.saving = false;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_REQUESTED_ADD_MRF_OPERATOR:
      state.journeyFlows.MrfManagement.mrfs.addMrfOperator.saving = true;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_COMPLETED_ADD_MRF_OPERATOR:
      state.journeyFlows.MrfManagement.mrfs.addMrfOperator.saving = false;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_REQUESTED_EDIT_MRF_MANAGER:
      state.journeyFlows.MrfManagement.mrfs.editMrfManager.saving = true;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_COMPLETED_EDIT_MRF_MANAGER:
      state.journeyFlows.MrfManagement.mrfs.editMrfManager.saving = false;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_REQUESTED_EDIT_MRF_OPERATOR:
      state.journeyFlows.MrfManagement.mrfs.editMrfOperator.saving = true;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_COMPLETED_EDIT_MRF_OPERATOR:
      state.journeyFlows.MrfManagement.mrfs.editMrfOperator.saving = false;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_REQUESTED_EDIT_MRF:
      state.journeyFlows.MrfManagement.mrfs.editMrf.saving = true;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_COMPLETED_EDIT_MRF:
      state.journeyFlows.MrfManagement.mrfs.editMrf.saving = false;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_REQUESTED_RESET_MRF_ACCESS_PIN:
      state.journeyFlows.MrfManagement.mrfs.isResettingMrfAccessPin = true;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_COMPLETED_RESET_MRF_ACCESS_PIN:
      state.journeyFlows.MrfManagement.mrfs.isResettingMrfAccessPin = false;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_REQUESTED_FETCH_MRF_ACCESS_PIN:
      state.journeyFlows.MrfManagement.mrfs.isFetchingMrfAccessPin = true;
      return {
        ...state,
      };
    case ACTION_MRF_MANAGEMENT_COMPLETED_FETCH_MRF_ACCESS_PIN:
      state.journeyFlows.MrfManagement.mrfs.isFetchingMrfAccessPin = false;
      state.journeyFlows.MrfManagement.mrfs.accessPin = action.payload.pin;
      return {
        ...state,
      };

    case ACTION_REQUEST_NEW_OTP_AND_REDIRECT_ON_SUCCESS:
      state.authRequired = action.payload;
      return {
        ...state,
      };
    case ACTION_ACCOUNT_CREATION_CREATED_NEW_ACCOUNT:
      state.accountData = action.payload;
      return {
        ...state,
      };
    case ACTION_INITIALISE_EXPERIENCE_BASED_ON_STORED_APP_STATE:
      const flow = action.payload.userType; // eslint-disable-line no-case-declarations
      state.auth = action.payload;
      state.flow = flow;
      state.journey = JOURNEY_DEFAULT_EXPERIENCE_STATES[flow];
      return {
        ...state,
      };
    case ACTION_ACCOUNT_CREATION_SET_VERIFICATION_URL_FOR_OTP:
      // nothing needed for now - @todo if required
      return {
        ...state,
      };
    case ACTION_ACCOUNT_DATA_SYNCED_FROM_BACKEND:
      state.accountData = action.payload;
      return {
        ...state,
      };
    case ACTION_ALL_ADDITIONAL_RELEVANT_DATA_SYNCED_FROM_BACKEND:
      if (null === action.payload) {
        return {
          ...state,
        };
      }
      // mrf operator state data
      if (
        undefined !== action.payload.mrf_operator &&
        undefined !== action.payload.mrf_operator_activities
      ) {
        if (null !== action.payload.mrf_operator) {
          state.journeyFlows.MrfOperations.mrf.operator_uuid =
            action.payload.mrf_operator.mrf_operator_uuid;
          state.journeyFlows.MrfOperations.mrf.uuid =
            action.payload.mrf_operator.mrf_uuid;
          state.journeyFlows.MrfOperations.mrf.manager_uuid =
            action.payload.mrf_operator.mrf_manager_uuid;
        }

        if (null !== action.payload.mrf_operator_activities) {
          state.journeyFlows.MrfOperations.mrf_operator.mrf_operator_activities =
            action.payload.mrf_operator_activities;
        }

        return {
          ...state,
        };
      }

      // all other user types
      return {
        ...state,
      };
    case ACTION_ACCOUNT_CREATION_VERIFIED_OTP_FOR_TOKENS:
      state.auth.idToken = action.payload.id_token;
      state.auth.token = action.payload.access_token;
      state.auth.aclGroups = action.payload.aclGroups;
      return {
        ...state,
      };
    case ACTION_ACCOUNT_CREATION_ADDED_PROFILES:
      state.accountData = action.payload;
      state.activeProfile = action.payload.profiles[0];
      return {
        ...state,
      };
    case ACTION_ACCOUNT_PROFILE_EDITED_PROFILE:
      // nothing needed for now - @todo if required
      return {
        ...state,
      };
    case ACTION_SELECT_ACTIVE_PROFILE:
      const result = state.accountData.profiles.filter((profile) => { // eslint-disable-line no-case-declarations
        if (profile.uuid == action.payload.uuid) {
          return profile;
        }
        return {
          ...state,
        };
      });
      state.activeProfile = result[0];
      if (undefined === result[0]) {
        state.activeProfile = state.accountData.profiles[0];
      }
      return {
        ...state,
      };
    case ACTION_ACCOUNT_CREATION_ADDED_REMINDERS:
      state.accountData = action.payload;
      return {
        ...state,
      };
    case ACTION_ACCOUNT_PROFILE_EDITED_REMINDER:
      // nothing needed for now - @todo if required
      return {
        ...state,
      };
    case ACTION_ACCOUNT_CREATION_UPDATED_ACCOUNT_NAME:
      state.accountData = action.payload;
      return {
        ...state,
      };
    case ACTION_STORE_TEMPORARY_ACCOUNT_NAME:
      state.tempAccountName = action.payload;
      return {
        ...state,
      };
    case ACTION_STORE_TEMPORARY_EMAIL_ADDRESS:
      state.tempEmail.email = action.payload.email;
      return {
        ...state,
      };
    case ACTION_ACCOUNT_CREATION_ADDED_NEW_ADDRESS:
      state.accountData = action.payload;
      return {
        ...state,
      };
    case ACTION_ACCOUNT_CREATION_SELECTED_NEW_ADDRESS:
      state.tempAddress = action.payload;
      return {
        ...state,
      };
    case ACTION_ACCOUNT_CREATION_WAITLIST_CRITERIA_CHECKED:
      state.waitlist = action.payload;
      return {
        ...state,
      };
    case UI_NOTIFICATION_BACKGROUND_PROCESSING_PROGRESS:
      state.backgroundProcessing = action.payload.status;
      state.backgroundProcessingMessage = action.payload.notification;
      return {
        ...state,
      };
    case UI_NOTIFICATION_BACKGROUND_PROCESSING_FAILURE:
      state.backgroundProcessingFailure = action.payload.status;
      state.backgroundProcessingFailureMessage = action.payload.notification;
      return {
        ...state,
      };
    case UI_NOTIFICATION_BACKGROUND_PROCESSING_FAILURE_CLEAR:
      state.backgroundProcessingFailure.failed = false;
      state.backgroundProcessingFailureMessage.message = "";
      return {
        ...state,
      };
    case UI_NOTIFICATION_BACKGROUND_PROCESSING_SHOULD_ALLOW_RETRY:
      state.backgroundProcessingShouldRetry[action.payload.process] =
        action.payload.shouldAllow;
      return {
        ...state,
      };
    case ACTION_USER_USING_OLD_VERSION_OF_APP:
      state.shouldUpgradeApp = true;
      return {
        ...state,
      };
    case ACTION_SET_APP_STORE_LINKS:
      state.appStoreLinks.androidStoreLink =
        action.payload.app_store_links.platforms.android;
      state.appStoreLinks.appleStoreLink =
        action.payload.app_store_links.platforms.ios;
      return {
        ...state,
      };
    case ACTION_USER_USING_LATEST_VERSION_OF_APP:
      state.shouldUpgradeApp = false;
      return {
        ...state,
      };
    case ACTION_USER_SIGNED_OUT:
      state.isLoggedIn = false;
      return {
        ...state,
      };
    case ACTION_USER_SIGNED_IN_SUCCESSFULLY:
      state.isLoggedIn = true;
      return {
        ...state,
      };
    case ACTION_STORE_PUSH_NOTIFICATION_TOKEN:
      state.pushNotificationToken = action.payload;
      return {
        ...state,
      };
    case ACTION_USER_IS_CURBCYCLE_MANAGEMENT_USER:
      state.isAccountProfileUser = false;
      state.isCurbCycleManagementUser = true;
      state.isCurbCycleDevelopmentTeamUser = false;
      state.isCurbCycleSupportTeamUser = false;
      state.isRetailSponsorUser = false;
      state.isLgaUser = false;
      state.isMrfOperatorUser = false;
      state.isMrfManagementUser = false;
      return {
        ...state,
      };
    case ACTION_USER_IS_CURBCYCLE_DEVELOPMENT_TEAM_USER:
      state.isAccountProfileUser = false;
      state.isCurbCycleManagementUser = false;
      state.isCurbCycleDevelopmentTeamUser = true;
      state.isCurbCycleSupportTeamUser = false;
      state.isRetailSponsorUser = false;
      state.isLgaUser = false;
      state.isMrfOperatorUser = false;
      state.isMrfManagementUser = false;
      return {
        ...state,
      };
    case ACTION_USER_IS_CURBCYCLE_SUPPORT_TEAM_USER:
      state.isAccountProfileUser = false;
      state.isCurbCycleManagementUser = false;
      state.isCurbCycleDevelopmentTeamUser = false;
      state.isCurbCycleSupportTeamUser = true;
      state.isRetailSponsorUser = false;
      state.isLgaUser = false;
      state.isMrfOperatorUser = false;
      state.isMrfManagementUser = false;
      return {
        ...state,
      };
    case ACTION_USER_IS_MRF_MANAGEMENT_USER:
      state.isAccountProfileUser = false;
      state.isCurbCycleManagementUser = false;
      state.isCurbCycleDevelopmentTeamUser = false;
      state.isCurbCycleSupportTeamUser = false;
      state.isRetailSponsorUser = false;
      state.isLgaUser = false;
      state.isMrfOperatorUser = false;
      state.isMrfManagementUser = true;
      return {
        ...state,
      };
    case ACTION_USER_IS_MRF_OPERATOR_USER:
      state.isAccountProfileUser = false;
      state.isCurbCycleManagementUser = false;
      state.isCurbCycleDevelopmentTeamUser = false;
      state.isCurbCycleSupportTeamUser = false;
      state.isRetailSponsorUser = false;
      state.isLgaUser = false;
      state.isMrfOperatorUser = true;
      state.isMrfManagementUser = false;
      return {
        ...state,
      };
    case ACTION_USER_IS_LGA_USER:
      state.isAccountProfileUser = false;
      state.isCurbCycleManagementUser = false;
      state.isCurbCycleDevelopmentTeamUser = false;
      state.isCurbCycleSupportTeamUser = false;
      state.isRetailSponsorUser = false;
      state.isLgaUser = true;
      state.isMrfOperatorUser = false;
      state.isMrfManagementUser = false;
      return {
        ...state,
      };
    case ACTION_USER_IS_RETAIL_SPONSOR_USER:
      state.isAccountProfileUser = false;
      state.isCurbCycleManagementUser = false;
      state.isCurbCycleDevelopmentTeamUser = false;
      state.isCurbCycleSupportTeamUser = false;
      state.isRetailSponsorUser = true;
      state.isLgaUser = false;
      state.isMrfOperatorUser = false;
      state.isMrfManagementUser = false;
      return {
        ...state,
      };
    case ACTION_USER_IS_ACCOUNT_PROFILE_USER:
      state.isAccountProfileUser = true;
      state.isCurbCycleManagementUser = false;
      state.isCurbCycleDevelopmentTeamUser = false;
      state.isCurbCycleSupportTeamUser = false;
      state.isRetailSponsorUser = false;
      state.isLgaUser = false;
      state.isMrfOperatorUser = false;
      state.isMrfManagementUser = false;
      return {
        ...state,
      };
    case ACTION_USER_IS_NOT_WAITLISTED:
      state.isWaitlisted = false;
      return {
        ...state,
      };
    case ACTION_USER_IS_WAITLISTED:
      state.isWaitlisted = true;
      return {
        ...state,
      };
    case ACTION_USER_FINISHED_SIGN_UP:
      state.isFinishedSignup = true;
      return {
        ...state,
      };
    case ACTION_MRF_UPDATED_CURRENT_BATCH_PAYLOAD:
      state.journeyFlows.MrfOperations.currentBatch.payload = action.payload;
      return {
        ...state,
      };
    case ACTION_MRF_SCANNED_BAG_TO_CURRENT_BATCH:
      state.journeyFlows.MrfOperations.currentBatch.bags =
        state.journeyFlows.MrfOperations.currentBatch.bags + 1;
      state.journeyFlows.MrfOperations.currentBatch.batchDetail.bags.push(
        action.payload
      );
      return {
        ...state,
      };
    case ACTION_MRF_CONFIRMED_TAGS_RECEIVED_WITH_WEIGHT:
    case ACTION_MRF_CANCELLED_SCANNING_CURRENT_BATCH:
      state.journeyFlows.MrfOperations.currentBatch.bags = 0;
      state.journeyFlows.MrfOperations.currentBatch.batchDetail.bags = [];
      return {
        ...state,
      };
    case ACTION_MRF_ACCOUNT_INFO_RETRIEVED:
      state.journeyFlows.MrfOperations.mrf.operator_uuid =
        action.payload.mrf_operator.mrf_operator_uuid;
      state.journeyFlows.MrfOperations.mrf.uuid =
        action.payload.mrf_operator.mrf_uuid;
      state.journeyFlows.MrfOperations.mrf.manager_uuid =
        action.payload.mrf_operator.mrf_manager_uuid;
      return {
        ...state,
      };
    case ACTION_MRF_ACCOUNT_ACTIVITIES_RETRIEVED:
      state.journeyFlows.MrfOperations.mrf_operator.mrf_operator_activities =
        action.payload.data;
      return {
        ...state,
      };
    case ACTION_RESET_INITIAL_STATE:
      state = {
        appStoreLinks: {
          androidStoreLink: "",
          appleStoreLink: "",
        },
        isInitialising: true,
        isLoggedIn: false,
        isWaitlisted: false,
        isFinishedSignup: false,
        isAccountProfileUser: false,
        isCurbCycleManagementUser: false,
        isCurbCycleDevelopmentTeamUser: false,
        isCurbCycleSupportTeamUser: false,
        isMrfManagementUser: false,
        isMrfOperatorUser: false,
        isLgaUser: false,
        isRetailSponsorUser: false,
        shouldUpgradeApp: false,
        pushNotificationToken: "",
        journeyFlows: {
          CurbCycleManagement: {
            accounts: {
              fetching: false,
              accounts: {
                current_page: 1,
                data: [],
                first_page_url: null,
                from: 1,
                last_page: 1,
                last_page_url: null,
                next_page_url: null,
                path: null,
                per_page: 15,
                prev_page_url: null,
                to: 1,
                total: 1,
              },
            },
            curby_codes: {
              fetching: false,
              curby_codes: {
                current_page: 1,
                data: [],
                first_page_url: null,
                from: 1,
                last_page: 1,
                last_page_url: null,
                next_page_url: null,
                path: null,
                per_page: 15,
                prev_page_url: null,
                to: 1,
                total: 1,
              },
            },
            mrfs: {
              accessPin: null,
              addMrf: {
                saving: false,
              },
              editMrf: {
                saving: false,
              },
              addMrfManager: {
                saving: false,
              },
              editMrfManager: {
                saving: false,
              },
              addMrfOperator: {
                fetchingFirstValidMrfManagerUuid: false,
                initialManagerUuid: null,
                saving: false,
              },
              editMrfOperator: {
                saving: false,
              },
              fetching: false,
              isResettingMrfAccessPin: false,
              mrfs: {
                current_page: 1,
                data: [],
                first_page_url: null,
                from: 1,
                last_page: 1,
                last_page_url: null,
                next_page_url: null,
                path: null,
                per_page: 15,
                prev_page_url: null,
                to: 1,
                total: 1,
              },
              mrfOperators: {
                fetching: false,
                mrfOperators: {
                  current_page: 1,
                  data: [],
                  first_page_url: null,
                  from: 1,
                  last_page: 1,
                  last_page_url: null,
                  next_page_url: null,
                  path: null,
                  per_page: 15,
                  prev_page_url: null,
                  to: 1,
                  total: 1,
                },
              },
              mrfManagers: {
                fetching: false,
                mrfManagers: {
                  current_page: 1,
                  data: [],
                  first_page_url: null,
                  from: 1,
                  last_page: 1,
                  last_page_url: null,
                  next_page_url: null,
                  path: null,
                  per_page: 15,
                  prev_page_url: null,
                  to: 1,
                  total: 1,
                },
              },
            },
            retailSponsors: {
              addRetailSponsor: {
                saving: false,
              },
              editRetailSponsor: {
                saving: false,
              },
              addRetailSponsorManager: {
                saving: false,
              },
              editRetailSponsorManager: {
                saving: false,
              },
              fetching: false,
              retailSponsors: {
                current_page: 1,
                data: [],
                first_page_url: null,
                from: 1,
                last_page: 1,
                last_page_url: null,
                next_page_url: null,
                path: null,
                per_page: 15,
                prev_page_url: null,
                to: 1,
                total: 1,
              },
              retailSponsorManagers: {
                fetching: false,
                retailSponsorManagers: {
                  current_page: 1,
                  data: [],
                  first_page_url: null,
                  from: 1,
                  last_page: 1,
                  last_page_url: null,
                  next_page_url: null,
                  path: null,
                  per_page: 15,
                  prev_page_url: null,
                  to: 1,
                  total: 1,
                },
              },
            },
            lgas: {
              addLGA: {
                saving: false,
              },
              editLGA: {
                saving: false,
              },
              addLGAManager: {
                saving: false,
              },
              editLGAManager: {
                saving: false,
              },
              fetching: false,
              lgas: {
                current_page: 1,
                data: [],
                first_page_url: null,
                from: 1,
                last_page: 1,
                last_page_url: null,
                next_page_url: null,
                path: null,
                per_page: 15,
                prev_page_url: null,
                to: 1,
                total: 1,
              },
              lgaManagers: {
                fetching: false,
                lgaManagers: {
                  current_page: 1,
                  data: [],
                  first_page_url: null,
                  from: 1,
                  last_page: 1,
                  last_page_url: null,
                  next_page_url: null,
                  path: null,
                  per_page: 15,
                  prev_page_url: null,
                  to: 1,
                  total: 1,
                },
              },
            },
            scannedMrfBatchDataCollection: {},
            managementAnalytics: {
              fetching: false,
              user_activity: {
                scans_using_curby_app: {
                  most_recent_scan: {},
                  total: 0,
                  top_5_accounts: [],
                  scans_by_date: [],
                },
              },
              total_signups: 0,
              total_waitlisted: 0,
              total_completed_signup: 0,
              total_users_who_scanned_within_last_14_days: 0,
              total_users_who_scanned_within_last_28_days: 0,
              total_users_who_scanned_more_than_28_days_ago: 0,
              full_scan_data: {
                individual_dates_min_to_max: [],
                total: 0,
                scans_by_date: {},
              },
              scans_over_last_24hrs: {
                total: 0,
                data: [],
              },
              new_account_registrations_last_24hrs: {
                total: 0,
                data: [],
              },
              participation_rate_view_data: [],
            },
          },
          MrfManagement: {
            curby_codes: {
              fetching: false,
              curby_codes: {
                current_page: 1,
                data: [],
                first_page_url: null,
                from: 1,
                last_page: 1,
                last_page_url: null,
                next_page_url: null,
                path: null,
                per_page: 15,
                prev_page_url: null,
                to: 1,
                total: 1,
              },
            },
            mrfs: {
              accessPin: null,
              addMrf: {
                saving: false,
              },
              editMrf: {
                saving: false,
              },
              addMrfManager: {
                saving: false,
              },
              editMrfManager: {
                saving: false,
              },
              addMrfOperator: {
                fetchingFirstValidMrfManagerUuid: false,
                initialManagerUuid: null,
                saving: false,
              },
              editMrfOperator: {
                saving: false,
              },
              fetching: false,
              isResettingMrfAccessPin: false,
              mrfs: {
                current_page: 1,
                data: [],
                first_page_url: null,
                from: 1,
                last_page: 1,
                last_page_url: null,
                next_page_url: null,
                path: null,
                per_page: 15,
                prev_page_url: null,
                to: 1,
                total: 1,
              },
              mrfOperators: {
                fetching: false,
                mrfOperators: {
                  current_page: 1,
                  data: [],
                  first_page_url: null,
                  from: 1,
                  last_page: 1,
                  last_page_url: null,
                  next_page_url: null,
                  path: null,
                  per_page: 15,
                  prev_page_url: null,
                  to: 1,
                  total: 1,
                },
              },
              mrfManagers: {
                fetching: false,
                mrfManagers: {
                  current_page: 1,
                  data: [],
                  first_page_url: null,
                  from: 1,
                  last_page: 1,
                  last_page_url: null,
                  next_page_url: null,
                  path: null,
                  per_page: 15,
                  prev_page_url: null,
                  to: 1,
                  total: 1,
                },
              },
            },
            scannedMrfBatchDataCollection: {},
            managementAnalytics: {
              fetching: false,
              scans_over_last_24hrs: {
                total: 0,
                top_5_accounts: [],
                data: [],
              },
              new_account_registrations_last_24hrs: {
                total: 0,
                data: [],
              },
              participation_rate_view_data: [],
            },
          },
          MrfOperations: {
            mrf: {
              uuid: "",
              operator_uuid: "",
              manager_uuid: "",
            },
            mrf_operator: {
              mrf_operator_activities: [],
            },
            currentBatch: {
              payload: {},
              allowedToSkipWeightEntry: {
                allowed: false,
                reason: "",
              },
              bags: 0,
              weight_in_g: 0,
              batchDetail: {
                bags: [],
              },
            },
          },
          RetailSponsor: {
            analytics: {
              fetching: false,
              min_date_unix: 0,
              max_date_unix: 0,
              total_bales_scanned_in: 0,
              total_bales_scanned_in_for_dates: {},
              total_weight_of_bales_scanned_in: 0,
              tonnage_offset_data_frame: [],
              average_bag_weight: 0,
              total_bags_scanned: 0,
              total_bags_scanned_for_dates: {},
              total_bags_scanned_by_postcode_sorted_output: {},
              total_bags_scanned_by_postcode_for_dates: {},
              individual_dates_min_to_max: [],
              participation: {},
              d: {},
            },
          },
          Lga: {
            analytics: {
              fetching: false,
              min_date_unix: 0,
              max_date_unix: 0,
              total_bales_scanned_in: 0,
              total_bales_scanned_in_for_dates: {},
              total_weight_of_bales_scanned_in: 0,
              tonnage_offset_data_frame: [],
              average_bag_weight: 0,
              total_bags_scanned: 0,
              total_bags_scanned_for_dates: {},
              total_bags_scanned_by_postcode_sorted_output: {},
              total_bags_scanned_by_postcode_for_dates: {},
              individual_dates_min_to_max: [],
              participation: {},
              d: {},
            },
          },
        },
        activeProfile: {
          uuid: "",
          name: "",
          avatar: "curby",
        },
        // will fetch updated values for this from the backend on initialisation to allow for overrides without releasing a new version
        appRules: {
          // if a newer version is available based on data returned by the backend we can prompt the user to update the app
          currentAppVersion: "0.0.1",
          // account profile rules
          accountProfile: {
            maxAllowedProfiles: 5,
          },
          // intending to initialise these values as required based on user type successfully authenticating
          mrfOperations: {},
          lgaOperations: {},
          retailSponsorOperations: {},
          managementOperations: {},
        },
        backgroundProcessing: {
          processing: false,
        },
        backgroundProcessingMessage: {
          message: "",
        },
        backgroundProcessingFailureMessage: {
          message: "",
        },
        backgroundProcessingFailure: {
          failed: false,
        },
        backgroundProcessingShouldRetry: {
          accountProfileEditProfile: false,
          accountCreationUsingEmail: false,
          accountCreationUpdateAccountName: false,
          accountCreationUpdateName: false,
          accountCreationAddProfiles: false,
          accountCreationAddReminders: false,
          accountCreationAddAddress: false,
          accountCreationAddAddressLgaParticipation: false,
          loginRequestingNewOtpToEmail: false,
          loginRespondingToOtpChallenge: false,
        },
        journey: {
          experience: {
            journey: null,
          },
        },
        auth: {
          aclGroups: [null],
          idToken: "",
          token: "",
        },
        authRequired: {
          authRequired: false,
          redirectOnSuccessTo: "",
        },
        accountData: {
          email: "",
        },
        tempAccountName: {
          name: "",
          first_name: "",
          last_name: "",
        },
        tempAddress: {
          formatted_address: "",
          address_components: "",
          point_coordinate_lat: "",
          point_coordinate_long: "",
        },
        tempEmail: {
          email: "",
        },
        waitlist: {
          waitlistStatusHasBeenVerified: false,
          shouldBeOnWaitlist: true,
        },
      };
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default rootReducer;
