import * as React from 'react';
import PropTypes from "prop-types";

import Map from './AwesomeMapComponent/Map';

const AwesomeMapComponent = (
  {
    awesomeAnalyticsData
  }
) => {
  
  return (
    <div>
      <Map
        awesomeAnalyticsData={awesomeAnalyticsData}
      />
    </div>
  )
};

AwesomeMapComponent.propTypes = {
  awesomeAnalyticsData: PropTypes.shape(PropTypes.any)
}

export default AwesomeMapComponent;