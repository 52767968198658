
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, connect } from "react-redux";
// thunks
import fetchAnalyticsThunk from "../../../../thunks/RetailSponsor/fetchAnalyticsThunk";
import fetchManagementAnalyticsThunk from "../../../../thunks/Management/Analytics/fetchAnalyticsThunk";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
// core components
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader.js";
import CardBody from "../../../../components/Card/CardBody.js";
import BaleBarGraph from "../../../../components/DashboardComponents/BaleBarGraph.js";
import CCScanBarGraph from "../../../../components/DashboardComponents/CCScanBarGraph.js";
import CCBarGraph from "../../../../components/DashboardComponents/CCBarGraph.js";
import AccountScanNumbersBarGraph from "../../../../components/DashboardComponents/AccountScanNumbersBarGraph.js";
import SignupsByProgramBarGraph from "../../../../components/DashboardComponents/SignupsByProgramBarGraph.js";
import ManualEntryReasonsPieChart from "./../../RetailSponsor/layouts/components/Charts/ManualEntryReasonsPieChart";
import NumberOfBagsReceivedPerDay from "./../../RetailSponsor/layouts/components/Charts/NumberOfBagsReceivedPerDay";
import MvpTrialParticipation from "./../../RetailSponsor/layouts/components/Charts/MvpTrialParticipation";
import TonnageTargetVsTime from "./../../RetailSponsor/layouts/components/Charts/TonnageTargetVsTime";
import BigNumberProgressBar from "./../../RetailSponsor/layouts/components/Charts/BigNumber/BigNumberProgressBar";
import PostMvpParticipationMap from "./../../../../components/DashboardComponents/Map/AwesomeMapComponent/PostMvpParticipationMap";
import InteractivityIndicator from "./../../../../components/DashboardComponents/InteractivityIndicator";
import AverageMonthlyBagCollectionByPostcode from "./../../RetailSponsor/layouts/components/Charts/AverageMonthlyBagCollectionByPostcode";
import NumberOfTrialSignUpsByPostcode from "./../../RetailSponsor/layouts/components/Charts/NumberOfTrialSignUpsByPostcode";
import BaleWeightScannedInPerDayWithBalePhotoModalController from "./../../RetailSponsor/layouts/components/Charts/BaleWeightScannedInPerDayWithBalePhotoModalController";
import ScansOutsideTheAppPerDayModalController from "./components/Charts/ScansOutsideTheAppPerDayModalController";
import MostRecentScanWidget from "./components/MostRecentScanWidget";
import Top5AccountsWidget from "./components/Top5AccountsWidget";
import ParticipationRatesChart from "./components/Charts/ParticipationRates/ParticipationRatesChart";
import WhereDidThePlasticGo from "./../../../../components/DashboardComponents/WhereDidThePlasticGo/WhereDidThePlasticGo";
import { getMyMrfProductStewardshipAnalytics, getStewardshipData, getStewardshipBaleData, getStewardshipCurbyData, getManagementQuicksightUrl } from "services/CurbCycleApi";
import Chart from 'react-google-charts';
import {MenuItem, Dropdown, DropdownButton} from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import Overview from "./Overview";
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import QSDashboard from "components/DashboardComponents/Dashboard.js";

const balesChartTooltipImage = require("./../../../../components/DashboardComponents/clickableBarImage.png");

function Dashboard({
  fetching,
  new_accounts,
  scans,
  user_activity,
  total_signups,
  total_completed_signup,
  total_waitlisted,
  total_users_who_scanned_within_last_14_days,
  total_users_who_scanned_within_last_28_days,
  total_users_who_scanned_more_than_28_days_ago,
  full_scan_data,
  fetchingAnalytics,
  min_date_unix,
  max_date_unix,
  total_bales_scanned_in,
  total_bales_scanned_in_for_dates,
  total_weight_of_bales_scanned_in,
  tonnage_offset_data_frame,
  average_bag_weight,
  total_bags_scanned,
  total_bags_scanned_for_dates,
  total_bags_scanned_by_postcode_sorted_output,
  total_bags_scanned_by_postcode_for_dates,
  individual_dates_min_to_max,
  participation,
  participation_rate_view_data,
  average_bale_condition_score,
  total_bags_manually_entered_for_dates,
  d,
}) {
  function calculateBagsScannedThisMonth() {
    let total = 0;
    let theDate = new Date().getMonth() + 1;
    for (let prop in total_bags_scanned_for_dates) {
      // console.log(theDate, prop, prop.substring(0,3), `0${theDate}/`);
      if (prop.substring(0, 3) === `0${theDate}/`) {
        total = total + total_bags_scanned_for_dates[prop];
      }
      // console.log(theDate, prop, () );
    }
    return total;
  }
  const [selectedProgram, setSelectedProgram] = useState(0);
  const handleChangeProgram = (event) => {
    setSelectedProgram(event.target.value);
  };
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  function handleChangePeriod(item) {
    setSelectedPeriod(item);
  };
  const [selectedDataAggregation, setSelectedDataAggregation] = useState(0);
  function handleChangeDataAggregation(item) {
    setSelectedDataAggregation(item);
  };
  const [analyticsData, setAnalyticsData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [monthlyData, setMonthlyData] = useState(null);
  const [dashUrl, setDashUrl] = useState(null);
  const dispatch = useDispatch();
  let targetOfTotalProductionPercentage = "10";
  const [postMvpLgaMapDataValue, setPostMvpLgaMapDataValue] = useState(null);
  const [theParticipationData, setTheParticipationData] = useState(null);
  const [theRelevantDataObject, setTheRelevantDataObject] = useState(null);
  const [
    thePostMvpParticipationData,
    setThePostMvpParticipationData,
  ] = useState(null);
  const [
    thePostMvpRelevantDataObject,
    setThePostMvpRelevantDataObject,
  ] = useState(null);

  let totalActualParticipants =
    undefined !== participation[0]
      ? participation[0].total_actual_participants
      : 0;
  // let howManyParticipatedOnce =
  //   undefined !== participation[0]
  //     ? undefined !== participation[0].stats.stats["1"]
  //       ? participation[0].stats.stats["1"].length
  //       : 0
  //     : 0;
  // let howManyBagsScannedThisMonth =
  //   undefined !== total_bags_scanned_for_dates
  //     ? calculateBagsScannedThisMonth()
  //     : 0;

  // let totalScansOutsideTheApp =
  //   undefined !== full_scan_data.total
  //     ? full_scan_data.total.toLocaleString(undefined, {
  //         maximumFractionDigits: 2,
  //       })
  //     : 0;
  // let datesMinToMax =
  //   undefined !== full_scan_data.individual_dates_min_to_max
  //     ? full_scan_data.individual_dates_min_to_max
  //     : [];
  // let scansByDate =
  //   undefined !== full_scan_data.scans_by_date
  //     ? full_scan_data.scans_by_date
  //     : {};
  // let scannedVsReceived =
  //   undefined === user_activity.scans_using_curby_app.scanned_vs_received
  //     ? { total_scanned: 0, total_scanned_received: 0 }
  //     : user_activity.scans_using_curby_app.scanned_vs_received[0];
  // let mrfManualEntries =
  //   undefined === user_activity.scans_using_curby_app.mrf_manual_entries
  //     ? { total_scanned: 0, total_scanned_received: 0 }
  //     : user_activity.scans_using_curby_app.mrf_manual_entries[0];

  // useEffect(() => {
  //   dispatch(fetchAnalyticsThunk());
  //   // dispatch(fetchManagementAnalyticsThunk());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (fetchingAnalytics === false) {
  //     // relevant data object preparation (thePostMvpRelevantDataObject)
  //     let postMvpArrayValueToSet = [];
  //     let postMvpObjectValueToSet = {};

  //     if (undefined !== d) {
  //       if (undefined !== d.d2) {
  //         if (undefined !== d.d2.postcodes) {
  //           Object.keys(d.d2.postcodes).forEach((item, index) => {
  //             if ("" !== item) {
  //               let weightInG =
  //                 undefined !== d.d2.postcodes[item]
  //                   ? d.d2.postcodes[item].total
  //                   : 0;
  //               let bagsCollected =
  //                 undefined !== d.d2.postcodes[item]
  //                   ? d.d2.postcodes[item].total
  //                   : 0;

  //               let objectToPush = {
  //                 postcode: parseInt(item),
  //                 lga_name: "Blah",
  //                 weight_in_g: weightInG * average_bag_weight,
  //                 bags_collected: bagsCollected,
  //                 participation_rate: 0,
  //                 sign_ups: 0,
  //                 units_string: `${Math.floor(
  //                   (total_bags_scanned_by_postcode_sorted_output[item] *
  //                     average_bag_weight) /
  //                     0.77
  //                 ).toLocaleString(undefined, {
  //                   style: "decimal",
  //                   maximumFractionDigits: 1,
  //                 })} wrappers of a 45g KitKat bar`,
  //               };

  //               postMvpArrayValueToSet.push(objectToPush);
  //               postMvpObjectValueToSet[item] = objectToPush;
  //               // console.log({
  //               //   objectToPush: objectToPush
  //               // });
  //             } else {
  //               // console.log({
  //               //   'here': 'here'
  //               // })
  //             }
  //           });
  //           setThePostMvpParticipationData(postMvpArrayValueToSet);
  //           setThePostMvpRelevantDataObject(postMvpObjectValueToSet);
  //         }
  //       }
  //     }

  //     // relevant data object preparation (theRelevantDataObject)
  //     let arrayValueToSet = [];
  //     let objectValueToSet = {};

  //     Object.keys(total_bags_scanned_by_postcode_sorted_output).forEach(
  //       (item, index) => {
  //         if ("" !== item) {
  //           // console.log(item);

  //           let participationRate = 0;
  //           let signUps = 0;

  //           if (undefined !== participation[1]) {
  //             participationRate = participation[1][item].participation_rate;
  //           }

  //           if (undefined !== participation[1]) {
  //             signUps = participation[1][item].sign_ups;
  //           }

  //           let objectToPush = {
  //             postcode: parseInt(item),
  //             lga_name: "Central Coast Council",
  //             weight_in_g:
  //               total_bags_scanned_by_postcode_sorted_output[item] *
  //               average_bag_weight,
  //             bags_collected:
  //               total_bags_scanned_by_postcode_sorted_output[item],
  //             participation_rate: participationRate,
  //             sign_ups: signUps,
  //             units_string: `${Math.floor(
  //               (total_bags_scanned_by_postcode_sorted_output[item] *
  //                 average_bag_weight) /
  //                 0.77
  //             ).toLocaleString(undefined, {
  //               style: "decimal",
  //               maximumFractionDigits: 1,
  //             })} wrappers of a 45g KitKat bar`,
  //           };

  //           arrayValueToSet.push(objectToPush);
  //           objectValueToSet[item] = objectToPush;
  //           // console.log({
  //           //   objectToPush: objectToPush
  //           // });
  //         } else {
  //           // console.log({
  //           //   'here': 'here'
  //           // })
  //         }
  //       }
  //     );

  //     setTheParticipationData(arrayValueToSet);
  //     setTheRelevantDataObject(objectValueToSet);
  //   }
  // }, [fetchingAnalytics]);

  // var options = {};

  useEffect(() => {
    // getStewardshipBaleData().then((data) => {
    //   data.sort(function(a,b){
    //     let d1 = new Date(a['month'])
    //     let d2 = new Date(b['month'])
    //     return d1.getTime() - d2.getTime()});
    //   var cumulative_weight = 0
    //   var summary_data = {}
    //   for (let i = 0; i < data.length; i++) {
    //     for (const [key, value] of Object.entries(data[i])) {
    //       if (key == 'month'){
    //         continue
    //       }
    //       if (key == 'total_weight'){
    //         cumulative_weight = cumulative_weight + value;
    //         data[i]['cumulative_weight'] = cumulative_weight;
    //       }
    //       if (key in summary_data){
    //         summary_data[key] = summary_data[key] + value;
    //       } else {
    //         summary_data[key] = value;
    //       }
    //     }
    //   }
    //   console.log(data);
    //   setMonthlyData(data);
    // });
    // getStewardshipCurbyData().then((data) => {
    //   console.log(data);
    //   setSummaryData(data[0]);
    // });
    
    getManagementQuicksightUrl().then((data) => {
      console.log(data)
      console.log(data.EmbedUrl)
      setDashUrl(data.EmbedUrl);
  
})
    // getStewardshipData().then((data) => {
    //   setAnalyticsData(data);
    //   console.log(data);
    //   console.log(data.time_12m.manual_entry_reasons)
    // });
    // dispatch(fetchManagementAnalyticsThunk());
  }, []);
//   if (!analyticsData || !monthlyData || !summaryData || !dashUrl) {
//     return (
//       <div>
//         <h1>Speaking with the Bilbies...</h1>
//       </div>
//     );
//   } else {
    
   
//   setTimeout(function(){
//     options = {
//       url: dashUrl,
//       container: document.getElementById("embeddingContainer"),
//       scrolling: "no",
//       height: "900vh",
//       iframeResizeOnSheetChange: false, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
//       width: "90%",
//       locale: "en-US",
//       footerPaddingEnabled: true,
//       sheetTabsDisabled: false, // use this option to enable or disable sheet tab controls in dashboard embedding
//       printEnabled: false, // use this option to enable or disable print option for dashboard embedding
//       undoRedoDisabled: true, // set this option to true to disable undo and redo buttons for dashboard embedding
//       resetDisabled: true, // set this option to true to disable reset button for dashboard embedding
//   };

//     embedDashboard(options);
// }, 20);
//   //const dashboard = 
//   }

  return (
    <div>
      <Fade in={true} timeout={3000}>
        <GridContainer
          style={{
            width: "100%",
            height: "100%",
            textAlign: "center",
          }}
          id="productStewardshipHeadingBox"
        > 
        
          
          <GridItem xs={12} sm={12} md={12}
          style={{textAlign: "center"}}>
            <h1>Management Dashboard</h1>
          
          <div id="embeddingContainer"
          ></div>
          <QSDashboard dashUrl={dashUrl}/>
            

            <GridContainer>
              
              <GridItem
                xs={11}
                sm={11}
                md={11}
                style={{
                  marginLeft: "5rem",
                }}
              >
                <GridContainer
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                </GridContainer>
              </GridItem>
              
            </GridContainer>

          {/* <Overview analyticsData={analyticsData} summaryAnalyticsData={summaryData} monthlyAnalyticsData={monthlyData}/> */}
        </GridItem>
        </GridContainer>
            

            
      </Fade>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user_activity:
      state.journeyFlows.CurbCycleManagement.managementAnalytics.user_activity,
    total_signups:
      state.journeyFlows.CurbCycleManagement.managementAnalytics.total_signups,
    total_completed_signup:
      state.journeyFlows.CurbCycleManagement.managementAnalytics
        .total_completed_signup,
    total_waitlisted:
      state.journeyFlows.CurbCycleManagement.managementAnalytics
        .total_waitlisted,
    total_users_who_scanned_within_last_14_days:
      state.journeyFlows.CurbCycleManagement.managementAnalytics
        .total_users_who_scanned_within_last_14_days,
    total_users_who_scanned_within_last_28_days:
      state.journeyFlows.CurbCycleManagement.managementAnalytics
        .total_users_who_scanned_within_last_28_days,
    total_users_who_scanned_more_than_28_days_ago:
      state.journeyFlows.CurbCycleManagement.managementAnalytics
        .total_users_who_scanned_more_than_28_days_ago,
    new_accounts:
      state.journeyFlows.CurbCycleManagement.managementAnalytics
        .new_account_registrations_last_24hrs.total,
    scans:
      state.journeyFlows.CurbCycleManagement.managementAnalytics
        .scans_over_last_24hrs.total,
    full_scan_data:
      state.journeyFlows.CurbCycleManagement.managementAnalytics.full_scan_data,
    fetching:
      state.journeyFlows.CurbCycleManagement.managementAnalytics.fetching,
    fetchingAnalytics: state.journeyFlows.RetailSponsor.analytics.fetching,
    min_date_unix: state.journeyFlows.RetailSponsor.analytics.min_date_unix,
    max_date_unix: state.journeyFlows.RetailSponsor.analytics.max_date_unix,
    total_bales_scanned_in:
      state.journeyFlows.RetailSponsor.analytics.total_bales_scanned_in,
    total_bales_scanned_in_for_dates:
      state.journeyFlows.RetailSponsor.analytics
        .total_bales_scanned_in_for_dates,
    total_weight_of_bales_scanned_in:
      state.journeyFlows.RetailSponsor.analytics
        .total_weight_of_bales_scanned_in,
    tonnage_offset_data_frame:
      state.journeyFlows.RetailSponsor.analytics.tonnage_offset_data_frame,
    average_bag_weight:
      state.journeyFlows.RetailSponsor.analytics.average_bag_weight,
    total_bags_scanned:
      state.journeyFlows.RetailSponsor.analytics.total_bags_scanned,
    total_bags_scanned_for_dates:
      state.journeyFlows.RetailSponsor.analytics.total_bags_scanned_for_dates,
    total_bags_manually_entered_for_dates:
      state.journeyFlows.RetailSponsor.analytics
        .total_bags_manually_entered_for_dates,
    total_bags_scanned_by_postcode_sorted_output:
      state.journeyFlows.RetailSponsor.analytics
        .total_bags_scanned_by_postcode_sorted_output,
    total_bags_scanned_by_postcode_for_dates:
      state.journeyFlows.RetailSponsor.analytics
        .total_bags_scanned_by_postcode_for_dates,
    average_bale_condition_score:
      state.journeyFlows.RetailSponsor.analytics.average_bale_condition_score,
    individual_dates_min_to_max:
      state.journeyFlows.RetailSponsor.analytics.individual_dates_min_to_max,
    participation: state.journeyFlows.RetailSponsor.analytics.participation,
    participation_rate_view_data:
      state.journeyFlows.CurbCycleManagement.managementAnalytics
        .participation_rate_view_data,
    d: state.journeyFlows.RetailSponsor.analytics.d,
  };
}

Dashboard.propTypes = {
  fetching: PropTypes.bool.isRequired,
  fetchingAnalytics: PropTypes.bool.isRequired,
  new_accounts: PropTypes.any,
  scans: PropTypes.any,
  user_activity: PropTypes.any,
  total_signups: PropTypes.any,
  total_completed_signup: PropTypes.any,
  total_waitlisted: PropTypes.any,
  total_users_who_scanned_within_last_14_days: PropTypes.any,
  total_users_who_scanned_within_last_28_days: PropTypes.any,
  total_users_who_scanned_more_than_28_days_ago: PropTypes.any,
  full_scan_data: PropTypes.any,
  min_date_unix: PropTypes.number,
  max_date_unix: PropTypes.number,
  total_bales_scanned_in: PropTypes.any,
  tonnage_offset_data_frame: PropTypes.any,
  total_weight_of_bales_scanned_in: PropTypes.number.isRequired,
  individual_dates_min_to_max: PropTypes.arrayOf(PropTypes.any).isRequired,
  average_bag_weight: PropTypes.number.isRequired,
  total_bags_scanned_by_postcode_sorted_output: PropTypes.arrayOf(PropTypes.any)
    .isRequired,
  total_bags_manually_entered_for_dates: PropTypes.number.isRequired,
  total_bales_scanned_in_for_dates: PropTypes.arrayOf(
    PropTypes.shape({ total_weight_of_bales: PropTypes.number })
  ).isRequired,
  total_bags_scanned_for_dates: PropTypes.arrayOf(PropTypes.any.isRequired)
    .isRequired,
  total_bags_scanned: PropTypes.number.isRequired,
  total_bags_scanned_by_postcode_for_dates: PropTypes.number.isRequired,
  d: PropTypes.shape({
    d2: PropTypes.shape({
      postcodes: PropTypes.arrayOf(
        PropTypes.shape({
          total: PropTypes.number,
        })
      ),
    }),
  }),
  participation: PropTypes.arrayOf({
    participation_rate: PropTypes.any,
    sign_ups: PropTypes.any,
  }),
  participation_rate_view_data: PropTypes.any,
  average_bale_condition_score: PropTypes.any,
};

export default connect(mapStateToProps)(Dashboard);
