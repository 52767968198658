import {
  managementRequestedRetailSponsors,
  managementReceivedRetailSponsors,
} from "../../../../redux/reducer";

import paginationHelper from '../../../../utilities/paginationHelper';

import { getAllRetailSponsors } from '../../../../services/CurbCycleApi';

export const pageChangeRetailSponsorsWithFiltersThunk = (filtersAsQueryString) => dispatch => {
  dispatch(managementRequestedRetailSponsors());
  getAllRetailSponsors(filtersAsQueryString)
    .then(result => {
      dispatch(managementReceivedRetailSponsors(result));
    })
    .catch(() => {
      dispatch(
        managementReceivedRetailSponsors(
          paginationHelper.getDefaultCollectionShape()
        )
      );
    });
};

export default pageChangeRetailSponsorsWithFiltersThunk;
