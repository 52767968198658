module.exports = {
  component: 'vfb-mb-form-element-contact-number-controller',
  elementPrefix: '',
  name: "MB: Contact number element controller - single field splits into constituent parts (can choose whether local or mobile)",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'element_name_prefix',
      value: 'element_name_prefix'
    },
    {
      type: 'literal',
      variable: 'input_field_label',
      value: 'Preferred contact number'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'business_contact_preferred_contact_number'
    },
    {
      type: 'literal',
      variable: 'force_number_type',
      value: 'n/a'
    },
    {
      type: 'literal',
      variable: 'pre_text',
      value: ''
    },
    {
      type: 'literal',
      variable: 'post_text',
      value: ''
    },
    {
      type: 'literal',
      variable: 'tooltip_content',
      value: ''
    }
  ]
}
