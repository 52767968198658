import React from "react";
import PropTypes from "prop-types";
// import cx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import ParallaxSlide from "@mui-treasury/components/slide/parallax";
// import DotIndicator from '@mui-treasury/components/indicator/dot';
// import { useArrowDarkButtonStyles } from "@mui-treasury/styles/button/arrowDark";

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    // a must if you want to set arrows, indicator as absolute
    position: "relative",
    width: "100%",
  },
  slide: {
    perspective: 1000, // create perspective
    overflow: "hidden",
    // relative is a must if you want to create overlapping layers in children
    position: "relative",
    paddingTop: spacing(8),
    [breakpoints.up("sm")]: {
      paddingTop: spacing(10),
    },
    [breakpoints.up("md")]: {
      paddingTop: spacing(14),
    },
  },
  imageContainer: {
    display: "block",
    position: "relative",
    zIndex: 2,
    paddingBottom: "56.25%",
  },
  image: {
    display: "block",
    position: "absolute",
    zIndex: 10,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    marginLeft: "12%",
    [breakpoints.up("sm")]: {
      marginLeft: "4%",
    },
  },
  arrow: {
    display: "none",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    [breakpoints.up("sm")]: {
      display: "inline-flex",
    },
  },
  arrowLeft: {
    left: 0,
    [breakpoints.up("lg")]: {
      left: -64,
    },
  },
  arrowRight: {
    right: 0,
    [breakpoints.up("lg")]: {
      right: -64,
    },
  },
  text: {
    // shared style for text-top and text-bottom
    fontFamily: "Poppins, san-serif",
    fontWeight: 900,
    position: "absolute",
    color: palette.common.white,
    padding: "0 8px",
    transform: "rotateY(45deg)",
    lineHeight: 1.2,
    [breakpoints.up("sm")]: {
      padding: "0 16px",
    },
    [breakpoints.up("md")]: {
      padding: "0 24px",
    },
  },
  title: {
    top: 20,
    left: "20%",
    height: "40%",
    fontSize: 40,
    zIndex: 1,
    background: "linear-gradient(0deg, rgba(255,255,255,0) 0%, #9c9c9c 100%)",
    [breakpoints.up("sm")]: {
      top: 40,
      fontSize: 72,
    },
    [breakpoints.up("md")]: {
      top: 52,
      fontSize: 72,
    },
  },
  subtitle: {
    top: 60,
    left: "0%",
    height: "52%",
    fontSize: 56,
    zIndex: 2,
    background: "linear-gradient(0deg, rgba(255,255,255,0) 0%, #888888 100%)",
    [breakpoints.up("sm")]: {
      top: 112,
      left: "6%",
      fontSize: 96,
    },
    [breakpoints.up("md")]: {
      top: 128,
      fontSize: 104,
    },
  },
  indicatorContainer: {
    textAlign: "center",
  },
}));

const Carousel = ({ dataMapCallback, data }) => {
  const classes = useStyles();
  // const arrowStyles = useArrowDarkButtonStyles();
  // const createStyle = (slideIndex, fineIndex) => {
  //   const diff = slideIndex - fineIndex;
  //   if (Math.abs(diff) > 1) return {};
  //   return {
  //     transform: `rotateY(${(-diff + 1) * 45}deg)`,
  //   };
  // };
  // eslint-disable-next-line react/prop-types
  const renderElements = ({ index, onChangeIndex }) => (
    <div key={Math.random()} className={classes.indicatorContainer}>
      {data.map(({ id }, i) => (
        <span
          key={Math.random()}
          active={(i === index ? "true" : "false").toString()}
          onClick={() => onChangeIndex(i)}
          style={{ margin: "0.5em", fontWeight: i === index ? 500 : 300 }}
        >
          {i + 1}
        </span>
      ))}
    </div>
  );

  return (
    <div className={classes.root}>
      <ParallaxSlide renderElements={renderElements}>
        {dataMapCallback}
      </ParallaxSlide>
    </div>
  );
};

Carousel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  dataMapCallback: PropTypes.func,
};

export default Carousel;
