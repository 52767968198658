import {
  managementRequestedCurbyCodes,
  managementReceivedCurbyCodes,
} from "../../../../redux/reducer";

import paginationHelper from '../../../../utilities/paginationHelper';

import { getAllCurbyCodes } from '../../../../services/CurbCycleApi';

export const pageChangeCurbyCodesWithFiltersThunk = (filtersAsQueryString) => dispatch => {
  dispatch(managementRequestedCurbyCodes());
  getAllCurbyCodes(filtersAsQueryString)
    .then(result => {
      dispatch(managementReceivedCurbyCodes(result));
    })
    .catch(() => {
      dispatch(
        managementReceivedCurbyCodes(
          paginationHelper.getDefaultCollectionShape()
        )
      );
    });
};

export default pageChangeCurbyCodesWithFiltersThunk;
