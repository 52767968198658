/*!
 
 =========================================================
 * Material Dashboard React - v1.9.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2020 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)
 
 * Coded by Creative Tim
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 
 */
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import MRF from "@material-ui/icons/Business";
import People from "@material-ui/icons/People";
import LibraryAdd from "@material-ui/icons/LibraryAdd";
import Settings from "@material-ui/icons/Settings";
import CodeOutlined from "@material-ui/icons/CodeOutlined";
import LGA from "@material-ui/icons/Map";
import VpnKey from "@material-ui/icons/VpnKey";
import NoteAdd from "@material-ui/icons/NoteAdd";
import CallSplit from "@material-ui/icons/CallSplit";
import LocationOn from "@material-ui/icons/LocationOn";
import Battery20 from "@material-ui/icons/Battery20";
import AddLocation from "@material-ui/icons/AddLocation";
import EditLocation from "@material-ui/icons/EditLocation";

const dashboardRoutes = [
  {
    path: "/management-analytics",
    name: "Dashboard",
    icon: Dashboard,
    layout: "/management",
    show: true,
  },
  {
    path: "/mrf-management-home",
    name: "SMRF Management",
    icon: MRF,
    layout: "/management",
    show: true,
  },
  {
    path: "/mrf-management/add",
    name: "SMRF Management - Add SMRF",
    icon: MRF,
    layout: "/management",
    show: false,
  },
  {
    path: "/mrf-management/edit/:uuid",
    name: "SMRF Management - Edit SMRF",
    icon: MRF,
    layout: "/management",
    show: false,
  },
  {
    path: "/mrf-management/mrf/:uuid/people",
    name: "SMRF Management - People",
    icon: MRF,
    layout: "/management",
    show: false,
  },
  {
    path: "/mrf-management/mrf/:uuid/security",
    name: "SMRF Management - Security",
    icon: MRF,
    layout: "/management",
    show: false,
  },
  {
    path: "/mrf-management/mrf/:uuid/add-mrf-manager",
    name: "MRF Management - People - Add SMRF Manager",
    icon: MRF,
    layout: "/management",
    show: false,
  },
  {
    path: "/mrf-management/mrf/:uuid/add-mrf-operator",
    name: "SMRF Management - People - Add SMRF Operator",
    icon: MRF,
    layout: "/management",
    show: false,
  },
  {
    path: "/mrf-management/mrf/:uuid/edit-mrf-manager",
    name: "SMRF Management - People - Edit SMRF Manager",
    icon: MRF,
    layout: "/management",
    show: false,
  },
  {
    path: "/mrf-management/mrf/:uuid/edit-mrf-operator",
    name: "SMRF Management - People - Edit SMRF Operator",
    icon: MRF,
    layout: "/management",
    show: false,
  },
  {
    path: "/curby-codes",
    name: "Curby Codes",
    icon: CodeOutlined,
    layout: "/management",
    show: true,
  },
  // accounts
  {
    path: "/accounts",
    name: "Accounts",
    icon: People,
    layout: "/management",
    show: true,
  },
  {
    path: "/account-summary/:uuid",
    name: "Accounts",
    icon: People,
    layout: "/management",
    show: false,
  },
  {
    path: "/account-crm-integration-settings/:uuid",
    name: "Accounts",
    icon: People,
    layout: "/management",
    show: false,
  },
  // {
  //   path: "/rule-engine",
  //   name: "Rule Engine",
  //   icon: Settings,
  //   component: RuleEngineUi,
  //   layout: "/management",
  //   show: true
  // },
  {
    path: "/content-builder",
    name: "Content Builder",
    icon: NoteAdd,
    layout: "/management",
    show: true,
  },
  {
    path: "/flow-builder",
    name: "Flow Builder",
    icon: CallSplit,
    layout: "/management",
    show: true,
  },
  {
    path: "/playground",
    name: "Playground",
    icon: LocationOn,
    layout: "/management",
    show: true,
  },
  {
    path: "/material-type-manager",
    name: "Material Type Manager",
    icon: Battery20,
    layout: "/management",
    show: true,
  },
  {
    path: "/material-type/:uuid",
    name: "Manage Material Journeys Page",
    // icon: People,
    layout: "/management",
    show: false,
  },
  {
    path: "/enabled-lga-engagement-options/:uuid",
    name: "Enable LGA Engagement Page",
    // icon: People,
    layout: "/management",
    show: false,
  },
  {
    path: "/lga-management-home",
    name: "LGAs",
    icon: LGA,
    layout: "/management",
    show: true,
  },
  {
    path: "/lga-management/add",
    name: "LGA Management - Add LGA",
    icon: LGA,
    show: false,
  },
  {
    path: "/lga-management/edit/:uuid",
    name: "LGA Management - Edit LGA",
    icon: LGA,
    layout: "/management",
    show: false,
  },
  {
    path: "/lga-management/lga/:uuid/people",
    name: "LGA Management - People",
    icon: LGA,
    layout: "/management",
    show: false,
  },
  {
    path: "/lga-management/lga/:uuid/add-lga-manager",
    name: "LGA Management - People - Add LGA Manager",
    icon: AddLocation,
    layout: "/management",
    show: true,
  },
  {
    path: "/secret",
    name: "One Time Secret",
    icon: VpnKey,
    layout: "/management",
    show: true,
  },
  {
    path: "/lga-management/lga/:uuid/edit-lga-manager",
    name: "LGA Management - People - Edit LGA Manager",
    icon: EditLocation,
    layout: "/management",
    show: false,
  },

  // All configs under here are generated using the generater.php tool
  {
    path: "/admin-settings",
    name: "Material Journey Management",
    icon: LibraryAdd,
    layout: "/management",
    show: true,
  },
  {
    path: "/admin-for-material-type-journeys",
    name: "Material Type Journeys",
    icon: Settings,
    layout: "/management",
    show: false,
  },
  {
    path: "/admin-for-experiments",
    name: "Experiments",
    icon: Settings,
    layout: "/management",
    show: false,
  },
  {
    path: "/another-bunch-of-settings",
    name: "Another Bunch Of Settings",
    icon: Settings,
    layout: "/management",
    show: true,
  },
  {
    path: "/admin-for-something-else",
    name: "Something Else",
    icon: Settings,
    layout: "/management",
    show: false,
  },
  {
    path: "/admin-for-stuff",
    name: "Stuff",
    icon: Settings,
    layout: "/management",
    show: false,
  },
];

export default dashboardRoutes;
