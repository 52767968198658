import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getAllMrfOperatorsAtMyMrf,
  toggleMrfOperatorStatusAtMyMrf,
} from "services/CurbCycleApi";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
import Icon from "@material-ui/core/Icon";
// core components
import GridItem from "../../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../../components/Grid/GridContainer.js";
import ActionableDataTable from "../../../layouts/components/ActionableDataTable/ActionableDataTable";
import Card from "../../../layouts/components/Card/Card.js";
import CardHeader from "../../../layouts/components/Card/CardHeader.js";
import CardIcon from "../../../layouts/components/Card/CardIcon.js";
import CardBody from "../../../layouts/components/Card/CardBody.js";
import Button from "../../../../../../components/CustomButtons/Button";

export default function ManageOperators(props) {
  const history = useHistory();

  const [isSearchingOperators, setIsSearchingOperators] = useState(true);
  const [filtersAsQueryString, setFiltersAsQueryString] = useState(null);
  const [tableData, setTableData] = useState({
    data: [],
  });
  const [activeIdentifier, setActiveIdentifier] = useState(null);
  const [
    myMrfOperatorsWithStatusTableData,
    setMyMrfOperatorsWithStatusTableData,
  ] = useState(undefined);

  useEffect(() => {
    let isCancelled = false;
    if (!isSearchingOperators) {
      return;
    }

    const searchMrfOperators = async () => {
      getAllMrfOperatorsAtMyMrf(filtersAsQueryString).then((response) => {
        // {
        //   {
        //     /* you could set a "fetchingStuff" state variable here
        //   e.g. setFetching(true);
        //   we can put our mock data here simulating a load time...  */
        //   }
        // }
        if (!isCancelled) {
          // console.log("[this is searchMrfOperators response]", response);
          setMyMrfOperatorsWithStatusTableData(response);
          setIsSearchingOperators(false);
          setActiveIdentifier(null);
        }
      });
    };
    searchMrfOperators();

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;
    if (!isSearchingOperators) {
      return;
    }
    const searchMrfOperators = async () => {
      getAllMrfOperatorsAtMyMrf(filtersAsQueryString).then((response) => {
        if (!isCancelled) {
          setMyMrfOperatorsWithStatusTableData(response);
          setIsSearchingOperators(false);
          setActiveIdentifier(null);
        }
      });
    };
    if (isSearchingOperators === true) {
      searchMrfOperators();
    }
    return () => {
      isCancelled = true;
    };
  }, [isSearchingOperators]);

  const handleNewToggleState = async (newToggleState) => {
    let payload = {
      key: newToggleState.key,
      active: newToggleState.value,
    };
    setActiveIdentifier(newToggleState.key);
    toggleMrfOperatorStatusAtMyMrf(newToggleState.key, payload).then(
      (response) => {
        setIsSearchingOperators(true);
      }
    );
  };

  useEffect(() => {
    if (undefined === myMrfOperatorsWithStatusTableData) {
      return;
    }
    let newTableData = {
      data: [],
    };
    newTableData.data = myMrfOperatorsWithStatusTableData.data.map((item) => {
      return [
        {
          display: `${item.account[0].email}`,
        },
        {
          hasActions: true,
          actions: [
            {
              type: "toggle-input-switch",
              color: "primary",
              label: item.status === "active" ? "Active" : "Inactive",
              spinnerLabel: "Updating",
              description: "Toggle Operator",
              size: "medium",
              context: {
                initialToggleState: {
                  identifier: `${item.mrf_operator_uuid}`,
                  value: item.status === "active" ? true : false,
                },
                isSaving: false,
                activeIdentifier: null,
                onToggleSwitch: (newToggleState) => {
                  handleNewToggleState(newToggleState);
                },
              },
            },
          ],
        },
      ];
    });
    setTableData(newTableData);
  }, [myMrfOperatorsWithStatusTableData]);

  useEffect(() => {
    if (undefined === myMrfOperatorsWithStatusTableData) {
      return;
    }
    if (activeIdentifier !== null) {
      let newTableData = {
        data: [],
      };
      newTableData.data = myMrfOperatorsWithStatusTableData.data.map((item) => {
        return [
          {
            display: `${item.account[0].email}`,
          },
          {
            hasActions: true,
            actions: [
              {
                type: "toggle-input-switch",
                color: "primary",
                label: item.status === "active" ? "Active" : "Inactive",
                spinnerLabel: "Updating",
                description: "Toggle Operator",
                size: "medium",
                context: {
                  initialToggleState: {
                    identifier: `${item.mrf_operator_uuid}`,
                    value: item.status === "active" ? true : false,
                  },
                  isSaving: true,
                  activeIdentifier: activeIdentifier,
                  onToggleSwitch: (newToggleState) => {
                    handleNewToggleState(newToggleState);
                  },
                },
              },
            ],
          },
        ];
      });
      setTableData(newTableData);
    }
  }, [activeIdentifier]);

  if (undefined === myMrfOperatorsWithStatusTableData) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <Fade in={true} timeout={3000}>
        <Card>
          <CardHeader color="primary" stats icon>
            <CardIcon color="primary">Manage SMRF Operators</CardIcon>
          </CardHeader>
          <br />
          <CardBody>
            <GridContainer
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <GridItem>
                <div className="data-table-filter-controls">
                  <form>
                    <label style={{ fontSize: "18px" }}>FILTER</label>
                    <input
                      style={{ cursor: "pointer" }}
                      type="radio"
                      name="status"
                      value="active"
                      cursor="pointer"
                      onClick={() => {
                        setIsSearchingOperators(true);
                        setFiltersAsQueryString("&status=active");
                      }}
                    />
                    <label>Active</label>
                    <input
                      style={{ cursor: "pointer" }}
                      type="radio"
                      name="status"
                      value="inactive"
                      onClick={() => {
                        setIsSearchingOperators(true);
                        setFiltersAsQueryString("&status=inactive");
                      }}
                    />
                    <label>Inactive</label>
                  </form>
                </div>
              </GridItem>
              <GridItem>
                <Button
                  link={true}
                  onClick={() => {
                    history.push(`/management/manage-people/add-mrf-operator`);
                  }}
                >
                  <Icon>add</Icon>
                  <label style={{ fontSize: "18px" }}>Add SMRF Operator</label>
                </Button>
              </GridItem>
            </GridContainer>
            <ActionableDataTable
              tableHeaderColor="success"
              tableHead={["Username", "Status"]}
              tableData={tableData.data}
            />
          </CardBody>
        </Card>
      </Fade>
    </div>
  );
}
