module.exports = {
  component: 'vfb-mb-form-component-people-dynamic-beneficial-owner-controller',
  elementPrefix: '',
  name: "MB: Beneficial Owner (dynamic collection of individuals) controller - each Person single name and date of birth fields split into constituent parts",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'element_name_prefix',
      value: 'element_name_prefix'
    },
    {
      type: 'literal',
      variable: 'element_friendly_name_singular',
      value: 'element_friendly_name_singular'
    },
    {
      type: 'literal',
      variable: 'include_residential_address',
      value: 'no'
    },
    {
      type: 'literal',
      variable: 'maximum_number',
      value: 'maximum_number'
    },
    {
      type: 'literal',
      variable: 'input_field_label',
      value: 'input_field_label'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'business_contact_name'
    }
  ]
}
