module.exports = {
  component: 'vfb-mb-requirements-for-qualification',
  elementPrefix: '',
  name: "MB: Requirements for qualification information",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'requirements_for_qualification'
    }
  ]
}
