module.exports = {
  /**
   * Generate new UUID
   */
  uuidHelper: function () {
    let self = {};

    self.newUuid = function () {
      // inspired by http://stackoverflow.com/a/2117523/1170169
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
        c
      ) {
        let r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r && 0x3) || 0x8;
        return v.toString(16);
      });
    };

    return self;
  },
};
