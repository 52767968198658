import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import geoJsonData from "./geoJsonData";
import { K_SIZE } from "./jss/maps";

function StatesMap({ awesomeAnalyticsData, onSwitchViewCallback }) {
  // const [minParticipationRate, setTheMinParticipationRate] = useState(50);
  // const [maxParticipationRate, setTheMaxParticipationRate] = useState(100);
  // const [thePostcode, setThePostcode] = useState(null);
  // const [theSignUps, setTheSignUps] = useState(null);
  // const [theParticipationRate, setTheParticipationRate] = useState(null);
  // const [theBagsCollected, setTheBagsCollected] = useState(null);
  // const [theWeightOfBagsCollected, setTheWeightOfBagsCollected] = useState(null);
  // const [theUnitsString, setTheUnitsString] = useState(null);
  // const [theMap, setTheMap] = useState(null);

  // const defaultOptions = {
  //   mapId: 'c18d89c3f87aca7',
  //   zoom: 4,
  //   center: {
  //     lat: -28.309356,
  //     lng: 140.309208
  //   }
  // };

  const defaultOptions = {
    mapId: "7ec42b1f011dc285",
    zoom: 4,
    center: {
      lat: -28.309356,
      lng: 140.309208,
    },
  };

  const createMapOptions = (maps) => {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL,
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
    };
  };

  const handleApiLoaded = (map, maps, awesomeAnalyticsData) => {
    const doAllTheInitalisationStuff = async (
      theMap,
      theMaps,
      theAnalyticsData
    ) => {
      // let features = map.data.addGeoJson(
      //   geoJsonData.au.levels.country,
      //   { idPropertyName: 'AUS_CODE16' }
      // );

      let features = map.data.addGeoJson(geoJsonData.au.levels.state, {
        idPropertyName: "STE_CODE16",
      });

      // let features = map.data.addGeoJson(
      //   geoJsonData.au.levels.lga,
      //   { idPropertyName: 'LGA_CODE20' }
      // );

      return features;
    };

    // const doAllTheEnrichmentStuff = async (map, maps, features, awesomeAnalyticsData) => {
    //   awesomeAnalyticsData.participationData.forEach((row) => {
    //     // console.log('row', row);
    //     let numberOfBags = row['bags_collected'];
    //     let participationRate = row['participation_rate'];
    //     let postcode = row['postcode'];
    //     let lgaName = row['lga_name'];
    //     let signUps = row['sign_ups'];
    //
    //     // keep track of min and max values
    //     if (participationRate < minParticipationRate) {
    //       setTheMinParticipationRate(participationRate);
    //     }
    //     if (participationRate > maxParticipationRate) {
    //       setTheMaxParticipationRate(participationRate);
    //     }
    //
    //     // update the existing row with the new data
    //     if (undefined !== map.data.getFeatureById(postcode)) {
    //       map.data.getFeatureById(postcode).setProperty('postcode', postcode);
    //       map.data.getFeatureById(postcode).setProperty('bags_collected', numberOfBags);
    //       map.data.getFeatureById(postcode).setProperty('participation_rate', participationRate);
    //       map.data.getFeatureById(postcode).setProperty('lga_name', lgaName);
    //       map.data.getFeatureById(postcode).setProperty('sign_ups', signUps);
    //     } else {
    //       console.log({
    //         postcode: postcode
    //       })
    //     }
    //   });
    //   return features
    // };

    const CallOnSwitchViewCallback = () => {
      onSwitchViewCallback({
        view: "iqr",
      });
    };

    doAllTheInitalisationStuff(map, maps, awesomeAnalyticsData).then(
      (features) => {
        map.data.addListener("click", CallOnSwitchViewCallback);
        // doAllTheEnrichmentStuff(map, maps, features, awesomeAnalyticsData)
        //   .then((theFeatures) => {
        //     console.log(theFeatures);
        //
        //     setTimeout(() => {
        //
        //       map.data.setStyle(styleFeature);
        //
        //       let arrayOfFeaturesToRemove = [];
        //
        //       theFeatures.forEach((item, index) => {
        //         if (undefined === item.i.postcode || item.i.sign_ups < 10) {
        //           arrayOfFeaturesToRemove.push(index);
        //         }
        //       });
        //
        //       arrayOfFeaturesToRemove.forEach((item, index) => {
        //         map.data.remove(features[item]);
        //       });
        //
        //       function mouseInToRegion(e) {
        //         setThePostcode(awesomeAnalyticsData.relevantDataObject[e.feature.j].postcode);
        //         setTheBagsCollected(awesomeAnalyticsData.relevantDataObject[e.feature.j].bags_collected);
        //         setTheParticipationRate(awesomeAnalyticsData.relevantDataObject[e.feature.j].participation_rate);
        //         setTheWeightOfBagsCollected(awesomeAnalyticsData.relevantDataObject[e.feature.j].bags_collected * awesomeAnalyticsData.average_bag_weight);
        //         setTheUnitsString(awesomeAnalyticsData.relevantDataObject[e.feature.j].units_string);
        //         setTheSignUps(awesomeAnalyticsData.relevantDataObject[e.feature.j].sign_ups);
        //         // set the hover state so the setStyle function can change the border
        //         e.feature.setProperty('state', 'hover');
        //       }
        //
        //       function mouseOutOfRegion(e) {
        //         // reset the hover state, returning the border to normal
        //         e.feature.setProperty('state', 'normal');
        //         setThePostcode(null);
        //         setTheBagsCollected(null);
        //         setTheWeightOfBagsCollected(null);
        //         setTheParticipationRate(null);
        //         setTheUnitsString(null);
        //         setTheSignUps(null);
        //       }
        //
        //       // map.data.addListener('click', mouseInToRegion);
        //       map.data.addListener('mouseover', mouseInToRegion);
        //       map.data.addListener('mouseout', mouseOutOfRegion);
        //     }, 10)
        //   })
      }
    );

    // setTimeout(
    //   () => {
    //     setTimeout(() => {
    //       loadParticipationData(map, awesomeAnalyticsData);
    //         setTimeout(() => {
    //           loadParticipationData(map, awesomeAnalyticsData);
    //         }, 100);
    //     }, 100);
    //     // console.log({
    //     //   "features": features
    //     // })
    //
    //     // console.log(features);
    //
    //     setTimeout(() => {
    //       let arrayOfFeaturesToRemove = [];
    //       features.forEach((item, index) => {
    //         if (undefined === item.i.postcode || item.i.sign_ups < 10) {
    //           arrayOfFeaturesToRemove.push(index);
    //         }
    //         console.log({
    //           feature: item
    //         })
    //       });
    //
    //
    //     }, 300);
    //
    //       // console.log({
    //       //   "arrayOfFeaturesToRemove": arrayOfFeaturesToRemove
    //       // });
    //
    //       arrayOfFeaturesToRemove.forEach((item, index) => {
    //         map.data.remove(features[item]);
    //       });
    //     // }, 2000);
    //   }, 200
    // );
  };

  // const styleFeature = (feature) => {
  //   // let delta = (feature.getProperty('participation_rate') - minParticipationRate) /
  //   //   (maxParticipationRate - minParticipationRate);

  //   let low = [5, 69, 54];  // color of smallest datum
  //   let high = [151, 83, 34];   // color of largest datum

  //   // delta represents where the value sits between the min and max
  //   // bags_collected
  //   // let delta = (feature.getProperty('participation_rate') - 2) /
  //   //   (50);
  //   // console.log(
  //   //   {
  //   //     "feature": feature,
  //   //     // "feature.getProperty('participation_rate')": feature.getProperty('participation_rate'),
  //   //     // "Number.MAX_VALUE": Number.MAX_VALUE,
  //   //     // "-Number.MAX_VALUE": -Number.MAX_VALUE,
  //   //     // "(-Number.MAX_VALUE - Number.MAX_VALUE)": (-Number.MAX_VALUE - Number.MAX_VALUE),
  //   //     // "(feature.getProperty('participation_rate') - Number.MAX_VALUE)": (feature.getProperty('participation_rate') - Number.MAX_VALUE),
  //   //     "minParticipationRate": minParticipationRate,
  //   //     "maxParticipationRate": maxParticipationRate,
  //   //     // "delta": delta
  //   //   }
  //   // );

  //   // let color = [];
  //   // for (let i = 0; i < 3; i++) {
  //   //   // calculate an integer color based on the delta
  //   //   color[i] = (high[i] - low[i]) * delta + low[i];
  //   // }
  //   //
  //   // // determine whether to show this shape or not
  //   // let showRow = true;
  //   // if (feature.getProperty('bags_collected') == null ||
  //   //   isNaN(feature.getProperty('bags_collected'))) {
  //   //   showRow = false;
  //   // }
  //   //
  //   // let outlineWeight = 0.7, zIndex = 1;
  //   // if (feature.getProperty('state') === 'hover') {
  //   //   outlineWeight = zIndex = 2;
  //   // }

  //   // return {
  //   //   strokeWeight: outlineWeight,
  //   //   strokeColor: '#fff17e',
  //   //   zIndex: zIndex,
  //   //   fillColor: 'hsl(' + color[0] + ',' + color[1] + '%,' + color[2] + '%)',
  //   //   fillOpacity: 0.75,
  //   //   visible: showRow
  //   // };

  //   return {
  //     strokeWeight: 0.1,
  //     strokeColor: '#fff17e',
  //     zIndex: 1,
  //     fillColor: 'hsl(' + 151 + ',' + 81 + '%,' + 1 + '%)',
  //     fillOpacity: 0.75,
  //     visible: true
  //   };
  // };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "60vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={defaultOptions.center}
        defaultZoom={defaultOptions.zoom}
        mapId={defaultOptions.mapId}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) =>
          handleApiLoaded(map, maps, awesomeAnalyticsData)
        }
        options={createMapOptions}
        hoverDistance={K_SIZE / 2}
      ></GoogleMapReact>
    </div>
  );
}

StatesMap.propTypes = {
  awesomeAnalyticsData: PropTypes.shape(PropTypes.any),
  onSwitchViewCallback: PropTypes.func
}

export default StatesMap;
