import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// custom/core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter";
import BigNumberProgressBar from "../DashboardComponents/BigNumber/BigNumberProgressBar";
import styles from "../../../../../../assets/jss/material-dashboard-react/components/cardContainerStyleIm.js";
// import { Link } from "react-router-dom";
import uuid from "../../../../../../utilities/uuid";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ReactTimeAgo from "react-time-ago";

TimeAgo.addDefaultLocale(en);

const useStyles = makeStyles(styles);

function ActionableCardContainer(props) {
  const classes = useStyles();
  const { containerData, pagination, cxs, csm, cmd } = props;

  const usePaginator = undefined !== pagination && pagination === true;

  if (containerData.length < 1) {
    return (
      <div className={classes.tableResponsive}>
        <GridContainer container justifyContent="center">
          <GridItem xs={12} sm={12} md={5}>
            <Card>
              <CardHeader stats icon>
                <CardIcon>
                  <Icon>error_outline</Icon>
                </CardIcon>
                <br />
                <p
                  className={classes.cardCategory}
                  style={{
                    color: "#ce4328",
                    fontWeight: "400",
                  }}
                >
                  No records match the search criteria
                </p>
              </CardHeader>

              <CardFooter stats>
                <Icon>error_outline</Icon>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  let propKeysWhichHaveAnAction = {};

  if (containerData.length > 0) {
    containerData[0].forEach((value, index) => {
      if (undefined !== value.hasActions && value.hasActions === true) {
        propKeysWhichHaveAnAction[index] = index;
      }
    });
  }

  if (usePaginator) {
    return (
      <div>
        <h1>
          YOU NEED TO BUILD PAGINATOR LOGIC IF YOU WANT TO PAGINATE THESE CARDS
        </h1>
      </div>
    );
  }

  return (
    <div>
      <GridContainer>
        {containerData.map((prop, key) => {
          return (
            <GridItem key={uuid.uuid()} xs={cxs} sm={csm} md={cmd}>
              {prop.map((prop, key) => {
                if (undefined !== propKeysWhichHaveAnAction[key]) {
                  let actionContent = prop.actions.map((action) => {
                    switch (action.type) {
                      case "container-card":
                        return (
                          <div key={uuid.uuid()}>
                            <CardHeader stats icon>
                              <CardIcon>
                                <Icon
                                  style={{
                                    color: `${action.iconColor}`,
                                    backgroundColor: `${action.color}`,
                                    borderRadius: "4px",
                                  }}
                                >
                                  {action.icon}
                                </Icon>
                              </CardIcon>
                              <>
                                <p className={classes.cardCategory}>
                                  {action.title}
                                </p>
                                <h3
                                  className={classes.cardTitle}
                                  style={{
                                    color: `${action.iconColor}`,
                                  }}
                                >
                                  {action.weight}
                                  <small
                                    style={{
                                      color: `${action.iconColor}`,
                                    }}
                                  >
                                    {action.weightUnit}
                                  </small>
                                </h3>
                              </>
                            </CardHeader>
                            <CardFooter stats>
                              {action.footerIconStartValue.timeAgo ? (
                                <GridItem>
                                  <Icon>{action.footerIconStart}</Icon>
                                  updated{" "}
                                  <ReactTimeAgo
                                    date={Date.now()}
                                    locale="en-US"
                                  />
                                </GridItem>
                              ) : action.footerIconStart ? (
                                <GridItem>
                                  <Icon>{action.footerIconStart}</Icon>
                                  {action.footerIconStartValue}
                                </GridItem>
                              ) : null}
                              <GridItem>
                                <Icon>{action.footerIconEnd}</Icon>
                                {action.footerIconEndValue}
                              </GridItem>
                            </CardFooter>
                          </div>
                        );
                      // =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
                      case "test-case-card-2":
                        return (
                          <div>
                            <CardHeader>
                              <h5 className={classes.cardHeader}>
                                {action.label}
                              </h5>
                            </CardHeader>
                            <CardBody>
                              <h3>
                                <i>{action.display}</i>
                              </h3>
                            </CardBody>
                          </div>
                        );
                      default:
                        throw new Error("you need to assign a case type");
                    }
                  });
                  return (
                    <Card className={classes.card} key={uuid.uuid()}>
                      {actionContent}
                    </Card>
                  );
                }
                if (undefined === propKeysWhichHaveAnAction[key]) {
                  return (
                    <Card className={classes.card} key={uuid.uuid()}>
                      <CardHeader>
                        <h5 className={classes.cardHeader}>{prop.label}</h5>
                      </CardHeader>
                      <CardBody>
                        <BigNumberProgressBar bigNumberText={prop.display} />
                      </CardBody>
                    </Card>
                  );
                }
                return (
                  <Card className={classes.card} key={uuid.uuid()}>
                    {prop.display}
                  </Card>
                );
              })}
            </GridItem>
          );
        })}
      </GridContainer>
    </div>
  );
}

ActionableCardContainer.defaultProps = {
  containerHeadColor: "gray",
  cardBackgroundColour: "gray",
};

ActionableCardContainer.propTypes = {
  containerHeadColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  cardBackgroundColour: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  containerData: PropTypes.arrayOf(PropTypes.any),
  cm: PropTypes.any,
  cmd: PropTypes.any,
  csm: PropTypes.any,
  cxs: PropTypes.any,
  pagination: PropTypes.any,
};

export default ActionableCardContainer;
