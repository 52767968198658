import React from 'react';

const AppFeatureFlagContext = React.createContext({
  use_mvp_experience: false,
  app_telemetry_enabled: true,
  account__enable_faqs: true,
  account__enable_change_of_address: false,
  account__enable_dynamic_account_content_blocks: false,
  account__enable_lga_contact_email: false,
  account__enable_support: false,
  waitlist__enable_faqs: true,
  waitlist__enable_change_of_address: false,
  waitlist__enable_dynamic_waitlist_content_blocks: false,
  waitlist__enable_support: false
});

export default AppFeatureFlagContext;