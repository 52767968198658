import { Store, set, get } from "idb-keyval";
const DATABASE_NAME = "CC_WEB_DASHBOARD_APP";
const DEFAULT_OBJECT_STORE = "state";
// const DATABASE_VERSION = 1;

const customStore = new Store(DATABASE_NAME, DEFAULT_OBJECT_STORE);

const storeData = async (key, value) => {
  try {
    await set(key, value, customStore);
  } catch (e) {
    // error while saving
    console.log(e, key, value);
  }
};

const storeJsonData = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await storeData(key, jsonValue);
  } catch (e) {
    // error while saving
    console.log(e, key, value);
  }
};

const getData = async (key) => {
  try {
    return await get(key, customStore);
  } catch (e) {
    // error while reading value
    console.log(e, key);
  }
};

const getJsonData = async (key) => {
  try {
    const jsonValue = await getData(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error while reading value
    console.log(e, key);
  }
};

const CurbCycleAppStorage = {
  getData,
  getJsonData,
  storeData,
  storeJsonData,
};

export default CurbCycleAppStorage;
