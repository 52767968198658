import React from "react";
import PropTypes from "prop-types";
import CardIcon from "../../Card/CardIcon";
import CardHeader from "../../Card/CardHeader";

function ProfileAvatar({ profile }) {
  let avatarSVG = null;
  let avatarSVGName = undefined !== profile.avatar ? profile.avatar : "curby";
  switch (avatarSVGName) {
    case "bird":
      avatarSVG = require("../Avatar/Bird.svg");
      break;
    case "bird2":
      avatarSVG = require("../Avatar/Bird2.svg");
      break;
    case "curby":
      avatarSVG = require("../Avatar/Curby.svg");
      break;
    case "devil":
      avatarSVG = require("../Avatar/Devil.svg");
      break;
    case "frog":
      avatarSVG = require("../Avatar/Frog.svg");
      break;
    case "koala":
      avatarSVG = require("../Avatar/Koala.svg");
      break;
    case "parrot":
      avatarSVG = require("../Avatar/Parrot.svg");
      break;
    case "snake":
      avatarSVG = require("../Avatar/Snake.svg");
      break;
    case "turtle":
      avatarSVG = require("../Avatar/Turtle.svg");
      break;
    default:
      throw new Error("That should not happen");
  }

  return <img src={avatarSVG} alt="" />;
}

ProfileAvatar.propTypes = {
  profile: PropTypes.shape({
    avatar: PropTypes.string,
  }).isRequired,
};

export default ProfileAvatar;
