module.exports = {
  component: 'vfb-mb-form-component-organisations-abn-lookup',
  elementPrefix: '',
  name: "MB: ABN lookup - typeahead component designed with specific behaviour related to selecting a company",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'element_name_prefix',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'input_field_label',
      value: 'input_field_label'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'registered_business'
    }
  ]
}
