import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core
// core components
import paginationHelper from "../../../../../utilities/paginationHelper";
import Fade from "@material-ui/core/Fade";
import GridItem from "../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../components/Grid/GridContainer.js";
import ActionableDataTable from "../../../../../components/ActionableDataTable/ActionableDataTable";
import Card from "../../../../../components/Card/Card.js";
import CardHeader from "../../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../../components/Card/CardIcon.js";
import CardBody from "../../../../../components/Card/CardBody.js";
import Button from "../../../../../components/CustomButtons/Button";
import { Link } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import pageChangeRetailSponsorsWithFiltersThunk from "../../../../../thunks/Management/RetailSponsors/DataPagination/pageChangeRetailSponsorsWithFiltersThunk";
import useDebounce from "../../../../../hooks/useDebounce";

function RetailSponsors({
  fetching,
  retailSponsorTableData,
  retailSponsorTableRawData,
}) {
  const dispatch = useDispatch();

  const [
    retailSponsorTableFilterOptions,
    setRetailSponsorTableFilterOptions,
  ] = useState(undefined);
  const [
    retailSponsorTableFilterForStatus,
    setRetailSponsorTableFilterForStatus,
  ] = useState(undefined);
  const debouncedRetailSponsorTableFilterOptions = useDebounce(
    retailSponsorTableFilterOptions,
    400
  );
  const debouncedRetailSponsorTableStatus = useDebounce(
    retailSponsorTableFilterForStatus,
    300
  );

  useEffect(() => {
    if (undefined === debouncedRetailSponsorTableFilterOptions) {
      return;
    }
    dispatch(
      pageChangeRetailSponsorsWithFiltersThunk(
        paginationHelper.generateFilterQueryStringFromFilterOptions(
          debouncedRetailSponsorTableFilterOptions
        )
      )
    );
  }, [debouncedRetailSponsorTableFilterOptions, dispatch]);

  useEffect(() => {
    const handler = setTimeout(
      paginationHelper.handleAddSimpleKeyValueFilterOption(
        "status",
        debouncedRetailSponsorTableStatus,
        setRetailSponsorTableFilterOptions,
        debouncedRetailSponsorTableFilterOptions
      ),
      500
    );

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedRetailSponsorTableStatus]);

  return (
    <div>
      <Fade in={true} timeout={3000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card loading={fetching}>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">Manage Retail Sponsors</CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={9}>
                    <div className="data-table-filter-controls">
                      <form>
                        <p>Status</p>
                        <input
                          type="radio"
                          name="status"
                          value="active"
                          defaultChecked={
                            debouncedRetailSponsorTableStatus === "active"
                          }
                          onClick={() => {
                            setRetailSponsorTableFilterForStatus("active");
                            paginationHelper.handleAddSimpleKeyValueFilterOption(
                              "status",
                              "active",
                              setRetailSponsorTableFilterOptions,
                              debouncedRetailSponsorTableFilterOptions
                            );
                          }}
                        />
                        <label>Active</label>
                        <input
                          type="radio"
                          name="status"
                          value="inactive"
                          defaultChecked={
                            debouncedRetailSponsorTableStatus === "inactive"
                          }
                          onClick={() => {
                            setRetailSponsorTableFilterForStatus("inactive");
                            paginationHelper.handleAddSimpleKeyValueFilterOption(
                              "status",
                              "inactive",
                              setRetailSponsorTableFilterOptions,
                              debouncedRetailSponsorTableFilterOptions
                            );
                          }}
                        />
                        <label>Inactive</label>
                      </form>
                    </div>
                  </GridItem>
                  {/*<GridItem xs={12} sm={12} md={3}>*/}
                  {/**/}
                  {/*</GridItem>*/}
                  <GridItem xs={1} sm={1} md={1}>
                    <Link to="/management/retail-sponsor-management/add">
                      <Button color={"warning"} link={true} size={"lg"}>
                        <span className="material-icons">add</span> Add Retail
                        Sponsor
                      </Button>
                    </Link>
                  </GridItem>
                </GridContainer>

                <ActionableDataTable
                  tableHeaderColor="warning"
                  tableHead={["Name", "Status", "Actions"]}
                  tableData={retailSponsorTableData}
                  pagination={true}
                  paginationConfig={{
                    collectionData: retailSponsorTableRawData,
                    handlePageChange: (payload) => {
                      setRetailSponsorTableFilterOptions(
                        paginationHelper.addSimpleKeyValueFilterOption(
                          debouncedRetailSponsorTableFilterOptions,
                          "page",
                          payload.selected,
                          true,
                          false
                        )
                      );
                    },
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fade>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    retailSponsors:
      state.journeyFlows.CurbCycleManagement.retailSponsors.retailSponsors,
    retailSponsorTableRawData:
      state.journeyFlows.CurbCycleManagement.retailSponsors.retailSponsors,
    retailSponsorTableData: state.journeyFlows.CurbCycleManagement.retailSponsors.retailSponsors.data.map(
      (item) => {
        return [
          {
            display: item.name,
          },
          {
            display: item.status,
          },
          {
            actions: [
              {
                type: "link-button",
                icon: "edit",
                color: "info",
                description: "Edit Retail Sponsor Record",
                size: "sm",
                context: {
                  uuid: item.uuid,
                  link: `/management/retail-sponsor-management/edit/${item.uuid}`,
                },
              },
              {
                type: "link-button",
                icon: "person",
                color: "warning",
                description: "Manage People",
                size: "sm",
                context: {
                  uuid: item.uuid,
                  link: `/management/retail-sponsor-management/retail-sponsor/${item.uuid}/people`,
                },
              },
            ],
          },
        ];
      }
    ),
    fetching: state.journeyFlows.CurbCycleManagement.retailSponsors.fetching,
  };
}

RetailSponsors.propTypes = {
  fetching: PropTypes.bool.isRequired,
  retailSponsorTableData: PropTypes.any.isRequired,
  retailSponsorTableRawData: PropTypes.any.isRequired,
};

export default connect(mapStateToProps)(RetailSponsors);
