import "date-fns";
import React, { useState } from "react";
import PropTypes from "prop-types";
// import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

export default function DatePicker({ date }) {
  const [selectedDate, handleDateChange] = useState(date);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiDatePicker value={selectedDate} onChange={handleDateChange} />
    </MuiPickersUtilsProvider>
  );
}

DatePicker.propTypes = {
  date: PropTypes.string.isRequired,
};
