import React from "react";
import PropTypes from "prop-types";
import TreeItem from "@material-ui/lab/TreeItem";
// import { useDrag } from 'react-dnd';

function CustomTreeItem({
  onSelectItemCallback,
  pathIdentifier,
  uuid,
  label,
  children,
}) {
  // const [{ opacity }, dragRef] = useDrag({
  //   item: { type: 'TREE_ITEM', children },
  //   collect: (monitor) => ({
  //     opacity: monitor.isDragging() ? 0.5 : 1
  //   })
  // });

  const doCallback = () => {
    onSelectItemCallback(uuid, pathIdentifier);
  };

  return (
    <TreeItem nodeId={uuid} label={label} onClick={doCallback}>
      {children}
    </TreeItem>
  );

  // return (
  //   <TreeItem nodeId={uuid} label={label} onClick={doCallback} ref={dragRef} style={{ opacity }}>
  //     {children}
  //   </TreeItem>
  // )
}

CustomTreeItem.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  onSelectItemCallback: PropTypes.func,
  pathIdentifier: PropTypes.string,
  uuid: PropTypes.string,
};

export default CustomTreeItem;
