import React, { useEffect, useState } from "react";
// import ReactIntense from "react-intense";
import moment from "moment";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
// custom components
import ActionableCardContainer from "../../../layouts/components/ActionableCardContainer/ActionableCardContainer";
// project tools
// import styles from "../../../../../../assets/jss/material-dashboard-react/views/dashboardStyleIm.js";
import { getMyMrfAnalyticsData } from "services/CurbCycleApi";
// import generateUUID from "../../../../../../utilities/uuid";
import materialTypeHelper from "../../../../../../utilities/materialTypeHelper";
import {
  successColor,
  warningColor,
  dangerColor,
} from "assets/jss/material-dashboard-react-im.js";

export default function InventoryAnalyticsSummaryWithDetail(props) {
  const [analyticsTableData, setAnalyticsTableData] = useState({ data: [] });

  useEffect(() => {
    let isCancelled = false;

    getMrfAnalytics().then((analyticsTableData) => {
      if (!isCancelled) {
        setAnalyticsTableData(analyticsTableData);
      }
    });

    const interval = setInterval(() => {
      getMrfAnalytics().then((analyticsTableData) => {
        if (!isCancelled) {
          setAnalyticsTableData(analyticsTableData);
        }
      });
    }, 900000);
    return () => {
      isCancelled = true;
      clearInterval(interval);
    };
  }, []);

  const getMrfAnalytics = async () => {
    return getMyMrfAnalyticsData().then((response) => {
      let tableData = undefined;
      tableData = response;
      let config = [
        {
          title: "On Hand Total",
          data_key: "on_hand_total",
          color: "primary",
          weight: materialTypeHelper
            .formatWeightGramsToTons(tableData.data.on_hand_total)
            .toFixed(2),
          weightUnit: " t",
          footerIconStart: "update",
          footerIconStartValue: {
            timeAgo: "timeAgo",
          },
          footerIconEnd: null,
          footerIconEndValue: null,
        },
        {
          title: "Inbound Today",
          data_key: "inbound_today",
          color: "primary",
          icon: "repeat",
          weight: materialTypeHelper
            .formatWeightGramsToTons(tableData.data.inbound_today)
            .toFixed(2),
          weightUnit: " t",
          footerIconStart: "update",
          footerIconStartValue: {
            timeAgo: "timeAgo",
          },
          footerIconEnd: "local_shipping",
          footerIconEndValue: tableData.data.inbound_trucks_today,
        },
        {
          title: "Outbound Today",
          data_key: "outbound_today",
          color: "primary",
          icon: "repeat",
          weight: materialTypeHelper
            .formatWeightGramsToTons(tableData.data.outbound_today)
            .toFixed(2),
          weightUnit: " t",
          footerIconStart: "update",
          footerIconStartValue: {
            timeAgo: "timeAgo",
          },
          footerIconEnd: "local_shipping",
          footerIconEndValue: tableData.data.outbound_trucks_today,
        },
        {
          title: "EPA Limit",
          data_key: "epa_limit",
          color: "primary",
          icon: "domain",
          weight: materialTypeHelper.formatWeightGramsToTons(
            tableData.data.epa_limit
          ),
          weightUnit: " t",
          footerIconStart: undefined,
          footerIconStartValue: {
            timeAgo: undefined,
          },
          footerIconEnd: undefined,
          footerIconEndValue: undefined,
        },
        {
          title: "Inbound Yesterday",
          data_key: "inbound_yesterday",
          color: "primary",
          icon: "repeat",
          inbound_trucks: "inbound_trucks",
          weight: materialTypeHelper
            .formatWeightGramsToTons(tableData.data.inbound_yesterday)
            .toFixed(2),
          weightUnit: " t",
          footerIconStart: "today",
          footerIconStartValue: moment()
            .subtract(1, "day")
            .format("DD-MM-YYYY"),
          footerIconEnd: "local_shipping",
          footerIconEndValue: tableData.data.inbound_trucks_yesterday,
        },
        {
          title: "Outbound Yesterday",
          data_key: "outbound_yesterday",
          color: "primary",
          icon: "repeat",
          outbound_trucks: "outbound_trucks",
          weight: materialTypeHelper
            .formatWeightGramsToTons(tableData.data.outbound_yesterday)
            .toFixed(2),
          weightUnit: " t",
          footerIconStart: "today",
          footerIconStartValue: moment()
            .subtract(1, "day")
            .format("DD-MM-YYYY"),
          footerIconEnd: "local_shipping",
          footerIconEndValue: tableData.data.outbound_trucks_yesterday,
        },
      ];

      const newTableData = {
        data: [],
      };

      newTableData.data = config.map((item) => {
        // =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
        if (item.data_key === "on_hand_total" && item.weight <= 850000000) {
          // Less Than 850 tons
          item.iconColor = successColor[0];
          item.color = "whitesmoke";
          item.icon = "done_all";
        }
        if (
          item.data_key === "on_hand_total" &&
          item.weight >= 850000001 &&
          item.weight <= 1650000000
        ) {
          // Between >= 850 tons and <= 1650 tons
          item.iconColor = warningColor[0];
          item.color = "whitesmoke";
          item.icon = "done";
        }
        if (item.data_key === "on_hand_total" && item.weight >= 1650000001) {
          // More than or equal to 1650 tons
          item.iconColor = dangerColor[0];
          item.color = "whitesmoke";
          item.icon = "warning_amber";
        }

        return [
          {
            hasActions: true,
            actions: [
              {
                type: "container-card",
                description: "",
                icon: item.icon,
                iconColor: item.iconColor,
                color: item.color,
                title: item.title,
                weight: item.weight,
                weightUnit: item.weightUnit,
                footerIconStart: item.footerIconStart,
                footerIconStartValue: item.footerIconStartValue,
                footerIconEnd: item.footerIconEnd,
                footerIconEndValue: item.footerIconEndValue,
                // linkURL: item.linkURL,
              },
            ],
          },
        ];
      });
      return newTableData;
    });
  };

  return (
    <ActionableCardContainer
      containerData={analyticsTableData.data}
      cxs={12}
      csm={12}
      cmd={4}
    />
  );
}
