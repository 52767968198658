import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Fade from "@material-ui/core/Fade";
import GridItem from "../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../components/Grid/GridContainer.js";
import ActionableDataTable from "../../../../../components/ActionableDataTable/ActionableDataTable";
import Card from "../../../../../components/Card/Card.js";
import CardHeader from "../../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../../components/Card/CardIcon.js";
import CardBody from "../../../../../components/Card/CardBody.js";
import Button from "../../../../../components/CustomButtons/Button";
import generateUUID from "../../../../../utilities/uuid";

const CreateThing = ({
  crudContext,
  crudContextCallback,
  forceRefreshDataValue,
}) => {
  const pageName = "Add Thing";

  return (
    <div>
      <Fade in={true} timeout={1000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card loading={false}>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">{pageName}</CardIcon>
              </CardHeader>
              <CardBody>
                <br />
                <pre>{JSON.stringify(crudContext, null, 2)}</pre>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fade>
    </div>
  );
};

const UpdateThing = ({
  crudContext,
  crudContextCallback,
  forceRefreshDataValue,
}) => {
  const pageName = "Update Thing";

  return (
    <div>
      <Fade in={true} timeout={1000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card loading={false}>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">{pageName}</CardIcon>
              </CardHeader>
              <CardBody>
                <br />
                <pre>{JSON.stringify(crudContext, null, 2)}</pre>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fade>
    </div>
  );
};

const ListSomethingElse = ({
  crudContext,
  crudContextCallback,
  forceRefreshDataValue,
}) => {
  const pageName = "Admin for Things";

  const [fetching, setFetching] = useState(true);

  const [data, setData] = useState({
    data: [],
  });

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let isCancelled = false;

    const getData = async () => {
      if (!isCancelled) {
        setData({
          data: [
            {
              key: "COFFEE_PODS",
              value: "Coffee Capsules",
            },
            {
              key: "SOFT_PLASTIC",
              value: "Soft plastics",
            },
          ],
        });

        setFetching(false);
      }
    };

    getData();

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const getData = async () => {
      if (!isCancelled) {
        setData({
          data: [
            {
              key: "COFFEE_PODS",
              value: "Coffee Capsules",
            },
            {
              key: "SOFT_PLASTIC",
              value: "Soft plastics",
            },
          ],
        });

        setFetching(false);
      }
    };

    getData();

    return () => {
      isCancelled = true;
    };
  }, [forceRefreshDataValue]);

  useEffect(() => {
    let theTableData = [];

    data.data.map((item) => {
      return theTableData.push([
        {
          display: item.value,
        },
        {
          display: item.key,
        },
        {
          actions: [
            {
              type: "callback-button",
              icon: "edit",
              color: "info",
              description: "Update Thing",
              size: "sm",
              context: {
                uuid: item.key,
                callback: () => {
                  crudContextCallback({
                    context: "u",
                    data: {
                      key: item.key,
                      value: item.value,
                    },
                  });
                },
              },
            },
            {
              type: "callback-button",
              icon: "delete",
              color: "danger",
              description: "Delete Thing",
              size: "sm",
              context: {
                uuid: item.key,
                callback: () => {
                  crudContextCallback({
                    context: "u",
                    data: {
                      key: item.key,
                      value: item.value,
                    },
                  });
                },
              },
            },
          ],
        },
      ]);
    });
    setTableData(theTableData);
    return;
  }, [data, crudContextCallback]);

  return (
    <div>
      <Fade in={true} timeout={1000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card loading={fetching}>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">{pageName}</CardIcon>
              </CardHeader>
              <CardBody>
                <GridItem xs={3} sm={3} md={3}>
                  <p></p>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <Button
                    color={"warning"}
                    onClick={() => {
                      crudContextCallback({
                        context: "c",
                        data: {
                          key: "",
                          value: "",
                        },
                      });
                    }}
                    link={true}
                    size={"lg"}
                  >
                    <span className="material-icons">add</span> Add Thing
                  </Button>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <ActionableDataTable
                    tableHeaderColor="warning"
                    tableHead={["Friendly Value", "Identifier", "Actions"]}
                    tableData={tableData}
                    pagination={false}
                  />
                </GridItem>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fade>
    </div>
  );
};

function AdminForSomethingElse() {
  const [crudContext, setCrudContext] = useState({
    context: "r",
    data: {},
  });

  const [forceRefreshDataValue, setForceRefreshDataValue] = useState({
    value: undefined,
  });

  // c for "create" as the add record context
  if (crudContext.context === "c") {
    return (
      <CreateThing
        crudContext={crudContext}
        forceRefreshDataValue={forceRefreshDataValue}
        crudContextCallback={(context, shouldRefresh = false) => {
          setCrudContext(context);
          if (true === shouldRefresh) {
            setForceRefreshDataValue({ value: generateUUID() });
          }
        }}
      />
    );
  }

  // u for "update" as the edit record context
  if (crudContext.context === "u") {
    return (
      <UpdateThing
        crudContext={crudContext}
        forceRefreshDataValue={forceRefreshDataValue}
        crudContextCallback={(context, shouldRefresh = false) => {
          setCrudContext(context);
          if (true === shouldRefresh) {
            setForceRefreshDataValue({ value: generateUUID() });
          }
        }}
      />
    );
  }

  // r for "read" as the default record context
  return (
    <ListSomethingElse
      crudContext={crudContext}
      forceRefreshDataValue={forceRefreshDataValue}
      crudContextCallback={(context, shouldRefresh = false) => {
        setCrudContext(context);
        if (true === shouldRefresh) {
          setForceRefreshDataValue({ value: generateUUID() });
        }
      }}
    />
  );
}

ListSomethingElse.propTypes = {
  crudContext: PropTypes.shape({
    data: PropTypes.shape({
      key: PropTypes.any
    })
  }),
  crudContextCallback: PropTypes.func.isRequired,
  forceRefreshDataValue: PropTypes.bool,
}

CreateThing.propTypes = {
  crudContext: PropTypes.shape({
    data: PropTypes.shape({
      key: PropTypes.any
    })
  }),
  crudContextCallback: PropTypes.func.isRequired,
  forceRefreshDataValue: PropTypes.bool,
}

UpdateThing.propTypes = {
  crudContext: PropTypes.shape({
    data: PropTypes.shape({
      key: PropTypes.any
    })
  }),
  crudContextCallback: PropTypes.func.isRequired,
  forceRefreshDataValue: PropTypes.bool,
}

export default AdminForSomethingElse;
