import createStyles from "@material-ui/styles/createStyles";
import { makeStyles } from "@material-ui/core/styles";

import {
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  greenColor,
  defaultFont,
} from "assets/jss/material-dashboard-react-im.js";

const useTableStyleIm = makeStyles((theme) =>
  createStyles({
    warningTableHeader: {
      color: warningColor[0],
    },
    primaryTableHeader: {
      color: theme.palette.primary.main,
    },
    dangerTableHeader: {
      color: dangerColor[1],
    },
    successTableHeader: {
      color: successColor[0],
    },
    infoTableHeader: {
      color: infoColor[0],
    },
    roseTableHeader: {
      color: roseColor[0],
    },
    grayTableHeader: {
      color: grayColor[1],
    },
    greenTableHeader: {
      color: greenColor[0],
    },
    table: {
      marginBottom: "0",
      width: "100%",
      maxWidth: "100%",
      backgroundColor: "transparent",
      borderSpacing: "0",
      borderCollapse: "collapse",
    },
    tableHeadCell: {
      color: "inherit",
      ...defaultFont,
      "&, &$tableCell": {
        fontSize: "1em",
        fontWeight: 400,
      },
    },
    tableCell: {
      ...defaultFont,
      lineHeight: "1.42857143",
      padding: "12px 8px",
      verticalAlign: "middle",
      fontSize: "0.8125rem",
    },
    tableResponsive: {
      width: "100%",
      marginTop: theme.spacing(0),
      overflowX: "auto",
    },
    tableHeadRow: {
      height: "56px",
      color: "inherit",
      display: "table-row",
      outline: "none",
      verticalAlign: "middle",
    },
    tableBodyRow: {
      height: "48px",
      color: "inherit",
      display: "table-row",
      outline: "none",
      verticalAlign: "middle",
    },
    buttonLink: {
      color: "#5159fc",
      textDecoration: "none",
      "&:hover,&:focus": {
        color: "#000adb",
        textDecoration: "none",
      },
    },
  })
);

export default useTableStyleIm;
