/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
import { DataGrid } from "@material-ui/data-grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Select from "@material-ui/core/Select";
import GridItem from "../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../components/Grid/GridContainer.js";
import LocalShipping from "@material-ui/icons/LocalShipping";
import Delete from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import NameIcon from "@material-ui/icons/Person";
// custom components
import Card from "../../layouts/components/Card/Card";
import TextField from "@material-ui/core/TextField";
import CardHeader from "../../layouts/components/Card/CardHeader.js";
import CardBody from "../../layouts/components/Card/CardBody.js";
import Button from "../../layouts/components/CustomButtons/Button";
import AddCircle from "@material-ui/icons/AddCircle";
import DatePicker from "../../layouts/components/DatePicker/DatePicker.js";
import DateAndTimePicker from "../../layouts/components/DatePicker/DateAndTimePicker.js";
// import DatePickerKeyboard from "../../layouts/components/DatePicker/DatePickerKeyboard.js";
import {
  getMrfLogisticsByDate,
  saveMrfLogistics,
  getMrfCustomers,
} from "../../../../../services/CurbCycleApi";

// helpers and utils
import materialTypeHelper from "utilities/materialTypeHelper.js";

function MrfLogisticsPage({ mrfUuid, materialTypes }) {
  const [data, setData] = useState(undefined);
  const [date, setDate] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [mrfCustomers, setMrfCustomers] = useState([]);

  const theCardHeaderStyle = {
    fontFamily: "nunito",
    fontSize: "2.0em",
    padding: 30,
  };

  useEffect(() => {
    const getAndSetData = async () => {
      const response = await getMrfLogisticsByDate(mrfUuid, date);
      if (response?.data) {
        const dataWithId = response.data.map((x, i) => {
          x.id = i;
          return x;
        });
        setData(dataWithId);
      }
    };

    const getAndSetCustomers = async () => {
      const response = await getMrfCustomers(mrfUuid);
      if (response?.data) {
        setMrfCustomers(response.data);
      }
    };

    getAndSetData();
    getAndSetCustomers();
    setIsLoading(false);
  }, []);

  const saveData = async () => {
    const updatedData = data.map((item) => {
      const itemCopy = { ...item };
      delete itemCopy.id;
      return itemCopy;
    });

    await saveMrfLogistics(mrfUuid, updatedData);
  };

  const dateNow = new Date();

  const VehicleDetails = ({ data }) => {
    return (
      <List style={{ width: "100%" }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{ width: "25px", height: "25px" }}>
              <NameIcon style={{ width: "20px", height: "20px" }} />
            </Avatar>
          </ListItemAvatar>

          {isEditMode ? (
            <TextField
              style={{
                marginLeft: 0,
              }}
              value={data.driverName}
              //onChange={(e) => updateDriverName(data.id, e.targetValue )}
            />
          ) : (
            <ListItemText primary="" secondary={data.driverName} />
          )}
        </ListItem>

        {/* <Divider variant="inset" component="li" /> */}
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{ width: "25px", height: "25px" }}>
              <LocalShipping style={{ width: "20px", height: "20px" }} />
            </Avatar>
          </ListItemAvatar>

          {isEditMode ? (
            <TextField
              style={{
                marginLeft: 0,
              }}
              value={data.rego}
              //onChange={(e) => updateDriverName(data.id, e.targetValue )}
            />
          ) : (
            <ListItemText primary="" secondary={data.rego} />
          )}
        </ListItem>
      </List>
    );
  };

  const MaterialTypesSelect = ({ item }) => {
    const materialTypeNames = materialTypes?.map((x) => x.name) || [];
    return (
      <Select
        style={{
          marginLeft: "1em",
        }}
        value={item.value || ""}
        //defaultValue={item.value || ""}
        onChange={(e) => onMaterialTypeSelect(e, item.id)}
        inputProps={{
          name: "material_type",
          id: "material_type_select",
        }}
      >
        {materialTypeNames.map((type) => {
          return (
            <option key={type} value={type}>
              {materialTypeHelper.getMaterialName(type)}
            </option>
          );
        })}
      </Select>
    );
  };

  const ButtonBlock = () => {
    return (
      <div style={{ padding: 15 }}>
        {showAddButton && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            onClick={addNew}
          >
            Add New
          </Button>
        )}
        {showDeleteButton && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<Delete />}
            onClick={removeItems}
          >
            Delete
          </Button>
        )}
      </div>
    );
  };

  const addNew = () => {
    setData([
      ...data,
      {
        id: data.length,
        arrivalTime: Date.now(),
        departureTime: Date.now(),
        pinCode: "",
        customerAccount: "",
        materialType: "",
        arrivalWeight: "",
        departureWeight: "",
        jobType: "inbound",
        vehicleDetails: {
          driverName: "",
          rego: "",
          type: "",
        },
      },
    ]);
  };

  const removeItems = () => {
    const updatedData = data.filter(
      (item, index) => !itemsToDelete.includes(index)
    );
    setData(updatedData);
  };

  const onItemUpdate = (item) => {
    updateItem(item.id, item.field, item.props.value);
  };

  const updateItem = (id, field, value) => {
    if (field !== "vehicleDetails" && isEditMode) {
      setIsEditMode(false);
    }
    let itemToUpdate = (data || []).find((x) => x.id === id);
    itemToUpdate[field] = value;
  };

  const onMaterialTypeSelect = (event, id) => {
    updateItem(id, "materialType", event.target.value);
  };

  const updateDriverName = (id, name) => {
    const newVehicleInfo = { ...data.vehicleDetails, driverName: name };
    updateItem(id, "vehicleDetails", newVehicleInfo);
  };

  const onJobTypeSelect = (value, id) => {
    updateItem(id, "jobType", value);
  };

  const onCustomerSelect = (value, id) => {
    updateItem(id, "pinCode", value.pinCode);
    updateItem(id, "customerAccount", value.customerAccount);
  };

  const handleCheckboxClick = (event) => {
    setShowAddButton(false);
    setShowDeleteButton(true);

    // no selected checkboxes
    if (!event?.length) {
      setShowAddButton(true);
      setShowDeleteButton(false);
    }

    setItemsToDelete(event);
  };

  const JobTypeEditMode = ({ id, jobType }) => {
    const tabValue = jobType === "inbound" ? 0 : 1;
    return (
      <Tabs
        value={tabValue}
        centered
        onChange={(e) => {
          const currentValue = tabValue === 0 ? "outbound" : "inbound";
          onJobTypeSelect(currentValue, id);
        }}
        indicatorColor="primary"
        textColor="primary"
        aria-label="icon label tabs example"
      >
        <Tab
          icon={<LocalShipping />}
          label={<span style={{ textTransform: "none" }}>Inbound</span>}
        />
        <Tab
          icon={
            <LocalShipping
              style={{
                MozTransform: "rotateY(180deg)",
                OTransform: "rotateY(180deg)",
                WebkitTransform: "rotateY(180deg)",
                transform: "rotateY(180deg)",
              }}
            />
          }
          label={<span style={{ textTransform: "none" }}>Outbound</span>}
        />
      </Tabs>
    );
  };

  const JobType = ({ jobType }) => {
    return jobType === "inbound" ? (
      <Tab
        label={<span style={{ textTransform: "none" }}>Inbound</span>}
        icon={<LocalShipping />}
      />
    ) : (
      <Tab
        icon={
          <LocalShipping
            style={{
              MozTransform: "rotateY(180deg)",
              OTransform: "rotateY(180deg)",
              WebkitTransform: "rotateY(180deg)",
              transform: "rotateY(180deg)",
            }}
          />
        }
        label={<span style={{ textTransform: "none" }}>Outbound</span>}
      />
    );
  };

  const CustomerSelect = ({ item }) => {
    return (
      <Select
        style={{
          marginLeft: "1em",
        }}
        defaultValue={{
          customerAccount: item.row.customerAccount,
          pinCode: item.row.pinCode,
        }}
        // defaultValue={{customerAccount: item.row.customerAccount, pinCode: item.row.customerAccount}}
        onChange={(x) => {
          onCustomerSelect(x.target.value, item.id);
        }}
        inputProps={{
          name: "customer",
          id: "customer_select",
        }}
      >
        {mrfCustomers.map((item) => {
          return (
            <option
              name="tania"
              key={item.pinCode}
              value={{ customerAccount: item.name, pinCode: item.pinCode }}
            >
              {`${item.pinCode}: ${item.name}`}
            </option>
          );
        })}
      </Select>
    );
  };

  const tableData = {
    columns: [
      {
        field: "id",
        headerName: "Id",
        width: 1,
        hide: true,
        editable: false,
      },
      {
        field: "jobType",
        headerName: "Job Type",
        width: 260,
        editable: true,
        renderCell: (item) => {
          return isEditMode ? (
            <JobTypeEditMode id={item.id} jobType={item.value} />
          ) : (
            <JobType jobType={item.value} />
          );
        },
      },
      {
        field: "arrivalTime",
        headerName: "Arrival Time",
        width: 180,
        editable: true,
        renderCell: (item) => {
          return <DateAndTimePicker date={item.value} />;
        },
      },
      {
        field: "departureTime",
        headerName: "Departure Time",
        width: 180,
        editable: true,
        renderCell: (item) => {
          return <DateAndTimePicker date={item.value} />;
        },
      },
      {
        field: "vehicleDetails",
        headerName: "Vehicle Details",
        width: 300,
        editable: true,
        renderCell: (item) => {
          return (
            <VehicleDetails
              data={{
                id: item.id,
                driverName: item.row.vehicleDetails.driverName,
                rego: item.row.vehicleDetails.rego,
              }}
            />
          );
        },
      },
      {
        field: "customer",
        headerName: "Pincode/Customer",
        width: 180,
        editable: true,
        renderCell: (item) => {
          return isEditMode ? (
            <CustomerSelect item={item} />
          ) : (
            `${item.row.pinCode}: ${item.row.customerAccount}`
          );
        },
      },
      {
        field: "materialType",
        headerName: "Material Type",
        width: 180,
        editable: true,
        renderCell: (item) => {
          return isEditMode ? (
            <MaterialTypesSelect item={item} />
          ) : (
            materialTypeHelper.getMaterialName(item.value)
          );
        },
      },
      {
        field: "arrivalWeight",
        headerName: "Arrival Weight",
        width: 180,
        editable: true,
      },
      {
        field: "departureWeight",
        headerName: "Departure Weight",
        width: 180,
        editable: true,
      },
      {
        field: "netWeight",
        headerName: "Net Weight",
        width: 180,
        editable: false,
      },
    ],
    rows: data
      ? data.map((x, index) => {
          return {
            id: index,
            arrivalTime: x.arrivalTime,
            departureTime: x.departureTime,
            pinCode: x.pinCode,
            customerAccount: x.customerAccount,
            materialType: x.materialType,
            arrivalWeight: x.arrivalWeightKg,
            departureWeight: x.departureWeightKg,
            vehicleDetails: x.vehicleDetails,
            netWeight:
              x.arrivalWeightKg && x.departureWeightKg
                ? x.arrivalWeightKg - x.departureWeightKg
                : "",
            jobType: x.jobType,
          };
        })
      : [],
  };

  return (
    <Fade in={true} timeout={3000}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" style={theCardHeaderStyle}>
              SMRF Logistics
            </CardHeader>
            <CardBody>
              <GridContainer
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-end"
              >
                <div style={{ width: "100%", marginBottom: "20px" }}>
                  Select a date: <DatePicker date={dateNow} />
                </div>
                <DataGrid
                  checkboxSelection
                  onSelectionModelChange={(e) => handleCheckboxClick(e)}
                  onCellClick={(row) => {
                    // if(row.field !== 'vehicleDetails') {
                    //   console.log('HERE!!!!!!!!!!!!!!!!!');
                    //   setIsEditMode(!isEditMode);
                    // };

                    // if (row.field === 'vehicleDetails' && !isEditMode) {
                    //   setIsEditMode(!isEditMode);
                    // };
                    setIsEditMode(!isEditMode);
                  }}
                  onEditCellChangeCommitted={(item) => onItemUpdate(item)}
                  components={{ Toolbar: ButtonBlock }}
                  disableSelectionOnClick
                  autoHeight
                  rowHeight={120}
                  {...tableData}
                />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Button
            style={{
              margin: "15px auto",
              width: "20%",
              display: "flex",
            }}
            color="primary"
            type="submit"
            onClick={(e) => saveData(e)}
          >
            Save
          </Button>
        </GridItem>
      </GridContainer>
    </Fade>
  );
}

MrfLogisticsPage.propTypes = {
  mrfUuid: PropTypes.string.isRequired,
  materialTypes: PropTypes.arrayOf({
    name: PropTypes.string.isRequired,
    baleTagColour: PropTypes.string.isRequired,
    indicativeDryWeightPerBale: PropTypes.number.isRequired,
    indicativeWetWeightPerBale: PropTypes.number.isRequired,
  }),
  data: PropTypes.arrayOf({
    driverName: PropTypes.string.isRequired,
    rego: PropTypes.string.isRequired,
  }).isRequired,
  jobType: PropTypes.string.isRequired,
  pinCode: PropTypes.string.isRequired,
};

export default MrfLogisticsPage;
