import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import paginationHelper from "../../../../utilities/paginationHelper";
import { useDispatch, connect } from "react-redux";
import pageChangeCurbyCodesWithFiltersThunk from "../../../../thunks/Management/CurbyCodes/DataPagination/pageChangeCurbyCodesWithFiltersThunk";
import useDebounce from "../../../../hooks/useDebounce";
// core components
import Fade from "@material-ui/core/Fade";
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import ActionableDataTable from "../../../../components/ActionableDataTable/ActionableDataTable";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../components/Card/CardIcon.js";
import CardBody from "../../../../components/Card/CardBody.js";

function CurbyCodes({ fetching, curbyCodeTableData, curbyCodeTableRawData }) {
  const dispatch = useDispatch();
  const [
    curbyCodeTableFilterOptions,
    setCurbyCodeTableFilterOptions,
  ] = useState(undefined);
  // const [curbyCodeTableFilterForStatus, setCurbyCodeTableFilterForStatus] = useState(undefined);
  const [
    curbyCodeTableFilterForBatchReference,
    setCurbyCodeTableFilterForBatchReference,
  ] = useState(null);
  const [
    curbyCodeTableFilterForShortCode,
    setCurbyCodeTableFilterForShortCode,
  ] = useState(null);

  const debouncedCurbyCodeTableFilterOptions = useDebounce(
    curbyCodeTableFilterOptions,
    100
  );
  // const debouncedCodeTableStatus = useDebounce(curbyCodeTableFilterForStatus, 300);
  const debouncedCodeTableBatchReference = useDebounce(
    curbyCodeTableFilterForBatchReference,
    300
  );
  const debouncedCodeTableShortCode = useDebounce(
    curbyCodeTableFilterForShortCode,
    300
  );

  useEffect(() => {
    if (undefined === debouncedCurbyCodeTableFilterOptions) {
      return;
    }
    dispatch(
      pageChangeCurbyCodesWithFiltersThunk(
        paginationHelper.generateFilterQueryStringFromFilterOptions(
          debouncedCurbyCodeTableFilterOptions
        )
      )
    );
  }, [debouncedCurbyCodeTableFilterOptions, dispatch]);

  // useEffect(() => {
  //   const handler = setTimeout(
  //     handleAddSimpleKeyValueFilterOption('status', debouncedCodeTableStatus),
  //     500
  //   );
  //
  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [debouncedCodeTableStatus]);

  useEffect(() => {
    const handler = setTimeout(
      paginationHelper.handleAddSimpleKeyValueFilterOption(
        "allocated_batch_reference",
        debouncedCodeTableBatchReference,
        setCurbyCodeTableFilterOptions,
        debouncedCurbyCodeTableFilterOptions
      ),
      500
    );

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedCodeTableBatchReference]);

  useEffect(() => {
    const handler = setTimeout(
      paginationHelper.handleAddSimpleKeyValueFilterOption(
        "short_code",
        debouncedCodeTableShortCode,
        setCurbyCodeTableFilterOptions,
        debouncedCurbyCodeTableFilterOptions
      ),
      500
    );

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedCodeTableShortCode]);

  const handleBatchReferenceInput = (event) => {
    setCurbyCodeTableFilterForBatchReference(event.target.value);
  };

  const handleShortCodeInput = (event) => {
    setCurbyCodeTableFilterForShortCode(event.target.value);
  };

  return (
    <div>
      <Fade in={true} timeout={1000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card loading={fetching}>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">Manage CurbyCodes</CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <form name="short_code">
                      <p>Short Code</p>
                      <input
                        type="text"
                        name="short_code"
                        defaultValue={debouncedCodeTableShortCode}
                        onChange={handleShortCodeInput}
                      />
                    </form>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <form name="batch_reference">
                      <p>Batch Reference</p>
                      <input
                        type="text"
                        name="allocated_batch_reference"
                        defaultValue={debouncedCodeTableBatchReference}
                        onChange={handleBatchReferenceInput}
                      />
                    </form>
                  </GridItem>
                </GridContainer>

                <ActionableDataTable
                  tableHeaderColor="warning"
                  tableHead={["Code", "Batch Ref", "Status", "Status Reason"]}
                  tableData={curbyCodeTableData}
                  pagination={true}
                  paginationConfig={{
                    collectionData: curbyCodeTableRawData,
                    handlePageChange: (payload) => {
                      setCurbyCodeTableFilterOptions(
                        paginationHelper.addSimpleKeyValueFilterOption(
                          debouncedCurbyCodeTableFilterOptions,
                          "page",
                          payload.selected,
                          true,
                          false
                        )
                      );
                    },
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fade>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    curby_codes: state.journeyFlows.CurbCycleManagement.curby_codes.curby_codes,
    curbyCodeTableRawData:
      state.journeyFlows.CurbCycleManagement.curby_codes.curby_codes,
    curbyCodeTableData: state.journeyFlows.CurbCycleManagement.curby_codes.curby_codes.data.map(
      (item) => {
        return [
          {
            display: item.short_code,
          },
          {
            display: item.allocated_batch_reference,
          },
          {
            display: item.status,
          },
          {
            display: item.status_reason,
          },
        ];
      }
    ),
    fetching: state.journeyFlows.CurbCycleManagement.curby_codes.fetching,
  };
}

CurbyCodes.propTypes = {
  curbyCodeTableData: PropTypes.any,
  curbyCodeTableRawData: PropTypes.any,
  fetching: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(CurbyCodes);
