const uuidHelper = require("./UuidHelper").uuidHelper();
const clone = require("clone");

const ContentRules = {
  contentRuleCrudManager: function () {
    /**
     * Properties
     */
    let self = {};

    /**
     * Template for new content rules
     *
     * @type {{uuid: string, name: string, type: string, label: string}}
     */
    self.contentRuleTemplate = {
      rule: "",
      type: "",
      value: "",
      state: "",
    };

    /**
     * Content Service
     *
     * @type {{}}
     */
    self.contentRuleService = {};

    /**
     * Function for handling updates to array indices
     *
     * @param array
     * @param oldIndex
     * @param newIndex
     *
     * @returns []
     */
    self.moveIndex = function (array, oldIndex, newIndex) {
      Array.prototype.moveIndex = function (oldIndexValue, newIndexValue) {
        if (newIndexValue >= this.length) {
          var k = newIndexValue - this.length;
          while (k-- + 1) {
            this.push(undefined);
          }
        }
        this.splice(newIndexValue, 0, this.splice(oldIndexValue, 1)[0]);
      };

      array.moveIndex(oldIndex, newIndex);
      return array;
    };

    /**
     * Helper method to split rel to array elements based on '::'
     *
     * Example of a rel:
     * '_components_::0::sections::1::fieldsets::3::fields::1'
     *
     * Expected result:
     * [
     *   '_components_',
     *   0,
     *   'sections',
     *   1,
     *   'fieldsets',
     *   3,
     *   'fields',
     *   1
     * ]
     *
     * @param rel
     * @returns {Array}
     */
    self.relToParts = function (rel) {
      var parts = rel.split("::");
      for (var i = 0; i < parts.length; i++) {
        if (!isNaN(parts[i])) {
          parts[i] = parseInt(parts[i]);
        }
      }
      return parts;
    };

    /**
     * Function for cleaning elements of a type from an array
     *
     * @param array
     * @param type
     * @returns []
     */
    self.cleanArray = function (array, type) {
      if (array.constructor !== Array) {
        return [];
      }

      Array.prototype.clean = function (deleteValue) {
        for (var i = 0; i < this.length; i++) {
          if (this[i] === deleteValue) {
            this.splice(i, 1);
            i--;
          }
        }

        if (this.length === 0) {
          return [];
        }

        return this;
      };

      array.clean(array, type);
      return array;
    };

    self.contentRuleService.getContentRuleStorageName = function () {
      let storageName = "";

      if (self.contentRuleService.hasCurrentProjectPrefix) {
        storageName += localStorage["current-project-prefix"] + "-";
      }

      storageName += "content-rule-tree";

      return storageName;
    };

    self.contentRuleService.getDefaultContentStateStorageName = function () {
      let storageName = "";

      if (self.contentRuleService.hasCurrentProjectPrefix) {
        storageName += localStorage["current-project-prefix"] + "-";
      }

      storageName += "default-content-states";

      return storageName;
    };

    self.contentRuleService.getStageProgressionRuleStorageName = function () {
      let storageName = "";

      if (self.contentRuleService.hasCurrentProjectPrefix) {
        storageName += localStorage["current-project-prefix"] + "-";
      }

      storageName += "stage-progression-rules";

      return storageName;
    };

    self.contentRuleService.getDefaultContentStates = function () {
      return JSON.parse(
        localStorage.getItem(
          self.contentRuleService.getDefaultContentStateStorageName()
        )
      );
    };

    self.contentRuleService.getStageProgressionRuleTree = function () {
      return JSON.parse(
        localStorage.getItem(
          self.contentRuleService.getStageProgressionRuleStorageName()
        )
      );
    };

    /**
     * Check if the content rule tree exists in localstorage
     *
     * @returns {boolean}
     */
    self.contentRuleService.hasContentRuleTree = function () {
      return !!localStorage[
        self.contentRuleService.getContentRuleStorageName()
      ];
    };

    /**
     * Check if the current project prefix exists in localstorage
     *
     * @returns {boolean}
     */
    self.contentRuleService.hasCurrentProjectPrefix = function () {
      return !!localStorage["current-project-prefix"];
    };

    /**
     * Update the content rule tree
     *
     * @param data
     */
    self.contentRuleService.updateContentRuleTree = function (data) {
      if (
        self.contentRuleService.hasContentRuleTree() &&
        self.contentRuleService.getContentRuleTree() === data
      ) {
        return;
      }

      localStorage.setItem(
        self.contentRuleService.getContentRuleStorageName(),
        JSON.stringify(data)
      );
    };

    /**
     * Update the default content states config
     *
     * @param data
     */
    self.contentRuleService.updateDefaultContentStates = function (data) {
      localStorage.setItem(
        self.contentRuleService.getDefaultContentStateStorageName(),
        JSON.stringify(data)
      );
    };

    /**
     * Update the default content states config
     *
     * @param data
     */
    self.contentRuleService.updateStageProgressionRuleTree = function (data) {
      localStorage.setItem(
        self.contentRuleService.getStageProgressionRuleStorageName(),
        JSON.stringify(data)
      );
    };

    /**
     * Update the content tree by rel path
     *
     * @param context_focus
     * @param rel_path
     * @param append_only
     */
    self.updateContentRuleByRelPath = function (
      context_focus,
      rel_path,
      append_only
    ) {
      let parts = self.relToParts(rel_path);

      if (parts[0] !== "_pages_") {
        throw new Error("Unexpected rel path structure");
      }

      let type = context_focus.type;

      if (append_only) {
        let content_tree = self.contentRuleService.getContentRuleTree();
        console.log(context_focus, type);
        switch (type) {
          case "page":
            content_tree["_pages_"].push(context_focus);
            break;
          case "content-section":
            content_tree["_pages_"][parts[1]]["content-sections"].push(
              context_focus
            );
            break;
          case "container":
            content_tree["_pages_"][parts[1]]["content-sections"][parts[3]][
              "containers"
            ].push(context_focus);
            break;
          case "content":
            content_tree["_pages_"][parts[1]]["content-sections"][parts[3]][
              "containers"
            ][parts[5]]["content"].push(context_focus);
            break;
          default:
            throw new Error("That should not happen");
        }
        self.contentRuleService.updateContentRuleTree(content_tree);
      } else {
        switch (type) {
          case "page":
            self.pageUpdate(parts[1], context_focus);
            break;
          case "content-section":
            self.contentSectionUpdate(parts[1], parts[3], context_focus);
            break;
          case "container":
            self.containerUpdate(parts[1], parts[3], parts[5], context_focus);
            break;
          case "content":
            self.contentUpdate(
              parts[1],
              parts[3],
              parts[5],
              parts[7],
              context_focus
            );
            break;
          default:
            throw new Error("That should not happen");
        }
      }
    };

    /**
     * Delete from the content tree by rel path
     *
     * @param rel_path
     */
    self.deleteContentRuleByRelPath = function (rel_path) {
      let parts = self.relToParts(rel_path);

      if (parts[0] !== "_pages_") {
        throw new Error("Unexpected rel path structure");
      }

      switch (parts.length) {
        case 2:
          self.pageDelete(parts[1]);
          break;
        case 4:
          self.contentSectionDelete(parts[1], parts[3]);
          break;
        case 6:
          self.containerDelete(parts[1], parts[3], parts[5]);
          break;
        case 8:
          self.contentDelete(parts[1], parts[3], parts[5], parts[7]);
          break;
        default:
          throw new Error("That should not happen");
      }
    };

    /**
     *
     * @param relPath
     * @param targetRelPath
     */
    self.moveContentRuleByRelPath = function (
      relPath,
      targetRelPath,
      deleteFromOriginalLocation
    ) {
      let relPathParts = self.relToParts(relPath);
      let targetRelPathParts = self.relToParts(targetRelPath);

      console.log(relPathParts);
      console.log(targetRelPathParts);

      if (targetRelPathParts[0] !== "_pages_") {
        throw new Error("Unexpected rel path structure");
      }

      let target = {};

      switch (targetRelPathParts.length) {
        case 2:
          // moving a content section to a page
          if (relPathParts.length !== 4) {
            throw new Error("Unexpected number of parts");
          }

          target = self.getFromTreeByRelPath(targetRelPath);
          let numberOfContentSections = target["content-sections"].length; // eslint-disable-line no-case-declarations

          // append to the target
          target["content-sections"][
            numberOfContentSections
          ] = self.getFromTreeByRelPath(relPath);

          if (deleteFromOriginalLocation) {
            // delete from original location
            self.deleteContentRuleByRelPath(relPath);
          }

          // update the target
          self.updateContentRuleByRelPath(target, targetRelPath);
          break;
        case 4:
          // moving a container to a content section
          if (relPathParts.length !== 6) {
            throw new Error("Unexpected number of parts");
          }

          target = self.getFromTreeByRelPath(targetRelPath);
          // let numberOfContainers = target["containers"].length;

          // append to the target
          target["containers"].push(self.getFromTreeByRelPath(relPath));

          if (deleteFromOriginalLocation) {
            // delete from original location
            self.deleteContentRuleByRelPath(relPath);
          }

          // update the target
          self.updateContentRuleByRelPath(target, targetRelPath);
          break;
        case 6:
          // moving content to a container
          if (relPathParts.length !== 8) {
            throw new Error("Unexpected number of parts");
          }

          target = self.getFromTreeByRelPath(targetRelPath);
          let numberOfContentElements = target["content"].length; // eslint-disable-line no-case-declarations

          // append to the target
          target["content"][
            numberOfContentElements
          ] = self.getFromTreeByRelPath(relPath);

          if (deleteFromOriginalLocation) {
            // delete from original location
            self.deleteContentRuleByRelPath(relPath);
          }

          // update the target
          self.updateContentRuleByRelPath(target, targetRelPath);
          break;
        default:
          throw new Error("That should not happen");
      }
    };

    /**
     * Returns an item from the component tree based on a valid rel
     *
     * @param relPath
     * @returns {*}
     */
    self.getFromTreeByRelPath = function (relPath) {
      let parts = self.relToParts(relPath);
      let tree = self.contentRuleService.getContentRuleTree();
      let item = {};
      let partsCount = parts.length;

      /**
       * Not a very nice way to do it but fine for MVP
       */
      switch (partsCount) {
        case 0:
          item = tree;
          break;
        case 1:
          item = tree[parts[0]];
          break;
        case 2:
          item = tree[parts[0]];
          item = item[parts[1]];
          break;
        case 3:
          item = tree[parts[0]];
          item = item[parts[1]];
          item = item[parts[2]];
          break;
        case 4:
          item = tree[parts[0]];
          item = item[parts[1]];
          item = item[parts[2]];
          item = item[parts[3]];
          break;
        case 5:
          item = tree[parts[0]];
          item = item[parts[1]];
          item = item[parts[2]];
          item = item[parts[3]];
          item = item[parts[4]];
          break;
        case 6:
          item = tree[parts[0]];
          item = item[parts[1]];
          item = item[parts[2]];
          item = item[parts[3]];
          item = item[parts[4]];
          item = item[parts[5]];
          break;
        case 7:
          item = tree[parts[0]];
          item = item[parts[1]];
          item = item[parts[2]];
          item = item[parts[3]];
          item = item[parts[4]];
          item = item[parts[5]];
          item = item[parts[6]];
          break;
        case 8:
          item = tree[parts[0]];
          item = item[parts[1]];
          item = item[parts[2]];
          item = item[parts[3]];
          item = item[parts[4]];
          item = item[parts[5]];
          item = item[parts[6]];
          item = item[parts[7]];
          break;
        case 9:
          item = tree[parts[0]];
          item = item[parts[1]];
          item = item[parts[2]];
          item = item[parts[3]];
          item = item[parts[4]];
          item = item[parts[5]];
          item = item[parts[6]];
          item = item[parts[7]];
          item = item[parts[8]];
          break;
        default:
          throw new Error("That should not happen");
      }

      return item;
    };

    /**
     * Returns the content tree
     *
     * Example of content tree structure:
     * {
     *   "_pages_" : [
     *     {
     *       "uuid" : "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
     *       "name" : "Some page name",
     *       "friendly-name" : "Some page name",
     *       "type" : "page",
     *       "label" : "Some page name",
     *       "content-sections" : [
     *         {
     *           "uuid" : "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
     *           "name" : "Some section name",
     *           "friendly-name" : "Some section name",
     *           "type" : "content-section",
     *           "label" : "Some section name",
     *           "containers": [
     *             {
     *               "uuid" : "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
     *               "name" : "Some container name",
     *               "friendly-name" : "Some container name",
     *               "type" : "container",
     *               "label" : "Some container name",
     *               "content": [
     *                 {
     *                   "uuid" : "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
     *                   "name" : "Some content name",
     *                   "friendly-name" : "Some content name",
     *                   "type" : "content",
     *                   "label" : "Some content name",
     *                 }
     *               ]
     *             }
     *           ]
     *         }
     *       ]
     *     }
     *   ]
     * }
     *
     * @returns {{}}
     */
    self.contentRuleService.getContentRuleTree = function () {
      if (self.contentRuleService.hasContentRuleTree()) {
        return JSON.parse(
          localStorage.getItem(
            self.contentRuleService.getContentRuleStorageName()
          )
        );
      }
    };

    /**
     * Returns the content sections from the tree
     *
     * Allows for providing a path to exclude
     *
     * @param optionalExclusionByRelPath
     * @returns {Array}
     */
    self.getArrayOfContentRuleTreeContentSections = function (
      optionalExclusionByRelPath
    ) {
      let tree = self.contentRuleService.getContentRuleTree();

      let numberOfPages = tree["_pages_"].length;
      let dataToReturn = [];

      for (let i = 0; i < numberOfPages; i++) {
        let thisPage = tree["_pages_"][i];
        let numberOfContentSections = thisPage["content-sections"].length;

        for (let j = 0; j < numberOfContentSections; j++) {
          let thisContentSection = tree["_pages_"][i]["content-sections"][j];
          let relPath = "_pages_::" + i + "::content-sections::" + j;

          if (undefined !== optionalExclusionByRelPath) {
            let exclusionPathParts = self.relToParts(
              optionalExclusionByRelPath
            );
            let relPathParts = self.relToParts(relPath);

            if (
              exclusionPathParts[1] === relPathParts[1] &&
              exclusionPathParts[3] === relPathParts[3]
            ) {
              // ignore these
            }
            // else {
            //   {
            //     dataToReturn.push({
            //       name: thisContentSection["friendly-name"],
            //       rel_path: relPath,
            //       parent: thisContentSection,
            //     });
            //   }
            // }
          }
          // else {
          //   dataToReturn.push({
          //     name: thisContentSection["friendly-name"],
          //     rel_path: relPath,
          //     parent: thisContentSection,
          //   });
          // }
        }
      }

      return dataToReturn;
    };

    /**
     * Returns the containers from the tree
     *
     * Allows for providing a path to exclude
     *
     * @param optionalExclusionByRelPath
     * @returns {Array}
     */
    self.getArrayOfContentRuleTreeContainers = function (
      optionalExclusionByRelPath
    ) {
      let tree = self.contentRuleService.getContentRuleTree();

      let numberOfPages = tree["_pages_"].length;
      let dataToReturn = [];

      for (let i = 0; i < numberOfPages; i++) {
        let thisPage = tree["_pages_"][i];
        let numberOfContentSections = thisPage["content-sections"].length;

        for (let j = 0; j < numberOfContentSections; j++) {
          let thisContentSection = tree["_pages_"][i]["content-sections"][j];
          let numberOfContainers = thisContentSection["containers"].length;

          for (let k = 0; k < numberOfContainers; k++) {
            let thisContainer =
              tree["_pages_"][i]["content-sections"][j]["containers"][k];
            let relPath =
              "_pages_::" +
              i +
              "::content-sections::" +
              j +
              "::containers::" +
              k;

            if (undefined !== optionalExclusionByRelPath) {
              let exclusionPathParts = self.relToParts(
                optionalExclusionByRelPath
              );
              let relPathParts = self.relToParts(relPath);

              if (
                exclusionPathParts[1] === relPathParts[1] &&
                exclusionPathParts[3] === relPathParts[3] &&
                exclusionPathParts[5] === relPathParts[5]
              ) {
                // ignore these
              }
              // else {
              //   {
              //     dataToReturn.push({
              //       name: thisContainer["friendly-name"],
              //       rel_path: relPath,
              //       parent: thisContainer,
              //     });
              //   }
              // }
            }
            // else {
            //   dataToReturn.push({
            //     name: thisContainer["friendly-name"],
            //     rel_path: relPath,
            //     parent: thisContainer,
            //   });
            // }
          }
        }
      }

      return dataToReturn;
    };

    /**
     * Methods
     */

    /**
     * @returns string
     */
    self.generateUuid = function () {
      return uuidHelper.newUuid();
    };

    /**
     * Create page using self.pageTemplate
     *
     * @returns {{}}
     */
    self.pageCreate = function () {
      let template = clone(self.pageTemplate);
      template.uuid = uuidHelper.newUuid();
      return template;
    };

    /**
     * Get the page based on supplied index
     *
     * @param pageIndex
     * @returns {{}}
     */
    self.pageRead = function (pageIndex) {
      var contentTree = self.contentRuleService.getContentRuleTree();
      return contentTree["_pages_"][pageIndex];
    };

    /**
     * Update the page based on supplied index and data
     *
     * @param pageIndex
     * @param pageData
     */
    self.pageUpdate = function (pageIndex, pageData) {
      var contentTree = self.contentRuleService.getContentRuleTree();
      contentTree["_pages_"][pageIndex] = pageData;
      self.contentRuleService.updateContentRuleTree(contentTree);
    };

    /**
     * Delete the page based on supplied index
     *
     * @param pageIndex
     */
    self.pageDelete = function (pageIndex) {
      var contentTree = self.contentRuleService.getContentRuleTree();
      contentTree["_pages_"].splice(pageIndex, 1);
      self.contentRuleService.updateContentRuleTree(contentTree);
    };

    /**
     * Create section using self.contentSectionTemplate
     *
     * @returns {{}}
     */
    self.contentSectionCreate = function () {
      let template = clone(self.contentSectionTemplate);
      template.uuid = uuidHelper.newUuid();
      return template;
    };

    /**
     * Get the section based on supplied indices
     *
     * @param pageIndex
     * @param sectionIndex
     * @returns {{}}
     */
    self.contentSectionRead = function (pageIndex, sectionIndex) {
      var contentTree = self.contentRuleService.getContentRuleTree();
      return contentTree["_pages_"][pageIndex]["content-sections"][
        sectionIndex
      ];
    };

    /**
     * Update the section based on supplied indices and data
     *
     * @param pageIndex
     * @param sectionIndex
     * @param sectionData
     */
    self.contentSectionUpdate = function (
      pageIndex,
      sectionIndex,
      sectionData
    ) {
      var contentTree = self.contentRuleService.getContentRuleTree();
      contentTree["_pages_"][pageIndex]["content-sections"][
        sectionIndex
      ] = sectionData;
      self.contentRuleService.updateContentRuleTree(contentTree);
    };

    /**
     * Delete the section based on supplied indices
     *
     * @param pageIndex
     * @param sectionIndex
     */
    self.contentSectionDelete = function (pageIndex, sectionIndex) {
      var contentTree = self.contentRuleService.getContentRuleTree();

      // delete the item from the content tree
      contentTree["_pages_"][pageIndex]["content-sections"].splice(
        sectionIndex,
        1
      );

      // guard against null values being left in the content tree
      contentTree["_pages_"][pageIndex]["content-sections"] = self.cleanArray(
        contentTree["_pages_"][pageIndex]["content-sections"],
        null
      );

      // commit the changes to the content tree
      self.contentRuleService.updateContentRuleTree(contentTree);
    };

    /**
     * Create container using self.containerTemplate
     *
     * @returns {{}}
     */
    self.containerCreate = function () {
      let template = clone(self.containerTemplate);
      template.uuid = uuidHelper.newUuid();
      return template;
    };

    /**
     * Get the container based on supplied indices
     *
     * @param pageIndex
     * @param sectionIndex
     * @param containerIndex
     * @returns {{}}
     */
    self.containerRead = function (pageIndex, sectionIndex, containerIndex) {
      var contentTree = self.contentRuleService.getContentRuleTree();
      return contentTree["_pages_"][pageIndex]["content-sections"][
        sectionIndex
      ]["containers"][containerIndex];
    };

    /**
     * Update the container based on supplied indices and data
     *
     * @param pageIndex
     * @param sectionIndex
     * @param containerIndex
     * @param containerData
     */
    self.containerUpdate = function (
      pageIndex,
      sectionIndex,
      containerIndex,
      containerData
    ) {
      var contentTree = self.contentRuleService.getContentRuleTree();
      contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
        "containers"
      ][containerIndex] = containerData;
      self.contentRuleService.updateContentRuleTree(contentTree);
    };

    /**
     * Delete the container based on supplied indices
     *
     * @param pageIndex
     * @param sectionIndex
     * @param containerIndex
     */
    self.containerDelete = function (pageIndex, sectionIndex, containerIndex) {
      var contentTree = self.contentRuleService.getContentRuleTree();

      // delete the item from the content tree
      contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
        "containers"
      ].splice(containerIndex, 1);

      // guard against null values being left in the content tree
      contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
        "containers"
      ] = self.cleanArray(
        contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
          "containers"
        ],
        null
      );

      // commit the changes to the content tree
      self.contentRuleService.updateContentRuleTree(contentTree);
    };

    /**
     * Create content using self.contentTemplate
     *
     * return {{}}
     */
    self.contentCreate = function () {
      let template = clone(self.contentTemplate);
      template.uuid = uuidHelper.newUuid();
      return template;
    };

    /**
     * Get the content based on supplied indices
     *
     * @param pageIndex
     * @param sectionIndex
     * @param containerIndex
     * @param contentIndex
     * @returns {{}}
     */
    self.contentRead = function (
      pageIndex,
      sectionIndex,
      containerIndex,
      contentIndex
    ) {
      var contentTree = self.contentRuleService.getContentRuleTree();
      return contentTree["_pages_"][pageIndex]["content-sections"][
        sectionIndex
      ]["containers"][containerIndex]["content"][contentIndex];
    };

    /**
     * Update the content based on supplied indices and data
     *
     * @param pageIndex
     * @param sectionIndex
     * @param containerIndex
     * @param contentIndex
     * @param contentData
     */
    self.contentUpdate = function (
      pageIndex,
      sectionIndex,
      containerIndex,
      contentIndex,
      contentData
    ) {
      var contentTree = self.contentRuleService.getContentRuleTree();
      contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
        "containers"
      ][containerIndex]["content"][contentIndex] = contentData;
      self.contentRuleService.updateContentRuleTree(contentTree);
    };

    /**
     * Delete the content based on supplied indices
     *
     * @param pageIndex
     * @param sectionIndex
     * @param containerIndex
     * @param contentIndex
     */
    self.contentDelete = function (
      pageIndex,
      sectionIndex,
      containerIndex,
      contentIndex
    ) {
      var contentTree = self.contentRuleService.getContentRuleTree();

      // delete the item from the content tree
      contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
        "containers"
      ][containerIndex]["content"].splice(contentIndex, 1);

      // guard against null values being left in the content tree
      contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
        "containers"
      ][containerIndex]["content"] = self.cleanArray(
        contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
          "containers"
        ][containerIndex]["content"],
        null
      );

      // commit the changes to the content tree
      self.contentRuleService.updateContentRuleTree(contentTree);
    };

    /**
     * Initial setup
     */

    // return
    return self;
  },
};

export default ContentRules;
