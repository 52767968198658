import React, { useState } from "react";
import PropTypes from "prop-types";
import GridItem from "../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../components/Grid/GridContainer.js";
import ToolingDrawer from "./layouts/ToolingDrawer";
import ContentToolDrawer from "./layouts/ContentToolDrawer";

function ContentManagerUi({ config }) {
  // const exampleConfig = {
  //   feed: {
  //     current_page: 1,
  //     data: [
  //       {
  //         uuid: "92a010a0-229e-4208-95af-53383b2bf276",
  //         type: "card",
  //         title: "Just testing",
  //         content: {
  //           config: [
  //             {
  //               uuid: "92a010a0-229e-4208-95af-53383b2bf277",
  //               type: "text:leftAlign:bold:h2",
  //               content: "Just testing again",
  //             },
  //             {
  //               uuid: "92a010a0-229e-4208-95af-53383b2bf279",
  //               type: "text:subdued",
  //               content: "Just testing again too!",
  //             },
  //             {
  //               uuid: "92a010a0-229e-4208-95af-53383b2bf274",
  //               type: "image",
  //               source: { uri: "https://cdn.curby.cc/curby-06-image.png" },
  //               style: {
  //                 resizeMode: "cover",
  //                 width: 330,
  //                 height: 300,
  //               },
  //             },
  //             {
  //               uuid: "92a010a0-229e-4208-95af-53383b2bf272",
  //               type: "text:caption:bold",
  //               content: "Just testing again too!",
  //             },
  //           ],
  //         },
  //       },
  //       {
  //         uuid: "92a010a0-229e-4208-95af-53383b2bf271",
  //         type: "image",
  //         source: { uri: "https://cdn.curby.cc/curby-2.png" },
  //         style: {
  //           resizeMode: "contain",
  //           width: 200,
  //           height: 200,
  //         },
  //       },
  //       {
  //         uuid: "92a010a0-229e-4208-95af-53383b2bf270",
  //         type: "image",
  //         source: { uri: "https://cdn.curby.cc/curby-06-image.png" },
  //         style: {
  //           resizeMode: "contain",
  //           width: 200,
  //           height: 200,
  //         },
  //       },
  //     ],
  //     first_page_url: null,
  //     from: 1,
  //     last_page: 1,
  //     last_page_url: null,
  //     next_page_url: null,
  //     path: null,
  //     per_page: 20,
  //     prev_page_url: null,
  //     to: 1,
  //     total: 1,
  //   },
  // };

  const [contentTreeContext, setContentTreeContext] = useState(undefined);

  return (
    <div>
      <h1>Curby App Content Manger</h1>

      <ContentToolDrawer
        contentTreeContext={contentTreeContext}
        onContentTreeContextChangeCallback={(newContext) => {
          // {
          //   /*console.log({*/
          // }
          // {
          //   /*newContext: newContext*/
          // }
          // {
          //   /*});*/
          // }
          setContentTreeContext(newContext);
        }}
      />

      <ToolingDrawer
        contentTreeContext={contentTreeContext}
        onContentTreeContextChangeCallback={(newContext) => {
          // {
          //   /*console.log({*/
          // }
          // {
          //   /*newContext: newContext*/
          // }
          // {
          //   /*});*/
          // }
          setContentTreeContext(newContext);
        }}
      />
      <GridContainer
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem
              xs={11}
              sm={11}
              md={11}
              style={{
                marginLeft: "6rem",
              }}
            >
              <GridContainer
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <GridItem xs={12} sm={12} md={4}></GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  {/*<h2>Content Tree Context</h2>*/}
                  {/*<pre>*/}
                  {/*{JSON.stringify(contentTreeContext, null, 2)}*/}
                  {/*</pre>*/}
                </GridItem>

                <GridItem xs={12} sm={12} md={4}></GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

ContentManagerUi.propTypes = {
  config: PropTypes.any.isRequired,
}

export default ContentManagerUi;
