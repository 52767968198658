import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import editMrfThunk from "../../../../../thunks/Management/MrfOperations/editMrfThunk";
import { Formik } from "formik";
// core components
import GridItem from "../../../../../components/Grid/GridItem";
import GridContainer from "../../../../../components/Grid/GridContainer";
import Card from "../../../../../components/Card/Card.js";
import CardHeader from "../../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../../components/Card/CardIcon.js";
import CardBody from "../../../../../components/Card/CardBody.js";
import Button from "../../../../../components/CustomButtons/Button";

function EditMrfManager({ mrfs }) {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [wasSuccessful, setWasSuccessful] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (wasSuccessful) {
      history.push("/management/mrf-management-home");
    }
  }, [wasSuccessful, history]);

  const { uuid } = useParams();
  let mrf;
  let flowIsBroken;

  if (mrfs.length < 1) {
    flowIsBroken = true;
    mrf = {
      name: "",
    };
  } else {
    mrf = mrfs.reduce((item) => {
      if (item.uuid === uuid) {
        return item;
      }
      return item;
    });
  }

  if (flowIsBroken === true) {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">Error Occurred</CardIcon>
              </CardHeader>
              <CardBody>
                <Link to="/management/mrf-management-home">
                  <blockquote style={{ color: "#g2d2g2" }}>
                    This can happen when you refresh the page manually during an
                    operation or try to navigate directly to this screen. The
                    application currently depends on the state provided by the
                    previous page in an effort to speed up display of
                    information and prevent unnecessary refreshing of data.
                  </blockquote>
                  <Button
                    style={{ margin: "15px auto", width: "100%" }}
                    color={"info"}
                    link={false}
                  >
                    Return to SMRF Management Area
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                Edit "{mrf.name}" ({uuid})
              </CardIcon>
              <Link to="/management/mrf-management-home">
                <Button color={"warning"} link={true}>
                  <span className="material-icons">cancel</span> Cancel
                </Button>
              </Link>
            </CardHeader>
            <CardBody>
              <Formik
                initialValues={{ name: mrf.name }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = "Required";
                  } else if (!/^[ A-Za-z0-9._%+-]{2,255}$/i.test(values.name)) {
                    errors.name = "Invalid SMRF Name";
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  dispatch(
                    editMrfThunk(values, uuid, setIsSaving, setWasSuccessful)
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <label aria-label="SMRF Name">Name</label>
                    <input
                      type="text"
                      name="name"
                      style={{
                        width: "99%",
                        height: "2.2em",
                        fontSize: "1em",
                        border: "1px solid #d2d2d2",
                        paddingLeft: "10px",
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <span style={{ color: "#cc2526" }}>
                      {errors.name && touched.name && errors.name}
                    </span>
                    <Button
                      style={{ margin: "15px auto", width: "100%" }}
                      color={"info"}
                      type="submit"
                      disabled={isSaving}
                    >
                      Save
                    </Button>
                  </form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    mrfs: state.journeyFlows.CurbCycleManagement.mrfs.mrfs.data,
  };
}

EditMrfManager.propTypes = {
  mrfs: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

export default connect(mapStateToProps)(EditMrfManager);
