module.exports = {
  component: 'vfb-mb-form-component-time-how-long-in-years-and-months',
  elementPrefix: '',
  name: "MB: How long in months and years - single field splits into constituent parts",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: ''
    },
    {
      type: 'literal',
      variable: 'element_name_prefix',
      value: 'element_name_prefix'
    },
    {
      type: 'literal',
      variable: 'input_field_label',
      value: 'How long'
    },
    {
      type: 'literal',
      variable: 'name',
      value: '_how_long'
    },
    {
      type: 'literal',
      variable: 'pre_text',
      value: ''
    },
    {
      type: 'literal',
      variable: 'post_text',
      value: ''
    },
    {
      type: 'literal',
      variable: 'tooltip_content',
      value: ''
    }
  ]
}
