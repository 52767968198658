import { grayColor } from "assets/jss/material-dashboard-react-im.js";

const cardContainerStyle = (theme) => ({
  hoverClass: {
    color: "#5159fc",
    textDecoration: "none",
    "&:hover,&:focus": {
      color: "#000adb",
      fontSize: "19px",
    },
  },

  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "18px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "nunito",
  },
  stats: {
    color: grayColor[0],
    display: "flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
});

export default cardContainerStyle;
