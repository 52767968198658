import React from "react";
import PropTypes from "prop-types";
// import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
// core components
import MainLayout from "./layouts/MainLayout";
import { connect } from "react-redux";

function AppRouter({ accountData, history }) {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" component={MainLayout} />
      </Switch>
    </Router>
  );
}

function mapStateToProps(state) {
  return {
    accountData: state.accountData,
  };
}

AppRouter.propTypes = {
  accountData: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
};

export default connect(mapStateToProps)(AppRouter);
