import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
// @material-ui/icons
import Icon from "@material-ui/core/Icon";
// core components
import GridItem from "../../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../../components/Grid/GridContainer.js";
// custom components
import Card from "../../../layouts/components/Card/Card";
import CardBody from "../../../layouts/components/Card/CardBody.js";
// project tools
import { Link } from "react-router-dom";
import { getMyMrfAnalyticsDataByMaterialType } from "../../../../../../services/CurbCycleApi";
import styles from "./CardRowStyles";

const useStyles = makeStyles(styles);

export default function CardRow({
  materialTypeKey,
  onClickedAction,
  materialData,
}) {
  const classes = useStyles();
  const [theData, setTheData] = useState({
    number_of_bales: 0,
    average_bale_condition: 0,
    on_hand_total: 0,
    epa_limit: 0,
    inbound_today: 0,
    inbound_trucks_today: 0,
    inbound_yesterday: 0,
    inbound_trucks_yesterday: 0,
    outbound_today: 0,
    outbound_trucks_today: 0,
    outbound_yesterday: 0,
    outbound_trucks_yesterday: 0,
  });

  useEffect(() => {
    const getTheData = async () => {
      try {
        const response = await getMyMrfAnalyticsDataByMaterialType(
          materialTypeKey
        );
        if (response) {
          // This syntax with the spread operator is very handy, It clones what ever is in theData variable and injects a new declared property: value, into it.
          setTheData({
            ...response.data,
            status: materialData.activeStatus,
            indicative_weight_per_bale_dry: materialData.dry,
            indicative_weight_per_bale_wet: materialData.wet,
            material_type: materialData.materialType,
            bale_tag_color: materialData.colorKey,
          });
        }
      } catch (e) {
        // handle error
      }
    };
    getTheData();
  }, []);

  const callback = () => {
    onClickedAction(materialTypeKey);
  };

  const rowStyleConfiguration = (theData) => {
    let style = {};
    if (theData.status !== "active") {
      style = { color: "#cccccc", pointerEvents: "none" };
    }
    return style;
  };

  const bodyStyle = rowStyleConfiguration(theData);

  return (
    <div>
      <Fade in={true} timeout={3000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Link
              style={bodyStyle}
              to="/management/inventory-management"
              onClick={callback}
            >
              <Card
                style={{
                  marginTop: 4,
                  marginRight: 2,
                  marginBottom: 4,
                  marginLeft: 0,
                }}
              >
                <CardBody style={bodyStyle} className={classes.hoverClass}>
                  <Icon
                    style={{
                      color: `${materialData.colorKey}`,
                      backgroundColor: `${materialData.colorKey}`,
                      borderRadius: "3px",
                      fontSize: "20px",
                      marginRight: "3%",
                      marginBottom: "-3px",
                    }}
                  >
                    check_box_outline_blank
                  </Icon>
                  {theData.material_type}
                </CardBody>
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Card
              style={{
                marginTop: 4,
                marginRight: 2,
                marginBottom: 4,
                marginLeft: 0,
              }}
            >
              <CardBody style={bodyStyle}>
                {theData.number_of_bales > 0
                  ? theData.number_of_bales
                  : 0 || "No data"}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Card
              style={{
                marginTop: 4,
                marginRight: 2,
                marginBottom: 4,
                marginLeft: 0,
              }}
            >
              <CardBody style={bodyStyle}>
                {theData.number_of_bales > 0
                  ? (theData.number_of_bales * materialData.dry).toFixed(2)
                  : "No data"}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Card
              style={{
                marginTop: 4,
                marginRight: 2,
                marginBottom: 4,
                marginLeft: 0,
              }}
            >
              <CardBody style={bodyStyle}>
                {theData.number_of_bales > 0
                  ? (theData.number_of_bales * materialData.wet).toFixed(2)
                  : "No data"}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <Card
              style={{
                marginTop: 4,
                marginRight: 2,
                marginBottom: 4,
                marginLeft: 0,
              }}
            >
              <CardBody style={bodyStyle}>
                {theData.average_bale_condition > 0 ? (
                  <span>
                    {theData.average_bale_condition.toFixed(2)}{" "}
                    <small> / 5</small>{" "}
                  </span>
                ) : (
                  0 || "No Data"
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fade>
    </div>
  );
}

CardRow.propTypes = {
  onClickedAction: PropTypes.func.isRequired,
  materialTypeKey: PropTypes.string.isRequired,
  materialData: PropTypes.shape({
    activeStatus: PropTypes.string.isRequired,
    dry: PropTypes.number.isRequired,
    wet: PropTypes.number.isRequired,
    materialType: PropTypes.string.isRequired,
    colorKey: PropTypes.string.isRequired,
  }).isRequired,
};
