/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
// import Dashboard from "@material-ui/icons/Dashboard";
import MRF from "@material-ui/icons/Business";
import People from "@material-ui/icons/People";
import Dashboard from "@material-ui/icons/BarChart";
import InventoryManagement from "@material-ui/icons/AddToQueue";
import Security from "@material-ui/icons/Security";
import SettingsIcon from "@material-ui/icons/SettingsSharp";
// import AccountTreeIcon from "@material-ui/icons/AccountTree";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

const dashboardRoutes = [
  // Dashboard routes
  {
    path: "/dashboard",
    name: "Dashboard",
    parent: null,
    icon: Dashboard,
    layout: "/management",
    show: true,
  },
  // {
  //   path: "/inventory-management",
  //   name: "Inventory Management",
  //   parent: null,
  //   icon: InventoryManagement,
  //   layout: "/management",
  //   show: true,
  // },
  // {
  //   path: "/logistics",
  //   name: "Logistics",
  //   parent: null,
  //   icon: LocalShippingIcon,
  //   layout: "/management",
  //   show: true,
  // },

  // MRF Operations / Administration screens and routes
  // {
  //   path: "/mrf-operations",
  //   name: "MRF Operations",
  //   parent: null,
  //   icon: AccountTreeIcon,
  //   layout: "/management",
  //   show: true,
  // },
  // {
  //   path: "/mrf-logistics",
  //   name: "SMRF Operations",
  //   parent: null,
  //   icon: LocalShippingIcon,
  //   layout: "/management",
  //   show: false,
  // },

  // People management routes Operators/Managers
  // {
  //   path: "/people",
  //   name: "People",
  //   parent: null,
  //   icon: People,
  //   layout: "/management",
  //   show: true,
  // },
  // {
  //   path: "/manage-people/add-mrf-operator",
  //   name: "SMRF Management - People - Add SMRF Operator",
  //   parent: "People",
  //   icon: MRF,
  //   layout: "/management",
  //   show: false,
  // },

  // Access and Security
  {
    path: "/security",
    name: "Access and Security",
    parent: null,
    icon: Security,
    layout: "/management",
    show: true,
  },
  // {
  //   path: "/mrf-settings",
  //   name: "SMRF Settings",
  //   parent: null,
  //   icon: SettingsIcon,
  //   layout: "/management",
  //   show: true,
  // },
];

export default dashboardRoutes;
