import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
// @material-ui/icons
import Icon from "@material-ui/core/Icon";
// custom components
import GridItem from "../../../../layouts/components/Grid/GridItem.js";
import GridContainer from "../../../../layouts/components/Grid/GridContainer.js";
import Card from "../../../../layouts/components/Card/Card";
import CardHeader from "../../../../layouts/components/Card/CardHeader.js";
import CardIcon from "../../../../layouts/components/Card/CardIcon.js";
import CardBody from "../../../../layouts/components/Card/CardBody.js";
import Button from "../../../../layouts/components/CustomButtons/Button";
import ActionableDataTable from "../../../../layouts/components/ActionableDataTable/ActionableDataTable";
import Modal from "../../../../layouts/components/Modal/ModalWithChildContent";
import BaleDetailsModal from "./BaleDetailsModal/BaleDetailsModal";
import Spinner from "../../../../layouts/components/Spinner/Spinner";
// project tools
import paginationHelper from "../../../../../../../utilities/paginationHelper";
import { getInboundBaleCodes } from "services/CurbCycleApi";
import materialTypeHelper from "utilities/materialTypeHelper";

export default function BaleCodesInbound(props) {
  const { materialType, backAction } = props;
  // console.log(materialType);
  const [isSearching, setIsSearching] = useState(false);
  // const [activityType, setEventType] = useState("SCANNED_IN_TO_INVENTORY_BY_MRF");
  const activityType = "SCANNED_IN_TO_INVENTORY_BY_MRF";
  // console.log(activityType);
  const [materialTypeTableData, setMaterialTypeTableData] = useState(undefined);
  const [tableData, setTableData] = useState({
    data: [],
  });
  const [materialTypeRawTableData, setMaterialTypeRawTableData] = useState({
    data: [],
  });
  const nullData = { data: [] };
  const [mrfBaleTableFilterOptions, setMrfBaleTableFilterOptions] = useState(
    `material_type=${materialType}&status_reason=${activityType}&page=1`
  );
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(undefined);
  const [modalContent, setModalContent] = useState(undefined);
  // =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

  useEffect(() => {
    if (!backAction) {
      return;
    }
    let isCancelled = false;
    setIsSearching(true);
    const getInventory = async () => {
      getInboundBaleCodes(mrfBaleTableFilterOptions).then((response) => {
        if (!isCancelled) {
          setMaterialTypeRawTableData(response);
          setMaterialTypeTableData(response);
        }
      });
      setIsSearching(false);
    };
    getInventory();
    return () => {
      isCancelled = true;
    };
  }, [isSearching, backAction]);

  // =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

  useEffect(() => {
    if (!materialTypeTableData) {
      return;
    }
    let newTableData = {
      data: [],
    };

    newTableData.data = materialTypeTableData.data.map((item) => {
      return [
        {
          hasActions: true,
          actions: [
            {
              type: "button-link",
              color: "transparent",
              description: "View Bale Details",
              label: `${item.mrf_bale_reference}`,
              size: "sm",
              context: {
                callback: () => {
                  handleModal([
                    item.mrf_bale_reference,
                    item.mrf_bale_condition_score,
                    item.metadata.images,
                    item.metadata.comments,
                    // .map((item) => {
                    //   return item.comment;
                    // }),
                  ]);
                },
              },
            },
          ],
        },
        {
          display: `${materialTypeHelper.getMaterialName(item.material_type)}`,
        },
        {
          display: `${item.weight_in_g / 1000}`,
        },
        {
          display: `${materialTypeHelper.getActivityName(item.status_reason)}`,
        },
      ];
    });
    setTableData(newTableData);
  }, [materialTypeTableData]);

  const handleModal = (data) => {
    setShowModal(true);
    setModalContent(<BaleDetailsModal modalData={data} />);
  };

  // console.log(materialTypeTableData);
  // =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

  return (
    <>
      <Fade in={true} timeout={3000}>
        <Card>
          <CardHeader color="gray" stats icon>
            <CardIcon color="gray">
              {materialTypeHelper.getMaterialName(materialType)}
            </CardIcon>
          </CardHeader>
          <br />
          <CardBody>
            <GridContainer
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <GridItem>
                <Button
                  link={true}
                  onClick={() => {
                    backAction();
                  }}
                >
                  <Icon>arrow_back</Icon>
                  <label style={{ fontSize: "18px" }}>Back</label>
                </Button>
              </GridItem>
            </GridContainer>
            {isSearching ? (
              <>
                <ActionableDataTable
                  tableHeaderColor="success"
                  tableHead={[
                    "Bale Codes",
                    "Material Type",
                    "Weight (Kg)",
                    "Current Status",
                  ]}
                  tableData={nullData.data}
                />
                <Spinner centerWindow={true} />
              </>
            ) : (
              <ActionableDataTable
                tableHeaderColor="success"
                tableHead={[
                  "Bale Codes",
                  "Material Type",
                  "Weight (Kg)",
                  "Current Status",
                ]}
                tableData={tableData.data}
                pagination={true}
                paginationConfig={{
                  collectionData: materialTypeRawTableData,
                  handlePageChange: (payload) => {
                    // console.log("payload", payload);
                    setMrfBaleTableFilterOptions(
                      paginationHelper.generateFilterQueryStringFromFilterOptions(
                        [
                          {
                            filterKey: "status_reason",
                            filterValue: activityType,
                            // filterValue: "SCANNED_IN_TO_INVENTORY_BY_MRF",
                            type: "simpleKeyValue",
                          },
                          {
                            filterKey: "material_type",
                            filterValue: materialType,
                            type: "simpleKeyValue",
                          },
                          {
                            filterKey: "page",
                            filterValue: payload.selected,
                            type: "simpleKeyValue",
                          },
                        ]
                      )
                    );
                    setIsSearching(true);
                  },
                }}
              />
            )}
          </CardBody>
        </Card>
      </Fade>
      <Modal
        shouldBeOpen={showModal}
        modalTitle={modalTitle}
        onCloseCallback={() => {
          setShowModal(false);
          setModalContent(undefined);
          setModalTitle(undefined);
        }}
      >
        {modalContent}
      </Modal>
    </>
  );
}

BaleCodesInbound.propTypes = {
  backAction: PropTypes.func.isRequired,
  materialType: PropTypes.string.isRequired,
};
