import React, { createRef, useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Footer from "../../../../components/Footer/Footer.js";
import Sidebar from "../../../../components/Sidebar/Sidebar2.js";
import routes from "../routes.js";
import styles from "../../../../assets/jss/material-dashboard-react/layouts/adminStyleNew.js";
import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/Symbol-Curbcycle.jpg";
// Views for Admin layout & shared pages
import AccountsPage from "../../SharedPages/Accounts/Accounts";
import AccountSummaryPage from "../../SharedPages/Accounts/AccountSummary/AccountSummary";
import AccountCrmIntegrationSettingsPage from "../../SharedPages/Accounts/CrmIntegration/CrmIntegrationSettings";
import MaterialJourneys from "../../SharedPages/Accounts/MaterialJourneys/MaterialJourneys";
import LGAEngagement from "../../SharedPages/Accounts/LGAEngagement/LGAEngagement";
import AnalyticsPage from "../../SharedPages/Analytics/Dashboard";
import CurbyCodesPage from "../../SharedPages/CurbyCodes/CurbyCodes";
import Playground from "../pages/Playground/Playground";
import ContentManager from "../pages/ContentBuilder/ContentManager";
import FlowBuilder from "../pages/FlowBuilder/FlowBuilder";
import MaterialTypeManager from "../pages/MaterialTypeManager/MaterialTypeManager";
import MrfOperationsPage from "../../SharedPages/MrfOperations/MrfOperations";
import MrfOperationsAddMrfPage from "../../SharedPages/MrfOperations/AddMrf";
import MrfOperationsEditMrfPage from "../../SharedPages/MrfOperations/EditMrf";
import MrfOperationsPeoplePage from "../../SharedPages/MrfOperations/People/People";
import MrfOperationsPeopleAddManagerPage from "../../SharedPages/MrfOperations/People/AddMrfManager";
import MrfOperationsPeopleAddOperatorPage from "../../SharedPages/MrfOperations/People/AddMrfOperator";
import MrfOperationsPeopleEditManagerPage from "../../SharedPages/MrfOperations/People/EditMrfManager";
import MrfOperationsPeopleEditOperatorPage from "../../SharedPages/MrfOperations/People/EditMrfOperator";
import MrfOperationsSecurityPage from "../../SharedPages/MrfOperations/Security/Security";
import LGAsPage from "../../SharedPages/LGAs/LGAs";
import LGAsAddLGAPage from "../../SharedPages/LGAs/AddLGA";
import LGAsEditLGAPage from "../../SharedPages/LGAs/EditLGA";
import LGAsPeoplePage from "../../SharedPages/LGAs/People/People";
import LGAsPeopleAddManagerPage from "../../SharedPages/LGAs/People/AddLGAManager";
import LGAsPeopleEditManagerPage from "../../SharedPages/LGAs/People/EditLGAManager";
import Secret from "../../Secret/index";

/**
 * The following import statements are generated through an automated process...
 */
import AdminSettings from "../pages/AdminSettings/AdminSettings";
import AdminForMaterialTypeJourneys from "../pages/AdminSettings/AdminForMaterialTypeJourneys";
import AdminForExperiments from "../pages/AdminSettings/AdminForExperiments";
import AnotherBunchOfSettings from "../pages/AnotherBunchOfSettings/AnotherBunchOfSettings";
import AdminForSomethingElse from "../pages/AnotherBunchOfSettings/AdminForSomethingElse";
import AdminForStuff from "../pages/AnotherBunchOfSettings/AdminForStuff";

let ps;

const useStyles = makeStyles(styles);

export default function MainLayout({ ...rest }) {
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices

  const switchRoutes = (
    <Switch>
      <Route path="/management/accounts" show={true}>
        <AccountsPage />
      </Route>
      <Route path="/management/account-summary/:uuid" show={false}>
        <AccountSummaryPage />
      </Route>
      <Route
        path="/management/account-crm-integration-settings/:uuid"
        show={false}
      >
        <AccountCrmIntegrationSettingsPage />
      </Route>
      <Route path="/management/material-type/:uuid" show={false}>
        <MaterialJourneys />
      </Route>
      <Route
        path="/management/enabled-lga-engagement-options/:uuid"
        show={false}
      >
        <LGAEngagement />
      </Route>
      <Route path="/management/management-analytics" show={true}>
        <AnalyticsPage />
      </Route>
      <Route path="/management/curby-codes" show={true}>
        <CurbyCodesPage />
      </Route>
      <Route path="/management/playground" show={true}>
        <Playground />
      </Route>
      <Route path="/management/content-builder" show={true}>
        <ContentManager />
      </Route>
      <Route path="/management/flow-builder" show={true}>
        <FlowBuilder />
      </Route>
      <Route path="/management/material-type-manager" show={true}>
        <MaterialTypeManager />
      </Route>
      <Route path="/management/mrf-management-home" show={true}>
        <MrfOperationsPage />
      </Route>
      <Route path="/management/mrf-management/add" show={false}>
        <MrfOperationsAddMrfPage />
      </Route>
      <Route path="/management/mrf-management/edit/:uuid" show={false}>
        <MrfOperationsEditMrfPage />
      </Route>
      <Route path="/management/mrf-management/mrf/:uuid/people" show={false}>
        <MrfOperationsPeoplePage />
      </Route>
      <Route
        path="/management/mrf-management/mrf/:uuid/add-mrf-manager"
        show={false}
      >
        <MrfOperationsPeopleAddManagerPage />
      </Route>
      <Route
        path="/management/mrf-management/mrf/:uuid/add-mrf-operator"
        show={false}
      >
        <MrfOperationsPeopleAddOperatorPage />
      </Route>
      <Route
        path="/management/mrf-management/mrf/:uuid/edit-mrf-manager"
        show={false}
      >
        <MrfOperationsPeopleEditManagerPage />
      </Route>
      <Route
        path="/management/mrf-management/mrf/:uuid/edit-mrf-manager"
        show={false}
      >
        <MrfOperationsPeopleEditOperatorPage />
      </Route>
      <Route path="/management/mrf-management/mrf/:uuid/security" show={false}>
        <MrfOperationsSecurityPage />
      </Route>
      <Route path="/management/lga-management-home" show={true}>
        <LGAsPage />
      </Route>
      <Route path="/management/lga-management/add" show={false}>
        <LGAsAddLGAPage />
      </Route>
      <Route path="/management/lga-management/edit/:uuid" show={false}>
        <LGAsEditLGAPage />
      </Route>
      <Route path="/management/lga-management/lga/:uuid/people" show={false}>
        <LGAsPeoplePage />
      </Route>
      <Route
        path="/management/lga-management/lga/:uuid/add-lga-manager"
        show={true}
      >
        <LGAsPeopleAddManagerPage />
      </Route>
      <Route
        path="/management/lga-management/lga/:uuid/edit-lga-manager"
        show={false}
      >
        <LGAsPeopleEditManagerPage />
      </Route>
      <Route path="/management/admin-settings" show={true}>
        <AdminSettings />
      </Route>
      <Route path="/management/admin-for-material-type-journeys" show={false}>
        <AdminForMaterialTypeJourneys />
      </Route>
      <Route path="/management/admin-for-experiments" show={false}>
        <AdminForExperiments />
      </Route>
      <Route path="/management/another-bunch-of-settings" show={true}>
        <AnotherBunchOfSettings />
      </Route>
      <Route path="/management/admin-for-something-else" show={false}>
        <AdminForSomethingElse />
      </Route>
      <Route path="/management/admin-for-stuff" show={false}>
        <AdminForStuff />
      </Route>
      <Route path="/management/secret" show={true}>
        <Secret />
      </Route>
      <Redirect from="/" to="/management/accounts" />
    </Switch>
  );

  const mainPanel = createRef();
  const [mobileOpen, setMobileOpen] = useState(false);
  const image = bgImage;
  const color = "";

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"CurbCycle"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}
      </div>
    </div>
  );
}
