import React from "react";
// @material-ui/core
// import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
// core components
import GridItem from "../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../components/Grid/GridContainer.js";
import CustomTabs from "../../layouts/components/CustomTabs/CustomTabs.js";
// import { useHistory, useParams } from "react-router-dom";
// import { useDispatch, connect } from "react-redux";
import ManageOperators from "./ManageOperators/ManageOperators";

// import styles from "../../../../../assets/jss/material-dashboard-react/views/dashboardStyleIm.js";

// const useStyles = makeStyles(styles);

function People() {
  // const classes = useStyles();
  // const dispatch = useDispatch();
  // const history = useHistory();
  // const { uuid } = useParams();

  return (
    <>
      <Fade in={true} timeout={3000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title="People:"
              headerColor="primary"
              tabs={[
                {
                  tabName: "Operators",
                  tabContent: <ManageOperators />,
                },
                // =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
                // Managers page already built with API request needing setup on
                // back-end

                // {
                //   tabName: "Managers",
                //   tabContent: <ManageManagers />,
                // },
              ]}
            />
          </GridItem>
        </GridContainer>
      </Fade>
    </>
  );
}

export default People;
