import {
  initialiseExperienceBasedOnStoredAppState,
  userIsAccountProfileUser,
  userIsCurbCycleManagementUser,
  userIsCurbCycleDevelopmentTeamUser,
  userIsCurbCycleSupportTeamUser,
  userIsMrfManagementUser,
  userIsMrfOperatorUser,
  userIsLgaUser,
  userIsRetailSponsorUser,
  userSignedInSuccessfully,
  userIsFinishedSignUp,
  userIsWaitlisted,
  accountDataSyncedFromBackend,
  selectActiveProfile,
  additionalRelevantDataSyncedFromBackend,
} from "../../redux/reducer";

import { getStoredStateData, getAllRelevantAccountDataForCurbyApp } from "../../services/CurbCycleApi";

export const experienceInitialisationThunk = () => (dispatch) => {
  // dispatch(userIsCurbCycleDevelopmentTeamUser());
  // dispatch(userSignedInSuccessfully());
  getStoredStateData()
    .then((stateData) => {
      dispatch(initialiseExperienceBasedOnStoredAppState(stateData));
      if (undefined !== stateData && stateData.idToken !== "") {
        // make sure state reflects any changes which may have been applied to the account across different devices
        getAllRelevantAccountDataForCurbyApp()
          .then((result) => {
            // account email will have received a OTP by email, make sure global state is updated to allow verification flow
            dispatch(accountDataSyncedFromBackend(result.account));
            dispatch(
              additionalRelevantDataSyncedFromBackend(
                result.additional_relevant_data
              )
            );

            // todo: refactor if required, it's a swell hadouken...
            if (undefined !== result.account.curby_app_user_metadata) {
              // we have metadata so check it and apply any relevant changes to state
              const metadata = result.account.curby_app_user_metadata;

              if (
                undefined !== metadata &&
                null !== metadata &&
                !Array.isArray(metadata)
              ) {
                // sign up is complete
                if (undefined !== metadata.isFinishedSignup) {
                  if (metadata.isFinishedSignup) {
                    dispatch(userIsFinishedSignUp());
                  }
                }

                // user is waitlisted
                if (undefined !== metadata.isWaitlisted) {
                  if (metadata.isWaitlisted) {
                    dispatch(userIsWaitlisted());
                  }
                }
              }
            }

            if (result.account.profiles.length > 0) {
              dispatch(selectActiveProfile(result.account.profiles[0]));
            } else {
              dispatch(
                selectActiveProfile({
                  uuid: "testing",
                })
              );
            }

            // handle specifics around user types
            if (stateData.aclGroups.includes("CurbCycle Management")) {
              dispatch(userIsCurbCycleManagementUser());
            } else if (stateData.aclGroups.includes("CurbCycle Development")) {
              dispatch(userIsCurbCycleDevelopmentTeamUser());
            } else if (stateData.aclGroups.includes("CurbCycle Support")) {
              dispatch(userIsCurbCycleSupportTeamUser());
            } else if (stateData.aclGroups.includes("MRF Manager")) {
              dispatch(userIsMrfManagementUser());
            } else if (stateData.aclGroups.includes("MRF Operator")) {
              dispatch(userIsMrfOperatorUser());
            } else if (
              stateData.aclGroups.includes("Local Government Authority")
            ) {
              dispatch(userIsLgaUser());
            } else if (stateData.aclGroups.includes("Retail Sponsor")) {
              dispatch(userIsRetailSponsorUser());
            } else {
              dispatch(userIsAccountProfileUser());
            }

            dispatch(userSignedInSuccessfully());
          })
          .catch((err) => {
            // ignore for now, this is WIP to make things smooth for app users without storing lots of data on the actual device
          });
      }
    })
    .catch((err) => {});
};

export default experienceInitialisationThunk;
