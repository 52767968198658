import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// import ColorPicker from "material-ui-color-picker";
import { ColorPicker } from "material-ui-color";
// core components
import GridItem from "../../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../../components/Grid/GridContainer.js";
// custom components
import Card from "../../../layouts/components/Card/Card";
import CardIcon from "../../../layouts/components/Card/CardIcon.js";
import CardHeader from "../../../layouts/components/Card/CardHeader.js";
import CardBody from "../../../layouts/components/Card/CardBody.js";
import Button from "../../../layouts/components/CustomButtons/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  column: {
    width: "50%",
  },
  logoColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  label: {
    marginRight: "10px",
    width: "45%",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  rowLabel: {
    width: "50%",
  },
  test: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const ThemeDetails = ({
  logo,
  defaultLogo,
  setLogo,
  setPrimaryColor,
  setSecondaryColor,
  setTheme,
  theme,
}) => {
  const classes = useStyles();

  const [updatedLogo, setUpdatedLogo] = useState(undefined);

  const changePrimaryColorHandler = (color) => {
    setPrimaryColor(`#${color.hex}`);
    setTheme({
      ...theme,
      palette: { ...theme.palette, primary: { main: `#${color.hex}` } },
    });
  };

  const changeSecondaryColorHandler = (color) => {
    setSecondaryColor(`#${color.hex}`);
    setTheme({
      ...theme,
      palette: { ...theme.palette, secondary: { main: `#${color.hex}` } },
    });
  };

  const onUploadClick = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      onLogoUpdate(reader.result);
    };
  };

  const onLogoUpdate = (logo) => {
    setUpdatedLogo(logo);
    setLogo(logo);
  };

  return (
    <>
      <Card>
        <CardHeader stats icon>
          <CardIcon color="gray">Theme</CardIcon>
        </CardHeader>
        <CardBody style={{ marginBottom: 30 }}>
          <GridContainer style={{ width: "100%", marginTop: "50px" }}>
            <GridItem xs={12}>
              <div className={classes.root}>
                <div className={classes.column}>
                  <div className={classes.row}>
                    <div className={classes.rowLabel}>Primary Colour</div>
                    <ColorPicker
                      hideTextfield
                      value={theme.palette.primary.main}
                      onChange={(event) => {
                        changePrimaryColorHandler(event);
                      }}
                    />
                  </div>
                  <div className={classes.row}>
                    <div className={classes.rowLabel}>Secondary Colour</div>
                    <ColorPicker
                      hideTextfield
                      value={theme.palette.secondary.main}
                      onChange={(event) => {
                        changeSecondaryColorHandler(event);
                      }}
                    />
                  </div>
                </div>
                <div className={classes.column}>
                  <div className={classes.root}>
                    <div>
                      <img
                        src={updatedLogo || defaultLogo || atob(logo)}
                        style={{ height: "50px", marginRight: "15px" }}
                        alt="logo"
                      />
                    </div>
                    <div>
                      <label htmlFor="upload-photo">
                        <input
                          style={{ display: "none" }}
                          id="upload-photo"
                          name="upload-photo"
                          type="file"
                          onChange={onUploadClick}
                        />
                        <Button color="primary" size="sm" component="span">
                          Update Logo
                        </Button>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
};

ThemeDetails.propTypes = {
  logo: PropTypes.string.isRequired,
  defaultLogo: PropTypes.string.isRequired,
  setLogo: PropTypes.func.isRequired,
  setPrimaryColor: PropTypes.func.isRequired,
  setSecondaryColor: PropTypes.func.isRequired,
  setTheme: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string.isRequired,
      }).isRequired,
      secondary: PropTypes.shape({
        main: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ThemeDetails;
