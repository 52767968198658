module.exports = {
  component: 'vfb-form-component-amex-gcp-corporate-card-selection',
  elementPrefix: '',
  name: "American Express High Level Corporate Card Selection",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'corporate_card_selection'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'corporate_card_selection'
    }
  ]
}
