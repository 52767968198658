module.exports = {
  component: 'vfb-mb-form-progress-button-stage-1',
  elementPrefix: '',
  name: "MB: Progress button to close out stage 1 of the application process",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'marketing_opt_in'
    }
  ]
}
