module.exports = {
  component: 'vfb-mb-privacy-statement',
  elementPrefix: '',
  name: "MB: Privacy Statement text and checkbox",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'marketing_opt_in'
    }
  ]
}
