/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "../../components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "../../components/Navbars/RTLNavbarLinks.js";
import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle.js";
import LogoutAvatar from "components/Sidebar/components/LogoutAvatar";

const useStyles = makeStyles(styles);
function Sidebar(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes } = props;

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.show === false) {
          return null;
        }

        var activePro = " ";
        var listItemClasses;
        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.layout + prop.path),
        });
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem
              button
              className={classes.itemLink + listItemClasses}
              title={`${prop.name}`}
            >
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(
                    "material-icons-two-tone",
                    classes.itemIcon,
                    whiteFontClasses,
                    {
                      [classes.itemIconRTL]: props.rtlActive,
                    }
                  )}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(
                    "material-icons-two-tone",
                    classes.itemIcon,
                    whiteFontClasses,
                    {
                      [classes.itemIconRTL]: props.rtlActive,
                    }
                  )}
                />
              )}
              {/*<ListItemText*/}
              {/*primary={props.rtlActive ? prop.rtlName : prop.name}*/}
              {/*className={classNames(classes.itemText, whiteFontClasses, {*/}
              {/*[classes.itemTextRTL]: props.rtlActive*/}
              {/*})}*/}
              {/*disableTypography={true}*/}
              {/*/>*/}
            </ListItem>
          </NavLink>
        );
      })}
      <ListItem>
        <LogoutAvatar />
      </ListItem>
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="https://www.curbcycle.com.au"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
        target="_blank"
      >
        <div className={classes.logoImage}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "30px", height: "30px" }}
          />
        </div>
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  // popOut: {
  //     header: PropTypes.string,
  //     description: PropTypes.string,
  //   },
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Sidebar);
