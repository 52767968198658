module.exports = {
  component: 'vfb-mb-form-element-checkbox-company-public-or-proprietary-with-label',
  elementPrefix: '',
  name: "MB: Public or Proprietary - checkboxes with label",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'pre_text',
      value: ''
    },
    {
      type: 'literal',
      variable: 'post_text',
      value: ''
    },
    {
      type: 'literal',
      variable: 'tooltip_content',
      value: ''
    }
  ]
}
