import {
  managementRequestedFirstValidMrfManager,
  managementReceivedFirstValidMrfManager
} from "../../../../redux/reducer";

import { getFirstValidMrfManager } from '../../../../services/CurbCycleApi';

export const fetchFirstValidMrfManagerThunk = (mrfUuid) => dispatch => {
  dispatch(managementRequestedFirstValidMrfManager());
  getFirstValidMrfManager(mrfUuid)
    .then(result => {
      dispatch(managementReceivedFirstValidMrfManager(result));
    })
    .catch(() => {
      dispatch(managementReceivedFirstValidMrfManager({
        uuid: null
      }));
    });
};

export default fetchFirstValidMrfManagerThunk;
