import React from "react";
// @material-ui/icons
import Icon from "@material-ui/core/Icon";
// custom components
import GridItem from "../../../../../layouts/components/Grid/GridItem.js";
import GridContainer from "../../../../../layouts/components/Grid/GridContainer.js";
import Card from "../../../../../layouts/components/Card/Card";
import CardHeader from "../../../../../layouts/components/Card/CardHeader.js";
import CardIcon from "../../../../../layouts/components/Card/CardIcon.js";
import CardBody from "../../../../../layouts/components/Card/CardBody.js";
import Spinner from "../../../../../layouts/components/Spinner/Spinner";
// project tools
import { duplicateArrayValues, decodeString } from "utilities/helpers/helpers";
import uuid from "utilities/uuid";
import { grayColor } from "assets/jss/material-dashboard-react-im";

export default function BaleDetailsModal(props) {
  const { modalData } = props;

  const imgStyle = {
    padding: 5,
    cursor: "pointer",
  };

  // =-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

  const scoreWithSentimentsHelper = () => {
    const theSentiments = [
      "sentiment_very_dissatisfied",
      "sentiment_dissatisfied",
      "sentiment_neutral",
      "sentiment_satisfied",
      "sentiment_very_satisfied",
    ];

    const allTheSentiments = theSentiments.map((theSentiments) => {
      let style = { fontSize: 40, color: grayColor[3] };
      if (
        modalData[1] === 1 &&
        theSentiments === "sentiment_very_dissatisfied"
      ) {
        style = { fontSize: 50, color: "#f71e1e" };
      }
      if (modalData[1] === 2 && theSentiments === "sentiment_dissatisfied") {
        style = { fontSize: 50, color: "#f7631e" };
      }
      if (modalData[1] === 3 && theSentiments === "sentiment_neutral") {
        style = { fontSize: 50, color: "#f7b21e" };
      }
      if (modalData[1] === 4 && theSentiments === "sentiment_satisfied") {
        style = { fontSize: 50, color: "#d4e617" };
      }
      if (modalData[1] === 5 && theSentiments === "sentiment_very_satisfied") {
        style = { fontSize: 50, color: "#03ff03" };
      }

      return (
        <Icon key={uuid.uuid()} style={style}>
          {theSentiments}
        </Icon>
      );
    });
    return allTheSentiments;
  };

  const images = (modalData) => {
    const images = duplicateArrayValues(modalData[2]).map((item) => {
      return (
        <img
          key={uuid.uuid()}
          style={imgStyle}
          src={`https://curbcycle-assets.s3-ap-southeast-2.amazonaws.com/${item}`}
          alt="bale image missing"
          height="300"
          name="baleImages"
          onClick={() =>
            window.open(
              `https://curbcycle-assets.s3-ap-southeast-2.amazonaws.com/${item}`,
              "_blank"
            )
          }
        />
      );
    });
    return images;
  };

  const commentsHelper = (modalData) => {
    let concat = [];
    const removeDuplicates = modalData[3].map((key, value) => {
      if (key.comment != "") {
        return concat.push(key.comment);
      }
      if (key.comment !== null) {
        key.comment.forEach((x) => concat.push(x));
      } else {
        return concat.push(key.comment);
      }
    });
    concat = Array.from(new Set(concat));
    concat = decodeString(concat[0]);
    return concat;
  };

  const image = images(modalData);
  const score = scoreWithSentimentsHelper(modalData);
  const comments = commentsHelper(modalData);

  if (!modalData) {
    return <Spinner centerWindow={true} />;
  }

  if (modalData !== undefined) {
    return (
      <>
        <Card style={{ height: "100%" }}>
          <div style={{ position: "absolute", right: 35, top: 5 }}>{score}</div>
          <CardHeader color="gray" stats icon>
            <CardIcon color="gray">{modalData[0]}</CardIcon>
          </CardHeader>
          <CardBody>
            <GridContainer
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <GridItem>
                <h4>
                  {!comments || comments == "" ? (
                    <i style={{ color: grayColor[3] }}>
                      No comments on file for this bale
                    </i>
                  ) : (
                    comments
                  )}
                </h4>
              </GridItem>
              <GridItem style={{ padding: 0 }}>
                <h4 style={{ marginRight: 30 }}>
                  Bale Score {modalData[1]} <small>/ 5</small>
                </h4>
              </GridItem>
            </GridContainer>
            <p style={{ fontSize: "15px" }}>
              click on an image to open in full size window
            </p>
            <GridContainer>
              {!image ? (
                <i style={{ color: grayColor[3] }}>
                  No images on file for this bale
                </i>
              ) : (
                image
              )}
            </GridContainer>
          </CardBody>
        </Card>
      </>
    );
  }
}
