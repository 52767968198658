import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ContentTree } from "../lib";

import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CustomTreeItem from "./TreeView/CustomTreeItem";

const useStyles = makeStyles({
  root: {
    height: "100%",
    flexGrow: 1,
    maxWidth: 400,
  },
});

function ContentTreeNavigationComponent({
  config,
  onContentTreeContextChangeCallback,
  hide = false,
}) {
  const crudManager = ContentTree.contentCrudManager();
  const [contentStorageName, setContentStorageName] = useState(
    crudManager.contentService.getContentStorageName()
  );
  const [contentTree, setContentTree] = useState(
    crudManager.contentService.getContentTree()
  );
  const [contentTreeContext, setContentTreeContext] = useState(undefined);

  useEffect(() => {
    if (undefined === contentTree || undefined === contentStorageName) {
      console.log("setting defaults");
      localStorage.setItem("current-project-prefix", "temporary");
      setContentStorageName(crudManager.contentService.getContentStorageName());
      crudManager.contentService.updateContentTree(
        crudManager.contentService.getTemplateForNewContentTree()
      );
      setContentTree(crudManager.contentService.getContentTree());
    }
  }, []);

  useEffect(() => {
    console.log({ contentTreeContext: contentTreeContext });
    onContentTreeContextChangeCallback(contentTreeContext);
  }, [contentTreeContext]);

  const classes = useStyles();

  if (undefined === contentTree || hide === true) {
    return null;
  }

  const setTheContentTreeContext = (nodeId, pathIdentifier) => {
    console.log(nodeId, pathIdentifier);
    setContentTreeContext(crudManager.getFromTreeByRelPath(pathIdentifier));
  };

  const moveContent = (
    relPath,
    targetRelPath,
    deleteFromOriginalLocation,
    draggedItem
  ) => {
    console.log(relPath, targetRelPath, deleteFromOriginalLocation);
    // crudManager.updateContentByRelPath(relPath, targetRelPath, deleteFromOriginalLocation);

    let contentTreeValue = crudManager.moveContentFromPathToPath(
      relPath,
      targetRelPath
    );

    setContentTree({ ...contentTreeValue });

    setContentTreeContext(crudManager.getFromTreeByRelPath(targetRelPath));
  };

  const contextMenuCallback = (
    relPath,
    targetRelPath,
    deleteFromOriginalLocation,
    draggedItem
  ) => {
    // console.log(relPath, targetRelPath, deleteFromOriginalLocation);
    // crudManager.updateContentByRelPath(relPath, targetRelPath, deleteFromOriginalLocation);

    console.log({ msg: "yayContextMenu!" });

    // let contentTreeValue = crudManager.moveContentFromPathToPath(relPath, targetRelPath);

    // setContentTree(
    //   {...contentTreeValue}
    // );
    //
    // setContentTreeContext(
    //   crudManager.getFromTreeByRelPath(
    //     targetRelPath
    //   )
    // );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {contentTree["_pages_"].map((page, pageIndex) => (
          <CustomTreeItem
            onContextMenuCallback={contextMenuCallback}
            moveContentCallback={moveContent}
            treeItemType={"CONTENT_PAGE"}
            pathIdentifier={`_pages_::${pageIndex}`}
            uuid={page.uuid}
            key={page.uuid}
            label={page.name}
            onSelectItemCallback={setTheContentTreeContext}
          >
            {page["content-sections"].map(
              (contentSection, contentSectionIndex) => (
                <CustomTreeItem
                  onContextMenuCallback={contextMenuCallback}
                  moveContentCallback={moveContent}
                  treeItemType={"CONTENT_SECTION"}
                  pathIdentifier={`_pages_::${pageIndex}::content-sections::${contentSectionIndex}`}
                  uuid={contentSection.uuid}
                  key={contentSection.uuid}
                  label={contentSection.name}
                  onSelectItemCallback={setTheContentTreeContext}
                >
                  {contentSection["containers"].map(
                    (container, containerIndex) => (
                      <CustomTreeItem
                        onContextMenuCallback={contextMenuCallback}
                        moveContentCallback={moveContent}
                        treeItemType={"CONTENT_CONTAINER"}
                        pathIdentifier={`_pages_::${pageIndex}::content-sections::${contentSectionIndex}::containers::${containerIndex}`}
                        uuid={container.uuid}
                        key={container.uuid}
                        label={container.name}
                        onSelectItemCallback={setTheContentTreeContext}
                      >
                        {container["content"].map((content, contentIndex) => (
                          <CustomTreeItem
                            onContextMenuCallback={contextMenuCallback}
                            moveContentCallback={moveContent}
                            treeItemType={"CONTENT_CONTENT"}
                            pathIdentifier={`_pages_::${pageIndex}::content-sections::${contentSectionIndex}::containers::${containerIndex}::content::${contentIndex}`}
                            uuid={content.uuid}
                            key={content.uuid}
                            label={content.name}
                            onSelectItemCallback={setTheContentTreeContext}
                          />
                        ))}
                      </CustomTreeItem>
                    )
                  )}
                </CustomTreeItem>
              )
            )}
          </CustomTreeItem>
        ))}
      </TreeView>
    </DndProvider>
  );
}

ContentTreeNavigationComponent.propTypes = {
  config: PropTypes.any.isRequired,
  hide: PropTypes.bool,
  onContentTreeContextChangeCallback: PropTypes.func.isRequired,
};

export default ContentTreeNavigationComponent;
