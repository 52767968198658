import React, { useState, useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// material-ui components
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import useSplitButtonStyle from "../../assets/jss/material-dashboard-react/components/splitButtonStyle.js";
import { useHistory } from "react-router-dom";

export default function SplitButton(props) {
  const classes = useSplitButtonStyle();
  const {
    actionOptions,
    label,
    color,
    round,
    children,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
  });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  // const [selectedIndex, setSelectedIndex] = useState(null);
  const history = useHistory();
  // let selectedIndex = null;

  const handleMenuItemClick = (event, index) => {
    // setSelectedIndex(index);
    // let selectedIndex = null;
    // selectedIndex = index;
    setOpen(false);
    // console.log({ object: actionOptions.options[index] });
    history.push({
      pathname: actionOptions.options[index].option_link,
      state: actionOptions.options[index].option_state,
    });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="flex-start">
      <Grid item xs={12}>
        <ButtonGroup
          style={{ margin: 0, padding: 0 }}
          className={btnClasses}
          variant="contained"
          // color={color}
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            {...rest}
            classes={muiClasses}
            style={{ margin: 0, padding: 5, paddingLeft: 15, paddingRight: 15 }}
            className={btnClasses}
            onClick={handleToggle}
            size="small"
          >
            {label}
          </Button>
          <Button
            {...rest}
            classes={muiClasses}
            style={{ margin: 0, padding: 5, paddingRight: 0 }}
            className={btnClasses}
            // color={color}
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          style={{ zIndex: 9 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {actionOptions.options.map((option, index) => (
                      <MenuItem
                        key={option.option_name}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option.option_name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}

SplitButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "transparent",
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  // use this to pass the classes props from Material-UI
  muiClasses: PropTypes.object,
  children: PropTypes.node,
  actionOptions: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.any),
  }),
  label: PropTypes.string,
};
