import React, { useState, createRef, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Footer from "../../../../components/Footer/Footer.js";
import Sidebar from "../../../../components/Sidebar/Sidebar2.js";
import routes from "../routes.js";
import styles from "../../../../assets/jss/material-dashboard-react/layouts/adminStyleNew.js";
import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/Symbol-Curbcycle.jpg";
// Views for Admin layout & shared pages
import AccountsPage from "../../SharedPages/Accounts/Accounts";
import AccountSummaryPage from "../../SharedPages/Accounts/AccountSummary/AccountSummary";
import AccountCrmIntegrationSettingsPage from "../../SharedPages/Accounts/CrmIntegration/CrmIntegrationSettings";
import MaterialJourneys from "../../SharedPages/Accounts/MaterialJourneys/MaterialJourneys";
import LGAEngagement from "../../SharedPages/Accounts/LGAEngagement/LGAEngagement";
import Secret from "../../Secret/index";

let ps;

const useStyles = makeStyles(styles);

export default function MainLayout({ ...rest }) {
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = createRef();
  const [mobileOpen, setMobileOpen] = useState(false);
  const image = bgImage;
  const color = "";

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const isMapsPage = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  const switchRoutes = (
    <Switch>
      <Route path="/management/accounts" show={true}>
        <AccountsPage />
      </Route>
      <Route path="/management/account-summary/:uuid" show={false}>
        <AccountSummaryPage />
      </Route>
      <Route
        path="/management/account-crm-integration-settings/:uuid"
        show={false}
      >
        <AccountCrmIntegrationSettingsPage />
      </Route>
      <Route path="/management/material-type/:uuid" show={false}>
        <MaterialJourneys />
      </Route>
      <Route
        path="/management/enabled-lga-engagement-options/:uuid"
        show={false}
      >
        <LGAEngagement />
      </Route>
      <Route path="/management/secret" show={true}>
        <Secret />
      </Route>
      <Redirect from="/" to="/management/accounts" />
    </Switch>
  );

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"CurbCycle"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {isMapsPage ? (
          <div className={classes.map}>{switchRoutes}</div>
        ) : (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        )}
        {isMapsPage ? <Footer /> : null}
      </div>
    </div>
  );
}
