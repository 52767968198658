import React from "react";
import PropTypes from "prop-types";
import MapViewManager from "./MapViewManager";

function Map({ awesomeAnalyticsData }) {
  return (
    // Important! Always set the container height explicitly
    <MapViewManager awesomeAnalyticsData={awesomeAnalyticsData} />
  );
}

Map.propTypes = {
  awesomeAnalyticsData: PropTypes.shape(PropTypes.any),
};

export default Map;
