import React, { useState } from "react";
import CountryMap from "./CountryMap";
import StatesMap from "./StatesMap";
import IQRMap from "./IQRMap";

function MapViewManager() {
  const defaultState = {
    views: {
      country: CountryMap,
      states: StatesMap,
      iqr: IQRMap,
    },
    currentView: "iqr",
  };

  const [mapViewState, setMapViewState] = useState(defaultState);
  const [map, setMap] = useState(undefined);

  const viewTextObject = {
    country:
      "There is currently one Curby Program trial underway with partcipants from Central Coast Council recycling Soft Plastics.",
    state:
      "The initial trial involved sending a CurbyPack containing 12 tags and bags which could be filled with soft plastics and placed in the yellow lid recycling bin.",
    iqr:
      "We confirmed participation of 2,437 households out of the 2,516 households registered for the trial.",
  };

  const [theViewText, setTheViewText] = useState(viewTextObject.country);

  const handleSwitchViewCallback = (nextViewContext) => {
    let theMapViewState = { ...mapViewState };
    theMapViewState.currentView = nextViewContext.view;
    setMapViewState(theMapViewState);
  };

  if (mapViewState.currentView === "country") {
    return <CountryMap onSwitchViewCallback={handleSwitchViewCallback} />;
  }

  if (mapViewState.currentView === "states") {
    return <StatesMap onSwitchViewCallback={handleSwitchViewCallback} />;
  }

  if (mapViewState.currentView === "iqr") {
    const buttonStyles = {
      padding: "5px",
      borderRadius: "10px",
      marginRight: "10px",
      marginBottom: "10px",
    };
    return (
      <>
        <div>
          <br />
        </div>

        <div
          style={{
            position: "absolute",
            width: "33%",
            height: "250px",
            zIndex: 10000,
            backgroundColor: "#fff",
            margin: "10px",
            border: "solid 1px #999",
            borderRadius: "10px",
            opacity: 0.95,
          }}
        >
          <div
            style={{
              fontSize: "1em",
              margin: "10px",
            }}
          >
            <div>
              <button
                style={buttonStyles}
                onClick={() => {
                  map.setZoom(0);
                  map.setCenter({
                    lat: -28.309356,
                    lng: 140.309208,
                  });
                  setTheViewText(viewTextObject.country);
                }}
              >
                Australia
              </button>
            </div>

            <div>
              <button style={buttonStyles} disabled="disabled">
                ACT
              </button>
              <button
                style={buttonStyles}
                onClick={() => {
                  map.setZoom(6);
                  map.setCenter({
                    lat: -33.309356,
                    lng: 151.309208,
                  });
                  setTheViewText(viewTextObject.state);
                }}
              >
                NSW
              </button>
              <button style={buttonStyles} disabled="disabled">
                SA
              </button>
              <button style={buttonStyles} disabled="disabled">
                TAS
              </button>
              <button style={buttonStyles} disabled="disabled">
                VIC
              </button>
              <button style={buttonStyles} disabled="disabled">
                WA
              </button>
            </div>

            <div>
              <button
                style={buttonStyles}
                onClick={() => {
                  map.setZoom(10);
                  map.setCenter({
                    lat: -33.309356,
                    lng: 151.309208,
                  });
                  setTheViewText(viewTextObject.iqr);
                }}
              >
                IQ Renew
              </button>
            </div>
          </div>
          <p
            style={{
              fontSize: "1em",
              margin: "10px",
            }}
          >
            {theViewText}
          </p>
        </div>

        <IQRMap
          onSwitchViewCallback={handleSwitchViewCallback}
          mapAccessorCallback={(map) => {
            setMap(map);
          }}
        />
      </>
    );
  }
}

export default MapViewManager;
