import React, { useState, createRef, useEffect } from "react";
import PropTypes from "prop-types";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import { connect, useDispatch } from "react-redux";
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import { styled } from '@material-ui/core/styles';
// core components
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import CardBody from "../../../../components/Card/CardBody.js";
import AverageMonthlyBagCollectionByPostcode from "./components/Charts/AverageMonthlyBagCollectionByPostcode";
import NumberOfTrialSignUpsByPostcode from "./components/Charts/NumberOfTrialSignUpsByPostcode";
import NumberOfBagsReceivedPerDay from "./components/Charts/NumberOfBagsReceivedPerDay";
import BaleWeightScannedInPerDayWithBalePhotoModalController from "./components/Charts/BaleWeightScannedInPerDayWithBalePhotoModalController";
import MvpTrialParticipation from "./components/Charts/MvpTrialParticipation";
import TonnageTargetVsTime from "./components/Charts/TonnageTargetVsTime";
import BigNumberProgressBar from "./components/Charts/BigNumber/BigNumberProgressBar";
import WhereDidThePlasticGo from "./../../../../components/DashboardComponents/WhereDidThePlasticGo/WhereDidThePlasticGo";
import PostMvpParticipationMap from "./../../../../components/DashboardComponents/Map/AwesomeMapComponent/PostMvpParticipationMap";
import InteractivityIndicator from "./../../../../components/DashboardComponents/InteractivityIndicator";
import styles from "./assets/jss/adminStyle.js";
import LogoutAvatar from "components/Sidebar/components/LogoutAvatar";
import curbCycleLogo from "./assets/nestle-curbcycle-logo.png";
import downloadCertificateButton from "./assets/download-certificate-button.png";
import logo from "./assets/nestle-logo.png";
import fetchAnalyticsThunk from "../../../../thunks/RetailSponsor/fetchAnalyticsThunk";
import Overview from "pages/JourneyFlows/SharedPages/Analytics/Overview.js";
import DateRange from "@material-ui/icons/DateRange";
import MrfStatsTable from "components/DashboardComponents/MrfStatsTable.js";
import UserStatsTable from "components/DashboardComponents/UserStatsTable.js";
import StewardStatsTable from "components/DashboardComponents/StewardStatsTable.js";
import SignupsByProgramBarGraph from "components/DashboardComponents/SignupsByProgramBarGraph.js";
import GenericBaleBarGraph from "components/DashboardComponents/GenericBaleBarGraph.js";
import { getRSQuicksightUrl } from "services/CurbCycleApi";
import GenericBarGraph from "components/DashboardComponents/GenericBarGraph.js";
import CCScanBarGraph from "components/DashboardComponents/CCScanBarGraph.js";
import GenericScanBarGraph from "components/DashboardComponents/GenericScanBarGraph.js";
import Dashboard from "components/DashboardComponents/Dashboard.js";
let ps;

const useStyles = makeStyles(styles);

const balesChartTooltipImage = require("./../../../../components/DashboardComponents/clickableBarImage.png");

function MainLayout({}) {
  // styles
  const classes = useStyles();
  const mainPanel = createRef();
  const [setMobileOpen] = useState(false);
  const [dashUrl, setDashUrl] = useState(null);

  useEffect(() => {
    getRSQuicksightUrl().then((data) => {
      setDashUrl(data.EmbedUrl)
    
    })
    
  }, []);


  return (
    <div
      id="productStewardshipHeadingArea"
      className={classes.wrapper}
      style={{
        backgroundColor: "#63513e",
        width: "100%",
      }}
    >
      <div
        style={{
          zIndex: 999999,
          position: "fixed",
          top: "1em",
          paddingTop: "15px",
          marginTop: "40",
          width: "10%",
          marginLeft: "85%",
          height: "50px",
        }}
      >
        <LogoutAvatar style={{ zIndex: 1000000 }}>N</LogoutAvatar>
      </div>

      <div className={classes.mainPanel} ref={mainPanel}
      >
        <div
          style={{
            display: "flex",
            flexFlow: "row wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              flexFlow: "column wrap",
            }}
          >
            <img
              src={curbCycleLogo}
              alt=""
              style={{
                maxWidth: "170px",
                paddingTop: "1em",
                paddingLeft: "1em",
                paddingRight: "1em",
              }}
            />
            <img
              src={logo}
              alt=""
              style={{ maxWidth: "170px", padding: "1em" }}
            />
          </div>

          <div
            style={{
              display: "inline",
            }}
          >
            <h1
              style={{
                color: "#fff",
                fontSize: "2.5em",
                fontWeight: "500",
                paddingTop: "1.5em",
                paddingBottom: "0",
                marginBottom: "0",
              }}
            >
              Nestle
            </h1>

            <h4
              style={{
                color: "#fff",
                fontSize: "1em",
                fontWeight: "500",
                paddingTop: "0",
                paddingLeft: "0.2em",
                marginTop: "0.1em",
                paddingBottom: "0",
                marginBottom: "0",
              }}
            >
              <b>Certification Number:</b> C123456
            </h4>

            <h4
              style={{
                color: "#fff",
                fontSize: "1em",
                fontWeight: "500",
                paddingTop: "0",
                paddingLeft: "0.2em",
                marginTop: "0.1em",
                paddingBottom: "0",
                marginBottom: "0",
              }}
            >

              
            </h4>

            {/* <img
              className="download-certificate-button"
              src={downloadCertificateButton}
              alt=""
              style={{
                maxWidth: "200px",
                paddingTop: "0.5em",
                paddingLeft: "0",
                paddingRight: "0.5em",
              }}
              onClick={() => {
                alert(
                  "When this capability is enabled, this will allow the Nestle team to download a certificate confirming tonnage offset..."
                );
              }}
            /> */}
          </div>
        </div>
      </div>

      <div
        className={classes.mainPanel}
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#eee"}}
      >
        <GridContainer
          style={{
            width: "100%",
            height: "140%",
          }}
          id="productStewardshipHeadingBox"
        >
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
            

            
          <h4>Program Statistics</h4>
          <Dashboard dashUrl={dashUrl}/>

            
                        </GridItem>
            </GridContainer>
                      <Divider/>
                      <p>(C) Curbcycle 2022</p>
                    </div>
                    
        <br style={{ clear: "both" }} />
      </div>
  );
}


export default MainLayout;
