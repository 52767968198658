import React, { useState } from "react";
import PropTypes from "prop-types";
import InteractivityIndicator from "./../InteractivityIndicator";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardBody from "../../../components/Card/CardBody.js";
import Modal from "../../../components/Modal/ModalWithChildContent";
import BigNumberProgressBar from "../BigNumber/BigNumberProgressBar";
const whereDidThePlasticGoImage = require("./where-did-the-plastic-go.png");

const WhereDidThePlasticGo = ({ awesomeAnalyticsData = {} }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(undefined);
  const [modalContent, setModalContent] = useState(undefined);

  const theBigCardStyle = {
    borderRadius: "6px",
    backgroundColor: "#f8f8f8",
    paddingRight: 0,
  };

  const theBiggestCardHeadingStyle = {
    marginTop: "-0.2em",
    marginBottom: "0em",
    color: "#63513e",
    textAlign: "left",
    fontWeight: 400,
    paddingBottom: 0,
  };

  const theBigCardHeadingStyle = {
    marginTop: "0.5em",
    color: "#63513e",
    textAlign: "left",
    fontWeight: 400,
    marginBottom: 0,
    paddingBottom: 0,
  };

  const theCardHeadingStyle = {
    marginTop: "-0.2em",
    fontSize: "1em",
    color: "#63513e",
    textAlign: "left",
    fontWeight: 400,
  };

  return (
    <>
      <GridItem
        xs={11}
        sm={11}
        md={11}
        style={{
          marginLeft: "5rem",
        }}
      >
        <Card>
          <CardBody style={theBigCardStyle}>
            <h3 style={theBiggestCardHeadingStyle}>
              Where did the plastic go?
            </h3>

            <h4 style={theBigCardHeadingStyle}>MRF Bale Inventory</h4>

            <GridContainer
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <GridItem xs={12} sm={12} md={2}>
                <Card>
                  <CardHeader>
                    <h5 style={theCardHeadingStyle}>Number of bales</h5>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar
                      bigNumberText={`${awesomeAnalyticsData.total_bales_scanned_in}`}
                    />
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={2}>
                <Card>
                  <CardHeader>
                    <h5 style={theCardHeadingStyle}>Total weight</h5>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar
                      bigNumberText={`${(
                        awesomeAnalyticsData.total_weight_of_bales_scanned_in /
                        1000000
                      ).toFixed(1)}t`}
                    />
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={2}>
                <Card>
                  <CardHeader>
                    <h5 style={theCardHeadingStyle}>Ave. bale weight</h5>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar
                      bigNumberText={`${(
                        awesomeAnalyticsData.total_weight_of_bales_scanned_in /
                        1000 /
                        awesomeAnalyticsData.total_bales_scanned_in
                      ).toFixed(0)}kg`}
                    />
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={2}>
                <Card>
                  <CardHeader>
                    <h5 style={theCardHeadingStyle}>Ave. condition</h5>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar
                      bigNumberText={`${
                        undefined ===
                          awesomeAnalyticsData.average_bale_condition_score ||
                        null ===
                          awesomeAnalyticsData.average_bale_condition_score
                          ? 0
                          : parseFloat(
                              awesomeAnalyticsData.average_bale_condition_score
                            ).toFixed(2)
                      }`}
                      bigNumberTextMeasurementText={`/5.00`}
                    />
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={2}>
                <Card>
                  <CardHeader>
                    <h5 style={theCardHeadingStyle}>
                      Recycled{" "}
                      <span style={{ fontSize: "0.7em" }}>(last 30 days)</span>
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar bigNumberText={`0`} />
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={2}>
                <Card>
                  <CardHeader>
                    <h5 style={theCardHeadingStyle}>Rejected to landfill</h5>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar bigNumberText={`0`} />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

            <h4 style={theBigCardHeadingStyle}>Soft Plastic Recycling</h4>

            <GridContainer
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <GridItem xs={12} sm={12} md={2}>
                <Card>
                  <CardHeader>
                    <h5 style={theCardHeadingStyle}>Trials and tests</h5>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar bigNumberText={`0kg`} />
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={2}>
                <Card
                  hasActionOnPress={true}
                  onPressCallback={() => {
                    setModalContent(
                      <div
                        style={{
                          overflow: "auto",
                        }}
                      >
                        <GridContainer
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <GridItem xs={12} sm={12} md={12}>
                            <h4>
                              13th March 2021 - Nestlé Australia co-develops
                              recycled plastic wrapper for KitKat
                            </h4>
                            <Card>
                              <CardBody>
                                <GridContainer>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <img
                                      style={{ maxWidth: "500px" }}
                                      alt=""
                                      src="https://cdn.curby.cc/CurbCycle+Kitkat+recycled+wrapper+-+infographic-01.png"
                                    />
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={5}>
                                    <p>
                                      In an Australian first, a group of
                                      companies have collaborated to produce
                                      Australia’s first soft plastic food
                                      wrapper made with recycled content. Food
                                      grade recycled soft plastic packaging is a
                                      key missing link in Australia’s bid to
                                      improve waste management and build a
                                      circular economy, with a lack of both
                                      collection and processing infrastructure
                                      making it difficult to keep waste out of
                                      landfill and impossible to meet demands
                                      for packaging with recycled content. The
                                      prototype KitKat wrapper, which represents
                                      Australia’s opportunity to close the loop
                                      on recycling soft plastics, has been
                                      created by a coalition of companies with a
                                      shared vision. Between them, Nestlé,
                                      CurbCycle, iQ Renew, Licella, Viva Energy
                                      Australia, LyondellBasell, REDcycle,
                                      Taghleef Industries and Amcor brought
                                      their individual expertise together to
                                      collect and process waste soft plastic,
                                      turn it back into oil, and create the
                                      prototype wrapper.
                                    </p>

                                    <p>
                                      <strong>More:</strong>
                                      <br />
                                      <a
                                        href="https://www.nestle.com/media/news/nestle-australia-co-develops-recycled-plastic-wrapper-kitkat"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        https://www.nestle.com/media/news/nestle-australia-co-develops-recycled-plastic-wrapper-kitkat
                                      </a>
                                    </p>
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    );
                    setModalTitle("Cat-HTR recycling");
                    setShowModal(true);
                  }}
                >
                  <CardHeader>
                    <h5 style={theCardHeadingStyle}>
                      Cat-HTR recycling
                      <InteractivityIndicator
                        titleCallback={() => {
                          return "Further information";
                        }}
                        messageHtmlCallback={() => {
                          return (
                            <em>
                              Click the '350kg' for more information about the
                              Cat-HTR process
                            </em>
                          );
                        }}
                        imageHtmlCallback={() => {
                          return (
                            <img
                              width={"210px"}
                              src={whereDidThePlasticGoImage}
                              alt=""
                            />
                          );
                        }}
                      />
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar bigNumberText={`350kg`} />
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={2}>
                <Card>
                  <CardHeader>
                    <h5 style={theCardHeadingStyle}>Energy production</h5>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar bigNumberText={`0kg`} />
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={2}>
                <Card>
                  <CardHeader>
                    <h5 style={theCardHeadingStyle}>Other recycling</h5>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar bigNumberText={`0kg`} />
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={2}>
                <Card>
                  <CardHeader>
                    <h5 style={theCardHeadingStyle}>Civil applications</h5>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar bigNumberText={`0kg`} />
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={2}>
                <Card>
                  <CardHeader>
                    <h5 style={theCardHeadingStyle}>Rejected to landfill</h5>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar bigNumberText={`0kg`} />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <Modal
        shouldBeOpen={showModal}
        modalTitle={modalTitle}
        onCloseCallback={() => {
          setShowModal(false);
          setModalContent(undefined);
          setModalTitle(undefined);
        }}
      >
        {modalContent}
      </Modal>
    </>
  );
};

WhereDidThePlasticGo.propTypes = {
  awesomeAnalyticsData: PropTypes.shape(PropTypes.any),
};

export default WhereDidThePlasticGo;
