import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
// import ListItemText from '@material-ui/core/ListItemText';
import ContentCreator from "./../components/ContentCreator";
// import ContentTreeNavigationComponent from './../components/ContentTreeNavigationComponent';

const useStyles = makeStyles({
  list: {
    width: window.outerWidth - 60,
  },
  fullList: {
    width: "auto",
  },
});

function ContentToolDrawer() {
  const classes = useStyles();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      style={{ marginLeft: 60 }}
    >
      <List>
        <ListItem key={"efdfsdf"}>
          <h4>Content Creation Tools</h4>
          <IconButton
            aria-label="close drawer"
            component="span"
            onClick={toggleDrawer(anchor, false)}
            style={{ position: "absolute", right: 60 }}
          >
            <Close />
          </IconButton>
        </ListItem>
        <Divider />
        <ListItem key={"efdfsdf2"}>
          <ContentCreator />
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            Create Curby App Content
          </Button>
          <Drawer
            style={{ zIndex: 0 }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default ContentToolDrawer;
