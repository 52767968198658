import {
  managementRequestedAddRetailSponsor,
  managementCompletedAddRetailSponsor,
} from "../../../redux/reducer";

import { addRetailSponsor } from '../../../services/CurbCycleApi';

export const addRetailSponsorThunk = (payload, setIsSaving, setWasSuccessful) => dispatch => {
  dispatch(managementRequestedAddRetailSponsor());
  setIsSaving(true);
  addRetailSponsor(payload)
    .then(result => {
      dispatch(managementCompletedAddRetailSponsor(result));
      setIsSaving(false);
      setWasSuccessful(true);
    })
    .catch(() => {
      dispatch(managementCompletedAddRetailSponsor({}));
      setIsSaving(false);
    });
};

export default addRetailSponsorThunk;
