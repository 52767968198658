import {
  managementRequestedAddLGA,
  managementCompletedAddLGA,
} from "../../../redux/reducer";

import { addLGA } from '../../../services/CurbCycleApi';

export const addLGAThunk = (payload, setIsSaving, setWasSuccessful) => dispatch => {
  dispatch(managementRequestedAddLGA());
  setIsSaving(true);
  addLGA(payload)
    .then(result => {
      dispatch(managementCompletedAddLGA(result));
      setIsSaving(false);
      setWasSuccessful(true);
    })
    .catch(() => {
      dispatch(managementCompletedAddLGA({}));
      setIsSaving(false);
    });
};

export default addLGAThunk;
