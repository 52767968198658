import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
// core components
import GridItem from "../../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../../components/Grid/GridContainer.js";
// custom components
import CustomTabs from "../../../layouts/components/CustomTabs/CustomTabs";
import BaleCodesInbound from "./BaleCodesInbound/BaleCodesInbound";
import BaleBatchCodesOutbound from "./BaleBatchCodesOutbound/BaleBatchCodesOutbound";

export default function InventoryMaterialTypeDataTable(props) {
  const { materialType, backAction, materialTypes } = props;

  return (
    <div>
      <Fade in={true} timeout={3000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title="Inventory Management:"
              headerColor="primary"
              tabs={[
                {
                  tabName: "Inbound",
                  tabContent: (
                    <BaleCodesInbound
                      materialType={materialType}
                      backAction={backAction}
                    />
                  ),
                },
                {
                  tabName: "Outbound",
                  tabContent: (
                    <BaleBatchCodesOutbound
                      materialTypes={materialTypes}
                      materialType={materialType}
                      backAction={backAction}
                    />
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </Fade>
    </div>
  );
}

InventoryMaterialTypeDataTable.propTypes = {
  backAction: PropTypes.func.isRequired,
  materialType: PropTypes.string.isRequired,
  materialTypes: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};
