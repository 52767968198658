import React from "react";
import PropTypes from "prop-types";
// @material-ui/icons
import Icon from "@material-ui/core/Icon";
// @material-ui/core components
import Fade from "@material-ui/core/Fade";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// core components
import useTableStyle from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import Button from "components/CustomButtons/Button";
import SplitButton from "components/CustomButtons/SplitButton";
import { Link } from "react-router-dom";
import uuid from "../../utilities/uuid";
import mapStatusNameToIconName from "../../utilities/mapStatusNameToIconName";
import Pagination from "./Pagination";

function ActionableDataTable(props) {
  const classes = useTableStyle();
  let actionPropKey = null;
  let statusPropKey = null;
  const {
    tableHead,
    tableData,
    tableHeaderAliases = {},
    tableHeaderColor,
    pagination,
    paginationConfig,
    thunkActionsConfig,
  } = props;

  const usePaginator = undefined !== pagination && pagination === true;

  if (tableData.length < 1) {
    return (
      <div className={classes.tableResponsive}>
        <Table className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow className={classes.tableHeadRow}>
                {tableHead.map((prop, key) => {
                  if (prop === "Actions") {
                    actionPropKey = key;
                  }
                  if (prop === "Status") {
                    statusPropKey = key;
                  }
                  return (
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                      key={key}
                    >
                      {prop === "Actions" || prop === "Status"
                        ? undefined !== tableHeaderAliases[prop]
                          ? tableHeaderAliases[prop]
                          : prop
                        : prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody></TableBody>
        </Table>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="5vh"
        >
          <span
            style={{
              color: "#ce4328",
              fontWeight: "300",
            }}
          >
            {" "}
            No records match the search criteria{" "}
          </span>
        </Box>
      </div>
    );
  }

  let propKeysWhichHaveAnAction = {};

  if (tableData.length > 0) {
    // console.log(tableData[0]);
    tableData[0].forEach((value, index) => {
      if (undefined !== value.hasActions && value.hasActions === true) {
        propKeysWhichHaveAnAction[index] = index;
      }
    });
  }

  if (usePaginator) {
    return (
      <div className={classes.tableResponsive}>
        <Table className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow className={classes.tableHeadRow}>
                {tableHead.map((prop, key) => {
                  if (prop === "Actions") {
                    actionPropKey = key;
                  }
                  if (prop === "Status") {
                    statusPropKey = key;
                  }
                  return (
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                      key={key}
                    >
                      {prop === "Actions" || prop === "Status"
                        ? undefined !== tableHeaderAliases[prop]
                          ? tableHeaderAliases[prop]
                          : prop
                        : prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <Fade in={true} timeout={1000}>
            <TableBody>
              {tableData.map((prop, key) => {
                return (
                  <TableRow key={uuid.uuid()} className={classes.tableBodyRow}>
                    {prop.map((prop, key) => {
                      if (
                        undefined !== propKeysWhichHaveAnAction[key] ||
                        (actionPropKey !== null && key === actionPropKey)
                      ) {
                        // cool, this is the actions property which should be a configuration object, let's map switch...
                        const actionContentPaginated = prop.actions.map(
                          (action) => {
                            let initialToggleState =
                              action.context.initialToggleState?.value;
                            const handleChange = (event) => {
                              action.context.onToggleSwitch({
                                key:
                                  action.context.initialToggleState?.identifier,
                                  value: event.target.checked,
                              });
                            };
                            switch (action.type) {
                              case "callback-button":
                                return (
                                  <Button
                                    key={uuid.uuid()}
                                    link={false}
                                    round={true}
                                    onClick={() => {action.context.callback()}}
                                    size={action.size}
                                    color={action.color}
                                  >
                                    <span
                                      title={action.description}
                                      className="material-icons"
                                    >
                                      {action.icon}
                                    </span>
                                  </Button>
                                );
                              case "button":
                                return (
                                  <Button
                                    key={uuid.uuid()}
                                    justIcon={true}
                                    link={true}
                                    onClick={action.onTrigger}
                                  >
                                    <span className="material-icons">
                                      {action.icon}
                                    </span>
                                  </Button>
                                );
                              case "link-button":
                                return (
                                  <Link
                                    key={uuid.uuid()}
                                    to={action.context.link}
                                  >
                                    <Button
                                      link={false}
                                      round={true}
                                      size={action.size}
                                      color={action.color}
                                    >
                                      <span
                                        title={action.description}
                                        className="material-icons"
                                      >
                                        {action.icon}
                                      </span>
                                    </Button>
                                  </Link>
                                );
                              case "image-link":
                                return (
                                  <Button
                                    style={{ margin: 0, padding: 0 }}
                                    key={uuid.uuid()}
                                    link={false}
                                    size={action.size}
                                    description={action.description}
                                    onClick={action.context.callback}
                                    color="transparent"
                                    justIcon
                                  >
                                    <Icon
                                      style={{ marginLeft: 20, padding: 0 }}
                                      color={action.color}
                                      title={action.description}
                                    >
                                      {action.icon}
                                    </Icon>
                                  </Button>
                                );
                              case "toggle-input-switch":
                                if (true === action.context.isSaving) {
                                  if (
                                    action.context.initialToggleState
                                      .identifier ===
                                    action.context.activeIdentifier
                                  ) {
                                    // render a spinner while identifier is saving/updating in the backend
                                    return (
                                      <FormControlLabel
                                        key={uuid.uuid()}
                                        control={
                                          <CircularProgress
                                            size={22}
                                            style={{
                                              paddingRight: 10,
                                              paddingLeft: 10,
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                            }}
                                          />
                                        }
                                        label={action.spinnerLabel}
                                      />
                                    );
                                  } else {
                                    // render a toggle switch while identifier is not saving/updating in the backend
                                    return (
                                      <FormGroup row key={uuid.uuid()}>
                                        <FormControlLabel
                                          key={uuid.uuid()}
                                          control={
                                            <Switch
                                              key={uuid.uuid()}
                                              disabled
                                              size={action.size}
                                              color={action.color}
                                              checked={initialToggleState}
                                            />
                                          }
                                          label={action.label}
                                        />
                                      </FormGroup>
                                    );
                                  }
                                }
                                return (
                                  <FormGroup row key={uuid.uuid()}>
                                    <FormControlLabel
                                      key={uuid.uuid()}
                                      control={
                                        <Switch
                                          key={uuid.uuid()}
                                          size={action.size}
                                          color={action.color}
                                          checked={initialToggleState}
                                          onChange={handleChange}
                                        />
                                      }
                                      label={action.label}
                                    />
                                  </FormGroup>
                                );
                              case "multi-link-button":
                                return (
                                  <SplitButton
                                    key={uuid.uuid()}
                                    actionOptions={action.context.actionOptions}
                                    label={action.context.label}
                                    color={action.color}
                                  />
                                );
                              case "link-button-calling-thunk-with-context":
                                return (
                                  <Button
                                    key={uuid.uuid()}
                                    link={false}
                                    round={true}
                                    size={action.size}
                                    color={action.color}
                                    onClick={() => {
                                      thunkActionsConfig.actionKeys[0].dispatch(
                                        thunkActionsConfig.actionKeys[0].thunk(
                                          action.context.params[0]
                                        )
                                      );
                                    }}
                                  >
                                    <span
                                      title={action.description}
                                      className="material-icons"
                                    >
                                      {action.icon}
                                    </span>
                                  </Button>
                                );
                              default:
                                throw new Error(
                                  "You have not assigned a case type"
                                );
                            }
                          }
                        );
                        return (
                          <TableCell
                            className={classes.tableCell}
                            key={uuid.uuid()}
                          >
                            {actionContentPaginated}
                          </TableCell>
                        );
                      }
                      if (
                        undefined === propKeysWhichHaveAnAction[key] &&
                        statusPropKey !== null &&
                        key === statusPropKey
                      ) {
                        return (
                          <TableCell
                            className={classes.tableCell}
                            key={uuid.uuid()}
                          >
                            <span
                              className="material-icons"
                              title={prop.display}
                            >
                              {" "}
                              {mapStatusNameToIconName.map(prop.display)}{" "}
                            </span>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          className={classes.tableCell}
                          key={uuid.uuid()}
                        >
                          {prop.display}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Fade>
        </Table>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="5vh"
          style={{
            backgroundColor: "#27253d",
          }}
        >
          <Pagination paginationConfig={paginationConfig} />
        </Box>
      </div>
    );
  }

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                if (prop === "Actions") {
                  actionPropKey = key;
                }
                if (prop === "Status") {
                  statusPropKey = key;
                }
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop === "Actions" || prop === "Status"
                      ? undefined !== tableHeaderAliases[prop]
                        ? tableHeaderAliases[prop]
                        : prop
                      : prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            return (
              <TableRow key={uuid.uuid()} className={classes.tableBodyRow}>
                {prop.map((prop, key) => {
                  if (
                    undefined !== propKeysWhichHaveAnAction[key] ||
                    (actionPropKey !== null && key === actionPropKey)
                  ) {
                    // cool, this is the actions property which should be a configuration object, let's map switch...
                    const actionContent = prop.actions.map((action) => {
                      let initialToggleState =
                        action.context.initialToggleState?.value;
                      const handleChange = (event) => {
                        action.context.onToggleSwitch({
                          key: action.context.initialToggleState?.identifier,
                          value: event.target.checked,
                        });
                      };
                      switch (action.type) {
                        case "button":
                          return (
                            <Button
                              key={uuid.uuid()}
                              justIcon={true}
                              link={true}
                              onClick={action.onTrigger}
                            >
                              <span className="material-icons">
                                {action.icon}
                              </span>
                            </Button>
                          );
                        case "link-button":
                          return (
                            <Link key={uuid.uuid()} to={action.context.link}>
                              <Button
                                link={false}
                                round={true}
                                size={action.size}
                                color={action.color}
                              >
                                <span
                                  title={action.description}
                                  className="material-icons"
                                >
                                  {action.icon}
                                </span>
                              </Button>
                            </Link>
                          );
                        case "image-link":
                          return (
                            <Button
                              style={{ margin: 0, padding: 0 }}
                              key={uuid.uuid()}
                              link={false}
                              size={action.size}
                              description={action.description}
                              onClick={action.context.callback}
                              color="transparent"
                              justIcon
                            >
                              <Icon
                                style={{ marginLeft: 20, padding: 0 }}
                                color={action.color}
                                title={action.description}
                              >
                                {action.icon}
                              </Icon>
                            </Button>
                          );
                        case "toggle-input-switch":
                          if (true === action.context.isSaving) {
                            if (
                              action.context.initialToggleState.identifier ===
                              action.context.activeIdentifier
                            ) {
                              // render a spinner while identifier is saving/updating in the backend
                              return (
                                <FormControlLabel
                                  key={uuid.uuid()}
                                  control={
                                    <CircularProgress
                                      size={22}
                                      style={{
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                      }}
                                    />
                                  }
                                  label={action.spinnerLabel}
                                />
                              );
                            } else {
                              // render a toggle switch while identifier is not saving/udating in the backend
                              return (
                                <FormGroup row key={uuid.uuid()}>
                                  <FormControlLabel
                                    key={uuid.uuid()}
                                    control={
                                      <Switch
                                        key={uuid.uuid()}
                                        disabled
                                        size={action.size}
                                        color={action.color}
                                        checked={initialToggleState}
                                      />
                                    }
                                    label={action.label}
                                  />
                                </FormGroup>
                              );
                            }
                          }
                          return (
                            <FormGroup row key={uuid.uuid()}>
                              <FormControlLabel
                                key={uuid.uuid()}
                                control={
                                  <Switch
                                    key={uuid.uuid()}
                                    size={action.size}
                                    color={action.color}
                                    checked={initialToggleState}
                                    onChange={handleChange}
                                  />
                                }
                                label={action.label}
                              />
                            </FormGroup>
                          );
                        case "multi-link-button":
                          return (
                            <SplitButton
                              key={uuid.uuid()}
                              actionOptions={action.context.actionOptions}
                              label={action.context.label}
                              color={action.color}
                            />
                          );
                        case "callback-button":
                          return (
                            <Button
                              key={uuid.uuid()}
                              link={false}
                              round={true}
                              onClick={() => {action.context.callback()}}
                              size={action.size}
                              color={action.color}
                            >
                              <span
                                title={action.description}
                                className="material-icons"
                              >
                                {action.icon}
                              </span>
                            </Button>
                          );
                        default:
                          throw new Error("You have not assigned a case type");
                      }
                    });
                    return (
                      <TableCell
                        className={classes.tableCell}
                        key={uuid.uuid()}
                      >
                        {actionContent}
                      </TableCell>
                    );
                  }
                  if (
                    undefined === propKeysWhichHaveAnAction[key] &&
                    statusPropKey !== null &&
                    key === statusPropKey
                  ) {
                    return (
                      <TableCell
                        className={classes.tableCell}
                        key={uuid.uuid()}
                      >
                        <span className="material-icons" title={prop.display}>
                          {" "}
                          {mapStatusNameToIconName.map(prop.display)}{" "}
                        </span>
                        ./src/pages/JourneyFlows/MrfManagement/components/ActionableCardContainer/ActionableCardContainer.js
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell className={classes.tableCell} key={uuid.uuid()}>
                      {prop.display}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

ActionableDataTable.defaultProps = {
  tableHeaderColor: "gray",
};

ActionableDataTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
};

ActionableDataTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    // "green",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  tableHeaderAliases: PropTypes.shape(PropTypes.any),
    pagination: PropTypes.any,
    paginationConfig: PropTypes.any,
    thunkActionsConfig: PropTypes.any
};

export default ActionableDataTable;
