import {
  resetInitialState,
  userSignedOut,
} from "../../redux/reducer";

import { clearAuthAndAccountData } from '../../services/CurbCycleApi';
import experienceInitialisationThunk from "../../thunks/Initialisation/experienceInitialisationThunk";

export const logOutThunk = (email, navigation) => dispatch => {
  clearAuthAndAccountData()
    .then(() => {
      dispatch(resetInitialState());
      dispatch(userSignedOut());
      dispatch(experienceInitialisationThunk());
    });
};

export default logOutThunk;
