const SupportedComponents = [ // vfb-form-component-amex-gcp-corporate-card-selection
  require('./components/mb/vfb-mb-form-element-select-field'),
  require('./components/mb/vfb-mb-content-section-header'),
  require('./components/mb/vfb-mb-content-section-header-h1'),
  require('./components/mb/vfb-mb-content-optional-heading-and-paragraph'),
  require('./components/mb/vfb-mb-form-component-time-best-time-to-contact'),
  require('./components/mb/vfb-mb-form-component-time-how-long-in-years-and-months'),
  require('./components/mb/vfb-mb-form-element-generic-input-field'),
  require('./components/mb/vfb-mb-form-element-text-input-field'),
  require('./components/mb/vfb-mb-form-element-checkbox-single-with-label'),
  require('./components/mb/vfb-mb-form-element-checkbox-yes-no-unanswered-with-label'),
  require('./components/mb/vfb-mb-form-component-locations-typeahead-address-lookup'),
  require('./components/mb/vfb-mb-form-component-organisations-abn-lookup'),
  require('./components/mb/vfb-mb-form-element-checkbox-company-public-or-proprietary-with-label'),
  require('./components/mb/vfb-mb-form-component-people-name-controller'),
  require('./components/mb/vfb-mb-form-component-people-name-on-card'),
  require('./components/mb/vfb-mb-form-component-people-date-of-birth-controller'),
  require('./components/mb/vfb-mb-form-component-people-dynamic-person'),
  require('./components/mb/vfb-mb-form-component-people-dynamic-person-controller'),
  require('./components/mb/vfb-mb-form-component-people-dynamic-beneficial-owner-controller'),
  require('./components/mb/vfb-mb-form-component-people-dynamic-partner-controller'),
  require('./components/mb/vfb-mb-form-element-contact-number-controller'),
  require('./components/mb/vfb-mb-form-progress-button-stage-1'),
  require('./components/mb/vfb-mb-form-progress-button-stage-5'),
  require('./components/mb/vfb-mb-marketing-opt-in-text'),
  require('./components/mb/vfb-mb-we-may-call-you'),
  require('./components/mb/vfb-mb-privacy-statement'),
  require('./components/mb/vfb-mb-privacy-statement-sole-trader'),
  require('./components/mb/vfb-mb-business-purpose-declaration'),
  require('./components/mb/vfb-mb-card-image'),
  require('./components/mb/vfb-mb-declaration'),
  require('./components/mb/vfb-mb-enrol-for-membership-rewards'),
  require('./components/mb/vfb-mb-requirements-for-qualification'),
  require('./components/vfb-form-component-locations-address-lookup'),
  require('./components/vfb-form-component-organisations-abn-lookup'),
  require('./components/vfb-form-component-amex-gcp-new-or-existing-customer-selection'),
  require('./components/vfb-form-component-amex-gcp-corporate-card-selection'),
  require('./components/vfb-form-component-amex-gcp-purchasing-solutions-selection'),
  require('./components/vfb-form-component-amex-gcp-american-express-corporate-card-selection'),
  require('./components/vfb-form-component-amex-gcp-american-express-corporate-purchasing-card-selection'),
  require('./components/vfb-form-component-amex-gcp-american-express-corporate-meeting-card-selection'),
  require('./components/vfb-form-component-amex-gcp-american-express-qantas-cobrand-card-selection'),
  require('./components/vfb-form-element-checkbox-single-with-label'),
  require('./components/vfb-form-element-checkbox-yes-no-unanswered-with-label'),
  require('./components/vfb-form-element-input-text-field'),
  {
    component: 'vfb-form-component-people-dynamic-person-controller-with-slider',
    elementPrefix: '',
    name: "Dynamic person container with slider",
    mandatory_bindings: [
      {
        type: 'formDataValue',
        variable: 'people',
        source: 'EXAMPLE'
      },
      {
        type: 'literal',
        variable: 'element_name_prefix',
        value: 'EXAMPLE'
      },
      {
        type: 'literal',
        variable: 'element_friendly_name_singular',
        value: 'EXAMPLE'
      }
    ]
  },
  {
    component: 'vf-text',
    elementPrefix: '',
    name: "Text Field",
    mandatory_bindings: [
      {
        type: 'literal',
        variable: 'id',
        value: 'EXAMPLE'
      },
      {
        type: 'literal',
        variable: 'label',
        value: 'EXAMPLE'
      },
      {
        type: 'literal',
        variable: 'name',
        value: 'EXAMPLE'
      }
    ]
  },
  {
    component: 'vfb-text-with-link',
    elementPrefix: '',
    name: "Text With Link",
    mandatory_bindings: [
      {
        type: 'literal',
        variable: 'link_pattern',
        value: 'EXAMPLE'
      },
      {
        type: 'literal',
        variable: 'link_text',
        value: 'EXAMPLE'
      },
      {
        type: 'literal',
        variable: 'variable_0',
        value: 'EXAMPLE'
      },
      {
        type: 'literal',
        variable: 'variable_1',
        value: 'EXAMPLE'
      },
      {
        type: 'literal',
        variable: 'variable_2',
        value: 'EXAMPLE'
      }
    ]
  }
];

export default SupportedComponents;

