import {
  managementRequestedAddRetailSponsorManager,
  managementCompletedAddRetailSponsorManager,
} from "../../../../../redux/reducer";

import { addRetailSponsorManager } from '../../../../../services/CurbCycleApi';

export const addRetailSponsorManagerThunk = (payload, retailSponsorUuid, setIsSaving, setWasSuccessful) => dispatch => {
  dispatch(managementRequestedAddRetailSponsorManager());
  setIsSaving(true);
  addRetailSponsorManager(retailSponsorUuid, payload)
    .then(result => {
      dispatch(managementCompletedAddRetailSponsorManager(result));
      setIsSaving(false);
      setWasSuccessful(true);
    })
    .catch(() => {
      dispatch(managementCompletedAddRetailSponsorManager({}));
      setIsSaving(false);
    });
};

export default addRetailSponsorManagerThunk;
