import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import PropTypes from "prop-types";
import { useDispatch, connect } from "react-redux";
// thunks
import fetchAnalyticsThunk from "../../../../thunks/RetailSponsor/fetchAnalyticsThunk";
import fetchManagementAnalyticsThunk from "../../../../thunks/Management/Analytics/fetchAnalyticsThunk";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
// core components
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader.js";
import CardBody from "../../../../components/Card/CardBody.js";
import BaleBarGraph from "../../../../components/DashboardComponents/BaleBarGraph.js";
import CCScanBarGraph from "../../../../components/DashboardComponents/CCScanBarGraph.js";
import CCBarGraph from "../../../../components/DashboardComponents/CCBarGraph.js";
import PostCodeParticipation from "../../../../components/DashboardComponents/PostCodeParticipation.js";
import AccountScanNumbersBarGraph from "../../../../components/DashboardComponents/AccountScanNumbersBarGraph.js";
import SignupsByProgramBarGraph from "../../../../components/DashboardComponents/SignupsByProgramBarGraph.js";
import ManualEntryReasonsPieChart from "../../../../components/DashboardComponents/ManualEntryReasonsPieChart";
import NumberOfBagsReceivedPerDay from "./../../RetailSponsor/layouts/components/Charts/NumberOfBagsReceivedPerDay";
import MvpTrialParticipation from "./../../RetailSponsor/layouts/components/Charts/MvpTrialParticipation";
import TonnageTargetVsTime from "./../../RetailSponsor/layouts/components/Charts/TonnageTargetVsTime";
import BigNumberProgressBar from "./../../RetailSponsor/layouts/components/Charts/BigNumber/BigNumberProgressBar";
import PostMvpParticipationMap from "./../../../../components/DashboardComponents/Map/AwesomeMapComponent/PostMvpParticipationMap";
import InteractivityIndicator from "./../../../../components/DashboardComponents/InteractivityIndicator";
import AverageMonthlyBagCollectionByPostcode from "./../../RetailSponsor/layouts/components/Charts/AverageMonthlyBagCollectionByPostcode";
import NumberOfTrialSignUpsByPostcode from "./../../RetailSponsor/layouts/components/Charts/NumberOfTrialSignUpsByPostcode";
import BaleWeightScannedInPerDayWithBalePhotoModalController from "./../../RetailSponsor/layouts/components/Charts/BaleWeightScannedInPerDayWithBalePhotoModalController";
import ScansOutsideTheAppPerDayModalController from "./components/Charts/ScansOutsideTheAppPerDayModalController";
import MostRecentScanWidget from "./components/MostRecentScanWidget";
import Top5AccountsWidget from "./components/Top5AccountsWidget";
import ParticipationRatesChart from "./components/Charts/ParticipationRates/ParticipationRatesChart";
import WhereDidThePlasticGo from "./../../../../components/DashboardComponents/WhereDidThePlasticGo/WhereDidThePlasticGo";
import { getMyMrfProductStewardshipAnalytics, getStewardshipData } from "services/CurbCycleApi";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import NameIcon from "@material-ui/icons/Person";
import {MenuItem, Dropdown, DropdownButton} from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import UserStatsTable from "components/DashboardComponents/UserStatsTable";
import MrfStatsTable from "components/DashboardComponents/MrfStatsTable";
import PostCodeStatsTable from "components/DashboardComponents/PostCodeStatsTable";
import StewardStatsTable from "components/DashboardComponents/StewardStatsTable";
import GenericBarGraph from "components/DashboardComponents/GenericBarGraph";
import GenericScanBarGraph from "components/DashboardComponents/GenericScanBarGraph";
import GenericBaleBarGraph from "components/DashboardComponents/GenericBaleBarGraph.js";

function Overview({analyticsData, monthlyAnalyticsData, summaryAnalyticsData}) {



  
  if (!analyticsData || !monthlyAnalyticsData || !summaryAnalyticsData) {
    return (
      <div>
        <h1>Speaking with the Bilbies...</h1>
      </div>
    );
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{
                textAlign: "center",
                color: "#4d4d4d",
                fontWeight: "400",
              }}>
            
            <h1>Soft Plastics</h1>
            </GridItem>
      <GridItem xs={11}
                  sm={11}
                  md={11}
                  style={{
                    marginLeft: "5rem",
                  }}>
                    
                  <GridContainer
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
      {/* <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Dessert (100g serving)</TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> */}
    <GridItem xs={12} sm={12} md={12}>
      <h4>Program Statistics</h4>
        <Card>
        <StewardStatsTable data={summaryAnalyticsData}/>
        
        </Card>
      </GridItem>
      
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ width: "100%" }}>
            <CardHeader color="success" stats icon>
              <h4
                style={{
                  color: "#63513e",
                  textAlign: "left",
                  paddingTop: "0.4em",
                  fontWeight: 400,
                }}
              >
                Soft Plastics Signups
              </h4>
                        </CardHeader>
              <CardBody style={{ width: "92%" }}>
                <SignupsByProgramBarGraph data={monthlyAnalyticsData.slice(monthlyAnalyticsData.length - 10, monthlyAnalyticsData.length)}/>
              </CardBody>
              </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ width: "100%" }}>
            <CardHeader color="success" stats icon>
              <h4
                style={{
                  color: "#63513e",
                  textAlign: "left",
                  paddingTop: "0.4em",
                  fontWeight: 400,
                }}
              >
                Total Soft Plastics Weight
              </h4>
            </CardHeader>
            <CardBody style={{ width: "92%" }}>
              <GenericBaleBarGraph 
                data={monthlyAnalyticsData}
                series1_title="Monthly Weight (kg) Collected"
                series2_title="Cumulative Weight (kg) Collected"
                series1="total_weight"
                series2="cumulative_weight"/>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ width: "100%" }}>
            <CardHeader color="success" stats icon>
              <h4
                style={{
                  color: "#63513e",
                  textAlign: "left",
                  paddingTop: "0.4em",
                  fontWeight: 400,
                }}
              >
                Monthly Curby bags received by Scan type
              </h4>
            </CardHeader>
            <CardBody style={{ width: "92%" }}>
            
              <GenericScanBarGraph data={monthlyAnalyticsData.slice(monthlyAnalyticsData.length - 14, monthlyAnalyticsData.length)} series1="full_scans" series2="mrf_only_scans" seriesTotal="curby_codes_received"/>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ width: "100%" }}>
            <CardHeader color="success" stats icon>
              <h4
                style={{
                  color: "#63513e",
                  textAlign: "left",
                  paddingTop: "0.4em",
                  fontWeight: 400,
                }}
              >
                Monthly Curby Bag Scan Data
              </h4>
            </CardHeader>
            <CardBody style={{ width: "92%" }}>
              <GenericBarGraph data={monthlyAnalyticsData.slice(monthlyAnalyticsData.length - 14, monthlyAnalyticsData.length)} 
              series1={["Total Received","curby_codes_received"] }
              series2={["Bags Scanned By Users","full_scans"]}/>
            </CardBody>
          </Card>
        </GridItem>
    {/* <h3>The Last 60 days at a glance</h3>
    
    <GridContainer
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
    <GridItem xs={12} sm={12} md={6}>
      <h4>User Statistics</h4>
      <Card>
      <UserStatsTable data={analyticsData.time_60d}/>

      </Card>
    </GridItem>

    <GridItem xs={12} sm={12} md={6}>
    <h4>Mrf Statistics</h4>
      <Card>
      <MrfStatsTable data={analyticsData.time_60d}/>
      </Card>
    </GridItem>
    
    </GridContainer>

    
            </GridContainer>
            </GridItem>
      </GridContainer>

    <GridContainer>
            <GridItem xs={11}
                  sm={11}
                  md={11}
                  style={{
                    marginLeft: "5rem",
                  }}>
                    <h3>Data over the Last 12 months</h3>
                    
                  <GridContainer
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                <GridItem xs={9} sm={9} md={3}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <h4
                      style={{
                        color: "#63513e",
                        textAlign: "left",
                        paddingTop: "0.4em",
                        fontWeight: 400,
                      }}
                    >
                      Users who scan with the Curby App
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar
                      bigNumberText={analyticsData.time_12m.summary_user_data.active_users}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={9} sm={9} md={3}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <h4
                      style={{
                        color: "#63513e",
                        textAlign: "left",
                        paddingTop: "0.4em",
                        fontWeight: 400,
                      }}
                    >
                      New Program Opt ins
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar
                      bigNumberText={analyticsData.time_12m.summary_user_data.new_opt_ins}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={9} sm={9} md={3}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <h4
                      style={{
                        color: "#63513e",
                        textAlign: "left",
                        paddingTop: "0.4em",
                        fontWeight: 400,
                      }}
                    >
                      Average Transport Time
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar
                      bigNumberText={`${(
                        analyticsData.time_12m.summary_user_data.trip_time
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 3,
                      })} days`}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={9} sm={9} md={3}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <h4
                      style={{
                        color: "#63513e",
                        textAlign: "left",
                        paddingTop: "0.4em",
                        fontWeight: 400,
                      }}
                    >
                      Curby Codes Scanned
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar
                      bigNumberText={analyticsData.time_12m.summary_cc_data.Total_bag_number}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              </GridContainer>
            </GridItem>
            </GridContainer>


            

            <GridContainer>
              <GridItem
                xs={11}
                sm={11}
                md={11}
                style={{
                  marginLeft: "5rem",
                }}
              >
                <GridContainer
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <GridItem xs={12} sm={12} md={12}>
                    <Card style={{ width: "100%" }}>
                      <CardHeader color="success" stats icon>
                        <h4
                          style={{
                            color: "#63513e",
                            textAlign: "left",
                            paddingTop: "0.4em",
                            fontWeight: 400,
                          }}
                        >
                          User Signups by Program
                        </h4>
                      </CardHeader>
            <CardBody style={{ width: "92%" }}>
              <SignupsByProgramBarGraph data={analyticsData.time_12m.signups_by_program}/>
            </CardBody>
            </Card>
            </GridItem>
            </GridContainer>
            </GridItem>
            </GridContainer> */}


            {/* <GridContainer> */}
              {/* DATA FOR CURBY CODES */}
              {/* <GridItem xs={11}
                  sm={11}
                  md={11}
                  style={{
                    marginLeft: "5rem",
                  }}>
                    
                  <GridContainer
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                <GridItem xs={9} sm={9} md={3}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <h4
                      style={{
                        color: "#63513e",
                        textAlign: "left",
                        paddingTop: "0.4em",
                        fontWeight: 400,
                      }}
                    >
                      CurbyCodes Scanned
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar
                      bigNumberText={analyticsData.time_12m.summary_cc_data.Total_bag_number}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={9} sm={9} md={3}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <h4
                      style={{
                        color: "#63513e",
                        textAlign: "left",
                        paddingTop: "0.4em",
                        fontWeight: 400,
                      }}
                    >
                      CurbyCodes Scanned at the MRF
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar
                      bigNumberText={`${((analyticsData.time_12m.summary_cc_data.Bags_received_by_mrf / analyticsData.time_12m.summary_cc_data.Total_bag_number) * 100).toLocaleString(undefined, {
                        maximumFractionDigits: 3,
                      })}%`}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={9} sm={9} md={3}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <h4
                      style={{
                        color: "#63513e",
                        textAlign: "left",
                        paddingTop: "0.4em",
                        fontWeight: 400,
                      }}
                    >
                      CurbyCodes Scanned by users
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar
                      bigNumberText={`${((analyticsData.time_12m.summary_cc_data.Total_user_scans / analyticsData.time_12m.summary_cc_data.Total_bag_number) * 100).toLocaleString(undefined, {
                        maximumFractionDigits: 3,
                      })}%`}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={9} sm={9} md={3}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <h4
                      style={{
                        color: "#63513e",
                        textAlign: "left",
                        paddingTop: "0.4em",
                        fontWeight: 400,
                      }}
                    >
                      CurbyCodes Scanned by MRF only
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <BigNumberProgressBar
                      bigNumberText={`${((analyticsData.time_12m.summary_cc_data.Bags_mrf_no_user_scan / analyticsData.time_12m.summary_cc_data.Total_bag_number) * 100).toLocaleString(undefined, {
                        maximumFractionDigits: 3,
                      })}%`}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              </GridContainer>
            </GridItem>
            </GridContainer> */}
            {/* <GridContainer>
              <GridItem
                xs={11}
                sm={11}
                md={11}
                style={{
                  marginLeft: "5rem",
                }}
              >
                <GridContainer
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <GridItem xs={12} sm={12} md={12}>
                    <Card style={{ width: "100%" }}>
                      <CardHeader color="success" stats icon>
                        <h4
                          style={{
                            color: "#63513e",
                            textAlign: "left",
                            paddingTop: "0.4em",
                            fontWeight: 400,
                          }}
                        >
                          Monthly Curby Code Scan Data
                        </h4>
                      </CardHeader>
            <CardBody style={{ width: "92%" }}>
              <CCBarGraph data={analyticsData.time_12m.time_series_cc_data}/>
            </CardBody>
            </Card>
            </GridItem>
            </GridContainer>
            </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem
                xs={11}
                sm={11}
                md={11}
                style={{
                  marginLeft: "5rem",
                }}
              >
                <GridContainer
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <GridItem xs={12} sm={12} md={12}>
                    <Card style={{ width: "100%" }}>
                      <CardHeader color="success" stats icon>
                        <h4
                          style={{
                            color: "#63513e",
                            textAlign: "left",
                            paddingTop: "0.4em",
                            fontWeight: 400,
                          }}
                        >
                          Scans by postcode
                        </h4>
                      </CardHeader>
            <CardBody style={{ width: "92%" }}>
            <PostCodeParticipation data={analyticsData.time_12m.post_code_scans}/>
            <PostCodeStatsTable data={analyticsData.time_12m.post_code_scans}/>
            </CardBody>
            </Card>
            
            </GridItem>
            </GridContainer>
            </GridItem>
            </GridContainer>
            


            <GridContainer>
              <GridItem
                xs={11}
                sm={11}
                md={11}
                style={{
                  marginLeft: "5rem",
                }}
              >
                <GridContainer
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <GridItem xs={12} sm={12} md={12}>
                    <Card style={{ width: "100%" }}>
                      <CardHeader color="success" stats icon>
                        <h4
                          style={{
                            color: "#63513e",
                            textAlign: "left",
                            paddingTop: "0.4em",
                            fontWeight: 400,
                          }}
                        >
                          Monthly Curby Codes by Scan Type
                        </h4>
                      </CardHeader>
            <CardBody style={{ width: "92%" }}>
              <CCScanBarGraph data={analyticsData.time_12m.time_series_scan_data}/>
            </CardBody>
            </Card>
            </GridItem>
            </GridContainer>
            </GridItem>
            </GridContainer>
            
            <GridContainer>
              <GridItem
                xs={11}
                sm={11}
                md={11}
                style={{
                  marginLeft: "5rem",
                }}
              >
                <GridContainer
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <GridItem xs={12} sm={12} md={12}>
                    <Card style={{ width: "100%" }}>
                      <CardHeader color="success" stats icon>
                        <h4
                          style={{
                            color: "#63513e",
                            textAlign: "left",
                            paddingTop: "0.4em",
                            fontWeight: 400,
                          }}
                        >
                          Account scans
                        </h4>
                      </CardHeader>
            <CardBody style={{ width: "92%" }}>
              <AccountScanNumbersBarGraph data={analyticsData.time_12m.scans_by_accounts}/>
            </CardBody>
            </Card>
            </GridItem>
            </GridContainer>
            </GridItem>
            </GridContainer> */}
                
              {/* DATA FOR BALES */}
              {/* <GridContainer>
              <GridItem xs={11}
                  sm={11}
                  md={11}
                  style={{
                    marginLeft: "5rem",
                  }}>
                    
                    <GridContainer
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                    
                    <GridItem xs={12} sm={12} md={4}>
                      <Card>
                        <CardHeader color="success" stats icon>
                          <h4
                            style={{
                              color: "#63513e",
                              textAlign: "left",
                              paddingTop: "0.4em",
                              fontWeight: 400,
                            }}
                          >
                            Bales Processed
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <BigNumberProgressBar
                            bigNumberText={analyticsData.time_12m.summary_bale_data.total_bales}
                          />
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <Card>
                        <CardHeader color="success" stats icon>
                          <h4
                            style={{
                              color: "#63513e",
                              textAlign: "left",
                              paddingTop: "0.4em",
                              fontWeight: 400,
                            }}
                          >
                            Total Weight of Bales
                          </h4>
                        </CardHeader>
                        <CardBody>
                        <BigNumberProgressBar
                            bigNumberText={`${(analyticsData.time_12m.summary_bale_data.total_weight /1000
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 3,
                            })}kg`}
                          />
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <Card>
                        <CardHeader color="success" stats icon>
                          <h4
                            style={{
                              color: "#63513e",
                              textAlign: "left",
                              paddingTop: "0.4em",
                              fontWeight: 400,
                            }}
                          >
                            Average Weight of Bales
                          </h4>
                        </CardHeader>
                        <CardBody>
                        <BigNumberProgressBar
                            bigNumberText={`${(analyticsData.time_12m.summary_bale_data.avg_weight /1000
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 3,
                              })}kg`}
                          />
                        </CardBody>
                      </Card>
                    </GridItem>
                    </GridContainer>
                  </GridItem>
                  
                  
            </GridContainer>
            <GridContainer>
              <GridItem
                xs={11}
                sm={11}
                md={11}
                style={{
                  marginLeft: "5rem",
                }}
              >
                <GridContainer
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <GridItem xs={12} sm={12} md={12}>
                    <Card style={{ width: "100%" }}>
                      <CardHeader color="success" stats icon>
                        <h4
                          style={{
                            color: "#63513e",
                            textAlign: "left",
                            paddingTop: "0.4em",
                            fontWeight: 400,
                          }}
                        >
                          Monthly Bale Data
                        </h4>
                      </CardHeader>
            <CardBody style={{ width: "92%" }}>
              <BaleBarGraph data={analyticsData.time_12m.time_series_bale_data}/>
            </CardBody>
            </Card>
            </GridItem>
            </GridContainer>
            </GridItem>
            </GridContainer> */}
{/* 
            <GridContainer>
              <GridItem
                xs={11}
                sm={11}
                md={11}
                style={{
                  marginLeft: "5rem",
                }}
              >
                <GridContainer
                  style={{
                    width: "45%",
                    height: "100%",
                  }}
                >
                  <GridItem xs={12} sm={12} md={12}>
                    <Card style={{ width: "100%" }}>
                      <CardHeader color="success" stats icon>
                        <h4
                          style={{
                            color: "#63513e",
                            textAlign: "left",
                            paddingTop: "0.4em",
                            fontWeight: 400,
                          }}
                        >
                          Manual Entry Reasons
                        </h4>
                      </CardHeader>
            <CardBody style={{ width: "92%" }}>
              <ManualEntryReasonsPieChart data={analyticsData.time_12m}/>
            </CardBody>
            </Card>
            </GridItem>*/}
            </GridContainer> 
            
            
    </GridItem>
    </GridContainer>
    </div>
  );
}

export default Overview;
