import "date-fns";
import React, { useState } from "react";
import PropTypes from "prop-types";
// import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

export default function DateAndTimePicker({ date }) {
  const [selectedDate, handleDateChange] = useState(date);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker value={selectedDate} onChange={handleDateChange} />
    </MuiPickersUtilsProvider>
  );
}

DateAndTimePicker.propTypes = {
  date: PropTypes.string.isRequired,
};