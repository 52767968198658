module.exports = {
  component: 'vfb-mb-form-element-select-field',
  elementPrefix: '',
  name: "MB: Select field - can specify row options via comma separated values",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'placeholder_text',
      value: 'Some placeholder'
    },
    {
      type: 'literal',
      variable: 'raw_options',
      value: 'option1,option2'
    },
    {
      type: 'literal',
      variable: 'validation_spec',
      value: 'text'
    },
    {
      type: 'literal',
      variable: 'pre_text',
      value: 'text'
    },
    {
      type: 'literal',
      variable: 'post_text',
      value: 'text'
    },
    {
      type: 'literal',
      variable: 'tooltip_content',
      value: 'text'
    }
  ]
}
