import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

function AnotherBunchOfSettings() {
  const options = [
    { name: "Something Else", path: "admin-for-something-else" },
    { name: "Stuff", path: "admin-for-stuff" },
  ];

  return options.map((option) => (
    <Link key={option.name} to={option.path}>
      <Button onClick={() => {}}>{option.name}</Button>
    </Link>
  ));
}

export default AnotherBunchOfSettings;
