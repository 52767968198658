module.exports = {
  component: 'vfb-form-component-locations-address-lookup',
  elementPrefix: '',
  name: "Address lookup - typeahead component designed with specific behaviour related to selecting an address",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'element_name_prefix',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'registered_business_address'
    }
  ]
}
