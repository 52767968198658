module.exports = {
  component: 'vfb-mb-we-may-call-you',
  elementPrefix: '',
  name: "MB: American Express may call you (text related to preferred time for a callback)",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'american_express_may_call_you'
    }
  ]
}
