module.exports = {
  component: 'vfb-mb-form-component-people-date-of-birth-controller',
  elementPrefix: '',
  name: "MB: Person Date of Birth element controller - single field splits into constituent parts",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'element_name_prefix',
      value: 'element_name_prefix'
    },
    {
      type: 'literal',
      variable: 'input_field_label',
      value: 'input_field_label'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'business_contact_date_of_birth'
    }
  ]
}
