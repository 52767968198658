import React, { useState, createRef, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
// core components
import Footer from "../../../../components/Footer/Footer.js";
import Sidebar from "../../../../components/Sidebar/SidebarIm.js";
import Spinner from "../layouts/components/Spinner/Spinner";
import routes from "../routes.js";
import styles from "../../../../assets/jss/material-dashboard-react/layouts/adminStyleNewIm.js";
import bgImage from "assets/img/sidebar-2.jpg";
import green from "@material-ui/core/colors/green";
// core components/views for Admin layout
import MrfOperationsPeoplePage from "../pages/People/People";
import MrfOperationsInventoryManagementPage from "../pages/InventoryManagement/InventorySummaryWithDataTableDetail";
import MrfOperationsPeopleAddOperatorPage from "../pages/People/AddMrfOperator";
import MrfOperationsSecurityPage from "../pages/Security/Security";
// import MrfOperationsOperationsPage from "../pages/MrfOperations/MrfOperations";
import MrfSettingsPage from "../pages/MrfSettings/MrfSettings";
import MrfOperationsDashboardPage from "../pages/MrfDashboard/MrfDashboard";
import MrfOperationsLogisticsPage from "../pages/MrfLogistics/MrfLogistics";
import {
  getMrfSettings,
  getAllRelevantAccountDataForCurbyApp,
} from "../../../../services/CurbCycleApi";

let ps;

const useStyles = makeStyles(styles);

export default function MainLayout({ ...rest }) {
  const classes = useStyles();
  const [mrfUuid, setMrfUuid] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [getSettingsAttemptFailed, setGetSettingsAttemptFailed] = useState(
    false
  );
  // const defaultLogo = `aHR0cHM6Ly9jZG4uY3VyYnkuY2MvbG9nby1pcXJlbmV3LnN2Zw==`;
  const defaultLogo = `https://cdn.curby.cc/logo-iqrenew.svg`;
  const [globalLogo, setGlobalLogo] = useState(defaultLogo);
  const [mrfSettingsData, setMrfSettingsData] = useState(undefined);

  const [customTheme, setCustomTheme] = useState({
    palette: {
      primary: {
        main: "#9c27b0",
      },
      secondary: {
        main: green[500],
      },
    },
  });

  const updateMrfSettings = (settings) => {
    setMrfSettingsData(settings);
  };

  useEffect(() => {
    const getAndSetMrfData = async (mrfUuid) => {
      await getMrfSettingsData(mrfUuid);
      setIsLoading(false);
    };

    const getAndSetMrfUuid = async () => {
      await getMrfUuid();
      setIsLoading(false);
    };
    getAndSetMrfUuid();

    if (mrfUuid) {
      getAndSetMrfData(mrfUuid);
    }
  }, [mrfUuid]);

  const getMrfUuid = async () => {
    try {
      const response = await getAllRelevantAccountDataForCurbyApp();
      setMrfUuid(response.additional_relevant_data?.mrf_manager?.mrf_uuid);
    } catch (err) {
      // handle error
    }
  };

  const updateDefaultTheme = (primaryColor, secondaryColor) => {
    setTheme({
      ...customTheme,
      palette: {
        primary: { main: primaryColor || customTheme.palette.primary.main },
        secondary: {
          main: secondaryColor || customTheme.palette.secondary.main,
        },
      },
    });
  };

  const getMrfSettingsData = async (mrfUuid) => {
    try {
      setIsLoading(true);
      const response = await getMrfSettings(mrfUuid);

      if (response.code === "ERROR_OCCURRED") {
        return setGetSettingsAttemptFailed(true);
      }
      setMrfSettingsData({ ...mrfSettingsData, ...response.data });

      const primaryColor = response.data?.theme?.primaryColor;
      const secondaryColor = response.data?.theme?.secondaryColor;

      if (primaryColor || secondaryColor) {
        updateDefaultTheme(primaryColor, secondaryColor);
      }
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
      return setGetSettingsAttemptFailed(true);
    }
  };

  const setTheme = (theme) => {
    setCustomTheme(theme);
  };

  const setLogo = (logo) => {
    setGlobalLogo(logo);
  };

  const switchRoutes = (
    <Switch>
      <Route path="/management/dashboard" show={false}>
        <MrfOperationsDashboardPage />
      </Route>
      <Route path="/management/inventory-management" show={true}>
        <MrfOperationsInventoryManagementPage
          materialTypes={mrfSettingsData?.materialTypes}
          loading={isLoading}
        />
      </Route>
      <Route path="/management/logistics" show={true}>
        <MrfOperationsLogisticsPage
          materialTypes={mrfSettingsData?.materialTypes}
          mrfUuid={mrfUuid}
        />
      </Route>
      {/* <Route path="/management/mrf-operations" show={true}>
        <MrfOperationsOperationsPage />
      </Route> */}
      <Route path="/management/people" show={true}>
        <MrfOperationsPeoplePage />
      </Route>
      <Route path="/management/manage-people/add-mrf-operator" show={false}>
        <MrfOperationsPeopleAddOperatorPage />
      </Route>
      <Route path="/management/security" show={true}>
        <MrfOperationsSecurityPage />
      </Route>
      <Route path="/management/mrf-settings" show={true}>
        <MrfSettingsPage
          theme={customTheme}
          setTheme={setTheme}
          setLogo={setLogo}
          globalLogo={globalLogo}
          mrfSettingsData={mrfSettingsData}
          mrfUuid={mrfUuid}
          updateMrfSettings={updateMrfSettings}
          getSettingsAttemptFailed={getSettingsAttemptFailed}
        />
      </Route>
      <Redirect from="/" to="/management/dashboard" />
    </Switch>
  );

  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = createRef();
  const [mobileOpen, setMobileOpen] = useState(false);
  const image = bgImage;
  const color = "";

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const isMapsPage = () => {
    return window.location.pathname === "/admin/maps";
  };
  const resizeFunction = () => {
    // if (window.innerWidth >= 960) {
    //   setMobileOpen(false);
    // }
  };
  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(mainPanel.current, {
    //     suppressScrollX: true,
    //     suppressScrollY: false,
    //   });
    //   document.body.style.overflow = "hidden";
    // }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    // return function cleanup() {
    //   if (navigator.platform.indexOf("Win") > -1) {
    //     ps.destroy();
    //   }
    //   window.removeEventListener("resize", resizeFunction);
    // };
  }, [mainPanel]);
  return (
    <>
      {isLoading ? (
        <Spinner centerWindow={true} />
      ) : (
        <ThemeProvider theme={createTheme(customTheme)}>
          <div className={classes.wrapper}>
            <Sidebar
              routes={routes}
              logo={globalLogo}
              logoText={"iQRenew"}
              image={image}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              color={color}
              {...rest}
            />
            <div className={classes.mainPanel} ref={mainPanel}>
              {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {isMapsPage ? (
                <div className={classes.map}>{switchRoutes}</div>
              ) : (
                <div className={classes.content}>
                  <div className={classes.container}>{switchRoutes}</div>
                </div>
              )}
              {!isMapsPage ? <Footer /> : null}
            </div>
          </div>
        </ThemeProvider>
      )}
    </>
  );
}
