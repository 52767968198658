import {
  managementRequestedMrfOperators,
  managementReceivedMrfOperators,
} from "../../../../redux/reducer";

import paginationHelper from '../../../../utilities/paginationHelper';

import { getAllMrfOperators } from '../../../../services/CurbCycleApi';

export const pageChangeMrfOperatorsWithFiltersThunk = (mrfUuid, filtersAsQueryString) => dispatch => {
  dispatch(managementRequestedMrfOperators());
  getAllMrfOperators(mrfUuid, filtersAsQueryString)
    .then(result => {
      dispatch(managementReceivedMrfOperators(result));
    })
    .catch(() => {
      dispatch(
        managementReceivedMrfOperators(
          paginationHelper.getDefaultCollectionShape()
        )
      );
    });
};

export default pageChangeMrfOperatorsWithFiltersThunk;
