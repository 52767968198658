import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Search from "@material-ui/icons/Search";
// core components
import Button from "components/CustomButtons/Button";
import { clear } from "idb-keyval";

const useStyles = makeStyles((theme) => ({
  search_bar: {
    "& label.Mui-focused": {
      color: "#2979ff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2979ff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "2979ff",
      },
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
  },
}));

export default function CustomSearchBar(props) {
  const { label, id, name, onClick, onChange, onSubmit, style } = props;

  const classes = useStyles({});

  return (
    <div style={style}>
      <form
        id={id}
        name={name}
        onSubmit={onSubmit}
        noValidate
        autoComplete="off"
      >
        <TextField
          id={id}
          name={name}
          type="text"
          className={classes.search_bar}
          label={label}
          onChange={onChange}
        />

        <Button
          type="button"
          onClick={onClick}
          color="white"
          aria-label="edit"
          justIcon
          round
        >
          <Search
            type="button"
            onClick={onClick}
            color="inherit"
            aria-label="edit"
          />
        </Button>
      </form>
    </div>
  );
}

// CustomSearch.propTypes = {
//   headerColor: PropTypes.oneOf([
//     "warning",
//     "success",
//     "danger",
//     "info",
//     "primary",
//     "rose",
//   ]),
//   title: PropTypes.string,
//   tabs: PropTypes.arrayOf(
//     PropTypes.shape({
//       tabName: PropTypes.string.isRequired,
//       tabIcon: PropTypes.object,
//       tabContent: PropTypes.node.isRequired,
//     })
//   ),
//   rtlActive: PropTypes.bool,
//   plainTabs: PropTypes.bool,
// };

CustomSearchBar.propTypes = {
  defaultValue: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  style: PropTypes.string,
  label: PropTypes.string,
};
