import * as React from "react";
import PropTypes from "prop-types";
import Tree from "react-d3-tree";
import { useCenteredTree } from "./useCenteredTree";
// import ContextMenu from 'react-context-menu';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
// import { useDrag, useDrop } from 'react-dnd';
// import {ItemTypes} from './../../constants/items';
import CustomTreeItem from "./../TreeView/CustomTreeItem";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const containerStyles = {
  width: "100vw",
  height: "100vh",
};

const SimpleDialog = ({
  onClose,
  selectedValue,
  nodeDatum,
  open,
  selectedNodeDatum,
}) => {
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  console.log({
    selectedValue: selectedNodeDatum,
  });

  return (
    <Dialog
      style={{ background: "transparent" }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        {selectedNodeDatum.children
          ? "Dialog for Branch Node"
          : "Dialog for Leaf Node"}
      </DialogTitle>
      <List>
        <ListItem
          autoFocus
          button
          onClick={() => handleListItemClick("addAccount")}
        >
          <pre>{JSON.stringify(selectedNodeDatum, null, 2)}</pre>
          <ListItemText primary="Add node" />
        </ListItem>
      </List>
    </Dialog>
  );
};

const renderNodeWithCustomEvents = ({
  nodeDatum,
  toggleNode,
  handleNodeClick,
  handleNodeRightClick,
  open,
  toggleOpenDialogCallback,
  selectedNodeDatum,
}) => (
  <g id={nodeDatum.name}>
    <CustomTreeItem
      moveNodeCallback={() => {
        alert("test moveNodeCallback!");
      }}
      treeItemType="TREE_ITEM"
      onClickCallback={() => {
        toggleNode();
      }}
      onContextMenuCallback={() => {
        toggleOpenDialogCallback(nodeDatum);
      }}
      pathIdentifier={nodeDatum.nodeNo}
      uuid={nodeDatum.uuid}
      label={nodeDatum.name}
    />

    <SimpleDialog
      selectedValue={nodeDatum}
      selectedNodeDatum={selectedNodeDatum}
      nodeDatum={nodeDatum}
      open={open}
      onClose={toggleOpenDialogCallback}
    />

    <text fill="black" strokeWidth="1" x="20" onClick={toggleNode}>
      {nodeDatum.name}
    </text>
    {nodeDatum.attributes?.name && (
      <text fill="black" x="20" dy="20" strokeWidth="1">
        Department: {nodeDatum.attributes?.name}
      </text>
    )}
  </g>
);

const Xp4 = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedNodeDatum, setSelectedNodeDatum] = React.useState(false);

  const toggleOpenDialogCallback = (nodeDatum) => {
    console.log(open);
    setOpen(!open);
    setSelectedNodeDatum(nodeDatum);
  };

  const renderContextMenuForBranchNode = () => {
    return (
      <div>
        <p>"Context menu for a branch node"</p>
      </div>
    );
  };

  const renderContextMenuForLeafNode = () => {
    return (
      <div>
        <p>"Context menu for a leaf node"</p>
      </div>
    );
  };

  const handleNodeClick = (nodeDatum) => {
    window.alert(
      nodeDatum.children ? "Clicked a branch node" : "Clicked a leaf node."
    );
  };

  const handleNodeRightClick = (nodeDatum) => {
    nodeDatum.children
      ? renderContextMenuForBranchNode()
      : renderContextMenuForLeafNode();
  };

  const [translate, containerRef] = useCenteredTree();

  return (
    <div style={containerStyles} ref={containerRef}>
      <DndProvider backend={HTML5Backend}>
        <Tree
          data={data}
          translate={translate}
          renderCustomNodeElement={(rd3tProps) =>
            renderNodeWithCustomEvents({
              ...rd3tProps,
              handleNodeClick,
              handleNodeRightClick,
              open,
              toggleOpenDialogCallback,
              selectedNodeDatum,
            })
          }
          orientation="vertical"
        />
      </DndProvider>
    </div>
  );
};

SimpleDialog.propTypes = {
  selectedValue: PropTypes.any,
  onClose: PropTypes.func,
  nodeDatum: PropTypes.any,
  open: PropTypes.bool,
  selectedNodeDatum: PropTypes.shape({
    children: PropTypes.node
  }),
  data: PropTypes.any,
};

Xp4.propTypes = {
  data: PropTypes.any,
};

export default Xp4;
