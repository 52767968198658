import React, { useEffect, useState } from "react";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
// core components
import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import ActionableDataTable from "../../../../components/ActionableDataTable/ActionableDataTable";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../components/Card/CardIcon.js";
import CardBody from "../../../../components/Card/CardBody.js";
import CustomSearchBar from "../../../../components/CustomSearchBar/CustomSearchBar";
import Spinner from "../../../../components/Spinner/Spinner";
import paginationHelper from "../../../../utilities/paginationHelper";
import useDebounce from "../../../../hooks/useDebounce";
import { getAllAccounts } from "services/CurbCycleApi";

export default function Accounts() {
  const accountType = "&account_type=INDIVIDUAL";
  const tableData = { data: [] };
  const [accountTableRawData, setAccountTableRawData] = useState({
    data: [],
  });
  const [results, setResults] = useState({ data: [] });
  const [isSearching, setIsSearching] = useState(false);
  const [accountTableFilter, setAccountTableFilter] = useState(null);
  const debouncedAccountTable = useDebounce(accountTableFilter, 500);

  useEffect(() => {
    let isCancelled = false;
    setIsSearching(true);
    getAllAccounts(debouncedAccountTable).then((response) => {
      if (!isCancelled) {
        setAccountTableRawData(response);
        setIsSearching(false);
      }
      let newTableData = {
        data: [],
      };
      newTableData.data = response.data.map((item) => {
        return [
          {
            display: item.internal_reference || "-",
          },
          {
            display: item.name,
          },
          {
            display: item.email,
          },
          {
            display:
              undefined === item.addresses[0]
                ? "N/A"
                : item.addresses[0].lga_key,
          },
          {
            display:
              undefined === item.addresses[0]
                ? "N/A"
                : item.addresses[0].formatted_address,
          },
          {
            display:
              undefined === item.account_points ? "N/A" : item.account_points,
          },
          {
            hasActions: true,
            actions: [
              {
                type: "multi-link-button",
                disabled: true,
                color: "info",
                description: "Material Journeys",
                size: "sm",
                context: {
                  label: "Actions",
                  uuid: item.uuid,
                  actionOptions: {
                    options: [
                      {
                        option_name: "Account Summary",
                        option_link: `/management/account-summary/${item.uuid}`,
                        option_state: {
                          account: item,
                        },
                      },
                      {
                        option_name: "CRM Integration Settings",
                        option_link: `/management/account-crm-integration-settings/${item.uuid}`,
                        option_state: {
                          account: item,
                        },
                      },
                      {
                        option_name: "Toggle Material Type Journeys",
                        option_link: `/management/material-type/${item.uuid}`,
                        option_state: {
                          account: item,
                        },
                      },
                    ],
                  },
                },
              },
            ],
          },
        ];
      });
      setResults(newTableData);
      return () => {
        isCancelled = true;
      };
    });
  }, [debouncedAccountTable]);

  const handleUuidInput = (event) => {
    event.preventDefault(event);
    console.log(event.target.value);

    if (event.target.value === undefined) {
      return;
    }
    if (event.target.value === "") {
      setAccountTableFilter(null);
    } else {
      setAccountTableFilter(`uuid=${event.target.value}${accountType}`);
      setIsSearching(true);
    }
  };

  const handleEmailInput = (event) => {
    event.preventDefault(event);
    console.log(event.target.value);

    if (event.target.value === undefined) {
      return;
    }
    if (event.target.value === "") {
      setAccountTableFilter(null);
    } else {
      setAccountTableFilter(`email=${event.target.value}${accountType}`);
      setIsSearching(true);
    }
  };

  return (
    <>
      <Fade in={true} timeout={1000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">Manage Accounts</CardIcon>
              </CardHeader>
              <br />
              <CardBody>
                <GridContainer
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="flex-end"
                >
                  <GridItem>
                    <CustomSearchBar
                      id="search-email"
                      name="email"
                      label="Email"
                      defaultValue={debouncedAccountTable}
                      onChange={(event) => {
                        if (event.target.value === "") {
                          setAccountTableFilter(null);
                        } else {
                          setAccountTableFilter(`email=${event.target.value}`);
                          setIsSearching(true);
                        }
                      }}
                      onClick={handleEmailInput}
                      onSubmit={handleEmailInput}
                    />
                  </GridItem>
                  <GridItem>
                    <CustomSearchBar
                      id="search-uuid"
                      name="email"
                      label="Int Ref or UUID"
                      defaultValue={debouncedAccountTable}
                      onChange={(event) => {
                        console.log(event.target.value);
                        if (event.target.value === "") {
                          setAccountTableFilter(null);
                        } else {
                          setAccountTableFilter(`uuid=${event.target.value}`);
                          setIsSearching(true);
                        }
                      }}
                      onClick={handleUuidInput}
                      onSubmit={handleUuidInput}
                    />
                  </GridItem>
                </GridContainer>
                {isSearching ? (
                  <div>
                    <ActionableDataTable
                      disabled
                      tableHeaderColor="warning"
                      tableHead={[
                        "Internal Reference",
                        "Name",
                        "Email",
                        "LGA Key",
                        "Address",
                        "Actions",
                      ]}
                      tableData={tableData.data}
                    />
                    <Spinner centerWindow={true} />
                  </div>
                ) : (
                  <ActionableDataTable
                    tableHeaderColor="warning"
                    tableHead={[
                      "Internal Reference",
                      "Name",
                      "Email",
                      "LGA Key",
                      "Address",
                      "Points",
                      "Actions",
                    ]}
                    tableData={results.data}
                    pagination={true}
                    paginationConfig={{
                      collectionData: accountTableRawData,
                      handlePageChange: (payload) => {
                        console.log(debouncedAccountTable);
                        setAccountTableFilter(
                          paginationHelper.generateFilterQueryStringFromFilterOptions(
                            [
                              {
                                filterKey: "page",
                                filterValue: payload.selected,
                                type: "simpleKeyValue",
                              },
                            ]
                          )
                        );
                      },
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fade>
    </>
  );
}
