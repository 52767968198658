import React, { useState, createRef, useEffect, componentDidMount } from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
// core components
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';


function Dashboard({dashUrl}) {

  React.useEffect(() =>  {
    if (dashUrl){
    embedDashboard({
      url: dashUrl,
      container: document.getElementById("embeddingContainer"),
      scrolling: "no",
      height: "900vh",
      iframeResizeOnSheetChange: false, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
      width: "90%",
      locale: "en-US",
      footerPaddingEnabled: true,
      sheetTabsDisabled: false, // use this option to enable or disable sheet tab controls in dashboard embedding
      printEnabled: false, // use this option to enable or disable print option for dashboard embedding
      undoRedoDisabled: true, // set this option to true to disable undo and redo buttons for dashboard embedding
      resetDisabled: true, // set this option to true to disable reset button for dashboard embedding
  });
  }
  console.log("updating dash ")
  }, [dashUrl])

  return (
    <div>
      <div id="embeddingContainer"
          ></div>
    </div>
  );
}


export default Dashboard;
