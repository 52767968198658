module.exports = {
  component: 'vfb-form-component-amex-gcp-new-or-existing-customer-selection',
  elementPrefix: '',
  name: "American Express - new or existing customer selection",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'new_application_application_type_selection'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'Label here'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'new_application_application_type_selection'
    }
  ]
}
