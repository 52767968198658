import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import Fade from "@material-ui/core/Fade";
import PropTypes from "prop-types";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import styles from "../../assets/jss/material-dashboard-react/components/cardStyle.js";

const useStyles = makeStyles(styles);

export default function Card(props) {
  const classes = useStyles();
  const {
    hasActionOnPress = false,
    hasDelegatedActionOnPress = false,
    onPressCallback = undefined,
    className,
    children,
    plain,
    loading,
    profile,
    chart,
    hasAnimation,
    ...rest
  } = props;
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile,
    [classes.cardChart]: chart,
    [className]: className !== undefined,
  });

  if (!hasActionOnPress) {
    return (
      <div className={cardClasses} {...rest}>
        <Fade in={true} timeout={1000}>
          <Box
            display={
              loading === false || loading === undefined ? "none" : "flex"
            }
            justifyContent="center"
            alignItems="center"
            minHeight="20vh"
          >
            <CircularProgress style={{ color: "#4aac60" }} />
          </Box>
        </Fade>
        <Fade in={true} timeout={1000}>
          <div style={{ display: loading === true ? "none" : "inline" }}>
            {children}
          </div>
        </Fade>
      </div>
    );
  }

  if (!hasDelegatedActionOnPress) {
    return (
      <div className={cardClasses} {...rest}>
        <Fade in={true} timeout={1000}>
          <Box
            display={
              loading === false || loading === undefined ? "none" : "flex"
            }
            justifyContent="center"
            alignItems="center"
            minHeight="20vh"
          >
            <CircularProgress style={{ color: "#4aac60" }} />
          </Box>
        </Fade>
        <Fade in={true} timeout={1000}>
          <div
            style={{
              animation:
                hasAnimation === true
                  ? `hasActionSparkle 3s linear infinite`
                  : false === "none",
              display: loading === true ? "none" : "inline",
            }}
            onClick={onPressCallback}
          >
            {children}
          </div>
        </Fade>
      </div>
    );
  }

  return (
    <div className={cardClasses} {...rest}>
      <Fade in={true} timeout={1000}>
        <Box
          display={loading === false || loading === undefined ? "none" : "flex"}
          justifyContent="center"
          alignItems="center"
          minHeight="20vh"
        >
          <CircularProgress style={{ color: "#4aac60" }} />
        </Box>
      </Fade>
      <Fade in={true} timeout={1000}>
        <div
          style={{
            animation:
              hasAnimation === true
                ? `hasActionSparkle 3s linear infinite`
                : false === "none",
            display: loading === true ? "none" : "inline",
          }}
        >
          {children}
        </div>
      </Fade>
    </div>
  );
}

Card.propTypes = {
  hasAnimation: PropTypes.bool,
  hasDelegatedActionOnPress: PropTypes.bool,
  onPressCallback: PropTypes.func,
  chart: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  hasActionOnPress: PropTypes.bool,
  loading: PropTypes.bool,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
};
