import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, connect } from "react-redux";
import AppExperimentFlagContext from "./AppExperimentFlagContext";
import AppFeatureFlagContext from "./AppFeatureFlagContext";
import {
  getEnabledAppExperiments,
  getAppMetadata,
} from "./services/CurbCycleApi";

import experienceInitialisationThunk from "./thunks/Initialisation/experienceInitialisationThunk";

import Router from "./Router";

const AppExperience = ({ pushNotificationsToken, flow }) => {
  const dispatch = useDispatch();

  /**
   * Experiment Flags
   */
  const [fetchingExperimentFlags, setFetchingExperimentFlags] = useState(false);
  const [experimentFlagValues, setExperimentFlagValues] = useState({
    experiments: {
      // defaults
    },
  });
  const [initialisedExperimentFlags, setInitialisedExperimentFlags] = useState(
    false
  );

  /**
   * Feature Flags
   */
  const [fetchingFeatureFlags, setFetchingFeatureFlags] = useState(false);
  const [featureFlagValues, setFeatureFlagValues] = useState({
    // defaults
    use_mvp_experience: false,
    app_telemetry_enabled: true,
    account__enable_faqs: true,
    account__enable_change_of_address: false,
    account__enable_dynamic_account_content_blocks: false,
    account__enable_lga_contact_email: false,
    account__enable_support: false,
    waitlist__enable_faqs: true,
    waitlist__enable_change_of_address: false,
    waitlist__enable_dynamic_waitlist_content_blocks: false,
    waitlist__enable_support: false,
  });
  const [initialisedFeatureFlags, setInitialisedFeatureFlags] = useState(false);

  useEffect((flow) => {
    // dispatch initialisation thunk
    dispatch(experienceInitialisationThunk());
  });

  useEffect(() => {
    let isCancelled = false;

    const fetchExperimentFlags = async () => {
      setInitialisedFeatureFlags(false);
      setFetchingFeatureFlags(true);
      getEnabledAppExperiments()
        .then((metadata) => {
          if (!isCancelled) {
            setExperimentFlagValues({ experiments: metadata });
            setInitialisedExperimentFlags(true);
            setFetchingExperimentFlags(false);
          }
        })
        .catch((err) => {
          if (!isCancelled) {
            setInitialisedExperimentFlags(true);
            setFetchingExperimentFlags(false);
          }
        });
    };

    const fetchFeatureFlags = async () => {
      setInitialisedFeatureFlags(false);
      setFetchingFeatureFlags(true);
      getAppMetadata()
        .then((metadata) => {
          if (!isCancelled) {
            setFeatureFlagValues(metadata.app_feature_flags);
            setInitialisedFeatureFlags(true);
            setFetchingFeatureFlags(false);
          }
        })
        .catch((err) => {
          if (!isCancelled) {
            setInitialisedFeatureFlags(true);
            setFetchingFeatureFlags(false);
          }
        });
    };

    fetchExperimentFlags();
    fetchFeatureFlags();

    return () => {
      isCancelled = true;
    };
  }, []);

  // flow is used so we know when the app is initialised correctly
  if (flow === null) {
    return null;
  }

  return (
    <AppExperimentFlagContext.Provider value={experimentFlagValues}>
      <AppFeatureFlagContext.Provider value={featureFlagValues}>
        <Router />
      </AppFeatureFlagContext.Provider>
    </AppExperimentFlagContext.Provider>
  );
};

function mapStateToProps(state) {
  return {
    flow: state.journey.experience.journey,
  };
}

AppExperience.propTypes = {
  flow: PropTypes.any,
  pushNotificationsToken: PropTypes.string,
};

export default connect(mapStateToProps)(AppExperience);
