import React from 'react';

import AwesomeMapComponent from '../../../../../components/DashboardComponents/Map/AwesomeMapComponent';

function Playground () {
  return (
    <div>
      <h1>Playground</h1>
      <AwesomeMapComponent />
    </div>
  );
}

export default Playground;
