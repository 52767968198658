import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
// thunks
import logOutThunk from "thunks/Auth/logOutThunk";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import { blue } from "@material-ui/core/colors";
// core components
import Button from "components/CustomButtons/Button";

const emails = ["username@gmail.com", "user02@gmail.com"];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const dispatch = useDispatch();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Do you want to logout?</DialogTitle>
      <List>
        <ListItem
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Button
            link={false}
            color={"danger"}
            style={{ width: "100%", height: "50px" }}
            onClick={() => {
              dispatch(logOutThunk(null, null));
            }}
          >
            Yes
          </Button>

          <Button
            link={false}
            style={{ width: "100%", height: "50px" }}
            onClick={handleClose}
          >
            No
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function LogoutAvatar() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Avatar
        title="Logout"
        className={classes.avatar}
        style={{
          width: "30px",
          height: "30px",
          color: "#fff",
          backgroundColor: "#788da1",
        }}
        onClick={handleClickOpen}
      >
        <PersonIcon />
      </Avatar>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
