import {
  managementRequestedEditMrf,
  managementCompletedEditMrf,
} from "../../../redux/reducer";

import { updateMrf } from '../../../services/CurbCycleApi';

export const editMrfThunk = (payload, uuid, setIsSaving, setWasSuccessful) => dispatch => {
  dispatch(managementRequestedEditMrf());
  setIsSaving(true);
  updateMrf(uuid, payload)
    .then(result => {
      dispatch(managementCompletedEditMrf(result));
      setIsSaving(false);
      setWasSuccessful(true);
    })
    .catch(() => {
      dispatch(managementCompletedEditMrf({}));
      setIsSaving(false);
    });
};

export default editMrfThunk;
