import React from "react";
import PropTypes from "prop-types";
import GridItem from "../../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../../components/Grid/GridContainer.js";
import Xp4 from "./components/D3Experiments/Xp4";

function MaterialTypeManagerUi({ config }) {
  const exampleConfig = {
    name: "SMRF Material Types",
    uuid: "ab2f6546-0e40-4b58-a324-017e3e677410",
    nodeNo: "0",
    value: 1,
    status: "In",
    type: "MainNode",
    mainRoot: "Root1",
    nodeBefore: "null",
    linkWidth: 0,
    children: [
      {
        name: "Aluminium",
        uuid: "581c976c-d73a-47ab-93e1-4015188249ee",
        nodeNo: "0::0",
        value: 5,
        status: "In",
        type: "Small",
        mainRoot: "Root1",
        nodeBefore: "Aluminium",
        linkWidth: 10,
      },
      {
        name: "Cardboard",
        uuid: "cd4ce8bc-ba86-46be-8a02-0da65b8f58bf",
        nodeNo: "0::1",
        value: 5,
        status: "In",
        type: "Small",
        mainRoot: "Root1",
        nodeBefore: "Cardboard",
        linkWidth: 10,
      },
      {
        name: "Coffee Pods",
        uuid: "9653c3a7-64dd-4c7c-b0c1-287ed51f2bd1",
        nodeNo: "0::2",
        value: 5,
        status: "In",
        type: "Small",
        mainRoot: "Root1",
        nodeBefore: "Coffee Pods",
        linkWidth: 10,
      },
      {
        name: "Mixed Paper",
        uuid: "ba1bf270-1ad0-4227-bda1-a32e573acaca",
        nodeNo: "0::3",
        value: 5,
        status: "In",
        type: "Small",
        mainRoot: "Root1",
        nodeBefore: "Mixed Paper",
        linkWidth: 10,
        children: [
          {
            name: "Grade A",
            uuid: "3a81cccb-f80b-44f9-a24c-6d8b29be0146",
            nodeNo: "0::3::0",
            value: 5,
            status: "In",
            type: "Small",
            mainRoot: "Root1",
            nodeBefore: "Mixed Paper",
            linkWidth: 300,
          },
          {
            name: "Grade B",
            uuid: "b9b779b6-5fa9-47e6-984e-2f59e6a3a091",
            nodeNo: "0::3::1",
            value: 5,
            status: "In",
            type: "Small",
            mainRoot: "Root1",
            nodeBefore: "Mixed Paper",
            linkWidth: 300,
          },
        ],
      },
      {
        name: "Mixed Plastic",
        uuid: "80e220e3-b5a7-452b-8a41-8722b3c08f5b",
        nodeNo: "0::4",
        value: 5,
        status: "In",
        type: "Small",
        mainRoot: "Root1",
        nodeBefore: "Mixed Plastic",
        linkWidth: 10,
      },
      {
        name: "Soft Plastic",
        uuid: "14f583bd-e280-4ade-a3c5-4ae8499ac6c5",
        nodeNo: "0::5",
        value: 5,
        status: "In",
        type: "Small",
        mainRoot: "Root1",
        nodeBefore: "Soft Plastic",
        linkWidth: 10,
      },
      {
        name: "Steel",
        uuid: "7a59e92c-c7bf-4e56-b5d0-025dab717b41",
        nodeNo: "0::6",
        value: 5,
        status: "In",
        type: "Small",
        mainRoot: "Root1",
        nodeBefore: "Steel",
        linkWidth: 10,
      },
      {
        name: "Textiles",
        uuid: "db4ffeea-f5e3-4148-b4a5-9f5d66a9cf0a",
        nodeNo: "0::7",
        value: 5,
        status: "In",
        type: "Small",
        mainRoot: "Root1",
        nodeBefore: "Textiles",
        linkWidth: 10,
      },
      {
        name: "Waste",
        uuid: "89aff33e-cd3d-4da5-bf9e-5912144519e1",
        nodeNo: "0::8",
        value: 5,
        status: "In",
        type: "Small",
        mainRoot: "Root1",
        nodeBefore: "Waste",
        linkWidth: 10,
      },
    ],
  };

  return (
    <div>
      <h1>Material Type Manger</h1>
      <Xp4 data={exampleConfig} />
      <GridContainer
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem
              xs={11}
              sm={11}
              md={11}
              style={{
                marginLeft: "6rem",
              }}
            >
              <GridContainer
                style={{
                  width: "100%",
                  height: "100%",
                }}
              ></GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

MaterialTypeManagerUi.propTypes = {
  config: PropTypes.any.isRequired,
}

export default MaterialTypeManagerUi;
