import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import pageChangeMrfsWithFiltersThunk from "../../../../thunks/Management/MrfOperations/DataPagination/pageChangeMrfsWithFiltersThunk";
import useDebounce from "../../../../hooks/useDebounce";
import paginationHelper from "../../../../utilities/paginationHelper";
// @material-ui/core components
import Fade from "@material-ui/core/Fade";
// core components
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import ActionableDataTable from "../../../../components/ActionableDataTable/ActionableDataTable";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../components/Card/CardIcon.js";
import CardBody from "../../../../components/Card/CardBody.js";
import Button from "../../../../components/CustomButtons/Button";

function MrfOperations({ fetching, mrfTableData, mrfTableRawData }) {
  const dispatch = useDispatch();

  const [mrfTableFilterOptions, setMrfTableFilterOptions] = useState(undefined);
  const [mrfTableFilterForStatus, setMrfTableFilterForStatus] = useState(
    undefined
  );
  const debouncedMrfTableFilterOptions = useDebounce(
    mrfTableFilterOptions,
    400
  );
  const debouncedMrfTableStatus = useDebounce(mrfTableFilterForStatus, 300);

  useEffect(() => {
    if (undefined === debouncedMrfTableFilterOptions) {
      return;
    }
    dispatch(
      pageChangeMrfsWithFiltersThunk(
        paginationHelper.generateFilterQueryStringFromFilterOptions(
          debouncedMrfTableFilterOptions
        )
      )
    );
  }, [debouncedMrfTableFilterOptions, dispatch]);

  useEffect(() => {
    const handler = setTimeout(
      paginationHelper.handleAddSimpleKeyValueFilterOption(
        "status",
        debouncedMrfTableStatus,
        setMrfTableFilterOptions,
        debouncedMrfTableFilterOptions
      ),
      500
    );

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedMrfTableStatus]);

  return (
    <div>
      <Fade in={true} timeout={3000}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card loading={fetching}>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">Manage SMRFs</CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={9}>
                    <div className="data-table-filter-controls">
                      <form>
                        <p>Status</p>
                        <input
                          type="radio"
                          name="status"
                          value="active"
                          defaultChecked={debouncedMrfTableStatus === "active"}
                          onClick={() => {
                            setMrfTableFilterForStatus("active");
                            paginationHelper.handleAddSimpleKeyValueFilterOption(
                              "status",
                              "active",
                              setMrfTableFilterOptions,
                              debouncedMrfTableFilterOptions
                            );
                          }}
                        />
                        <label>Active</label>
                        <input
                          type="radio"
                          name="status"
                          value="inactive"
                          defaultChecked={
                            debouncedMrfTableStatus === "inactive"
                          }
                          onClick={() => {
                            setMrfTableFilterForStatus("inactive");
                            paginationHelper.handleAddSimpleKeyValueFilterOption(
                              "status",
                              "inactive",
                              setMrfTableFilterOptions,
                              debouncedMrfTableFilterOptions
                            );
                          }}
                        />
                        <label>Inactive</label>
                      </form>
                    </div>
                  </GridItem>
                  {/*<GridItem xs={12} sm={12} md={3}>*/}
                  {/**/}
                  {/*</GridItem>*/}
                  <GridItem xs={1} sm={1} md={1}>
                    <Link to="/management/mrf-management/add">
                      <Button color={"warning"} link={true} size={"lg"}>
                        <span className="material-icons">add</span> Add SMRF
                      </Button>
                    </Link>
                  </GridItem>
                </GridContainer>

                <ActionableDataTable
                  tableHeaderColor="warning"
                  tableHead={["Name", "Status", "Actions"]}
                  tableData={mrfTableData}
                  pagination={true}
                  paginationConfig={{
                    collectionData: mrfTableRawData,
                    handlePageChange: (payload) => {
                      setMrfTableFilterOptions(
                        paginationHelper.addSimpleKeyValueFilterOption(
                          debouncedMrfTableFilterOptions,
                          "page",
                          payload.selected,
                          true,
                          false
                        )
                      );
                    },
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fade>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    mrfs: state.journeyFlows.CurbCycleManagement.mrfs.mrfs,
    mrfTableRawData: state.journeyFlows.CurbCycleManagement.mrfs.mrfs,
    mrfTableData: state.journeyFlows.CurbCycleManagement.mrfs.mrfs.data.map(
      (item) => {
        return [
          {
            display: item.name,
          },
          {
            display: item.status,
          },
          {
            actions: [
              {
                type: "link-button",
                icon: "edit",
                color: "info",
                description: "Edit SMRF Record",
                size: "sm",
                context: {
                  uuid: item.uuid,
                  link: `/management/mrf-management/edit/${item.uuid}`,
                },
              },
              {
                type: "link-button",
                icon: "person",
                color: "warning",
                description: "Manage People",
                size: "sm",
                context: {
                  uuid: item.uuid,
                  link: `/management/mrf-management/mrf/${item.uuid}/people`,
                },
              },
              {
                type: "link-button",
                icon: "security",
                color: "rose",
                description: "Manage Login Pin",
                size: "sm",
                context: {
                  uuid: item.uuid,
                  link: `/management/mrf-management/mrf/${item.uuid}/security`,
                },
              },
            ],
          },
        ];
      }
    ),
    fetching: state.journeyFlows.CurbCycleManagement.mrfs.fetching,
  };
}

MrfOperations.propTypes = {
  fetching: PropTypes.bool.isRequired,
  mrfTableData: PropTypes.any,
  mrfTableRawData: PropTypes.any,
};

export default connect(mapStateToProps)(MrfOperations);
