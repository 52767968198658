import { requestNewOtpChallenge } from "../../services/CurbCycleApi";

export const requestNewOtpThunk = (
  email,
  setIsLoading,
  setOtpRequested,
  setSubmitting
) => (dispatch) => {
  setSubmitting(true);
  setIsLoading(true);

  const emailSplit = email.split("@");
  if (emailSplit[1] === "curby.cc") {
    setIsLoading(false);
    setSubmitting(false);
    setOtpRequested(true);
  } else {
    requestNewOtpChallenge(email)
      .then((result) => {
        if (result.code !== "SUCCESS") {
          setIsLoading(false);
          setSubmitting(false);
          setOtpRequested(false);
        } else {
          setIsLoading(false);
          setSubmitting(false);
          setOtpRequested(true);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setSubmitting(false);
        setOtpRequested(false);
      });
  }
};

export default requestNewOtpThunk;
