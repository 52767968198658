import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
const image = require("./info.png");

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 440,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default function InteractivityIndicator({
  titleCallback,
  messageHtmlCallback,
  imageHtmlCallback,
}) {
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography color="inherit">{titleCallback()}</Typography>
          <br />
          <div style={{ width: "440px", float: "left" }}>
            <div style={{ width: "210px", float: "left" }}>
              {imageHtmlCallback()}
            </div>
            <div style={{ width: "210px", float: "left", marginLeft: "10px" }}>
              {messageHtmlCallback()}
            </div>
          </div>
          <div style={{ clear: "both" }}></div>
        </React.Fragment>
      }
    >
      <span
        style={{ position: "absolute", paddingLeft: "10px", fontWeight: 400 }}
      >
        <img width="15px" height="15px" src={image} alt="" />
      </span>
    </HtmlTooltip>
  );
}

InteractivityIndicator.propTypes = {
  imageHtmlCallback: PropTypes.func,
  messageHtmlCallback: PropTypes.func,
  titleCallback: PropTypes.func,
};
