import React, { useEffect, useState } from 'react';
// @material-ui/core
import { Formik } from 'formik';
// core components
import GridItem from '../../../../../components/Grid/GridItem';
import GridContainer from '../../../../../components/Grid/GridContainer';
import Card from '../../layouts/components/Card/Card.js';
import CardHeader from '../../layouts/components/Card/CardHeader.js';
import CardIcon from '../../layouts/components/Card/CardIcon.js';
import CardBody from '../../layouts/components/Card/CardBody.js';
import Button from '../../../../../components/CustomButtons/Button';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import addMrfOperatorThunk from '../../../../../thunks/MrfOperations/Management/People/MrfOperators/addMrfOperatorThunk';

function AddMrfOperator() {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [wasSuccessful, setWasSuccessful] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (wasSuccessful) {
      history.push(`/management/people`);
    }
  }, [wasSuccessful, isSaving, history]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card loading={isSaving}>
            <CardHeader color="primary" stats icon>
              <CardIcon color="success">Add SMRF Operator</CardIcon>
              <Link to="/management/people">
                <Button color={'warning'} link={true}>
                  <span className="material-icons">cancel</span> Cancel
                </Button>
              </Link>
            </CardHeader>
            <CardBody>
              <Formik
                initialValues={{ username: '' }}
                validate={(values) => {
                  const errors = {};
                  if (!values.username) {
                    errors.username = 'Required';
                  } else if (values.username.length < 3) {
                    errors.username = 'SMRF Operator usernames should be at least 3 characters';
                  } else if (!/^[A-Za-z0-9+-]{3,90}$/i.test(values.username)) {
                    errors.username =
                      'Invalid username, must be between 3 and 90 characters long, no "@","%" or "." characters are allowed, You Do not need to type the @... this will be appended to the username automatically';
                  } else if (values.username.length > 90) {
                    errors.username = 'SMRF Operator usernames should be a maximum of 90 characters';
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  dispatch(addMrfOperatorThunk(values, setIsSaving, setWasSuccessful));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <label aria-label="SMRF Operator username">Username</label>
                    <input
                      type="text"
                      name="username"
                      style={{
                        width: '99%',
                        height: '2.2em',
                        fontSize: '1em',
                        border: '1px solid #d2d2d2',
                        paddingLeft: '10px',
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                    />
                    <span style={{ color: '#cc2526' }}>{errors.username && touched.username && errors.username}</span>
                    <Button style={{ margin: '15px auto', width: '100%' }} color={'info'} type="submit" disabled={isSaving}>
                      Save
                    </Button>
                  </form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(AddMrfOperator);
