module.exports = {
  component: 'vfb-mb-form-component-locations-address-lookup',
  elementPrefix: '',
  name: "MB: Address lookup - typeahead component designed with specific behaviour related to selecting an address",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'element_name_prefix',
      value: 'element_name_prefix'
    },
    {
      type: 'literal',
      variable: 'input_field_label',
      value: 'input_field_label'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'registered_business_address'
    },
    {
      type: 'literal',
      variable: 'pre_text',
      value: ''
    },
    {
      type: 'literal',
      variable: 'post_text',
      value: ''
    },
    {
      type: 'literal',
      variable: 'tooltip_content',
      value: ''
    }
  ]
}
