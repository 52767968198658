import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
// @material-ui/core components
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import useSpinnerStyleIm from "../../../../../../assets/jss/material-dashboard-react/components/spinnerStyleIm.js";

export default function Spinner(props) {
  const classes = useSpinnerStyleIm();
  const { className, children, centerWindow, ...rest } = props;
  const spinnerClasses = classNames({
    [classes.spinner]: true,
    [classes.tableResponsiveCenterWindow]: centerWindow,
  });

  return (
    <div className={spinnerClasses} {...rest}>
      <Box position="relative" display="inline-flex">
        <CircularProgress className={classes.spinner} {...rest} size={150} />
        <Box className={classes.box}>
          <Typography
            className={classes.text}
            variant="caption"
            component="div"
            color="textSecondary"
          >
            Loading...
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

Spinner.propTypes = {
  centerWindow: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};
