import {
  managementRequestedLGAs,
  managementReceivedLGAs,
} from "../../../../redux/reducer";

import paginationHelper from '../../../../utilities/paginationHelper';

import { getAllLGAs } from '../../../../services/CurbCycleApi';

export const pageChangeLGAsWithFiltersThunk = (filtersAsQueryString) => dispatch => {
  dispatch(managementRequestedLGAs());
  getAllLGAs(filtersAsQueryString)
    .then(result => {
      dispatch(managementReceivedLGAs(result));
    })
    .catch(() => {
      dispatch(
        managementReceivedLGAs(
          paginationHelper.getDefaultCollectionShape()
        )
      );
    });
};

export default pageChangeLGAsWithFiltersThunk;
