import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import {
  getCurbyProgramDataCache,
  getAccountEnabledLGA,
  toggleAccountMaterialLGA,
} from "../../../../../services/CurbCycleApi";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
import Icon from "@material-ui/core/Icon";
// core components
import ProfileAvatar from "../../../../../components/Avatar/ProfileAvatar/ProfileAvatar";
import GridContainer from "../../../../../components/Grid/GridContainer";
import GridItem from "../../../../../components/Grid/GridItem";
import Card from "../../../../../components/Card/Card";
import CardHeader from "../../../../../components/Card/CardHeader";
import CardIcon from "../../../../../components/Card/CardIcon";
import Button from "../../../../../components/CustomButtons/Button";
import CardBody from "../../../../../components/Card/CardBody";
import ActionableDataTable from "../../../../../components/ActionableDataTable/ActionableDataTable";

function LGAEngagement() {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [cacheData, setCacheData] = useState("");
  const [tableData, setTableData] = useState({ data: [] });
  const [enabledLgaOptions, setEnabledLgaOptions] = useState(undefined);
  const [activeIdentifier, setActiveIdentifier] = useState(null);
  const history = useHistory();
  const { uuid } = useParams();

  const account = history.location.state.account;

  useEffect(() => {
    let isCancelled = false;
    const getCacheData = async () => {
      getCurbyProgramDataCache(uuid).then((response) => {
        if (!isCancelled) {
          setCacheData(response.config.lga_details.name);
        }
      });
    };
    getCacheData();
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;
    const getEnabledLGAStates = async () => {
      getAccountEnabledLGA(uuid).then((response) => {
        if (!isCancelled) {
          setEnabledLgaOptions(response);
          setShouldRefresh(false);
          setActiveIdentifier(null);
        }
      });
    };
    getEnabledLGAStates();
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;
    const getEnabledLGAStates = async () => {
      getAccountEnabledLGA(uuid).then((response) => {
        if (!isCancelled) {
          setEnabledLgaOptions(response);
          setShouldRefresh(false);
          setActiveIdentifier(null);
        }
      });
    };
    if (shouldRefresh === true) {
      getEnabledLGAStates();
    }
    return () => {
      isCancelled = true;
    };
  }, [shouldRefresh]);

  const handleNewToggleState = async (newToggleState) => {
    let payload = {
      key: newToggleState.key,
      enabled: newToggleState.value,
    };
    setActiveIdentifier(newToggleState.key);
    toggleAccountMaterialLGA(uuid, payload).then((response) => {
      setShouldRefresh(true);
    });
  };

  useEffect(() => {
    if (undefined === enabledLgaOptions) {
      return;
    }

    let newTableData = { data: [] };
    newTableData.data = enabledLgaOptions.data.map((item) => {
      return [
        {
          display: `${item.key}`,
        },
        {
          display: `${item.value}`,
        },
        {
          hasActions: true,
          actions: [
            {
              type: "toggle-input-switch",
              color: "primary",
              label: item.enabled === true ? "On" : "Off",
              spinnerLabel: "Updating",
              description: "LGA Engagement Options",
              size: "medium",
              context: {
                initialToggleState: {
                  identifier: `${item.key}`,
                  value: item.enabled === true ? true : false,
                },
                toggleState: {
                  identifier: `${item.key}`,
                  value: undefined,
                },
                isSaving: false,
                activeIdentifier: null,
                onToggleSwitch: (newToggleState) => {
                  handleNewToggleState(newToggleState);
                },
              },
            },
          ],
        },
      ];
    });
    setTableData(newTableData);
  }, [enabledLgaOptions]);

  useEffect(() => {
    if (undefined === enabledLgaOptions) {
      return;
    }

    if (activeIdentifier !== null) {
      let newTableData = { data: [] };
      newTableData.data = enabledLgaOptions.data.map((item) => {
        return [
          {
            display: `${item.key}`,
          },
          {
            display: `${item.value}`,
          },
          {
            hasActions: true,
            actions: [
              {
                type: "toggle-input-switch",
                color: "primary",
                label: item.enabled === true ? "On" : "Off",
                spinnerLabel: "Updating",
                description: "LGA Engagement Options",
                size: "medium",
                context: {
                  initialToggleState: {
                    identifier: `${item.key}`,
                    value: item.enabled === true ? true : false,
                  },
                  toggleState: {
                    identifier: `${item.key}`,
                    value: undefined,
                  },
                  isSaving: true,
                  activeIdentifier: activeIdentifier,
                  onToggleSwitch: (newToggleState) => {
                    handleNewToggleState(newToggleState);
                  },
                },
              },
            ],
          },
        ];
      });
      setTableData(newTableData);
    }
  }, [activeIdentifier]);

  if (undefined === enabledLgaOptions) {
    return <div>Loading</div>;
  }

  if (undefined === account) {
    return <div>Loading</div>;
  }

  return (
    <Fade in={true} timeout={3000}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon
                color="success"
                style={{ fontSize: "22px", fontWeight: 400 }}
              >
                LGA Engagement Options
              </CardIcon>
            </CardHeader>
            <br />
            <CardBody>
              <GridContainer
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
              >
                <GridItem>
                  <Button
                    link={true}
                    onClick={() => {
                      history.push({
                        pathname: `/management/material-type/${uuid}`,
                        state: { account: account },
                      });
                    }}
                  >
                    <Icon>arrow_back</Icon>
                    <label style={{ fontSize: "18px" }}>Back</label>
                  </Button>
                </GridItem>
              </GridContainer>

              <GridContainer
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-start"
              >
                <GridItem xs={5}>
                  <Card>
                    <CardIcon color="primary">
                      <ProfileAvatar profile={{ avatar: "curby" }} />
                    </CardIcon>
                    <p
                      style={{
                        fontSize: "22px",
                        paddingLeft: 150,
                        paddingRight: 5,
                      }}
                    >
                      {account.email}
                    </p>
                    <p
                      style={{
                        paddingLeft: 150,
                        paddingRight: 5,
                      }}
                    >
                      LGA {cacheData}
                    </p>
                    <CardBody>
                      <p style={{ fontSize: "18px" }}>
                        {account.addresses[0].formatted_address}
                      </p>
                      <p style={{ fontSize: "18px" }}>
                        {account.name}'s account has ({account.account_points})
                        points
                      </p>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
              <h3>
                When LGA engagement option is toggled on a contact council
                button for that material type will be visible in the app
              </h3>
              <ActionableDataTable
                tableHeaderColor="warning"
                tableHead={["Material", "Type", "Actions"]}
                tableData={tableData.data}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Fade>
  );
}

LGAEngagement.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        account: PropTypes.shape({
          account_points: PropTypes.number,
          uuid: PropTypes.string,
          name: PropTypes.string,
          addresses: PropTypes.arrayOf({
            formatted_address: PropTypes.string,
          }),
          email: PropTypes.string,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  accountData: PropTypes.any.isRequired,
};

export default LGAEngagement;
