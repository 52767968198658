import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ContentTreeNavigationComponent from "./../components/ContentTreeNavigationComponent";

const useStyles = makeStyles({
  list: {
    width: window.outerWidth / 2,
  },
  fullList: {
    width: "auto",
  },
});

function ToolingDrawer({
  contentTreeContext,
  onContentTreeContextChangeCallback,
}) {
  const classes = useStyles();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      style={{ marginLeft: 60 }}
    >
      <List>
        <ListItem key={"efdfsdf"}>
          <h4>Content Tree Navigator</h4>
        </ListItem>
        <ListItem key={"efdfsdf2"}>
          <ContentTreeNavigationComponent
            onContentTreeContextChangeCallback={(newContext) => {
              onContentTreeContextChangeCallback(newContext);
            }}
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem key={"efdfsdf3"}>
          <h4>Content Tree Context</h4>
        </ListItem>
        <ListItem key={"efdfsdf4"}>
          <pre>{JSON.stringify(contentTreeContext, null, 2)}</pre>
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            Content Tree Navigator
          </Button>
          <Drawer
            style={{ zIndex: 0 }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

ToolingDrawer.propTypes = {
  contentTreeContext: PropTypes.any.isRequired,
  onContentTreeContextChangeCallback: PropTypes.func.isRequired,
};

export default ToolingDrawer;
