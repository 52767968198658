import { alpha , makeStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/styles/createStyles";
import { hexToRgb } from "@material-ui/core";

import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  roseCardHeader,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

const useCardIconStyle = makeStyles((theme) => {
  const primaryColor = theme.palette.primary.main;
  const primaryColorFade75 = alpha(primaryColor, 0.75);

  return createStyles({
    cardIcon: {
      // "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader": {
      borderRadius: "3px",
      backgroundColor: grayColor[0],
      padding: "15px",
      marginTop: "-20px",
      marginRight: "15px",
      float: "left",
      background:
        "linear-gradient(60deg, " +
        primaryColor +
        ", " +
        primaryColorFade75 +
        ")",
      boxShadow:
        "0 4px 20px 0 rgba(" +
        hexToRgb("000") +
        ",.14), 0 7px 10px -5px rgba(" +
        hexToRgb(primaryColor) +
        ",.4)",
      // },
    },
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    roseCardHeader,
  });
});

export default useCardIconStyle;
