import { Buffer } from "buffer";

// Takes an array and creates a new array removing any duplicates
const duplicateArrayValues = (val) => {
  let formattedVal = val;
  formattedVal = Array.from(new Set(val));
  return formattedVal;
};

// Takes a snake case string of all Capital chars like HELLO_WORLD and
// returns a space separated string first chars Capitalized
const strToLowerWithCap = (str) => {
  let friendlyString = str
    .toLowerCase()
    .replace("_", " ")
    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()); // first char of each word uppercase
  return friendlyString;
};
// Takes a string format like this "2021-09-21T07:01:06.776307Z" and
//  outputs formatted time string like "2021-09-21 07:01"
const formatTime = (timestamp) => {
  let result = "";
  if (typeof timestamp === "string" && timestamp.split("T").length > 1) {
    const date = timestamp.split("T")[0];
    const time = timestamp.split("T")[1].substring(0, 5);
    result = `${date} ${time}`;
  }
  return result;
};

// Decodes a base64 string
const decodeString = (str) => {
  if (!str) {
    return;
  }
  const decodedString = Buffer.from(str, "base64").toString("ascii");
  return decodedString;
};

// Encodes a base64 string
const encodeString = (str) => {
  if (!str) {
    return;
  }
  const encodedString = Buffer.from(str).toString("base64");
  return encodedString;
};

export {
  duplicateArrayValues,
  strToLowerWithCap,
  formatTime,
  decodeString,
  encodeString,
};
