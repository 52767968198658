import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import Fade from "@material-ui/core/Fade";
// core components
import ActionableDataTable from "../../../../../../components/ActionableDataTable/ActionableDataTable.js";
import Card from "../../../../../../components/Card/Card.js";
import CardHeader from "../../../../../../components/Card/CardHeader.js";
import CardIcon from "../../../../../../components/Card/CardIcon.js";
import CardBody from "../../../../../../components/Card/CardBody.js";
import Button from "../../../../../../components/CustomButtons/Button";
import {
  getMaterialJourneyProductStewardDropOffOptions,
  toggleMaterialJourneyProductStewardDropOffOptions,
} from "services/CurbCycleApi";

function UpdateMaterialTypeJourneyComponent({ crudContext }) {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [tableData, setTableData] = React.useState({
    data: [],
  });
  const [activeIdentifier, setActiveIdentifier] = useState(null);
  const [
    enabledMaterialTypeDropOffJourneyOptions,
    setEnabledMaterialTypeDropOffJourneyOptions,
  ] = React.useState(undefined);

  useEffect(() => {
    let isCancelled = false;
    const getMaterialTypeJourneyDropOffOptionsStates = async () => {
      getMaterialJourneyProductStewardDropOffOptions().then((response) => {
        if (!isCancelled) {
          let structureWeWantForUsingTheActionableDataTable = {
            data: [],
          };
          // console.log({ response: response, crudContext: crudContext });
          response.data.forEach((item) => {
            if (item.key === crudContext.data.key) {
              structureWeWantForUsingTheActionableDataTable.data.push(item);
            }
          });
          setEnabledMaterialTypeDropOffJourneyOptions(
            structureWeWantForUsingTheActionableDataTable
          );
          setShouldRefresh(false);
          setActiveIdentifier(null);
        }
      });
    };
    getMaterialTypeJourneyDropOffOptionsStates();
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;
    const getMaterialTypeJourneyDropOffOptionsStates = async () => {
      getMaterialJourneyProductStewardDropOffOptions().then((response) => {
        if (!isCancelled) {
          let structureWeWantForUsingTheActionableDataTable = {
            data: [],
          };
          response.data.forEach((item) => {
            if (item.key === crudContext.data.key) {
              structureWeWantForUsingTheActionableDataTable.data.push(item);
            }
          });
          setEnabledMaterialTypeDropOffJourneyOptions(
            structureWeWantForUsingTheActionableDataTable
          );
          setShouldRefresh(false);
          setActiveIdentifier(null);
        }
        setShouldRefresh(false);
        setActiveIdentifier(null);
      });
    };
    if (shouldRefresh === true) {
      getMaterialTypeJourneyDropOffOptionsStates();
    }
    return () => {
      isCancelled = true;
    };
  }, [shouldRefresh]);

  const handleNewToggleState = async (newToggleState) => {
    if (
      enabledMaterialTypeDropOffJourneyOptions.data[0].show_drop_off_points ===
      true
    ) {
      alert(
        `Are you sure you want to disable drop off locations for ${key}! This change will affect all users`
      );
    }
    let payload = {
      key: newToggleState.key,
      show_drop_off_points: newToggleState.value,
    };

    setActiveIdentifier(newToggleState.key);
    toggleMaterialJourneyProductStewardDropOffOptions(
      // uuid,
      payload
    ).then((response) => {
      setShouldRefresh(true);
    });
  };

  useEffect(() => {
    if (undefined === enabledMaterialTypeDropOffJourneyOptions) {
      // console.log(enabledMaterialTypeDropOffJourneyOptions);
      return;
    }
    let newTableData = {
      data: [],
    };
    newTableData.data = enabledMaterialTypeDropOffJourneyOptions.data.map(
      (item) => {
        return [
          {
            // display: `${item.key}`,
            display: "Show drop-off options",
          },
          {
            hasActions: true,
            actions: [
              {
                type: "toggle-input-switch",
                color: "primary",
                label: item.show_drop_off_points === true ? "On" : "Off",
                spinnerLabel: "Updating",
                description: "Material Type Drop Offs",
                size: "medium",
                context: {
                  initialToggleState: {
                    identifier: `${item.key}`,
                    value: item.show_drop_off_points === true ? true : false,
                  },
                  toggleState: {
                    identifier: `${item.key}`,
                    value: undefined,
                  },
                  isSaving: false,
                  activeIdentifier: null,
                  onToggleSwitch: (newToggleState) => {
                    handleNewToggleState(newToggleState);
                  },
                },
              },
            ],
          },
        ];
      }
    );
    setTableData(newTableData);
  }, [enabledMaterialTypeDropOffJourneyOptions]);

  useEffect(() => {
    //
    if (undefined === enabledMaterialTypeDropOffJourneyOptions) {
      return;
    }

    if (activeIdentifier !== null) {
      let newTableData = {
        data: [],
      };
      newTableData.data = enabledMaterialTypeDropOffJourneyOptions.data.map(
        (item) => {
          return [
            {
              // display: `${item.key}`,
              display: "Changing drop-off options",
            },
            {
              actions: [
                {
                  type: "toggle-input-switch",
                  color: "primary",
                  label: item.show_drop_off_points === true ? "On" : "Off",
                  spinnerLabel: "Updating",
                  description: "Material Type Drop Offs",
                  size: "medium",
                  context: {
                    initialToggleState: {
                      identifier: `${item.key}`,
                      value: item.show_drop_off_points === true ? true : false,
                    },
                    toggleState: {
                      identifier: `${item.key}`,
                      value: undefined,
                    },
                    isSaving: true,
                    activeIdentifier: activeIdentifier,
                    onToggleSwitch: (newToggleState) => {
                      handleNewToggleState(newToggleState);
                    },
                  },
                },
              ],
            },
          ];
        }
      );
      setTableData(newTableData);
    }
  }, [activeIdentifier]);

  if (undefined === enabledMaterialTypeDropOffJourneyOptions) {
    return <div>Loading</div>;
  }

  const pageName = "Material Type Journey Options";

  const key = crudContext.data.key;

  return (
    <div>
      <Fade in={true} timeout={2000}>
        <Card loading={false}>
          <CardHeader color="success" stats icon>
            <Button
              link={true}
              onClick={() => {
                window.location.href =
                  "/management/admin-for-material-type-journeys";
              }}
            >
              <span className="material-icons">cancel</span> Cancel
            </Button>
            <CardIcon color="success">{pageName}</CardIcon>
          </CardHeader>
          <CardBody>
            <p>
              When Show drop off points is on, location options to drop off for{" "}
              {key} type will be visible in the app.
            </p>
            <ActionableDataTable
              tableHeaderColor="warning"
              tableHead={["Options", "Actions"]}
              tableData={tableData.data}
            />
          </CardBody>
        </Card>
      </Fade>
    </div>
  );
}

UpdateMaterialTypeJourneyComponent.propTypes = {
  crudContext: PropTypes.shape({
    data: PropTypes.shape({
      key: PropTypes.any,
    }),
  }),
};

export default UpdateMaterialTypeJourneyComponent;
