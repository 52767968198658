module.exports = {
  component: 'vfb-form-element-checkbox-single-with-label',
  elementPrefix: '',
  name: "Checkbox Single (amex)",
  mandatory_bindings: [
    {
      type: 'literal',
      variable: 'id',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'label',
      value: 'EXAMPLE'
    },
    {
      type: 'literal',
      variable: 'name',
      value: 'EXAMPLE'
    }
  ]
}
