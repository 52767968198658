const uuidHelper = require("./UuidHelper").uuidHelper();
const clone = require("clone");

const ContentTree = {
  contentCrudManager: function () {
    /**
     * Properties
     */
    let self = {};

    /**
     * Template for new contents
     *
     * @type {{uuid: string, name: string, type: string, label: string}}
     */
    self.contentTemplate = {
      uuid: "",
      name: "New content",
      "friendly-name": "",
      "content-spec": {
        type: "vue_component",
        specification: [],
        html: "",
        rules: {},
      },
      type: "content",
      label: "",
    };

    /**
     * Template for new content
     *
     * @type {{uuid: string, name: string, type: string, label: string, contents: Array}}
     */
    self.containerTemplate = {
      uuid: "",
      name: "New container",
      "friendly-name": "",
      type: "container",
      label: "",
      content: [],
    };

    /**
     * Template for new content-sections
     *
     * @type {{uuid: string, name: string, type: string, label: string, content: Array}}
     */
    self.contentSectionTemplate = {
      uuid: "",
      name: "New section",
      "friendly-name": "",
      type: "content-section",
      label: "",
      containers: [],
    };

    /**
     * Template for new pages
     *
     * @type {{uuid: string, name: string, type: string, label: string, "content-sections": Array}}
     */
    self.pageTemplate = {
      uuid: "",
      name: "New page",
      "friendly-name": "",
      type: "page",
      label: "",
      "content-sections": [],
    };

    /**
     * Content Service
     *
     * @type {{}}
     */
    self.contentService = {};

    /**
     * Function for handling updates to array indices
     *
     * @param array
     * @param oldIndex
     * @param newIndex
     *
     * @returns []
     */
    self.moveIndex = function (array, oldIndex, newIndex) {
      Array.prototype.moveIndex = function (oldIndexValue, newIndexValue) {
        if (newIndexValue >= this.length) {
          var k = newIndexValue - this.length;
          while (k-- + 1) {
            this.push(undefined);
          }
        }
        this.splice(newIndexValue, 0, this.splice(oldIndexValue, 1)[0]);
      };

      array.moveIndex(oldIndex, newIndex);
      return array;
    };

    /**
     * Helper method to split rel to array elements based on '::'
     *
     * Example of a rel:
     * '_components_::0::sections::1::fieldsets::3::fields::1'
     *
     * Expected result:
     * [
     *   '_components_',
     *   0,
     *   'sections',
     *   1,
     *   'fieldsets',
     *   3,
     *   'fields',
     *   1
     * ]
     *
     * @param rel
     * @returns {Array}
     */
    self.relToParts = function (rel) {
      var parts = rel.split("::");
      for (var i = 0; i < parts.length; i++) {
        if (!isNaN(parts[i])) {
          parts[i] = parseInt(parts[i]);
        }
      }
      return parts;
    };

    /**
     *
     * @param relPath
     * @param targetRelPath
     */
    self.moveContentToRelPath = function (
      relPath,
      targetRelPath,
      deleteFromOriginalLocation
    ) {
      let relPathParts = self.relToParts(relPath);
      let targetRelPathParts = self.relToParts(targetRelPath);

      console.log(relPathParts);
      console.log(targetRelPathParts);

      if (targetRelPathParts[0] !== "_pages_") {
        throw new Error("Unexpected rel path structure");
      }

      let target = {};

      switch (targetRelPathParts.length) {
        case 2:
          // moving a content section to a page
          if (relPathParts.length !== 2) {
            throw new Error("Unexpected number of parts");
          }

          target = self.getFromTreeByRelPath(targetRelPath);
          let numberOfContentSections = target["content-sections"].length; // eslint-disable-line no-case-declarations

          // append to the target
          target["content-sections"][
            numberOfContentSections
          ] = self.getFromTreeByRelPath(relPath);

          if (deleteFromOriginalLocation) {
            // delete from original location
            self.deleteContentByRelPath(relPath);
          }

          // update the target
          self.updateContentByRelPath(target, targetRelPath);
          break;
        case 4:
          // moving a container to a content section
          if (relPathParts.length !== 6) {
            throw new Error("Unexpected number of parts");
          }

          target = self.getFromTreeByRelPath(targetRelPath);
          // let numberOfContainers = target['containers'].length

          // append to the target
          target["containers"].push(self.getFromTreeByRelPath(relPath));

          if (deleteFromOriginalLocation) {
            // delete from original location
            self.deleteContentByRelPath(relPath);
          }

          // update the target
          self.updateContentByRelPath(target, targetRelPath);
          break;
        case 6:
          // moving content to a container
          if (relPathParts.length !== 8) {
            throw new Error("Unexpected number of parts");
          }

          target = self.getFromTreeByRelPath(targetRelPath);
          let numberOfContentElements = target["content"].length; // eslint-disable-line no-case-declarations

          // append to the target
          target["content"][
            numberOfContentElements
          ] = self.getFromTreeByRelPath(relPath);

          if (deleteFromOriginalLocation) {
            // delete from original location
            self.deleteContentByRelPath(relPath);
          }

          // update the target
          self.updateContentByRelPath(target, targetRelPath);
          break;
        default:
          throw new Error("That should not happen");
      }
    };

    self.moveContentFromPathToPath = function (fromRelPath, toRelPath) {
      let samePage = false;
      let sameSection = false;
      let sameContainer = false;
      let theContentTree;
      let theUpdatedContent;

      const fromRelPathParts = self.relToParts(fromRelPath);
      const toRelPathParts = self.relToParts(toRelPath);

      if (fromRelPathParts.length === toRelPathParts.length) {
        // check if it is a sort within the same type
        switch (fromRelPathParts.length) {
          case 2:
            // we're dealing with pages and we're going to be sorting them
            theContentTree = self.contentService.getContentTree();
            theUpdatedContent = self.moveIndex(
              theContentTree["_pages_"],
              fromRelPathParts[1],
              toRelPathParts[1]
            );
            theContentTree["_pages_"] = theUpdatedContent;
            self.contentService.updateContentTree(theContentTree);

            console.log("case 2", {
              toRelPathParts: toRelPathParts,
              fromRelPathParts: fromRelPathParts,
            });

            break;
          case 4:
            // we're dealing with sections so we're either going to be sorting them or moving to another page
            // let's start with the simple path and see if we're on the same page
            samePage = fromRelPathParts[1] === toRelPathParts[1];

            if (samePage === true) {
              theContentTree = self.contentService.getContentTree();
              theUpdatedContent = self.moveIndex(
                theContentTree["_pages_"][fromRelPathParts[1]][
                  "content-sections"
                ],
                fromRelPathParts[3],
                toRelPathParts[3]
              );
              theContentTree["_pages_"][fromRelPathParts[1]][
                "content-sections"
              ] = theUpdatedContent;
              self.contentService.updateContentTree(theContentTree);

              console.log("case 4", {
                toRelPathParts: toRelPathParts,
                fromRelPathParts: fromRelPathParts,
              });
            } else {
              console.log("case 4 : not yet implemented");
            }

            break;
          case 6:
            // we're dealing with containers so we're either going to be sorting them or moving to another section, possibly in another page
            // let's start with the simple path and see if we're on the same page
            samePage = fromRelPathParts[1] === toRelPathParts[1];
            sameSection = fromRelPathParts[3] === toRelPathParts[3];

            if (samePage === true && sameSection === true) {
              theContentTree = self.contentService.getContentTree();
              theUpdatedContent = self.moveIndex(
                theContentTree["_pages_"][fromRelPathParts[1]][
                  "content-sections"
                ][fromRelPathParts[3]]["containers"],
                fromRelPathParts[5],
                toRelPathParts[5]
              );
              theContentTree["_pages_"][fromRelPathParts[1]][
                "content-sections"
              ][fromRelPathParts[3]]["containers"] = theUpdatedContent;
              self.contentService.updateContentTree(theContentTree);

              console.log("case 6", {
                toRelPathParts: toRelPathParts,
                fromRelPathParts: fromRelPathParts,
              });
            } else {
              console.log("case 6: not yet implemented");
            }

            break;
          case 8:
            // we're dealing with content so we're either going to be sorting the content or moving it to another container possibly in another section and possibly within another page
            // let's start with the simple path and see if we're on the same page
            samePage = fromRelPathParts[1] === toRelPathParts[1];
            sameSection = fromRelPathParts[3] === toRelPathParts[3];
            sameContainer = fromRelPathParts[5] === toRelPathParts[5];

            if (samePage === true && sameSection === true) {
              theContentTree = self.contentService.getContentTree();
              theUpdatedContent = self.moveIndex(
                theContentTree["_pages_"][fromRelPathParts[1]][
                  "content-sections"
                ][fromRelPathParts[3]]["containers"][fromRelPathParts[5]][
                  "content"
                ],
                fromRelPathParts[7],
                toRelPathParts[7]
              );
              theContentTree["_pages_"][fromRelPathParts[1]][
                "content-sections"
              ][fromRelPathParts[3]]["containers"][fromRelPathParts[5]][
                "content"
              ] = theUpdatedContent;
              self.contentService.updateContentTree(theContentTree);

              console.log("case 8", {
                toRelPathParts: toRelPathParts,
                fromRelPathParts: fromRelPathParts,
              });
            } else {
              console.log("case 8: not yet implemented");
            }
            break;
          default:
            throw new Error("That should not happen");
        }
      } else {
        if (toRelPathParts.length > fromRelPathParts.length) {
          console.log(
            "toRelPathParts.length > fromRelPathParts.length : not yet implemented",
            {
              toRelPathParts: toRelPathParts,
              fromRelPathParts: fromRelPathParts,
            }
          );
        } else {
          // determine where the difference is between them
          console.log(
            "toRelPathParts.length < fromRelPathParts.length : not yet implemented",
            {
              toRelPathParts: toRelPathParts,
              fromRelPathParts: fromRelPathParts,
            }
          );
        }
      }

      return self.contentService.getContentTree();
    };

    /**
     * Function for cleaning elements of a type from an array
     *
     * @param array
     * @param type
     * @returns []
     */
    self.cleanArray = function (array, type) {
      if (array.constructor !== Array) {
        return [];
      }

      Array.prototype.clean = function (deleteValue) {
        for (var i = 0; i < this.length; i++) {
          if (this[i] === deleteValue) {
            this.splice(i, 1);
            i--;
          }
        }

        if (this.length === 0) {
          return [];
        }

        return this;
      };

      array.clean(array, type);
      return array;
    };

    self.contentService.getContentStorageName = function () {
      let storageName = "";

      if (self.contentService.hasCurrentProjectPrefix) {
        storageName += localStorage["current-project-prefix"] + "-";
      }

      storageName += "content-tree";

      return storageName;
    };

    /**
     * Check if the content tree exists in localstorage
     *
     * @returns {boolean}
     */
    self.contentService.hasContentTree = function () {
      return !!localStorage[self.contentService.getContentStorageName()];
    };

    /**
     * Check if the current project prefix exists in localstorage
     *
     * @returns {boolean}
     */
    self.contentService.hasCurrentProjectPrefix = function () {
      return !!localStorage["current-project-prefix"];
    };

    /**
     * Update the content tree
     *
     * @param data
     */
    self.contentService.updateContentTree = function (data) {
      if (
        self.contentService.hasContentTree() &&
        self.contentService.getContentTree() === data
      ) {
        return;
      }

      localStorage.setItem(
        self.contentService.getContentStorageName(),
        JSON.stringify(data)
      );
    };

    self.contentService.getTemplateForNewContentTree = function () {
      return {
        _pages_: [
          {
            uuid: "9513c7c8-5d36-4c1a-a81e-7d122084e1ae",
            name: "Example Page",
            "friendly-name": "Example Page",
            type: "page",
            label: "",
            "content-sections": [
              {
                uuid: "7c73123b-11ac-43d0-a2c2-5f3d8b4ddaf9",
                name: "Example Content Section",
                "friendly-name": "Example Content Section",
                type: "content-section",
                label: "",
                containers: [
                  {
                    uuid: "9655d763-70a9-443d-8d16-3527c89e97d9",
                    name: "Example Container",
                    "friendly-name": "Example Container",
                    type: "container",
                    label: "",
                    content: [
                      {
                        uuid: "a964747c-d258-48f0-b314-c351375d266a",
                        name: "Example Content",
                        "friendly-name": "Example Content",
                        "content-spec": {
                          type: "react_component",
                          specification: [
                            {
                              binding: [
                                {
                                  type: "literal",
                                  variable: "message",
                                  value: "Hello World!",
                                },
                              ],
                              component: "h1",
                              component_name: "A h1 tag",
                              elementPrefix: "",
                            },
                          ],
                        },
                        type: "content",
                        label: "",
                      },
                      {
                        uuid: "a964747c-d258-48f0-b314-c351375d266b",
                        name: "Example Content2",
                        "friendly-name": "Example Content2",
                        "content-spec": {
                          type: "react_component",
                          specification: [
                            {
                              binding: [
                                {
                                  type: "literal",
                                  variable: "message",
                                  value: "Hello World!",
                                },
                              ],
                              component: "h1",
                              component_name: "A h1 tag",
                              elementPrefix: "",
                            },
                          ],
                        },
                        type: "content",
                        label: "",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
    };

    /**
     * Update the content tree by rel path
     *
     * @param context_focus
     * @param rel_path
     * @param append_only
     */
    self.updateContentByRelPath = function (
      context_focus,
      rel_path,
      append_only
    ) {
      let parts = self.relToParts(rel_path);

      if (parts[0] !== "_pages_") {
        throw new Error("Unexpected rel path structure");
      }

      let type = context_focus.type;

      if (append_only) {
        let content_tree = self.contentService.getContentTree();
        console.log(context_focus, type);
        switch (type) {
          case "page":
            content_tree["_pages_"].push(context_focus);
            break;
          case "content-section":
            content_tree["_pages_"][parts[1]]["content-sections"].push(
              context_focus
            );
            break;
          case "container":
            content_tree["_pages_"][parts[1]]["content-sections"][parts[3]][
              "containers"
            ].push(context_focus);
            break;
          case "content":
            content_tree["_pages_"][parts[1]]["content-sections"][parts[3]][
              "containers"
            ][parts[5]]["content"].push(context_focus);
            break;
          default:
            throw new Error("That should not happen");
        }
        self.contentService.updateContentTree(content_tree);
      } else {
        switch (type) {
          case "page":
            self.pageUpdate(parts[1], context_focus);
            break;
          case "content-section":
            self.contentSectionUpdate(parts[1], parts[3], context_focus);
            break;
          case "container":
            self.containerUpdate(parts[1], parts[3], parts[5], context_focus);
            break;
          case "content":
            self.contentUpdate(
              parts[1],
              parts[3],
              parts[5],
              parts[7],
              context_focus
            );
            break;
          default:
            throw new Error("That should not happen");
        }
      }
    };

    /**
     * Delete from the content tree by rel path
     *
     * @param rel_path
     */
    self.deleteContentByRelPath = function (rel_path) {
      let parts = self.relToParts(rel_path);

      if (parts[0] !== "_pages_") {
        throw new Error("Unexpected rel path structure");
      }

      switch (parts.length) {
        case 2:
          self.pageDelete(parts[1]);
          break;
        case 4:
          self.contentSectionDelete(parts[1], parts[3]);
          break;
        case 6:
          self.containerDelete(parts[1], parts[3], parts[5]);
          break;
        case 8:
          self.contentDelete(parts[1], parts[3], parts[5], parts[7]);
          break;
        default:
          throw new Error("That should not happen");
      }
    };

    /**
     *
     * @param relPath
     * @param targetRelPath
     */
    self.moveContentByRelPath = function (
      relPath,
      targetRelPath,
      deleteFromOriginalLocation
    ) {
      let relPathParts = self.relToParts(relPath);
      let targetRelPathParts = self.relToParts(targetRelPath);

      console.log(relPathParts);
      console.log(targetRelPathParts);

      if (targetRelPathParts[0] !== "_pages_") {
        throw new Error("Unexpected rel path structure");
      }

      let target = {};

      switch (targetRelPathParts.length) {
        case 2:
          // moving a content section to a page
          if (relPathParts.length !== 4) {
            throw new Error("Unexpected number of parts");
          }

          target = self.getFromTreeByRelPath(targetRelPath);
          let numberOfContentSections = target["content-sections"].length; // eslint-disable-line no-case-declarations

          // append to the target
          target["content-sections"][
            numberOfContentSections
          ] = self.getFromTreeByRelPath(relPath);

          if (deleteFromOriginalLocation) {
            // delete from original location
            self.deleteContentByRelPath(relPath);
          }

          // update the target
          self.updateContentByRelPath(target, targetRelPath);
          break;
        case 4:
          // moving a container to a content section
          if (relPathParts.length !== 6) {
            throw new Error("Unexpected number of parts");
          }

          target = self.getFromTreeByRelPath(targetRelPath);
          // let numberOfContainers = target["containers"].length;

          // append to the target
          target["containers"].push(self.getFromTreeByRelPath(relPath));

          if (deleteFromOriginalLocation) {
            // delete from original location
            self.deleteContentByRelPath(relPath);
          }

          // update the target
          self.updateContentByRelPath(target, targetRelPath);
          break;
        case 6:
          // moving content to a container
          if (relPathParts.length !== 8) {
            throw new Error("Unexpected number of parts");
          }

          target = self.getFromTreeByRelPath(targetRelPath);
          let numberOfContentElements = target["content"].length; // eslint-disable-line no-case-declarations

          // append to the target
          target["content"][
            numberOfContentElements
          ] = self.getFromTreeByRelPath(relPath);

          if (deleteFromOriginalLocation) {
            // delete from original location
            self.deleteContentByRelPath(relPath);
          }

          // update the target
          self.updateContentByRelPath(target, targetRelPath);
          break;
        default:
          throw new Error("That should not happen");
      }
    };

    /**
     * Returns an item from the component tree based on a valid rel
     *
     * @param relPath
     * @returns {*}
     */
    self.getFromTreeByRelPath = function (relPath) {
      let parts = self.relToParts(relPath);
      let tree = self.contentService.getContentTree();
      let item = {};
      let partsCount = parts.length;

      /**
       * Not a very nice way to do it but fine for MVP
       */
      switch (partsCount) {
        case 0:
          item = tree;
          break;
        case 1:
          item = tree[parts[0]];
          break;
        case 2:
          item = tree[parts[0]];
          item = item[parts[1]];
          break;
        case 3:
          item = tree[parts[0]];
          item = item[parts[1]];
          item = item[parts[2]];
          break;
        case 4:
          item = tree[parts[0]];
          item = item[parts[1]];
          item = item[parts[2]];
          item = item[parts[3]];
          break;
        case 5:
          item = tree[parts[0]];
          item = item[parts[1]];
          item = item[parts[2]];
          item = item[parts[3]];
          item = item[parts[4]];
          break;
        case 6:
          item = tree[parts[0]];
          item = item[parts[1]];
          item = item[parts[2]];
          item = item[parts[3]];
          item = item[parts[4]];
          item = item[parts[5]];
          break;
        case 7:
          item = tree[parts[0]];
          item = item[parts[1]];
          item = item[parts[2]];
          item = item[parts[3]];
          item = item[parts[4]];
          item = item[parts[5]];
          item = item[parts[6]];
          break;
        case 8:
          item = tree[parts[0]];
          item = item[parts[1]];
          item = item[parts[2]];
          item = item[parts[3]];
          item = item[parts[4]];
          item = item[parts[5]];
          item = item[parts[6]];
          item = item[parts[7]];
          break;
        case 9:
          item = tree[parts[0]];
          item = item[parts[1]];
          item = item[parts[2]];
          item = item[parts[3]];
          item = item[parts[4]];
          item = item[parts[5]];
          item = item[parts[6]];
          item = item[parts[7]];
          item = item[parts[8]];
          break;
        default:
          throw new Error("That should not happen");
      }

      return item;
    };

    /**
     * Returns the content tree
     *
     * Example of content tree structure:
     * {
     *   "_pages_" : [
     *     {
     *       "uuid" : "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
     *       "name" : "Some page name",
     *       "friendly-name" : "Some page name",
     *       "type" : "page",
     *       "label" : "Some page name",
     *       "content-sections" : [
     *         {
     *           "uuid" : "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
     *           "name" : "Some section name",
     *           "friendly-name" : "Some section name",
     *           "type" : "content-section",
     *           "label" : "Some section name",
     *           "containers": [
     *             {
     *               "uuid" : "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
     *               "name" : "Some container name",
     *               "friendly-name" : "Some container name",
     *               "type" : "container",
     *               "label" : "Some container name",
     *               "content": [
     *                 {
     *                   "uuid" : "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
     *                   "name" : "Some content name",
     *                   "friendly-name" : "Some content name",
     *                   "type" : "content",
     *                   "label" : "Some content name",
     *                 }
     *               ]
     *             }
     *           ]
     *         }
     *       ]
     *     }
     *   ]
     * }
     *
     * @returns {{}}
     */
    self.contentService.getContentTree = function () {
      if (self.contentService.hasContentTree()) {
        return JSON.parse(
          localStorage.getItem(self.contentService.getContentStorageName())
        );
      }
    };

    self.contentService.getFromFlattenedTreeByUuid = function (uuid) {
      var flattenedTree = self.contentService.getFlattenedContentTree();
      let totalNumberOfFlattenedContentTreeItems = flattenedTree.length;

      for (let i = 0; i < totalNumberOfFlattenedContentTreeItems; i++) {
        if (flattenedTree[i]["uuid"].indexOf(uuid) !== -1) {
          return flattenedTree[i];
        }
      }

      return false;
    };

    self.contentService.getFlattenedContentTree = function () {
      var contentTree = JSON.parse(
        localStorage.getItem(self.contentService.getContentStorageName())
      );

      var flattenedTree = [];
      // handle each page, content section, container and content
      var numberOfPages = contentTree["_pages_"].length;
      console.log();

      for (var i = 0; i < numberOfPages; i++) {
        flattenedTree.push({
          uuid: contentTree["_pages_"][i]["uuid"],
          type: contentTree["_pages_"][i]["type"],
          "friendly-name": contentTree["_pages_"][i]["friendly-name"],
        });

        var numberOfContentSections =
          contentTree["_pages_"][i]["content-sections"].length;

        for (var j = 0; j < numberOfContentSections; j++) {
          flattenedTree.push({
            uuid: contentTree["_pages_"][i]["content-sections"][j]["uuid"],
            type: contentTree["_pages_"][i]["content-sections"][j]["type"],
            "friendly-name":
              contentTree["_pages_"][i]["content-sections"][j]["friendly-name"],
          });

          var numberOfContentContainers =
            contentTree["_pages_"][i]["content-sections"][j]["containers"]
              .length;

          for (var k = 0; k < numberOfContentContainers; k++) {
            flattenedTree.push({
              uuid:
                contentTree["_pages_"][i]["content-sections"][j]["containers"][
                  k
                ]["uuid"],
              type:
                contentTree["_pages_"][i]["content-sections"][j]["containers"][
                  k
                ]["type"],
              "friendly-name":
                contentTree["_pages_"][i]["content-sections"][j]["containers"][
                  k
                ]["friendly-name"],
            });

            var numberOfContent =
              contentTree["_pages_"][i]["content-sections"][j]["containers"][k][
                "content"
              ].length;

            for (var l = 0; l < numberOfContent; l++) {
              flattenedTree.push({
                uuid:
                  contentTree["_pages_"][i]["content-sections"][j][
                    "containers"
                  ][k]["content"][l]["uuid"],
                type:
                  contentTree["_pages_"][i]["content-sections"][j][
                    "containers"
                  ][k]["content"][l]["type"],
                "friendly-name":
                  contentTree["_pages_"][i]["content-sections"][j][
                    "containers"
                  ][k]["content"][l]["friendly-name"],
              });
            }
          }
        }
      }
      return flattenedTree;
    };

    /**
     * Returns the content sections from the tree
     *
     * Allows for providing a path to exclude
     *
     * @param optionalExclusionByRelPath
     * @returns {Array}
     */
    self.getArrayOfContentTreeContentSections = function (
      optionalExclusionByRelPath
    ) {
      let tree = self.contentService.getContentTree();

      let numberOfPages = tree["_pages_"].length;
      let dataToReturn = [];

      for (let i = 0; i < numberOfPages; i++) {
        let thisPage = tree["_pages_"][i];
        let numberOfContentSections = thisPage["content-sections"].length;

        for (let j = 0; j < numberOfContentSections; j++) {
          // let thisContentSection = tree["_pages_"][i]["content-sections"][j];
          let relPath = "_pages_::" + i + "::content-sections::" + j;

          if (undefined !== optionalExclusionByRelPath) {
            let exclusionPathParts = self.relToParts(
              optionalExclusionByRelPath
            );
            let relPathParts = self.relToParts(relPath);

            if (
              exclusionPathParts[1] === relPathParts[1] &&
              exclusionPathParts[3] === relPathParts[3]
            ) {
              // ignore these
            }
            // else {
            //   {
            //     dataToReturn.push({
            //       name: thisContentSection["friendly-name"],
            //       rel_path: relPath,
            //       parent: thisContentSection,
            //     });
            //   }
            // }
          }
          // else {
          //   dataToReturn.push({
          //     name: thisContentSection["friendly-name"],
          //     rel_path: relPath,
          //     parent: thisContentSection,
          //   });
          // }
        }
      }

      return dataToReturn;
    };

    /**
     * Returns the containers from the tree
     *
     * Allows for providing a path to exclude
     *
     * @param optionalExclusionByRelPath
     * @returns {Array}
     */
    self.getArrayOfContentTreeContainers = function (
      optionalExclusionByRelPath
    ) {
      let tree = self.contentService.getContentTree();

      let numberOfPages = tree["_pages_"].length;
      let dataToReturn = [];

      for (let i = 0; i < numberOfPages; i++) {
        let thisPage = tree["_pages_"][i];
        let numberOfContentSections = thisPage["content-sections"].length;

        for (let j = 0; j < numberOfContentSections; j++) {
          let thisContentSection = tree["_pages_"][i]["content-sections"][j];
          let numberOfContainers = thisContentSection["containers"].length;

          for (let k = 0; k < numberOfContainers; k++) {
            // let thisContainer =
            //   tree["_pages_"][i]["content-sections"][j]["containers"][k];
            let relPath =
              "_pages_::" +
              i +
              "::content-sections::" +
              j +
              "::containers::" +
              k;

            if (undefined !== optionalExclusionByRelPath) {
              let exclusionPathParts = self.relToParts(
                optionalExclusionByRelPath
              );
              let relPathParts = self.relToParts(relPath);

              if (
                exclusionPathParts[1] === relPathParts[1] &&
                exclusionPathParts[3] === relPathParts[3] &&
                exclusionPathParts[5] === relPathParts[5]
              ) {
                // ignore these
              }
              // else {
              //   {
              //     dataToReturn.push({
              //       name: thisContainer["friendly-name"],
              //       rel_path: relPath,
              //       parent: thisContainer,
              //     });
              //   }
              // }
            }
            // else {
            //   dataToReturn.push({
            //     name: thisContainer["friendly-name"],
            //     rel_path: relPath,
            //     parent: thisContainer,
            //   });
            // }
          }
        }
      }

      return dataToReturn;
    };

    /**
     * Methods
     */

    /**
     * @returns string
     */
    self.generateUuid = function () {
      return uuidHelper.newUuid();
    };

    /**
     * Create page using self.pageTemplate
     *
     * @returns {{}}
     */
    self.pageCreate = function () {
      let template = clone(self.pageTemplate);
      template.uuid = uuidHelper.newUuid();
      return template;
    };

    /**
     * Get the page based on supplied index
     *
     * @param pageIndex
     * @returns {{}}
     */
    self.pageRead = function (pageIndex) {
      var contentTree = self.contentService.getContentTree();
      return contentTree["_pages_"][pageIndex];
    };

    /**
     * Update the page based on supplied index and data
     *
     * @param pageIndex
     * @param pageData
     */
    self.pageUpdate = function (pageIndex, pageData) {
      var contentTree = self.contentService.getContentTree();
      contentTree["_pages_"][pageIndex] = pageData;
      self.contentService.updateContentTree(contentTree);
    };

    /**
     * Delete the page based on supplied index
     *
     * @param pageIndex
     */
    self.pageDelete = function (pageIndex) {
      var contentTree = self.contentService.getContentTree();
      contentTree["_pages_"].splice(pageIndex, 1);
      self.contentService.updateContentTree(contentTree);
    };

    /**
     * Create section using self.contentSectionTemplate
     *
     * @returns {{}}
     */
    self.contentSectionCreate = function () {
      let template = clone(self.contentSectionTemplate);
      template.uuid = uuidHelper.newUuid();
      return template;
    };

    /**
     * Get the section based on supplied indices
     *
     * @param pageIndex
     * @param sectionIndex
     * @returns {{}}
     */
    self.contentSectionRead = function (pageIndex, sectionIndex) {
      var contentTree = self.contentService.getContentTree();
      return contentTree["_pages_"][pageIndex]["content-sections"][
        sectionIndex
      ];
    };

    /**
     * Update the section based on supplied indices and data
     *
     * @param pageIndex
     * @param sectionIndex
     * @param sectionData
     */
    self.contentSectionUpdate = function (
      pageIndex,
      sectionIndex,
      sectionData
    ) {
      var contentTree = self.contentService.getContentTree();
      contentTree["_pages_"][pageIndex]["content-sections"][
        sectionIndex
      ] = sectionData;
      self.contentService.updateContentTree(contentTree);
    };

    /**
     * Delete the section based on supplied indices
     *
     * @param pageIndex
     * @param sectionIndex
     */
    self.contentSectionDelete = function (pageIndex, sectionIndex) {
      var contentTree = self.contentService.getContentTree();

      // delete the item from the content tree
      contentTree["_pages_"][pageIndex]["content-sections"].splice(
        sectionIndex,
        1
      );

      // guard against null values being left in the content tree
      contentTree["_pages_"][pageIndex]["content-sections"] = self.cleanArray(
        contentTree["_pages_"][pageIndex]["content-sections"],
        null
      );

      // commit the changes to the content tree
      self.contentService.updateContentTree(contentTree);
    };

    /**
     * Create container using self.containerTemplate
     *
     * @returns {{}}
     */
    self.containerCreate = function () {
      let template = clone(self.containerTemplate);
      template.uuid = uuidHelper.newUuid();
      return template;
    };

    /**
     * Get the container based on supplied indices
     *
     * @param pageIndex
     * @param sectionIndex
     * @param containerIndex
     * @returns {{}}
     */
    self.containerRead = function (pageIndex, sectionIndex, containerIndex) {
      var contentTree = self.contentService.getContentTree();
      return contentTree["_pages_"][pageIndex]["content-sections"][
        sectionIndex
      ]["containers"][containerIndex];
    };

    /**
     * Update the container based on supplied indices and data
     *
     * @param pageIndex
     * @param sectionIndex
     * @param containerIndex
     * @param containerData
     */
    self.containerUpdate = function (
      pageIndex,
      sectionIndex,
      containerIndex,
      containerData
    ) {
      var contentTree = self.contentService.getContentTree();
      contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
        "containers"
      ][containerIndex] = containerData;
      self.contentService.updateContentTree(contentTree);
    };

    /**
     * Delete the container based on supplied indices
     *
     * @param pageIndex
     * @param sectionIndex
     * @param containerIndex
     */
    self.containerDelete = function (pageIndex, sectionIndex, containerIndex) {
      var contentTree = self.contentService.getContentTree();

      // delete the item from the content tree
      contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
        "containers"
      ].splice(containerIndex, 1);

      // guard against null values being left in the content tree
      contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
        "containers"
      ] = self.cleanArray(
        contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
          "containers"
        ],
        null
      );

      // commit the changes to the content tree
      self.contentService.updateContentTree(contentTree);
    };

    /**
     * Create content using self.contentTemplate
     *
     * return {{}}
     */
    self.contentCreate = function () {
      let template = clone(self.contentTemplate);
      template.uuid = uuidHelper.newUuid();
      return template;
    };

    /**
     * Get the content based on supplied indices
     *
     * @param pageIndex
     * @param sectionIndex
     * @param containerIndex
     * @param contentIndex
     * @returns {{}}
     */
    self.contentRead = function (
      pageIndex,
      sectionIndex,
      containerIndex,
      contentIndex
    ) {
      var contentTree = self.contentService.getContentTree();
      return contentTree["_pages_"][pageIndex]["content-sections"][
        sectionIndex
      ]["containers"][containerIndex]["content"][contentIndex];
    };

    /**
     * Update the content based on supplied indices and data
     *
     * @param pageIndex
     * @param sectionIndex
     * @param containerIndex
     * @param contentIndex
     * @param contentData
     */
    self.contentUpdate = function (
      pageIndex,
      sectionIndex,
      containerIndex,
      contentIndex,
      contentData
    ) {
      var contentTree = self.contentService.getContentTree();
      contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
        "containers"
      ][containerIndex]["content"][contentIndex] = contentData;
      self.contentService.updateContentTree(contentTree);
    };

    /**
     * Delete the content based on supplied indices
     *
     * @param pageIndex
     * @param sectionIndex
     * @param containerIndex
     * @param contentIndex
     */
    self.contentDelete = function (
      pageIndex,
      sectionIndex,
      containerIndex,
      contentIndex
    ) {
      var contentTree = self.contentService.getContentTree();

      // delete the item from the content tree
      contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
        "containers"
      ][containerIndex]["content"].splice(contentIndex, 1);

      // guard against null values being left in the content tree
      contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
        "containers"
      ][containerIndex]["content"] = self.cleanArray(
        contentTree["_pages_"][pageIndex]["content-sections"][sectionIndex][
          "containers"
        ][containerIndex]["content"],
        null
      );

      // commit the changes to the content tree
      self.contentService.updateContentTree(contentTree);
    };

    /**
     * Initial setup
     */

    // return
    return self;
  },
};

export default ContentTree;
